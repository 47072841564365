import {
  FC,
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import centerIcon from '@assets/icons/center-flight.svg';
import minusIcon from '@assets/icons/minus.svg';
import plusIcon from '@assets/icons/plus.svg';
import { SCALE_STEP } from '@core/constants/viewers/imageViewer';
import { EImageViewerStyle } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import {
  setDefaultImageStyles,
  setDefaultImageViewerScale,
  updateImageViewerScale,
  updateImagesStyles,
  useImageViewerSelector,
  useSamplesSelector,
} from '@core/store/slices';
import { toPercents } from '@core/utils';
import { Control } from '@modules/Viewers/components/Controls/components/Control';
import { useImageViewerContext } from '@modules/Viewers/views/ImageViewer/contexts';
import {
  getFrameLimiter,
  getPositionData,
} from '@modules/Viewers/views/ImageViewer/utils/getFrameLimiter';
import { getMiniFrameSize } from '@modules/Viewers/views/ImageViewer/utils/viewers/miniView';
import { RgbMask, SegmentationMask } from '@modules/Viewers/views/ImageViewer/widgets/ImageMasks';
import styles from './styles.scss';

interface IProps {
  imagesContainer: MutableRefObject<HTMLDivElement | null>;
}

const initialFrameSize = {
  width: 100,
  height: 100,
};

export const MiniViewer: FC<IProps> = memo(({ imagesContainer }) => {
  const [frameSize, setFrameSize] = useState(initialFrameSize);
  const { selectedSample } = useSamplesSelector();
  const { scale, flags, styles: viewerStyles } = useImageViewerSelector();
  const { loadingState, onLoadImage } = useImageViewerContext();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatchTyped();

  const { rgb: isLoadedRGB, segmentation: isLoadedSegmentation } = loadingState;

  const handleZoomOut = useCallback(() => {
    let decreasedScale = _.round(scale.current - SCALE_STEP, 1);
    decreasedScale = decreasedScale < 1 ? 1 : decreasedScale;

    const frameLimiter = getFrameLimiter(decreasedScale);

    const containerPosition = getPositionData(frameLimiter, viewerStyles.container.current);
    const miniFramePosition = getPositionData(frameLimiter, viewerStyles.miniFrame.current);

    const payload = {
      [EImageViewerStyle.Container]: {
        current: containerPosition.position,
      },
      [EImageViewerStyle.MiniFrame]: {
        current: miniFramePosition.position,
      },
    };

    if (imagesContainer.current) {
      const imagesContainerFrameRect = imagesContainer.current.getBoundingClientRect();
      const { left: containerLeft, top: containerTop } = containerPosition.position;

      const currentMiniFrameSize = getMiniFrameSize(scale.current, imagesContainerFrameRect);
      const updatedMiniFrameSize = getMiniFrameSize(decreasedScale, imagesContainerFrameRect);

      const xContainerScaleStepInPercent = (containerLeft * SCALE_STEP) / scale.current;
      const yContainerScaleStepInPercent = (containerTop * SCALE_STEP) / scale.current;

      const deltaY = updatedMiniFrameSize.height - currentMiniFrameSize.height;
      const deltaX = updatedMiniFrameSize.width - currentMiniFrameSize.width;

      const currentContainerTop = containerTop - yContainerScaleStepInPercent;
      const currentContainerLeft = containerLeft - xContainerScaleStepInPercent;

      const currentMiniFrameTop =
        decreasedScale <= 1 ? 0 : miniFramePosition.position.top - deltaY / 2;
      const currentMiniFrameLeft =
        decreasedScale <= 1 ? 0 : miniFramePosition.position.left - deltaX / 2;

      payload[EImageViewerStyle.Container].current = {
        top: currentContainerTop,
        left: currentContainerLeft,
      };

      payload[EImageViewerStyle.MiniFrame].current = {
        top: currentMiniFrameTop,
        left: currentMiniFrameLeft,
      };
    }

    dispatch(updateImageViewerScale({ current: decreasedScale }));
    dispatch(updateImagesStyles(payload));
  }, [dispatch, scale.current, viewerStyles.container.current, viewerStyles.miniFrame.current]);

  const handleZoomIn = useCallback(() => {
    const increasedScale = _.round(scale.current + SCALE_STEP, 1);

    const frameLimiter = getFrameLimiter(increasedScale);
    const containerPosition = getPositionData(frameLimiter, viewerStyles.container.current);
    const miniFramePosition = getPositionData(frameLimiter, viewerStyles.miniFrame.current);

    const payload = {
      [EImageViewerStyle.Container]: {
        current: containerPosition.position,
      },
      [EImageViewerStyle.MiniFrame]: {
        current: miniFramePosition.position,
      },
    };

    if (imagesContainer.current) {
      const imagesContainerFrameRect = imagesContainer.current.getBoundingClientRect();
      const { left: containerLeft, top: containerTop } = containerPosition.position;

      const currentMiniFrameSize = getMiniFrameSize(scale.current, imagesContainerFrameRect);
      const updatedMiniFrameSize = getMiniFrameSize(increasedScale, imagesContainerFrameRect);

      const xContainerScaleStepInPercent = (containerLeft * SCALE_STEP) / scale.current;
      const yContainerScaleStepInPercent = (containerTop * SCALE_STEP) / scale.current;

      const deltaY = currentMiniFrameSize.height - updatedMiniFrameSize.height;
      const deltaX = currentMiniFrameSize.width - updatedMiniFrameSize.width;

      const currentContainerTop = containerTop + yContainerScaleStepInPercent;
      const currentContainerLeft = containerLeft + xContainerScaleStepInPercent;

      const currentMiniFrameTop = miniFramePosition.position.top + deltaY / 2;
      const currentMiniFrameLeft = miniFramePosition.position.left + deltaX / 2;

      payload[EImageViewerStyle.Container].current = {
        top: currentContainerTop,
        left: currentContainerLeft,
      };

      payload[EImageViewerStyle.MiniFrame].current = {
        top: currentMiniFrameTop,
        left: currentMiniFrameLeft,
      };
    }

    dispatch(updateImageViewerScale({ current: increasedScale }));
    dispatch(updateImagesStyles(payload));
  }, [dispatch, scale.current, viewerStyles.container.current, viewerStyles.miniFrame.current]);

  const handleSetDefaultPosition = useCallback(() => {
    dispatch(setDefaultImageViewerScale());
    dispatch(setDefaultImageStyles());
  }, [dispatch]);

  const updateFrameSize = useCallback((scale: number, imagesContainer: HTMLDivElement) => {
    const imageFrameRect = imagesContainer.getBoundingClientRect();
    const frameSize = getMiniFrameSize(scale, imageFrameRect);

    setFrameSize(frameSize);
  }, []);

  const isEnabledSegmentationMask = flags.segmentationMask && selectedSample?.segmentation_mask;

  const miniViewerStyles = useMemo(() => {
    const properties: React.CSSProperties = {};
    const { top, left } = viewerStyles.miniFrame.current;

    if (left) {
      properties.left = toPercents(left);
    }

    if (top) {
      properties.top = toPercents(top);
    }

    return properties;
  }, [viewerStyles.miniFrame.current]);

  const frameSizeStyles = useMemo(() => {
    const properties: React.CSSProperties = {};
    const { width, height } = frameSize;

    if (height) {
      properties.height = toPercents(height);
    }

    if (width) {
      properties.width = toPercents(width);
    }

    return properties;
  }, [scale.current, frameSize.width, frameSize.height]);

  // NOTE: update mini frame size based on changed scale
  useEffect(() => {
    if (scale.current && imagesContainer.current) {
      updateFrameSize(scale.current, imagesContainer.current);
    }
  }, [scale.current]);

  return (
    <div className={styles.miniView}>
      <div className={styles.controls}>
        <Control
          className={styles.control}
          icon={plusIcon}
          active
          disabled={false}
          onClick={handleZoomIn}
        />
        <Control
          className={styles.control}
          icon={centerIcon}
          active
          disabled={false}
          onClick={handleSetDefaultPosition}
        />
        <Control
          className={styles.control}
          icon={minusIcon}
          active
          disabled={false}
          onClick={handleZoomOut}
        />
      </div>
      <div className={styles.images} ref={containerRef}>
        <div
          style={{
            ...miniViewerStyles,
            ...frameSizeStyles,
          }}
          className={styles.capturedAreaFrame}
        />
        <RgbMask sample={selectedSample} isLoaded={isLoadedRGB} onLoad={onLoadImage} />
        {isEnabledSegmentationMask && (
          <SegmentationMask
            sample={selectedSample}
            isLoaded={isLoadedSegmentation}
            onLoad={onLoadImage}
          />
        )}
      </div>
    </div>
  );
});
