import { RootState } from '..';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ECookiesField } from '@core/constants';
import { ESidebar } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';
import { cookiesStorage } from '@core/services';
import { setCurrentAnomalyId } from './anomalies';
import { resetControls, setFlightPath, setSolarPanels, updateCompareMode } from './controls';
import { resetImageViewer } from './imageViewer';
import { setCurrentInspectionIdBySiteId, setInspectionAndReportToCompareIds } from './inspections';
import { reloadMap } from './map';
import { setCurrentProgramId } from './programs';
import { setCurrentReportId, setCurrentReportIdByCurrentInspectionId } from './reports';
import { resetAnomalySamples, resetCurrentReportSamples } from './samples';
import { setCurrentSiteId, setCurrentSiteIdAndUpdatePrograms } from './sites';
import { isZoomInBySidebar } from '../utils/sidebar/zoom';

interface IInitialState {
  sidebar: ESidebar;
  sidebarPrevious: ESidebar | null;
  loading: boolean;
}

const initialState: IInitialState = {
  sidebar: ESidebar.Sites,
  sidebarPrevious: null,
  loading: false,
};

const setSidebar = createAsyncThunk(
  'sidebar/setSidebar',
  (sidebar: ESidebar, { dispatch, getState }) => {
    let state = getState() as RootState;
    const sidebarCurrent = state.sidebar.sidebar;

    dispatch(startDataProcessing());

    if (isZoomInBySidebar(sidebar, sidebarCurrent)) {
      const currentSiteId = state.sites.currentSiteId;
      const programsForCurrentLocation = state.programs.programs.filter(
        (program) => program.location_id === currentSiteId,
      );
      const isProgramSingle = programsForCurrentLocation.length === 1;
      const isSelectedSiteWithSingleProgram = currentSiteId && isProgramSingle;

      // NOTE: Handle skipping from "Site" to "Zone" when there is single site with single zone on the map
      if (
        isSelectedSiteWithSingleProgram &&
        [ESidebar.Sites, ESidebar.Site, ESidebar.Zone].includes(sidebar)
      ) {
        dispatch(setCurrentProgramId(programsForCurrentLocation[0].program_id));
        dispatch(setCurrentSiteIdAndUpdatePrograms(currentSiteId));
        sidebar = ESidebar.Zone;
      }
    }

    switch (sidebar) {
      case ESidebar.Sites:
        dispatch(setCurrentSiteId(null));
        dispatch(setCurrentProgramId(null));
        dispatch(setSolarPanels(false));
        dispatch(setFlightPath(false));
        break;
      case ESidebar.Site:
        dispatch(resetControls());
        dispatch(setSolarPanels(false));
        dispatch(setFlightPath(false));
        // NOTE: update state due to a large number of asynchronous operations
        // Sometimes this results in inconsistent data
        state = getState() as RootState;
        if (state.sites.currentSiteId) {
          dispatch(
            setCurrentInspectionIdBySiteId({
              siteId: state.sites.currentSiteId,
              options: { withReport: true },
            }),
          );
        }
        break;
      case ESidebar.Zone:
        dispatch(resetAnomalySamples());
        dispatch(setCurrentAnomalyId(null));
        dispatch(setSolarPanels(true));
        dispatch(setFlightPath(false));

        state = getState() as RootState;

        if (state.inspections.currentInspectionId) {
          dispatch(setCurrentReportIdByCurrentInspectionId(state.inspections.currentInspectionId));
        }
        break;
      case ESidebar.Anomaly:
        dispatch(setSolarPanels(true));
        dispatch(setFlightPath(true));
        break;
    }

    if (sidebar !== ESidebar.Anomaly) {
      dispatch(resetImageViewer());
    }

    if (![ESidebar.Zone, ESidebar.Anomaly, ESidebar.Site].includes(sidebar)) {
      dispatch(setCurrentReportId(null));
      dispatch(resetCurrentReportSamples());
    }

    state = getState() as RootState;

    if (state.inspections.inspectionToCompareId && sidebar !== ESidebar.Zone) {
      dispatch(setInspectionAndReportToCompareIds(null));
      dispatch(updateCompareMode(false));
    }

    // NOTE: current available sidebar modes to persist and restore after refresh page
    const availableSidebarsToPersist = [ESidebar.Zone, ESidebar.CreateSite];

    if (!availableSidebarsToPersist.includes(sidebar)) {
      cookiesStorage.removeItem(ECookiesField.StoragePersistPermission);
    }

    dispatch(updateSidebar(sidebar));
    dispatch(finishDataProcessing());
    dispatch(reloadMap(true));
  },
);

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    startDataProcessing: (state) => {
      state.loading = true;
    },
    finishDataProcessing: (state) => {
      state.loading = true;
    },
    updateSidebar: (state, actions: PayloadAction<ESidebar>) => {
      state.sidebarPrevious = state.sidebar;
      state.sidebar = actions.payload;
    },
    resetSidebar: () => initialState,
  },
});

const sidebarReducer = sidebarSlice.reducer;

const { startDataProcessing, finishDataProcessing, updateSidebar, resetSidebar } =
  sidebarSlice.actions;

const useSidebarSelector = () => useSelectorTyped((state) => state.sidebar);

export {
  setSidebar,
  updateSidebar,
  resetSidebar,
  sidebarReducer,
  useSidebarSelector,
  initialState as sidebarInitialState,
};
