import { EAnomalyMode } from '@core/enums/anomalies';
import { ISite } from '@core/interfaces';
import { IAnomaly } from '@core/interfaces/anomaly';
import {
  getDisconnectedCostByReportAnomaly,
  getMixedCostByReportAnomaly,
  getThermalCostByReportAnomaly,
  getVisualCostByReportAnomaly,
} from '../formulas/anomaliesCosts';

export function getAnomalyCosts(anomaly: IAnomaly, site: ISite) {
  const {
    thermal_surface: thermalSurface,
    visual_surface: visualSurface,
    disconnected_panels: disconnectedPanels,
  } = anomaly;

  const {
    thermal_factor: thermalFactor,
    rgb_factor: rgbFactor,
    cost_per_defective_panel: costPerDefectivePanel,
  } = site;

  let costs = 0;

  switch (anomaly.mode) {
    case EAnomalyMode.Visual: {
      if (rgbFactor && costPerDefectivePanel) {
        costs = getVisualCostByReportAnomaly({
          visualSurface: visualSurface as number,
          rgbFactor,
          costPerDefectivePanel,
        });
      }
      break;
    }
    case EAnomalyMode.Thermal: {
      if (thermalFactor && costPerDefectivePanel) {
        costs = getThermalCostByReportAnomaly({
          thermalSurface: thermalSurface as number,
          thermalFactor,
          costPerDefectivePanel,
        });
      }
      break;
    }
    case EAnomalyMode.Mixed: {
      if (rgbFactor && thermalFactor && costPerDefectivePanel) {
        costs = getMixedCostByReportAnomaly({
          visualSurface: visualSurface as number,
          thermalSurface: thermalSurface as number,
          rgbFactor,
          thermalFactor,
          costPerDefectivePanel,
        });
      }
      break;
    }
    case EAnomalyMode.String: {
      if (costPerDefectivePanel) {
        costs = getDisconnectedCostByReportAnomaly({
          disconnectedPanels: disconnectedPanels as number,
          costPerDefectivePanel,
        });
      }
      break;
    }
  }

  return costs;
}
