import { MutableRefObject, createContext, createRef, useContext } from 'react';
import { EAspectRatio } from '@core/enums';
import { ELoadingState } from '../enums/loading';

export type TImageViewerLoadingState = {
  [key in ELoadingState]: boolean;
};

interface IImageViewerContextValue {
  mainContainer: MutableRefObject<HTMLDivElement | null>;
  loadingState: TImageViewerLoadingState;
  rgbAspectRatio: EAspectRatio;
  onLoadImage: (imageType: keyof TImageViewerLoadingState) => void;
}

const initialState: IImageViewerContextValue = {
  mainContainer: createRef(),
  loadingState: {
    [ELoadingState.RGB]: true,
    [ELoadingState.Segmentation]: true,
    [ELoadingState.Thermal]: true,
    [ELoadingState.Anomaly]: true,
  },
  onLoadImage: () => void 0,
  rgbAspectRatio: EAspectRatio.DJIDrone,
};

export const ImageViewerContext = createContext<IImageViewerContextValue>(initialState);

export const useImageViewerContext = (): IImageViewerContextValue => {
  const context = useContext(ImageViewerContext);

  if (!context) {
    throw new Error('"useImageViewerContext" must be used within an "ImageViewerProvider"');
  }

  return context;
};
