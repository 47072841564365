import React, { ButtonHTMLAttributes, memo, useMemo } from 'react';
import cn from 'classnames';
import styles from './styles.scss';

export enum EButtonColor {
  Blue = 'blue',
  Yellow = 'yellow',
  Red = 'red',
  Green = 'green',
  Grey = 'grey',
}

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: EButtonColor;
}

export const Button: React.FC<IProps> = memo(
  ({ children, color = 'blue', className, ...props }) => {
    const buttonClasses = useMemo(() => {
      const classes = [
        styles.button,
        'text-sm h-[45px] w-full rounded-[3px] disabled:hover:opacity-100',
      ];

      switch (color) {
        case EButtonColor.Blue:
          classes.push('bg-outflier-blue text-[#FDFDFD]');
          break;
        case EButtonColor.Yellow:
          classes.push('bg-outflier-yellow text-[#FDFDFD]');
          break;
        case EButtonColor.Red:
          classes.push('bg-outflier-red hover:!bg-outflier-red hover:!opacity-70 text-[#FDFDFD]');
          break;
        case EButtonColor.Green:
          classes.push(
            'bg-outflier-green hover:!bg-outflier-green hover:!opacity-70 text-[#FDFDFD]',
          );
          break;
        case EButtonColor.Grey:
          classes.push(
            'bg-outflier-soft-black hover:!bg-outflier-soft-black hover:!opacity-70 text-[#FDFDFD]',
          );
          break;
      }

      classes.push(className);

      return cn(classes.join(' ').trim());
    }, [color]);

    return (
      <button className={buttonClasses} {...props}>
        {children}
      </button>
    );
  },
);
