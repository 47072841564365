import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EViewer } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';

interface IInitialState {
  viewer: EViewer;
  sidebarViewer: EViewer;
}

const initialState: IInitialState = {
  viewer: EViewer.Map,
  sidebarViewer: EViewer.Video,
};

const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    toggleViewers: (state) => {
      const viewer = state.viewer;
      const sidebarViewer = state.sidebarViewer;
      state.viewer = sidebarViewer;
      state.sidebarViewer = viewer;
    },
    setViewer: (state, action: PayloadAction<EViewer>) => {
      state.viewer = action.payload;
    },
    setSidebarViewer: (state, action: PayloadAction<EViewer>) => {
      state.sidebarViewer = action.payload;
    },
    resetViewer: () => initialState,
  },
});

const viewerReducer = viewerSlice.reducer;

const { setViewer, setSidebarViewer, toggleViewers, resetViewer } = viewerSlice.actions;

const useViewerSelector = () => useSelectorTyped((state) => state.viewer);

export {
  setViewer,
  setSidebarViewer,
  resetViewer,
  toggleViewers,
  viewerReducer,
  useViewerSelector,
  initialState as viewerInitialState,
};
