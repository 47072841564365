import { t } from 'i18next';
import delayedIcon from '@assets/icons/anomalies/tile_delayed.svg';
import repairedIcon from '@assets/icons/anomalies/tile_repaired.svg';
import toRepairIcon from '@assets/icons/anomalies/tile_to-repair.svg';
import { EAnomalyMode, EAnomalyRepairmentStatus, EAnomalyStatus } from '@core/enums/anomalies';
import { EAnomalyTiles } from '../enums';
import { IAnomalyTileItem } from '../interfaces';

const documentStyle = getComputedStyle(document.documentElement);

type IAnomalyTiles = {
  [key in EAnomalyTiles]: {
    title: string;
    data: IAnomalyTileItem<any>[];
  };
};

export const ANOMALY_TILES: IAnomalyTiles = {
  [EAnomalyTiles.Repair]: {
    title: t('sidebar.widgets.charts.repair.title'),
    data: [
      {
        key: EAnomalyRepairmentStatus.ToRepair,
        title: t('sidebar.widgets.charts.repair.tiles.to_repair'),
        icon: toRepairIcon,
        style: {
          backgroundColor: documentStyle.getPropertyValue('--red'),
        },
      },
      {
        key: EAnomalyRepairmentStatus.Delayed,
        title: t('sidebar.widgets.charts.repair.tiles.delayed'),
        icon: delayedIcon,
        style: {
          backgroundColor: documentStyle.getPropertyValue('--white'),
        },
      },
      {
        key: EAnomalyRepairmentStatus.Repaired,
        title: t('sidebar.widgets.charts.repair.tiles.repaired'),
        icon: repairedIcon,
        style: {
          backgroundColor: documentStyle.getPropertyValue('--green'),
        },
      },
    ],
  },
  [EAnomalyTiles.Status]: {
    title: t('sidebar.status'),
    data: [
      {
        key: EAnomalyStatus.False,
        title: t('sidebar.widgets.charts.severity.tiles.false'),
        icon: '',
        style: {
          backgroundColor: documentStyle.getPropertyValue('--outflier-blue-grey'),
        },
      },
      {
        key: EAnomalyStatus.Minor,
        title: t('sidebar.widgets.charts.severity.tiles.minor'),
        icon: '',
        style: {
          backgroundColor: documentStyle.getPropertyValue('--white'),
        },
      },
      {
        key: EAnomalyStatus.ToReview,
        title: t('sidebar.widgets.charts.severity.tiles.to_review'),
        icon: '',
        style: {
          backgroundColor: documentStyle.getPropertyValue('--outflier-yellow'),
        },
      },
      {
        key: EAnomalyStatus.Major,
        title: t('sidebar.widgets.charts.severity.tiles.major'),
        icon: '',
        style: {
          backgroundColor: documentStyle.getPropertyValue('--red'),
        },
      },
    ],
  },
  [EAnomalyTiles.Type]: {
    title: t('sidebar.widgets.charts.type.title'),
    data: [
      {
        key: EAnomalyMode.Thermal,
        title: t('sidebar.widgets.charts.type.tiles.thermal'),
        icon: '',
        style: {
          backgroundColor: documentStyle.getPropertyValue('--outflier-red-light-shade'),
        },
      },
      {
        key: EAnomalyMode.String,
        title: t('sidebar.widgets.charts.type.tiles.string'),
        icon: '',
        style: {
          backgroundColor: documentStyle.getPropertyValue('--outflier-yellow-light-shade'),
        },
      },
      {
        key: EAnomalyMode.Visual,
        title: t('sidebar.widgets.charts.type.tiles.rgb'),
        icon: '',
        style: {
          backgroundColor: documentStyle.getPropertyValue('--outflier-blue-cyan'),
        },
      },
      {
        key: EAnomalyMode.Mixed,
        title: t('sidebar.widgets.charts.type.tiles.multimodal'),
        icon: '',
        style: {
          backgroundColor: documentStyle.getPropertyValue('--outflier-blue-sky'),
        },
      },
    ],
  },
};
