import { FC, memo, useMemo } from 'react';
import { ISolarPanelsWithIncidentAnomalies } from '@core/api/report/solarPanels';
import { stackedBarChartStylesData } from '@modules/Sidebar/widgets/SolarPanels/config/styles';
import {
  aggregateDefectivePanelsByAnomalyTypeAndSubtype,
  prepareSolarPanelsData,
} from '@modules/Sidebar/widgets/SolarPanels/utils';
import { StackedBarChart } from './StackedChart';

interface IProps {
  solarPanels: ISolarPanelsWithIncidentAnomalies[];
}

export const SolarPanelsChart: FC<IProps> = memo(({ solarPanels }) => {
  const aggregatedData = useMemo(
    () => aggregateDefectivePanelsByAnomalyTypeAndSubtype(solarPanels),
    [solarPanels],
  );

  const chartData = useMemo(
    () => [prepareSolarPanelsData({ aggregatedAnomaliesData: aggregatedData })],
    [aggregatedData],
  );

  return <StackedBarChart chartData={chartData} stylesData={stackedBarChartStylesData} />;
});
