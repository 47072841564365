interface IOptions {
  x: number;
  y: number;
  color: string;
  losses: number;
  costRatio: string;
}

const BAR_LOSSES_VALUE_OFFSET = 15;
const BAR_COST_RATIO_OFFSET = 40;

export const getGroupText = (svg, options: IOptions) => {
  const { x, y, color, losses, costRatio } = options;

  const xNumericPosition = x - BAR_LOSSES_VALUE_OFFSET;
  const yNumericPosition = y;
  const xCostRatioPosition = x + BAR_COST_RATIO_OFFSET;
  const yCostRatioPosition = y;

  svg
    .append('text')
    .classed('bar-title-amount', true)
    .attr('text-anchor', 'middle')
    .attr('fill', color)
    .attr('x', xNumericPosition)
    .attr('y', yNumericPosition)
    .style('font-size', '28px')
    .style('font-weight', '500')
    .style('font-family', 'Archivo, sans-serif')
    .text(losses);

  svg
    .append('text')
    .classed('bar-title-costRatio', true)
    .attr('text-anchor', 'middle')
    .attr('fill', color)
    .attr('x', xCostRatioPosition)
    .attr('y', yCostRatioPosition)
    .style('font-size', '12px')
    .style('font-weight', '500')
    .style('font-family', 'Archivo, sans-serif')
    .text(costRatio);
};
