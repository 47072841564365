import _ from 'lodash';
import { ISubgroup } from '@modules/Sidebar/interfaces';

export const getMaxValueFromGroups = (groupsSubgroups: ISubgroup[][]) => {
  const sumValuesPerGroup = groupsSubgroups.map((group) =>
    _.sum(group.map((subgroup) => subgroup.value)),
  );
  return Math.max(...sumValuesPerGroup);
};

export const getMaxYAxisValue = (value: number) => {
  const precision = String(Math.ceil(value)).length - 1;
  return _.ceil(value, -precision);
};
