import { axiosInstance } from '@core/api/axios';
import { IAnomaly } from '@core/interfaces/anomaly';

export const getReportAnomaliesEstimatedLoss = async (
  reportId: number | string,
): Promise<IAnomaly[]> => {
  return axiosInstance
    .get<IAnomaly[]>(`/reports/${reportId}/anomalies/estimated_loss`)
    .then((res) => res.data);
};
