interface IPopoverPositionParams {
  parentHeight: number;
  parentTop: number;
  popoverHeight: number;
  correctionValue: number;
}

export const getPopoverYPosition = ({
  parentHeight,
  parentTop,
  popoverHeight,
  correctionValue,
}: IPopoverPositionParams) => {
  return parentTop - correctionValue - popoverHeight / 2 + parentHeight / 2;
};
