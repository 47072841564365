import { EFlightPlansSection, EInspectionZone, EOverviewZone } from '../enums';

export const selectedZoneOptionsConfig = {
  [EFlightPlansSection.Overview]: [
    {
      name: 'Option 1',
      value: EOverviewZone.Option1,
    },
    {
      name: 'Request custom plan',
      value: EOverviewZone.RequestCustomPlan,
    },
  ],
  [EFlightPlansSection.Inspection]: [
    {
      name: 'Option 1',
      value: EInspectionZone.Option1,
    },
    {
      name: 'Request custom plan',
      value: EInspectionZone.RequestCustomPlan,
    },
  ],
};
