import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { EAnomalyMode } from '@core/enums/anomalies';
import { AnomalyDetailsContext } from './contexts';
import styles from './styles.scss';
import { ThermalView, MixedView, StringsView, VisualView } from './views';

interface IProps {
  className?: string;
  scrollTop?: number;
  type: EAnomalyMode;
  isEditable: boolean;
  isDisabled?: boolean;
}

const views = {
  [EAnomalyMode.Thermal]: () => <ThermalView />,
  [EAnomalyMode.String]: () => <StringsView />,
  [EAnomalyMode.Visual]: () => <VisualView />,
  [EAnomalyMode.Mixed]: () => <MixedView />,
};

export const AnomalyDetails: FC<IProps> = memo(
  ({ isEditable, isDisabled = false, type, className, scrollTop }) => {
    const { t } = useTranslation();

    const contextValue = useMemo(
      () => ({ scrollTop, isEditable, isDisabled }),
      [scrollTop, isEditable, isDisabled],
    );

    return (
      <div className={cn(styles.root, className)}>
        <h2 className={styles.title}>{t('sidebar.anomaly.widgets.details.title')}</h2>
        <AnomalyDetailsContext.Provider value={contextValue}>
          {views[type]()}
        </AnomalyDetailsContext.Provider>
      </div>
    );
  },
);
