import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DEFAULT_NUMERIC_VALUE, MISSING_VALUE } from '@core/constants';
import { EControlStepCounterAction } from '@core/enums';
import { IAnomaly } from '@core/interfaces/anomaly';
import { useCurrentAnomalySelector } from '@core/store/slices';
import { toPixels } from '@core/utils';
import { HEADER_HEIGHT } from '@modules/Layout/constants';
import { getPopoverYPosition } from '@modules/Sidebar/utils/charts';
import { StepperPopover } from '@modules/Sidebar/widgets/AnomalyDetails/components/Popovers';
import {
  AverageTemperatureTile,
  DetailsTile,
  SubtypeTile,
  TemperatureDifferenceTile,
} from '@modules/Sidebar/widgets/AnomalyDetails/components/Tiles';
import { PanelsAffectedTile } from '@modules/Sidebar/widgets/AnomalyDetails/components/Tiles/PanelsAffected';
import { STRING_INITIAL_POPOVERS_STYLES } from '@modules/Sidebar/widgets/AnomalyDetails/config';
import {
  PANELS_AFFECTED_POPOVER_HEIGHT,
  TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
} from '@modules/Sidebar/widgets/AnomalyDetails/constants';
import { useAnomalyDetailsContext } from '@modules/Sidebar/widgets/AnomalyDetails/contexts';
import {
  EAnomalyDetailsPopovers,
  EAnomalyDetailsUpdateFields,
} from '@modules/Sidebar/widgets/AnomalyDetails/enums';
import { useUpdateAnomaly } from '@modules/Sidebar/widgets/AnomalyDetails/hooks';
import { IUpdatePopoverTopPositionParams } from '@modules/Sidebar/widgets/AnomalyDetails/interfaces';
import {
  formatAverageTemperature,
  formatTemperatureDifference,
  getRoundedTemperature,
} from '@modules/Sidebar/widgets/AnomalyDetails/utils';
import styles from '../../styles.scss';

export const StringsView = () => {
  const { t } = useTranslation();
  const detailsContext = useAnomalyDetailsContext();
  const currentAnomaly = useCurrentAnomalySelector();

  const [isOpenedPanelsAffectedModal, setIsOpenedPanelsAffectedModal] = useState(false);
  const [isOpenedTemperatureDifferenceModal, setIsOpenedTemperatureDifferenceModal] =
    useState(false);

  const [panelsAffectedValue, setPanelsAffectedValue] = useState<number>(
    currentAnomaly?.disconnected_panels ?? DEFAULT_NUMERIC_VALUE,
  );
  const [temperatureDifferenceValue, setTemperatureDifferenceValue] = useState<number>(
    currentAnomaly?.temperature_differential ?? DEFAULT_NUMERIC_VALUE,
  );
  const [popoverStyles, setPopoverStyles] = useState(STRING_INITIAL_POPOVERS_STYLES);

  const panelsAffectedRef = useRef<HTMLDivElement | null>(null);
  const temperatureDiffRef = useRef<HTMLDivElement | null>(null);

  useUpdateAnomaly({
    isAllowed: !isOpenedPanelsAffectedModal,
    anomaly: currentAnomaly,
    key: EAnomalyDetailsUpdateFields.DisconnectedPanels,
    editableValue: panelsAffectedValue,
  });
  useUpdateAnomaly({
    isAllowed: !isOpenedTemperatureDifferenceModal,
    anomaly: currentAnomaly,
    key: EAnomalyDetailsUpdateFields.TemperatureDifferential,
    editableValue: temperatureDifferenceValue,
  });

  const resetStringViewState = useCallback((anomaly: IAnomaly) => {
    setIsOpenedPanelsAffectedModal(false);
    setIsOpenedTemperatureDifferenceModal(false);

    setPopoverStyles(STRING_INITIAL_POPOVERS_STYLES);

    setPanelsAffectedValue(anomaly.disconnected_panels ?? DEFAULT_NUMERIC_VALUE);
    setTemperatureDifferenceValue(anomaly.temperature_differential ?? DEFAULT_NUMERIC_VALUE);
  }, []);

  const handleUpdatePanelsAffectedValue = useCallback(
    (counterAction: EControlStepCounterAction) => {
      setPanelsAffectedValue((value) =>
        counterAction === EControlStepCounterAction.Decrease ? value - 1 : value + 1,
      );
    },
    [],
  );

  const handleTemperatureDifferenceValue = useCallback(
    (counterAction: EControlStepCounterAction) => {
      setTemperatureDifferenceValue((value) => {
        const roundedValue = _.floor(value);
        return counterAction === EControlStepCounterAction.Decrease
          ? roundedValue - 1
          : roundedValue + 1;
      });
    },
    [],
  );

  const handleShowPanelsAffectedModal = useCallback(() => setIsOpenedPanelsAffectedModal(true), []);
  const handleHidePanelsAffectedModal = useCallback(
    () => setIsOpenedPanelsAffectedModal(false),
    [],
  );

  const handleShowTemperatureDifferenceModal = useCallback(
    () => setIsOpenedTemperatureDifferenceModal(true),
    [],
  );
  const handleHideTemperatureDifferenceModal = useCallback(
    () => setIsOpenedTemperatureDifferenceModal(false),
    [],
  );

  const updatePopoverTopPosition = useCallback(
    ({ ref, key, popoverHeight }: IUpdatePopoverTopPositionParams) => {
      if (ref.current) {
        const { height, top } = ref.current.getBoundingClientRect();
        const popoverTopPosition = getPopoverYPosition({
          parentHeight: height,
          parentTop: top,
          popoverHeight,
          correctionValue: HEADER_HEIGHT,
        });

        setPopoverStyles((styles) => ({
          ...styles,
          [key]: {
            ...styles[key],
            top: toPixels(popoverTopPosition),
          },
        }));
      }
    },
    [],
  );

  useEffect(() => {
    if (isOpenedPanelsAffectedModal) {
      updatePopoverTopPosition({
        ref: panelsAffectedRef,
        key: EAnomalyDetailsPopovers.PanelsAffected,
        popoverHeight: PANELS_AFFECTED_POPOVER_HEIGHT,
      });
    } else if (isOpenedTemperatureDifferenceModal) {
      updatePopoverTopPosition({
        ref: temperatureDiffRef,
        key: EAnomalyDetailsPopovers.TemperatureDifference,
        popoverHeight: TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
      });
    }
  }, [detailsContext.scrollTop, isOpenedPanelsAffectedModal, isOpenedTemperatureDifferenceModal]);

  useEffect(() => {
    if (currentAnomaly?.id) {
      resetStringViewState(currentAnomaly);
    }
  }, [currentAnomaly?.id]);

  const subtypeTitle = t('sidebar.anomaly.widgets.details.tiles.subtype.title');
  const averageTypeTitle = t('sidebar.anomaly.widgets.details.tiles.averageTemperature.title');
  const panelsAffectedTitle = t('sidebar.anomaly.widgets.details.tiles.panelsAffected.title');
  const temperatureDifferenceTitle = t(
    'sidebar.anomaly.widgets.details.tiles.temperatureDifference.title',
  );

  const formattedAverageTemperatureValue = useMemo(
    () =>
      _.flowRight(
        formatAverageTemperature,
        getRoundedTemperature,
      )(currentAnomaly?.mean_temperature),
    [currentAnomaly?.mean_temperature],
  );

  const formattedTemperatureDifferenceValue = useMemo(
    () =>
      _.flowRight(formatTemperatureDifference, getRoundedTemperature)(temperatureDifferenceValue),
    [temperatureDifferenceValue],
  );

  return (
    <ul className={styles.tiles}>
      <li className={styles.row}>
        <DetailsTile title={subtypeTitle}>
          <SubtypeTile isEditable={false} isDisabled={false} value={MISSING_VALUE} />
        </DetailsTile>
        <DetailsTile className={styles.avgTemp} title={averageTypeTitle}>
          <AverageTemperatureTile
            value={formattedAverageTemperatureValue}
            className={styles.avgTemp}
          />
        </DetailsTile>
      </li>
      <li className={styles.row}>
        <DetailsTile title={panelsAffectedTitle} ref={panelsAffectedRef}>
          <div className={styles.details}>
            <StepperPopover
              classNames={{
                popover: styles.panelsAffectedPopover,
                value: styles.panelsAffectedValue,
              }}
              value={panelsAffectedValue.toString()}
              style={popoverStyles[EAnomalyDetailsPopovers.PanelsAffected]}
              title={panelsAffectedTitle}
              isOpen={isOpenedPanelsAffectedModal}
              onClose={handleHidePanelsAffectedModal}
              onUpdate={handleUpdatePanelsAffectedValue}
            />
            <PanelsAffectedTile
              isEditable={detailsContext.isEditable}
              isDisabled={detailsContext.isDisabled}
              value={panelsAffectedValue}
              onClick={handleShowPanelsAffectedModal}
            />
          </div>
        </DetailsTile>
        <DetailsTile title={temperatureDifferenceTitle} ref={temperatureDiffRef}>
          <div className={styles.details}>
            <StepperPopover
              classNames={{
                popover: styles.temperatureDifferencePopover,
                value: styles.temperatureDifferenceValue,
              }}
              value={formattedTemperatureDifferenceValue}
              style={popoverStyles[EAnomalyDetailsPopovers.TemperatureDifference]}
              title={temperatureDifferenceTitle}
              isOpen={isOpenedTemperatureDifferenceModal}
              onClose={handleHideTemperatureDifferenceModal}
              onUpdate={handleTemperatureDifferenceValue}
            />
            <TemperatureDifferenceTile
              isEditable={detailsContext.isEditable}
              isDisabled={detailsContext.isDisabled}
              value={formattedTemperatureDifferenceValue}
              onClick={handleShowTemperatureDifferenceModal}
            />
          </div>
        </DetailsTile>
      </li>
    </ul>
  );
};
