import { FC, memo, useMemo } from 'react';
import cn from 'classnames';
import { t } from 'i18next';
import { anomaliesChartsColor } from '@core/constants';
import { EAnomalyMode } from '@core/enums/anomalies';
import { useCurrentReportSelector } from '@core/store/slices';
import { getLowerCasedValueSplitBySeparator } from '@core/utils';
import { ORDERED_ANOMALY_CHARTS_KEYS } from '@modules/Sidebar/views/Site/constants';
import { ANOMALY_STATISTICS_PARAMS } from '@modules/Sidebar/views/Zone/constants';
import { getStylesData } from '@modules/Sidebar/views/Zone/utils/anomalyCharts/getStylesData';
import { DonutChart } from '@components/Chart';
import { Tile } from '@components/Tile/Tile';
import styles from './styles.scss';

const getters = {
  getDonutChartLabel(chartKey: string) {
    return t(`sidebar.widgets.charts.${chartKey}.title`);
  },
  getTileLabel(chartKey: string, anomalyKey: string) {
    return t(
      `sidebar.widgets.charts.${chartKey}.tiles.${getLowerCasedValueSplitBySeparator(anomalyKey)}`,
    );
  },
};

export interface IAnomalyStatisticsProps {
  className?: string;
}

export const AnomalyStatistics: FC<IAnomalyStatisticsProps> = memo(({ className }) => {
  const currentReport = useCurrentReportSelector();

  const filteredAnomalies = useMemo(
    () => currentReport?.anomalies?.filter((anomaly) => anomaly.mode !== EAnomalyMode.Manual),
    [currentReport?.anomalies],
  );

  const stylesData = useMemo(
    () =>
      Object.values(ANOMALY_STATISTICS_PARAMS).reduce(
        (acc, { anomalyChartKey, anomalyAggregatedKey }) => ({
          ...acc,
          [anomalyChartKey]: getStylesData({
            anomalies: filteredAnomalies ?? [],
            anomalyAggregatedKey,
            anomalyChartKey,
            defaultValue: anomaliesChartsColor[anomalyChartKey],
          }),
        }),
        {},
      ),
    [filteredAnomalies],
  );

  return (
    <div className={cn(styles.root, className)}>
      {ORDERED_ANOMALY_CHARTS_KEYS.map((chartKey) => {
        const selectedStylesData = stylesData[chartKey];

        return (
          <div className={styles.container} key={chartKey}>
            <div className={styles.chart}>
              <DonutChart data={selectedStylesData} label={getters.getDonutChartLabel(chartKey)} />
            </div>
            <ul className={styles.tilesList}>
              {selectedStylesData.map(({ key, value }) => (
                <Tile
                  className={styles.tile}
                  key={key}
                  as='li'
                  label={getters.getTileLabel(chartKey, key)}
                  value={value}
                />
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
});
