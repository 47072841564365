import { axiosInstance } from '@core/api/axios';

export interface ITenantToTokenRequest {
  refresh_token: string;
  tenant: string;
}

export interface ITenantToTokenResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
}

export const addTenantToToken = async (body: ITenantToTokenRequest) => {
  return axiosInstance.post<ITenantToTokenResponse>('token/add_tenant', body).then((response) => {
    if (!response) throw new Error();
    return response?.data;
  });
};
