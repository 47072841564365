import { DEFAULT_NUMERIC_VALUE, EMPTY_VALUE } from '@core/constants';

interface IResetEstimatedLossesFields {
  submode: string;
  thermal_surface: number;
  visual_surface: number;
  disconnected_panels: number;
  anomaly_temperature: number;
  mean_temperature: number;
  temperature_differential: number;
}

export const RESET_ESTIMATED_LOSSES_ANOMALY_FIELDS: IResetEstimatedLossesFields = {
  submode: EMPTY_VALUE,
  thermal_surface: DEFAULT_NUMERIC_VALUE,
  visual_surface: DEFAULT_NUMERIC_VALUE,
  disconnected_panels: DEFAULT_NUMERIC_VALUE,
  anomaly_temperature: DEFAULT_NUMERIC_VALUE,
  mean_temperature: DEFAULT_NUMERIC_VALUE,
  temperature_differential: DEFAULT_NUMERIC_VALUE,
};
