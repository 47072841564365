import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import { t } from 'i18next';
import _ from 'lodash';
import delayedIcon from '@assets/icons/anomalies/table_delayed.svg';
import repairedIcon from '@assets/icons/anomalies/table_repaired.svg';
import toRepairIcon from '@assets/icons/anomalies/table_to-repair.svg';
import chevronRight from '@assets/icons/chevron-right.svg';
import { ESortOrder } from '@core/enums';
import { EAnomalyMode, EAnomalyRepairmentStatus } from '@core/enums/anomalies';
import {
  createAnomalyName,
  formatMoney,
  getLowerCasedValueSplitBySeparator,
  sliceString,
} from '@core/utils';
import { toNegative } from '@core/utils/math';
import { EAnomalyTableColumn } from '@modules/Sidebar/views/Zone/enums';
import { IAnomalyTableRow } from '@modules/Sidebar/views/Zone/interfaces/anomalies';
import { Shield } from '@components/Shield/Shield';
import styles from './styles.scss';

const MAX_ANOMALY_NAME_LENGTH = 9;

const repairIcons = {
  [EAnomalyRepairmentStatus.Delayed]: delayedIcon,
  [EAnomalyRepairmentStatus.ToRepair]: toRepairIcon,
  [EAnomalyRepairmentStatus.Repaired]: repairedIcon,
};

export const shieldPropsConfig = {
  [EAnomalyMode.Thermal]: {
    shield: {
      text: t('typesAnomalies.thermal'),
      className: cn(styles.shieldText, 'bg-outflier-thermal-transparent', 'text-outflier-thermal'),
    },
  },
  [EAnomalyMode.String]: {
    shield: {
      text: t('typesAnomalies.string'),
      className: cn(styles.shieldText, 'bg-outflier-string-transparent', 'text-outflier-string'),
    },
  },
  [EAnomalyMode.Visual]: {
    shield: {
      text: t('typesAnomalies.visual'),
      className: cn(styles.shieldText, 'bg-outflier-visual-transparent', 'text-outflier-visual'),
    },
  },
  [EAnomalyMode.Mixed]: {
    shield: {
      text: t('typesAnomalies.mixed'),
      className: cn(styles.shieldText, 'bg-outflier-mixed-transparent', 'text-outflier-mixed'),
    },
  },
  // NOTE: deprecated field
  [EAnomalyMode.Manual]: {
    shield: {
      text: t('typesAnomalies.manual'),
      className: cn(styles.shieldText),
    },
  },
};

export const anomalyTableCellsConfig = {
  columns: [
    {
      key: EAnomalyTableColumn.AnomalyId,
      className: styles.anomalyColumn,
      render: ({ isSortable, order, onSort }) => {
        const label = t('sidebar.zone.tables.anomalies.head.anomalyId');
        let layout = <span>{label}</span>;

        if (isSortable) {
          layout = (
            <button className={styles.sortBtn} onClick={onSort.bind(null, 'id')}>
              <span>{label}</span>
              <ReactSVG
                className={cn(styles.chevron, {
                  '-rotate-90': order === ESortOrder.Asc,
                  'rotate-90': order === ESortOrder.Desc,
                })}
                src={chevronRight}
              />
            </button>
          );
        }

        return <div className={styles.anomalyIdColumn}>{layout}</div>;
      },
    },
    {
      key: EAnomalyTableColumn.Type,
      className: '',
      render: () => (
        <div className={styles.typeColumn}>
          <span>{t('sidebar.zone.tables.anomalies.head.type')}</span>
        </div>
      ),
    },
    {
      key: EAnomalyTableColumn.LostYear,
      className: '',
      render: () => (
        <div className={styles.lostYearColumn}>
          <span>{t('sidebar.zone.tables.anomalies.head.lostYear')}</span>
        </div>
      ),
    },
    {
      key: EAnomalyTableColumn.Repair,
      className: '',
      render: () => (
        <div className={styles.repairColumn}>
          <span>{t('sidebar.zone.tables.anomalies.head.repair')}</span>
        </div>
      ),
    },
  ],
  row: [
    {
      key: EAnomalyTableColumn.AnomalyId,
      className: styles.anomalyIdColumn,
      render: ({ id, status }: IAnomalyTableRow) => {
        return (
          <div className={styles.anomalyIdTableCell}>
            <div
              className={cn(
                styles.anomalyIdStatus,
                styles[`anomalyIdStatus-${getLowerCasedValueSplitBySeparator(status)}`],
              )}
            />
            <span className={cn(styles.cellLabel, styles.anomalyIdLabel)}>
              {sliceString(createAnomalyName(id), MAX_ANOMALY_NAME_LENGTH)}
            </span>
          </div>
        );
      },
    },
    {
      key: EAnomalyTableColumn.Type,
      className: styles.typeColumn,
      render: ({ mode }: IAnomalyTableRow) => {
        return <Shield {...shieldPropsConfig[mode]} />;
      },
    },
    {
      key: EAnomalyTableColumn.LostYear,
      className: styles.lostYearColumn,
      render: ({ cost, currency }: IAnomalyTableRow) => {
        return (
          <div className={cn(styles.cellLabel, styles.lostYearLabel)}>
            {formatMoney(_.round(toNegative(cost)), currency)}
          </div>
        );
      },
    },
    {
      key: EAnomalyTableColumn.Repair,
      className: styles.repairColumn,
      render: ({ repairment_status }: IAnomalyTableRow) => {
        return (
          repairment_status && (
            <ReactSVG className={styles.repairIcon} src={repairIcons[repairment_status]} />
          )
        );
      },
    },
  ],
};
