import { Polygon } from '@turf/turf';
import { LineString } from '@turf/turf';
import { FeatureCollection } from 'geojson';
import { ECreateSiteSteps } from '@core/constants';
import { ECreateSiteSections, EGeneratedProgramPreview } from '@core/enums/sites/createSite';
import { TEditRole, TRolesWithoutSuperuser } from './editSite';
import { IProgram } from '../program';

export interface ICreateSiteInitialState {
  loc_id: string | null;
  steps: {
    current: ECreateSiteSteps;
    maxCurrent: ECreateSiteSteps;
  };
  [ECreateSiteSections.SearchLocation]: {
    lat: number;
    lng: number;
    selectedLocation: IMapboxCityData | null;
  };
  [ECreateSiteSections.Name]: {
    name: string;
  };
  [ECreateSiteSections.Perimeter]: {
    gps_boundaries: {
      top_left_lat: number;
      top_left_lng: number;
      bottom_right_lat: number;
      bottom_right_lng: number;
    };
    isLoadingSite: boolean;
    processingPercentage: number | null; // for site perimeter creation
    currentIndex: number | null;
    data: Array<FeatureCollection | Polygon | any>;
  };
  [ECreateSiteSections.NoFlyZones]: {
    sequenceIndex: number;
    currentIndex: number | null;
    data: INoFlyZone[];
  };
  [ECreateSiteSections.Obstacles]: {
    sequenceIndex: number;
    currentIndex: number | null;
    data: IObstacle[];
  };
  [ECreateSiteSections.Picture]: {
    image: string | null;
    imageUrl: string | null;
  };
  [ECreateSiteSections.InspectionFrequency]: {
    inspectionFrequency: number;
  };
  [ECreateSiteSections.Roles]: {
    [key in TRolesWithoutSuperuser]: TEditRole[];
  };
  [ECreateSiteSections.Zones]: {
    isLoadingZones: boolean;
    fakePerimeterProcessing: IFakePerimeterProcessing;
    programs: IProgram[];
    currentProgramId: string;
    currentPlanIndex: null | number;
    currentOverviewIndex: null | number;
    programPreview: EGeneratedProgramPreview;
  };
}

export type IPerimeter = {
  type: 'Polygon';
  coordinates: [];
};

export interface INoFlyZone {
  id: number | string;
  name: string;
  altitude?: number;
  type: 'no_fly_zone';
  perimeter: Polygon;
}

export interface IObstacle {
  id: number | string;
  name: string;
  altitude?: number;
  type: 'obstacle';
  perimeter: LineString;
}

export interface IMapboxCityData {
  id: number | string;
  name: string;
  coordinates: [number, number];
}

export interface IFakePerimeterProcessing {
  percentage: number; // for zones perimeter creation
  currentStep: number;
}

export interface IZonesPerimeterStorageData {
  percentage: number;
  timestamp: number;
}
