import { ISolarPanelsWithIncidentAnomalies } from '@core/api/report/solarPanels';
import { ESolarPanelsStatus } from '@core/enums/reports';

export function getSolarPanelsCounters(data: ISolarPanelsWithIncidentAnomalies[]) {
  return data.reduce(
    (acc, solarPanel) => {
      let status = ESolarPanelsStatus.Active;

      if (solarPanel.incident_anomalies.length) {
        status = ESolarPanelsStatus.Defective;
      }

      return { ...acc, [status]: acc[status] + 1 };
    },
    {
      [ESolarPanelsStatus.Active]: 0,
      [ESolarPanelsStatus.Defective]: 0,
    },
  );
}
