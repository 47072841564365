import { format } from 'date-fns';
import i18n from 'i18next';
import { FORMAT_DATE_LONG } from '@core/constants';
import { ESiteStatuses } from '@core/enums';
import { ELocationStatus } from '@core/interfaces';

export const getInspectedRowData = (
  lastInspectionDate?: string,
  locationStatus?: ELocationStatus,
  status?: ESiteStatuses,
) => {
  if (locationStatus === ELocationStatus.UnderCreation) return i18n.t('underCreation');
  if (locationStatus === ELocationStatus.WaitingForApproval) return i18n.t('waitingForApproval');
  if (status === ESiteStatuses.ReportProcessing) return i18n.t('statuses.reportProcessing');
  return lastInspectionDate
    ? format(new Date(lastInspectionDate), FORMAT_DATE_LONG)
    : i18n.t('toBeInspected');
};
