import { t } from 'i18next';
import { EAnomalySubtype } from '@core/enums/anomalies';
import {
  SUBTYPE_POPOVER_HEIGHT,
  SURFACE_AFFECTED_POPOVER_HEIGHT,
  TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
} from '../constants';
import { EAnomalyDetailsPopovers } from '../enums';

export const THERMAL_INITIAL_POPOVERS_STYLES = {
  [EAnomalyDetailsPopovers.Subtype]: {
    height: SUBTYPE_POPOVER_HEIGHT,
  },
  [EAnomalyDetailsPopovers.SurfaceAffected]: {
    height: SURFACE_AFFECTED_POPOVER_HEIGHT,
  },
  [EAnomalyDetailsPopovers.TemperatureDifference]: {
    height: TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
  },
};

export const SUBTYPE_LABEL = {
  [EAnomalySubtype.Hotspot]: t(
    'sidebar.anomaly.widgets.details.tiles.subtype.modal.options.hotspot',
  ),
  [EAnomalySubtype.MultipleHotspot]: t(
    'sidebar.anomaly.widgets.details.tiles.subtype.modal.options.multiple_hotspot',
  ),
  [EAnomalySubtype.Coldspot]: t(
    'sidebar.anomaly.widgets.details.tiles.subtype.modal.options.coldspot',
  ),
  [EAnomalySubtype.BypassDiode]: t(
    'sidebar.anomaly.widgets.details.tiles.subtype.modal.options.bypass_diode',
  ),
};
