import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DEFAULT_NUMERIC_VALUE, MISSING_VALUE } from '@core/constants';
import { EControlStepCounterAction } from '@core/enums';
import { IAnomaly } from '@core/interfaces/anomaly';
import { useCurrentAnomalySelector } from '@core/store/slices';
import { toPixels } from '@core/utils';
import { HEADER_HEIGHT } from '@modules/Layout/constants';
import { getPopoverYPosition } from '@modules/Sidebar/utils/charts';
import {
  StepperPopover,
  SubtypePopover,
} from '@modules/Sidebar/widgets/AnomalyDetails/components/Popovers';
import {
  AverageTemperatureTile,
  DetailsTile,
  SubtypeTile,
  SurfaceAffectedTile,
  TemperatureDifferenceTile,
} from '@modules/Sidebar/widgets/AnomalyDetails/components/Tiles';
import {
  THERMAL_INITIAL_POPOVERS_STYLES,
  SUBTYPE_LABEL,
} from '@modules/Sidebar/widgets/AnomalyDetails/config/thermal';
import {
  SUBTYPE_POPOVER_HEIGHT,
  SURFACE_AFFECTED_POPOVER_HEIGHT,
  TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
} from '@modules/Sidebar/widgets/AnomalyDetails/constants';
import { useAnomalyDetailsContext } from '@modules/Sidebar/widgets/AnomalyDetails/contexts';
import {
  EAnomalyDetailsPopovers,
  EAnomalyDetailsUpdateFields,
} from '@modules/Sidebar/widgets/AnomalyDetails/enums';
import { useUpdateAnomaly } from '@modules/Sidebar/widgets/AnomalyDetails/hooks';
import {
  IUpdatePopoverTopPositionParams,
  SubtypeOption,
} from '@modules/Sidebar/widgets/AnomalyDetails/interfaces';
import {
  formatAverageTemperature,
  formatSurfaceAffected,
  formatTemperatureDifference,
  getRoundedSurfaceAffected,
  getRoundedTemperature,
} from '@modules/Sidebar/widgets/AnomalyDetails/utils';
import styles from '../../styles.scss';

export const ThermalView = () => {
  const { t } = useTranslation();
  const detailsContext = useAnomalyDetailsContext();
  const currentAnomaly = useCurrentAnomalySelector();

  const [isOpenedSubtypeModal, setIsOpenedSubtypeModal] = useState(false);
  const [isOpenedSurfaceAffectedModal, setIsOpenedSurfaceAffectedModal] = useState(false);
  const [isOpenedTemperatureDifferenceModal, setIsOpenedTemperatureDifferenceModal] =
    useState(false);

  const [subtypeValue, setSubtypeValue] = useState<SubtypeOption>({
    value: currentAnomaly?.submode ?? null,
    label: currentAnomaly?.submode ? SUBTYPE_LABEL[currentAnomaly.submode] : MISSING_VALUE,
  });

  const [surfaceAffectedValue, setSurfaceAffectedValue] = useState(
    currentAnomaly?.thermal_surface ?? DEFAULT_NUMERIC_VALUE,
  );
  const [temperatureDifferenceValue, setTemperatureDifferenceValue] = useState(
    currentAnomaly?.temperature_differential ?? DEFAULT_NUMERIC_VALUE,
  );

  const [popoverStyles, setPopoverStyles] = useState(THERMAL_INITIAL_POPOVERS_STYLES);

  const subtypeTileRef = useRef<HTMLDivElement | null>(null);
  const surfaceAffectedRef = useRef<HTMLDivElement | null>(null);
  const temperatureDiffRef = useRef<HTMLDivElement | null>(null);

  useUpdateAnomaly({
    isAllowed: !isOpenedSubtypeModal,
    anomaly: currentAnomaly,
    key: EAnomalyDetailsUpdateFields.Subtype,
    editableValue: subtypeValue.value,
  });
  useUpdateAnomaly({
    isAllowed: !isOpenedSurfaceAffectedModal,
    anomaly: currentAnomaly,
    key: EAnomalyDetailsUpdateFields.ThermalSurface,
    editableValue: surfaceAffectedValue,
  });
  useUpdateAnomaly({
    isAllowed: !isOpenedTemperatureDifferenceModal,
    anomaly: currentAnomaly,
    key: EAnomalyDetailsUpdateFields.TemperatureDifferential,
    editableValue: temperatureDifferenceValue,
  });

  const resetThermalViewState = useCallback((anomaly: IAnomaly) => {
    setIsOpenedSurfaceAffectedModal(false);
    setIsOpenedSubtypeModal(false);
    setIsOpenedTemperatureDifferenceModal(false);

    setPopoverStyles(THERMAL_INITIAL_POPOVERS_STYLES);

    setSurfaceAffectedValue(anomaly.thermal_surface ?? DEFAULT_NUMERIC_VALUE);
    setTemperatureDifferenceValue(anomaly.temperature_differential ?? DEFAULT_NUMERIC_VALUE);
    setSubtypeValue({
      value: anomaly.submode ?? null,
      label: anomaly.submode ? SUBTYPE_LABEL[anomaly.submode] : MISSING_VALUE,
    });
  }, []);

  const handleSubtypeValue = useCallback((option: SubtypeOption) => {
    setSubtypeValue(option);
  }, []);

  const handleUpdateSurfaceAffectedValue = useCallback(
    (counterAction: EControlStepCounterAction) => {
      setSurfaceAffectedValue((value) => {
        const percent = 1 / 100;
        return counterAction === EControlStepCounterAction.Decrease
          ? value - percent
          : value + percent;
      });
    },
    [],
  );

  const handleTemperatureDifferenceValue = useCallback(
    (counterAction: EControlStepCounterAction) => {
      setTemperatureDifferenceValue((value) => {
        const roundedValue = _.floor(value);
        return counterAction === EControlStepCounterAction.Decrease
          ? roundedValue - 1
          : roundedValue + 1;
      });
    },
    [],
  );

  const handleShowSubtypeModal = useCallback(() => setIsOpenedSubtypeModal(true), []);
  const handleHideSubtypeModal = useCallback(() => setIsOpenedSubtypeModal(false), []);

  const handleShowSurfaceAffectedModal = useCallback(
    () => setIsOpenedSurfaceAffectedModal(true),
    [],
  );
  const handleHideSurfaceAffectedModal = useCallback(
    () => setIsOpenedSurfaceAffectedModal(false),
    [],
  );

  const handleShowTemperatureDifferenceModal = useCallback(
    () => setIsOpenedTemperatureDifferenceModal(true),
    [],
  );
  const handleHideTemperatureDifferenceModal = useCallback(
    () => setIsOpenedTemperatureDifferenceModal(false),
    [],
  );

  const updatePopoverTopPosition = useCallback(
    ({ ref, key, popoverHeight }: IUpdatePopoverTopPositionParams) => {
      if (ref.current) {
        const { height, top } = ref.current.getBoundingClientRect();
        const popoverTopPosition = getPopoverYPosition({
          parentHeight: height,
          parentTop: top,
          popoverHeight,
          correctionValue: HEADER_HEIGHT,
        });

        setPopoverStyles((styles) => ({
          ...styles,
          [key]: {
            ...styles[key],
            top: toPixels(popoverTopPosition),
          },
        }));
      }
    },
    [],
  );

  useEffect(() => {
    if (isOpenedSubtypeModal) {
      updatePopoverTopPosition({
        ref: subtypeTileRef,
        key: EAnomalyDetailsPopovers.Subtype,
        popoverHeight: SUBTYPE_POPOVER_HEIGHT,
      });
    } else if (isOpenedSurfaceAffectedModal) {
      updatePopoverTopPosition({
        ref: surfaceAffectedRef,
        key: EAnomalyDetailsPopovers.SurfaceAffected,
        popoverHeight: SURFACE_AFFECTED_POPOVER_HEIGHT,
      });
    } else if (isOpenedTemperatureDifferenceModal) {
      updatePopoverTopPosition({
        ref: temperatureDiffRef,
        key: EAnomalyDetailsPopovers.TemperatureDifference,
        popoverHeight: TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
      });
    }
  }, [
    detailsContext.scrollTop,
    isOpenedSubtypeModal,
    isOpenedSurfaceAffectedModal,
    isOpenedTemperatureDifferenceModal,
  ]);

  useEffect(() => {
    if (currentAnomaly?.id) {
      resetThermalViewState(currentAnomaly);
    }
  }, [currentAnomaly?.id]);

  const subtypeTitle = t('sidebar.anomaly.widgets.details.tiles.subtype.title');
  const averageTypeTitle = t('sidebar.anomaly.widgets.details.tiles.averageTemperature.title');
  const surfaceAffectedTitle = t('sidebar.anomaly.widgets.details.tiles.surfaceAffected.default');
  const temperatureDifferenceTitle = t(
    'sidebar.anomaly.widgets.details.tiles.temperatureDifference.title',
  );

  const formattedSurfaceAffectedValue = useMemo(
    () => _.flowRight(formatSurfaceAffected, getRoundedSurfaceAffected)(surfaceAffectedValue),
    [surfaceAffectedValue],
  );

  const formattedAverageTemperatureValue = useMemo(
    () =>
      _.flowRight(
        formatAverageTemperature,
        getRoundedTemperature,
      )(currentAnomaly?.mean_temperature),
    [currentAnomaly?.mean_temperature],
  );

  const formattedTemperatureDifferenceValue = useMemo(
    () =>
      _.flowRight(formatTemperatureDifference, getRoundedTemperature)(temperatureDifferenceValue),
    [temperatureDifferenceValue],
  );

  return (
    <ul className={styles.tiles}>
      <li className={styles.row}>
        <DetailsTile title={subtypeTitle} ref={subtypeTileRef}>
          <div className={styles.details}>
            <SubtypePopover
              className={styles.subtypePopover}
              selectedValue={subtypeValue.value}
              style={popoverStyles[EAnomalyDetailsPopovers.Subtype]}
              isOpen={isOpenedSubtypeModal}
              onClose={handleHideSubtypeModal}
              onUpdate={handleSubtypeValue}
            />
            <SubtypeTile
              isEditable={detailsContext.isEditable}
              isDisabled={detailsContext.isDisabled}
              value={subtypeValue.label}
              onClick={handleShowSubtypeModal}
            />
          </div>
        </DetailsTile>
        <DetailsTile title={averageTypeTitle}>
          <AverageTemperatureTile value={formattedAverageTemperatureValue} />
        </DetailsTile>
      </li>
      <li className={styles.row}>
        <DetailsTile title={surfaceAffectedTitle} ref={surfaceAffectedRef}>
          <div className={styles.details}>
            <StepperPopover
              classNames={{
                popover: styles.surfaceAffectedPopover,
                value: styles.surfaceAffectedValue,
              }}
              value={formattedSurfaceAffectedValue}
              style={popoverStyles[EAnomalyDetailsPopovers.SurfaceAffected]}
              title={surfaceAffectedTitle}
              isOpen={isOpenedSurfaceAffectedModal}
              onClose={handleHideSurfaceAffectedModal}
              onUpdate={handleUpdateSurfaceAffectedValue}
            />
            <SurfaceAffectedTile
              isEditable={detailsContext.isEditable}
              isDisabled={detailsContext.isDisabled}
              value={formattedSurfaceAffectedValue}
              onClick={handleShowSurfaceAffectedModal}
            />
          </div>
        </DetailsTile>
        <DetailsTile title={temperatureDifferenceTitle} ref={temperatureDiffRef}>
          <div className={styles.details}>
            <StepperPopover
              classNames={{
                popover: styles.temperatureDifferencePopover,
                value: styles.temperatureDifferenceValue,
              }}
              value={formattedTemperatureDifferenceValue}
              style={popoverStyles[EAnomalyDetailsPopovers.TemperatureDifference]}
              title={temperatureDifferenceTitle}
              isOpen={isOpenedTemperatureDifferenceModal}
              onClose={handleHideTemperatureDifferenceModal}
              onUpdate={handleTemperatureDifferenceValue}
            />
            <TemperatureDifferenceTile
              isEditable={detailsContext.isEditable}
              isDisabled={detailsContext.isDisabled}
              value={formattedTemperatureDifferenceValue}
              onClick={handleShowTemperatureDifferenceModal}
            />
          </div>
        </DetailsTile>
      </li>
    </ul>
  );
};
