import axios from 'axios';
import { API_URL } from '@core/config';
import { WEATHER_API_URL } from '@core/constants';
import { accessTokenInterceptor } from '@core/interceptors';
import { refreshTokenInterceptor } from '@core/interceptors/refreshToken';
import { tenantInterceptor } from '@core/interceptors/tenant';

export const axiosConfig = {
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Compression-Type': 'gzip',
  },
};

export const axiosWeatherApiConfig = {
  baseURL: WEATHER_API_URL,
  withCredentials: false,
};

export const axiosInstance = axios.create(axiosConfig);
accessTokenInterceptor(axiosInstance);
refreshTokenInterceptor(axiosInstance);
tenantInterceptor(axiosInstance);

export const nonTenantAxiosInstance = axios.create(axiosConfig);
accessTokenInterceptor(nonTenantAxiosInstance);
refreshTokenInterceptor(nonTenantAxiosInstance);

export const weatherAxiosInstance = axios.create(axiosWeatherApiConfig);

export type TAxiosInstance = typeof axiosInstance;
