import { IAnomaly } from '@core/interfaces/anomaly';
import { IReport } from '@core/interfaces/report';

export function expandAnomalies(anomalies: IAnomaly[], report: IReport) {
  return anomalies.map((anomaly) => ({
    ...anomaly,
    report_id: report.id,
    inspection_id: report.inspection_id,
    inspection_name: report.inspection_name,
    program_name: report.program_name,
    program_id: report.program_id,
  }));
}
