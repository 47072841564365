import { IChartData } from '@modules/Sidebar/interfaces';

type GroupValue = string;
type GroupPair = { [subgroupTitle: string]: GroupValue };

export const aggregateGroupValues = (chartData: IChartData[]) =>
  chartData.reduce((acc, data) => {
    const { group } = data.data;
    return { ...acc, [group.key]: group.title };
  }, {} as GroupPair);
