import React, { useCallback, useState } from 'react';
import cn from 'classnames';
import { orderBy } from 'lodash';
import { ECurrency } from '@core/enums/shared';
import { ESortOrder } from '@core/enums/table';
import { useCurrentReportSelector, useCurrentSiteSelector } from '@core/store/slices';
import { generateRowCellKey, setTableData } from '@modules/Sidebar/views/Zone/utils/anomalyTable';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableBodyCell,
  TableHeadCell,
} from '@components/Table';
import { anomalyTableCellsConfig } from './Cells/TableCells';
import styles from './styles.scss';

interface IProps {
  className?: string;
  isSortable: boolean;
  onRowClick?: (anomalyId: number) => void;
}

export const AnomaliesTable: React.FC<IProps> = React.memo(
  ({ className, isSortable, onRowClick }) => {
    const currentReport = useCurrentReportSelector();
    const currentSite = useCurrentSiteSelector();
    const [sortOrder, setSortOrder] = useState<ESortOrder>(ESortOrder.Desc);
    const [anomaliesTableData, setAnomaliesTableData] = useState(() =>
      currentReport?.anomalies && currentSite
        ? orderBy(
            setTableData({
              anomalies: currentReport.anomalies,
              site: currentSite,
              currency: ECurrency.Euro,
            }),
            'id',
            [ESortOrder.Desc],
          )
        : [],
    );

    const handleSortColumn = useCallback((key: string) => {
      let order = ESortOrder.Desc;

      setSortOrder((sortOrder) => {
        order = sortOrder === ESortOrder.Desc ? ESortOrder.Asc : ESortOrder.Desc;
        return order;
      });
      setAnomaliesTableData((tableData) => orderBy(tableData, key, [order]));
    }, []);

    return (
      <Table className={cn(styles.table, className)}>
        <TableHead className={styles.head}>
          <TableRow className={styles.row}>
            {anomalyTableCellsConfig.columns.map(({ key, className, render }) => (
              <TableHeadCell key={key} className={cn(styles.cell, styles.headCell, className)}>
                {render({ isSortable, order: sortOrder, onSort: handleSortColumn })}
              </TableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={styles.body}>
          {anomaliesTableData.map((anomaly) => (
            <TableRow
              key={anomaly.id}
              className={styles.row}
              onClick={onRowClick?.bind(null, anomaly.id)}
            >
              {anomalyTableCellsConfig.row.map(({ key, className, render }) => (
                <TableBodyCell
                  key={generateRowCellKey(key, anomaly.id)}
                  className={cn(styles.cell, styles.bodyCell, className)}
                >
                  {render(anomaly)}
                </TableBodyCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  },
);
