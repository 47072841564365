import React, { memo, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import { FORMAT_DATE_LONG, FORMAT_TIME_24H } from '@core/constants';
import { EAppAction, EDynamicZoomActions, ESidebar } from '@core/enums';
import { EReportSelector } from '@core/enums/reports/report';
import { useDispatchTyped } from '@core/hooks';
import { IInspection, ISelectOption } from '@core/interfaces';
import { updateStatuses, useCurrentSiteSelector, useSidebarSelector } from '@core/store/slices';
import {
  setInspectionAndReportToCompareIds,
  updateCurrentInspectionId,
} from '@core/store/slices/inspections';
import { reloadMap, setDynamicZoom } from '@core/store/slices/map';
import {
  fetchProgramsStatuses,
  updateProgramsWorstAnomalyStatus,
} from '@core/store/slices/programs';
import {
  fetchAnomaliesAndSolarPanelsToReports,
  setCurrentReportIdByCurrentInspectionId,
} from '@core/store/slices/reports';
import { isNull, isString } from '@core/utils';
import { Select } from '@components/Select';
import { transformDate } from './utils/transformDate';
import { IUpdateScrollPosition } from '../../interfaces';

interface IProps {
  toCompare?: boolean;
  inspection: IInspection | null;
  inspectionsOptions: IInspection[];
  onUpdateScrollPosition?: (options: IUpdateScrollPosition) => void;
}

export const InspectionsDate: React.FC<IProps> = memo(
  ({ toCompare = false, inspection, inspectionsOptions, onUpdateScrollPosition }) => {
    const dispatch = useDispatchTyped();
    const { sidebar } = useSidebarSelector();
    const currentSite = useCurrentSiteSelector();

    const options = useMemo(
      () => inspectionsOptions.reverse().map(({ id, date }) => ({ id, name: transformDate(date) })),
      [inspectionsOptions],
    );

    const selectedOption: ISelectOption | null = useMemo(
      () =>
        inspection
          ? {
              id: inspection.id,
              name: transformDate(inspection.date),
            }
          : null,
      [inspection],
    );

    const handleSelect = useCallback(
      (option: ISelectOption | null) => {
        if (!isNull(option) && isString(option.id)) {
          if (toCompare) {
            dispatch(setInspectionAndReportToCompareIds(option.id));
            dispatch(reloadMap(true));
          } else {
            dispatch(updateCurrentInspectionId(option.id));
            dispatch(setCurrentReportIdByCurrentInspectionId(option.id));

            const shouldUpdateProgramsStatuses = [
              ESidebar.Site,
              ESidebar.Zone,
              ESidebar.Anomaly,
            ].includes(sidebar);
            const shouldUpdateAnomaliesAndSolarPanels = [ESidebar.Zone, ESidebar.Anomaly].includes(
              sidebar,
            );
            const shouldUpdateProgramsWorstAnomalyStatus = sidebar !== ESidebar.Sites;

            if (shouldUpdateProgramsStatuses && currentSite?.loc_id) {
              dispatch(
                fetchProgramsStatuses({
                  data: {
                    locationId: currentSite.loc_id,
                    inspectionId: option.id,
                  },
                  action: EAppAction.HeaderInspectionDropdownSelect,
                }),
              );
            }

            // NOTE: get solar panels and anomalies only for "Zone", "Anomaly" levels
            if (shouldUpdateAnomaliesAndSolarPanels) {
              dispatch(
                fetchAnomaliesAndSolarPanelsToReports({
                  selector: EReportSelector.GetBySelectedReport,
                }),
              );
            }

            if (shouldUpdateProgramsWorstAnomalyStatus) {
              dispatch(updateProgramsWorstAnomalyStatus());
            }

            onUpdateScrollPosition?.({
              inspectionId: option.id,
              visibleInspections: inspectionsOptions,
            });
            dispatch(updateStatuses());
            // NOTE: move it before reloadMap if necessary
            dispatch(
              setDynamicZoom({
                action: EDynamicZoomActions.SelectInspectionDateByDropdown,
                isAllowedZoom: false,
              }),
            );
            dispatch(reloadMap(true));
          }
        }
      },
      [
        toCompare,
        sidebar,
        currentSite?.loc_id,
        inspectionsOptions,
        dispatch,
        onUpdateScrollPosition,
      ],
    );

    const isDisabledSelect = sidebar === ESidebar.Anomaly;

    return sidebar === ESidebar.CreateSite ? (
      <span className='text-outflier-white font-normal text-sm'>
        <span className='font-medium'>{format(new Date(), FORMAT_DATE_LONG + ' ')}</span>
        {format(new Date(), FORMAT_TIME_24H)}
      </span>
    ) : (
      <Select
        classNames={{ root: isDisabledSelect ? 'pointer-events-none' : '' }}
        options={options}
        selected={selectedOption}
        onChange={handleSelect}
      />
    );
  },
);
