export enum EAnomalyMode {
  Thermal = 'thermal',
  Visual = 'rgb',
  Mixed = 'multimodal',
  Manual = 'manual',
  String = 'string',
}

export enum EAnomalySubtype {
  Hotspot = 'hotspot',
  MultipleHotspot = 'multiple_hotspot',
  Coldspot = 'coldspot',
  BypassDiode = 'bypass_diode',
}
