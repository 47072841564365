import { useEffect } from 'react';
import { IUsersMeResponse } from '@core/api/user/users';
import { useDispatchTyped } from '@core/hooks';
import { fetchUser } from '@core/store/slices';

interface IFetchUserDeps {
  isAuthenticated: boolean;
  userData: IUsersMeResponse | null;
}
export const useFetchUser = ({ isAuthenticated, userData }: IFetchUserDeps) => {
  const dispatch = useDispatchTyped();

  useEffect(() => {
    if (isAuthenticated && !userData) {
      dispatch(fetchUser());
    }
  }, [isAuthenticated, userData]);
};
