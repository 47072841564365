import { EAnomalyRepairmentStatus } from '@core/enums/anomalies';
import { IAnomaly } from '@core/interfaces/anomaly';

type TResponse = {
  [key in EAnomalyRepairmentStatus]: IAnomaly[];
};

function getDefaultValue() {
  return Object.values(EAnomalyRepairmentStatus).reduce(
    (acc, status) => ({ ...acc, [status]: [] }),
    {} as TResponse,
  );
}
export function getSeparatedAnomaliesByStatus(anomalies: IAnomaly[]) {
  return anomalies.reduce((acc, anomaly) => {
    if (anomaly.repairment_status) {
      acc[anomaly.repairment_status].push(anomaly);
    }

    return acc;
  }, getDefaultValue());
}
