import { memo, useCallback, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import { getAggregatedDataByGridColumns } from '@modules/Sidebar/utils';
import { DEFAULT_GRID_COLUMNS } from '@modules/Sidebar/views/Anomaly/constants';
import { IAnomalyTileItem } from '@modules/Sidebar/views/Anomaly/interfaces';
import styles from './styles.scss';

interface IAnomalyProps<T extends string> {
  className?: string;
  gridColumns?: number;
  title: string;
  selectedTile: T | null;
  data: IAnomalyTileItem<T>[];
  isEditable?: boolean;
  isDisabled?: boolean;
  onClick?: (key: T) => void;
}

const AnomalyTiles = <T extends string>({
  title: tilesTitle,
  gridColumns = DEFAULT_GRID_COLUMNS,
  selectedTile,
  className,
  data,
  isEditable = false,
  isDisabled = false,
  onClick,
}: IAnomalyProps<T>) => {
  const aggregatedStylesDataByGridColumns = useMemo(
    () => getAggregatedDataByGridColumns<IAnomalyTileItem<T>>(data, gridColumns),
    [data, gridColumns],
  );

  const handleTileClick = useCallback(
    (key: T) => {
      if (isEditable && onClick) {
        onClick(key);
      }
    },
    [isEditable, onClick],
  );

  return (
    <div className={cn(styles.root, className)}>
      <h2 className={styles.title}>{tilesTitle}</h2>
      <div className={styles.tilesList}>
        {aggregatedStylesDataByGridColumns.map((columns, idx) => (
          <ul key={idx} className={styles.tilesRow}>
            {columns.map(({ key, title: tileTitle, icon, style }) => (
              <li
                key={key}
                style={selectedTile === key ? style : {}}
                className={cn(styles.tileItem, {
                  [styles.isActive]: selectedTile === key,
                  ['cursor-pointer']: isEditable,
                })}
              >
                <button
                  className={styles.tileBtn}
                  onClick={handleTileClick.bind(null, key)}
                  disabled={isDisabled ? isDisabled : selectedTile === key}
                >
                  {icon && <ReactSVG src={icon} className={styles.icon} />}
                  <span className={styles.tileTitle}>{tileTitle}</span>
                </button>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default memo(AnomalyTiles) as <T extends string>(props: IAnomalyProps<T>) => JSX.Element;
