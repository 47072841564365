import { MutableRefObject, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { authStorage } from '@core/utils';

export const useUpdateTenant = (ref: MutableRefObject<string | null>) => {
  const location = useLocation();

  useEffect(() => {
    ref.current = authStorage.tenant.get();
  }, [location.pathname]);
};
