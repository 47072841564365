// TODO:

/* eslint-disable @typescript-eslint/ban-types */
import { toast } from 'react-toastify';
import { RootState } from '..';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { api } from '@core/api';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { EAnomalyStatus } from '@core/enums/anomalies';
import { useSelectorTyped } from '@core/hooks';
import { IUpdateAnomalyParams } from '@core/interfaces/anomaly';
import { IReport } from '@core/interfaces/report';
import { setPreloader } from './preloader';
import { setReports } from './reports';

interface IInitialState {
  anomaliesType: EAnomalyStatus | null;
  toCompareAnomaliesType: EAnomalyStatus | null;
  loading: boolean;
  currentAnomalyId: number | null;
}

const initialState: IInitialState = {
  anomaliesType: null,
  toCompareAnomaliesType: null,
  currentAnomalyId: null,
  loading: false,
};

export const updateAnomaly = createAsyncThunk<void, IUpdateAnomalyParams, {}>(
  'anomalies/updateAnomaly',
  async ({ anomalyId, reportId, payload }, { dispatch, getState }) => {
    try {
      dispatch(setPreloader(true));

      const state = getState() as RootState;
      const reports = state.reports.reports;

      const anomalyResponse = await api.anomalies.updateAnomaly(anomalyId, payload);
      const reportResponse = await api.reports.getReportById(reportId);

      const selectedReport =
        reports.find((report) => report.anomalies?.find((anomaly) => anomaly.id === anomalyId)) ||
        ({} as IReport);
      const updatedReport = { ...selectedReport, ...reportResponse };

      const updatedReportAnomalies = (updatedReport?.anomalies || [])?.map((anomaly) =>
        anomaly.id === anomalyId
          ? {
              ...anomaly,
              ...anomalyResponse,
            }
          : anomaly,
      );

      const updatedReports = reports.map((report: IReport) =>
        report.id === updatedReport.id
          ? {
              ...updatedReport,
              anomalies: updatedReportAnomalies,
            }
          : report,
      );

      dispatch(setReports(updatedReports));
    } catch {
      toast.error(t('errors.updateAnomalyFail'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(setPreloader(false));
    }
  },
);

const anomaliesSlice = createSlice({
  name: 'anomalies',
  initialState,
  reducers: {
    startDataProcessing: (state) => {
      state.loading = true;
    },
    finishDataProcessing: (state) => {
      state.loading = false;
    },
    setCurrentAnomalyId: (state, action: PayloadAction<number | null>) => {
      state.currentAnomalyId = action.payload;
    },
    setAnomaliesType: (state, action: PayloadAction<EAnomalyStatus | null>) => {
      state.anomaliesType = action.payload;
    },
    setToCompareAnomaliesType: (state, action: PayloadAction<EAnomalyStatus | null>) => {
      state.toCompareAnomaliesType = action.payload;
    },
    resetAnomalies: () => initialState,
  },
});

const anomaliesReducer = anomaliesSlice.reducer;

const { setCurrentAnomalyId, setAnomaliesType, setToCompareAnomaliesType, resetAnomalies } =
  anomaliesSlice.actions;

const useAnomaliesSelector = () => useSelectorTyped((state) => state.anomalies);

const useCurrentReportAnomaliesSelector = () => {
  return useSelectorTyped((state) => {
    const currentReport = state.reports.reports.find(
      (report) => report.id === state.reports.currentReportId,
    );
    const reportAnomalies = currentReport?.anomalies;

    return reportAnomalies || [];
  });
};

const useCurrentAnomalySelector = () =>
  useSelectorTyped((state) => {
    const reportAnomalies = state.reports.reports.flatMap((report) => report.anomalies ?? []);
    const currentAnomaly = reportAnomalies.find(
      (anomaly) => anomaly.id === state.anomalies.currentAnomalyId,
    );

    return currentAnomaly;
  });

const useCurrentAnomalyIdSelector = () =>
  useSelectorTyped((state) => state.anomalies.currentAnomalyId);

export {
  anomaliesReducer,
  useAnomaliesSelector,
  setCurrentAnomalyId,
  setAnomaliesType,
  setToCompareAnomaliesType,
  resetAnomalies,
  useCurrentAnomalySelector,
  useCurrentAnomalyIdSelector,
  useCurrentReportAnomaliesSelector,
  initialState as anomaliesInitialState,
};
