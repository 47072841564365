import { Feature } from 'geojson';
import { anomaliesChartsColor, anomaliesChartsColorWithGreyShade } from '@core/constants';
import {
  EAnomalyAggregatedKey,
  EAnomalyStatisticCategory,
  EAnomalyStatus,
} from '@core/enums/anomalies';
import { IAnomaly } from '@core/interfaces/anomaly';
import { IReport } from '@core/interfaces/report';
import { getColor, getRightmostCoordinateFromPolygonCoordinates, mergePolygons } from '@core/utils';

interface IAnomaliesFeatureDeps {
  anomaliesType: EAnomalyStatus | null | undefined;
  report: IReport;
  selectedAnomaly?: IAnomaly;
  hasSolarPanels: boolean;
  anomalyCategory: EAnomalyStatisticCategory;
}

const matchingCategoryToAnomalyKeyMap: {
  [key in EAnomalyStatisticCategory]: EAnomalyAggregatedKey;
} = {
  [EAnomalyStatisticCategory.Severity]: EAnomalyAggregatedKey.Status,
  [EAnomalyStatisticCategory.Type]: EAnomalyAggregatedKey.Mode,
  [EAnomalyStatisticCategory.Repair]: EAnomalyAggregatedKey.RepairmentStatus,
};

class AnomalyStyleHelper {
  protected aggregatedKey: EAnomalyAggregatedKey;

  constructor(
    private readonly hasSolarPanels: boolean,
    private readonly chartCategory: EAnomalyStatisticCategory,
  ) {
    this.hasSolarPanels = hasSolarPanels;
    this.chartCategory = chartCategory;
    this.aggregatedKey = matchingCategoryToAnomalyKeyMap[this.chartCategory];
  }

  getColor(anomaly: IAnomaly, selectedAnomaly?: IAnomaly) {
    const nonExistentSelectedAnomaly = !selectedAnomaly;
    const isSelectedAnomaly = anomaly.id === selectedAnomaly?.id;

    const categoryValue = anomaly[this.aggregatedKey];

    if (!categoryValue) {
      return null;
    }

    const activeAnomalyColor = anomaliesChartsColor[this.chartCategory][categoryValue];
    const passiveAnomalyColor =
      anomaliesChartsColorWithGreyShade[this.chartCategory][categoryValue];

    return nonExistentSelectedAnomaly
      ? activeAnomalyColor
      : isSelectedAnomaly
      ? activeAnomalyColor
      : passiveAnomalyColor;
  }

  getBorderColor(anomaly: IAnomaly, selectedAnomaly?: IAnomaly) {
    const isSelectedAnomaly = anomaly.id === selectedAnomaly?.id;

    if (this.hasSolarPanels) {
      return getColor(isSelectedAnomaly ? '--outflier-blue' : '--outflier-color5');
    }

    const categoryValue = anomaly[this.aggregatedKey];

    if (!categoryValue) {
      return null;
    }

    return isSelectedAnomaly
      ? getColor('--outflier-blue')
      : anomaliesChartsColor[this.chartCategory][categoryValue];
  }

  getBorderWidth(anomaly: IAnomaly, selectedAnomaly?: IAnomaly) {
    const isSelectedAnomaly = anomaly.id === selectedAnomaly?.id;
    const borderWidth = {
      default: 1,
      withoutPanels: 2,
      selected: 4,
    };

    if (this.hasSolarPanels) {
      return isSelectedAnomaly ? borderWidth.selected : borderWidth.default;
    }

    return isSelectedAnomaly ? borderWidth.selected : borderWidth.withoutPanels;
  }

  getOpacity(anomaly: IAnomaly, selectedAnomaly?: IAnomaly) {
    const isSelectedAnomaly = anomaly.id === selectedAnomaly?.id;
    const opacity = {
      default: 1,
      nonActive: 0.7,
      withoutPanels: 0,
    };

    if (this.hasSolarPanels) {
      return !selectedAnomaly?.id || isSelectedAnomaly ? opacity.default : opacity.nonActive;
    }

    return opacity.withoutPanels;
  }
}

export const getAnomaliesFeatures = ({
  anomaliesType,
  selectedAnomaly,
  report,
  hasSolarPanels,
  anomalyCategory,
}: IAnomaliesFeatureDeps): Feature[] => {
  const anomalyHelper = new AnomalyStyleHelper(hasSolarPanels, anomalyCategory);
  const reportAnomalies = anomaliesType
    ? [...(report.anomalies ?? [])].filter(({ status }) => status === anomaliesType)
    : [...(report.anomalies ?? [])];

  return reportAnomalies.flatMap((anomaly) =>
    anomaly.features
      ? anomaly.features.map((feature) => ({
          ...feature,
          id: anomaly.id,
          properties: {
            ...feature.properties,
            color: anomalyHelper.getColor(anomaly, selectedAnomaly),
            borderWidth: anomalyHelper.getBorderWidth(anomaly, selectedAnomaly),
            opacity: anomalyHelper.getOpacity(anomaly, selectedAnomaly),
            borderColor: anomalyHelper.getBorderColor(anomaly, selectedAnomaly),
            coordinates: anomaly.features
              ? getRightmostCoordinateFromPolygonCoordinates(
                  anomaly.features[0].geometry.coordinates,
                )
              : [],
          },
        }))
      : [],
  );
};

export const getAnomaliesPerimeterFeatures = ({
  anomaliesType,
  report,
}: Pick<IAnomaliesFeatureDeps, 'anomaliesType' | 'report'>): Feature[] => {
  const reportAnomalies = anomaliesType
    ? [...(report.anomalies ?? [])].filter(({ status }) => status === anomaliesType)
    : [...(report.anomalies ?? [])];

  return reportAnomalies
    .map((anomaly) => {
      const polygon = mergePolygons(anomaly.features || []);

      return polygon
        ? {
            ...polygon,
            properties: {
              ...polygon.properties,
              anomalyId: anomaly.id,
              borderColor: getColor('--outflier-blue'),
              borderWidth: 4,
            },
          }
        : null;
    })
    .filter(Boolean) as Feature[];
};
