import { t } from 'i18next';
import _ from 'lodash';
import { EAnomalyMode, EAnomalyRepairmentStatus } from '@core/enums/anomalies';
import { IAnomaly } from '@core/interfaces/anomaly';
import { toNegative } from '@core/utils/math';
import { EEstimatedLossesGroup } from '@modules/Sidebar/widgets/EstimatedLosses/enums';

interface IAnomaliesChartData {
  visibleAnomalies: IAnomaly[];
  separatedAnomaliesByRepairmentStatus: {
    [key in EAnomalyRepairmentStatus]: IAnomaly[];
  };
  totalCostToRepairByReportsAnomalies: number;
  anomalyCostsByModes: {
    [key in EAnomalyRepairmentStatus]: {
      [EAnomalyMode.Thermal]: number;
      [EAnomalyMode.String]: number;
      [EAnomalyMode.Visual]: number;
    };
  };
  costRatio: string;
}

const documentStyle = getComputedStyle(document.documentElement);

export const prepareAnomaliesChartData = ({
  visibleAnomalies,
  separatedAnomaliesByRepairmentStatus,
  totalCostToRepairByReportsAnomalies,
  anomalyCostsByModes,
  costRatio,
}: IAnomaliesChartData) => {
  const toRepairCosts = anomalyCostsByModes[EAnomalyRepairmentStatus.ToRepair];

  return {
    metadata: {
      bar: {
        title: {
          value: toNegative(totalCostToRepairByReportsAnomalies),
          costRatio: costRatio,
          color: documentStyle.getPropertyValue('--outflier-yellow'),
        },
      },
    },
    data: {
      group: {
        key: EEstimatedLossesGroup.AfterRepair,
        title: t('sidebar.widgets.estimatedLosses.tiles.afterRepair.tooltipLabel'),
        value: t('sidebar.widgets.estimatedLosses.tiles.afterRepair.title', {
          repaired: separatedAnomaliesByRepairmentStatus[EAnomalyRepairmentStatus.Repaired].length,
          total: visibleAnomalies.length,
        }),
        color: documentStyle.getPropertyValue('--white'),
      },
      subgroups: [
        {
          key: EAnomalyMode.Thermal,
          position: 1,
          title: t('sidebar.widgets.estimatedLosses.popover.subgroups.thermal'),
          color: documentStyle.getPropertyValue('--outflier-red-light-shade'),
          value: _.round(toRepairCosts[EAnomalyMode.Thermal], 1),
        },
        {
          key: EAnomalyMode.String,
          position: 2,
          title: t('sidebar.widgets.estimatedLosses.popover.subgroups.string'),
          color: documentStyle.getPropertyValue('--outflier-yellow-light-shade'),
          value: _.round(toRepairCosts[EAnomalyMode.String], 1),
        },
        {
          key: EAnomalyMode.Visual,
          position: 3,
          title: t('sidebar.widgets.estimatedLosses.popover.subgroups.rgb'),
          color: documentStyle.getPropertyValue('--outflier-blue-cyan'),
          value: _.round(toRepairCosts[EAnomalyMode.Visual], 1),
        },
      ],
    },
  };
};
