import { ECurrency } from '@core/enums/shared';
import { ISite } from '@core/interfaces';
import { IAnomaly } from '@core/interfaces/anomaly';
import { createAnomalyName } from '@core/utils';
import { getAnomalyCosts } from '@modules/Sidebar/widgets/EstimatedLosses/utils';

export interface ITableDataOptions {
  anomalies: IAnomaly[];
  site: ISite;
  currency: ECurrency;
}

export interface ITableRowDataOptions {
  anomaly: IAnomaly;
  site: ISite;
  currency: ECurrency;
}

export function setTableData({ anomalies, ...rest }: ITableDataOptions) {
  return anomalies.map((anomaly) => setTableRowData({ anomaly, ...rest }));
}

export function setTableRowData({ anomaly, site, currency }: ITableRowDataOptions) {
  const { id, mode, status, repairment_status } = anomaly;

  return {
    id,
    mode,
    status,
    repairment_status,
    currency,
    name: createAnomalyName(id),
    cost: getAnomalyCosts(anomaly, site),
  };
}
