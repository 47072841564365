import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_ZONE_NAME_LENGTH } from '@core/constants/validation';
import { ESiteEditActions } from '@core/enums/sites/editSite';
import { useDispatchTyped } from '@core/hooks';
import { TSelectZonePayload } from '@core/interfaces';
import { updateEditSiteZones, useSiteEditInfoSelector } from '@core/store/slices';
import { setCurrentInspectionIdByProgramId } from '@core/store/slices/inspections';
import {
  setCurrentProgramId,
  useProgramsForCurrentLocationSelector,
} from '@core/store/slices/programs';
import { sliceString } from '@core/utils';
import ZonesBody from './ZonesBody';
import { ITableBodyColumn, ITableHeadColumn } from './interfaces/table';
import styles from './styles.scss';
import { generateFlightZoneKey, generateNameZoneKey } from './utils';

const ZonesEdit = () => {
  const { t } = useTranslation();
  const programs = useProgramsForCurrentLocationSelector();
  const editInfo = useSiteEditInfoSelector();
  const dispatch = useDispatchTyped();

  const head: ITableHeadColumn[] = useMemo(
    () => [
      {
        name: t('sidebar.site.edit.sections.zones.table.head.column1.keyWithCount', {
          count: programs.length,
        }),
        className: styles.tableHeadColumn,
      },
      {
        name: null,
        className: styles.tableHeadColumn,
      },
    ],
    [programs],
  );

  const body: ITableBodyColumn[] = useMemo(
    () =>
      programs
        .map((zone) => {
          const foundZone = editInfo?.zones.zones.find(
            (editedZone) => zone.program_id === editedZone.program_id,
          );
          return foundZone ? { ...zone, customName: foundZone.customName } : zone;
        })
        .map((zone, index) => {
          const name =
            'customName' in zone && zone.customName ? zone.customName : generateNameZoneKey(index);

          return {
            id: zone.program_id,
            name: name,
            data: [
              {
                id: generateNameZoneKey(index),
                name: name,
                formattedName: sliceString(name, MAX_ZONE_NAME_LENGTH),
                className: styles.zoneIdColumn,
              },
              {
                id: generateFlightZoneKey(index),
                name: zone.flightPlan?.length
                  ? t('sidebar.site.edit.sections.zones.table.body.column2.keyWithCount', {
                      count: zone.flightPlan.length,
                    })
                  : '',
                className: styles.flightPlansColumn,
              },
            ],
          };
        }),
    [programs, editInfo?.zones.zones],
  );

  const handleTableRowClick = useCallback(
    (zoneId: string, customName: string) => {
      dispatch(
        updateEditSiteZones({
          action: ESiteEditActions.SelectOne,
          data: {
            id: zoneId,
            customName: customName,
          },
        } as TSelectZonePayload),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (editInfo?.zones.selectedZone?.program_id) {
      const programId = String(editInfo.zones.selectedZone.program_id);

      dispatch(setCurrentProgramId(programId));
      dispatch(setCurrentInspectionIdByProgramId({ programId }));
      // TODO: need to add a separate sidebar mode for "edit site"
    }
  }, [editInfo?.zones.selectedZone?.program_id]);

  return (
    <>
      <ZonesBody
        text={{
          notification: t('sidebar.site.edit.sections.zones.notification'),
        }}
        head={head}
        body={body}
        onClick={handleTableRowClick}
      />
    </>
  );
};

export default ZonesEdit;
