const NUMERIC_SIZE = new Map([
  [[1e3, 1e6], 'k'],
  [[1e6, 1e9], 'm'],
  [[1e9, 1e12], 'b'],
]);

interface IParams {
  maxNumber?: number;
  fixed?: number;
}

export function formatNumber(currentNumber: number, options = {} as IParams) {
  const { maxNumber, fixed } = options;

  const num = maxNumber ?? currentNumber;

  for (const entry of NUMERIC_SIZE) {
    const [range, unit] = entry;
    const [min, max] = range;

    if (num >= min && num <= max) {
      let res = currentNumber / min;

      if (fixed) {
        res = Number(res.toFixed(fixed));
      }

      return [res, unit].join('');
    }
  }

  return currentNumber;
}
