import { EAnomalyStatus } from '@core/enums/anomalies';
import { IAnomaly } from '@core/interfaces/anomaly';

export const getAnomaliesStatus = (anomalies: IAnomaly[]) => {
  const amountReviews = anomalies?.filter(({ status }) => status === EAnomalyStatus.ToReview)
    .length;
  const amountFalse = anomalies?.filter(({ status }) => status === EAnomalyStatus.False).length;
  const amountMinor = anomalies?.filter(({ status }) => status === EAnomalyStatus.Minor).length;
  const amountMajor = anomalies?.filter(({ status }) => status === EAnomalyStatus.Major).length;

  return [
    { name: 'To Review', amount: amountReviews },
    { name: 'False', amount: amountFalse },
    { name: 'Minor', amount: amountMinor },
    { name: 'Major', amount: amountMajor },
  ];
};
