import { useRef } from 'react';
import mapboxgl, { MapboxOptions } from 'mapbox-gl';
import MapboxCompare from 'mapbox-gl-compare';
import { ACCESS_MAPBOX_TOKEN } from '@core/constants';
import { REGULAR_STYLE_URL, MAP_MAX_ZOOM } from '@core/constants/mapbox';
import { IMapConfig } from '@core/interfaces/map';

mapboxgl.accessToken = ACCESS_MAPBOX_TOKEN;

interface IProps {
  minZoom: number;
  compareMode?: boolean;
}

export const useMapbox = ({ minZoom, compareMode = false }: IProps) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const compareMapContainer = useRef<HTMLDivElement | null>(null);
  const beforeMapContainer = useRef<HTMLDivElement | null>(null);
  const afterMapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const compareMap = useRef<mapboxgl.Map | null>(null);
  const beforeMap = useRef<mapboxgl.Map | null>(null);
  const afterMap = useRef<mapboxgl.Map | null>(null);

  const handleZoomIn = () => {
    if (compareMode) {
      beforeMap.current?.zoomIn();
      afterMap.current?.zoomIn();
    } else {
      map.current?.zoomIn();
    }
  };
  const handleZoomOut = () => {
    if (compareMode) {
      beforeMap.current?.zoomOut();
      afterMap.current?.zoomOut();
    } else {
      map.current?.zoomOut();
    }
  };

  const handleZoomCenter = (points?: [number, number][]) => {
    if (!map.current || !points?.length) return;

    const bounds = new mapboxgl.LngLatBounds();

    points.forEach((point) => bounds.extend(point));

    map.current.fitBounds(bounds, { padding: 50 });
  };

  const handleZoomTo = (lng: number, lat: number, zoom: number = 12, isCompareMode = false) => {
    if (isCompareMode) {
      if (!beforeMap.current || !afterMap.current || !zoom) return;
      [beforeMap.current, afterMap.current].forEach((map) =>
        map.flyTo({
          zoom,
          center: [lng, lat],
          duration: 1700,
        }),
      );
    } else {
      if (!map.current || !zoom) return;

      map.current.flyTo({
        zoom,
        center: [lng, lat],
        duration: 1700,
      });
    }
  };

  const handleInitializing = (onLoaded?: () => void, compareMode = false, config?: IMapConfig) => {
    if (!compareMode) {
      let mapboxOptions: MapboxOptions = {
        container: mapContainer.current ?? '',
        style: REGULAR_STYLE_URL,
        minZoom,
        maxZoom: MAP_MAX_ZOOM,
        attributionControl: false,
        dragRotate: false,
        logoPosition: 'top-right',
      };

      if (config) {
        mapboxOptions = { ...mapboxOptions, ...config };
      }

      map.current = new mapboxgl.Map(mapboxOptions);
    } else {
      if (config) {
        beforeMap.current = new mapboxgl.Map({
          container: beforeMapContainer.current ?? '',
          style: REGULAR_STYLE_URL,
          minZoom: config.minZoom,
          maxZoom: config.maxZoom,
          attributionControl: false,
          dragRotate: false,
          center: config.center,
          zoom: config.initialZoom,
        });
        afterMap.current = new mapboxgl.Map({
          container: afterMapContainer.current ?? '',
          style: REGULAR_STYLE_URL,
          minZoom: config.minZoom,
          maxZoom: config.maxZoom,
          attributionControl: false,
          logoPosition: 'top-right',
          dragRotate: false,
          center: config.center,
          zoom: config.initialZoom,
        });

        compareMap.current = new MapboxCompare(
          beforeMap.current,
          afterMap.current,
          compareMapContainer.current,

          {
            mousemove: false,
            orientation: 'vertical',
            attributionControl: false,
          },
        );
      }
    }

    onLoaded && onLoaded();
  };

  return {
    map,
    compareMap,
    beforeMap,
    afterMap,
    mapContainer,
    beforeMapContainer,
    afterMapContainer,
    compareMapContainer,
    handleInitializing,
    handleZoomIn,
    handleZoomOut,
    handleZoomCenter,
    handleZoomTo,
  };
};
