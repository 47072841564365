import { createContext, useContext } from 'react';

export interface IEstimatedLossesValue {
  formattedMoneyPerYear: string;
  scrollTop?: number;
}

export const EstimatedLossesContext = createContext<IEstimatedLossesValue | undefined>(undefined);
export const useEstimatedLossesContext = (): IEstimatedLossesValue => {
  const context = useContext(EstimatedLossesContext);

  if (!context) {
    throw new Error('"useEstimatedLossesContext" must be used within an "EstimatedLossesContext"');
  }

  return context;
};
