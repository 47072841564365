import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Form, Formik, FormikErrors } from 'formik';
import outboardLogo from '@assets/icons/outboard-logo.svg';
import bgImage from '@assets/images/login-bg.jpg';
import { api } from '@core/api';
import { IAccessTokenRequest } from '@core/api/user/tokens';
import { useAuth } from '@core/contexts/auth';
import { EAsyncStatus, EClientErrors } from '@core/enums';
import { ELoginMode } from '@core/enums/accessControl';
import { useDispatchTyped } from '@core/hooks';
import {
  setLoadedUserError,
  setLoadedUserStatus,
  setPreloader,
  setUserData,
} from '@core/store/slices';
import { Button } from '@components/Button';
import { EButtonColor } from '@components/Button/Button';
import { NetworkConnectionModal } from '@components/Modal/components/NetworkConnection';
import styles from './styles.scss';
import { isDisabled } from '../..//utils/isDisabled';
import { FormContent } from '../../components/FormContent';
import { ToggleButton } from '../../components/ToggleButton';
import { getSubmitBtnText } from '../../utils/getSubmitBtnText';
import { getValidationSchema } from '../../utils/getValidationSchema';

const Login = () => {
  const { onLogin } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const [mode, setMode] = useState<ELoginMode>(ELoginMode.Login);
  const submitButton = getSubmitBtnText(mode);

  const loginUser = useCallback(
    async (values: IAccessTokenRequest, setErrors: any) => {
      try {
        dispatch(setPreloader(true));
        dispatch(setLoadedUserStatus(EAsyncStatus.Pending));

        const body: IAccessTokenRequest = {
          username: values.username.trim(),
          password: values.password,
        };

        await api.user.getAccessToken(body);
        const fetchedUser = await api.user.usersMe();

        dispatch(setUserData(fetchedUser));
        dispatch(setLoadedUserStatus(EAsyncStatus.Success));
        onLogin(fetchedUser);
      } catch (error) {
        const message = error instanceof AxiosError ? 'somethingIsWrong' : 'invalidEmailOrPassword';

        setErrors({ username: t(`errors.${message}`) });
        dispatch(setLoadedUserError(message));
        dispatch(setLoadedUserStatus(EAsyncStatus.Error));
      } finally {
        dispatch(setPreloader(false));
      }
    },
    [dispatch, onLogin],
  );

  const forgotPassword = useCallback(
    async (values: IAccessTokenRequest, { resetForm, setErrors }: any) => {
      if (mode === ELoginMode.SuccessNewPassword) {
        resetForm();
        setMode(ELoginMode.Login);
        return;
      }

      try {
        dispatch(setPreloader(true));

        await api.user.restorePassword(values.username.trim());
        setMode(ELoginMode.SuccessNewPassword);
      } catch (error) {
        const message =
          error instanceof AxiosError && error.response?.status === EClientErrors.BadRequest
            ? 'userNotFound'
            : 'somethingIsWrong';
        setErrors({ username: t(`errors.${message}`) });
      } finally {
        dispatch(setPreloader(false));
      }
    },
    [mode, dispatch],
  );

  const handleFormSubmit = useCallback(
    async (values: IAccessTokenRequest, { setErrors, resetForm }: any) => {
      if (mode === ELoginMode.Login) {
        return loginUser(values, setErrors);
      }

      forgotPassword(values, { setErrors, resetForm });
    },
    [mode, forgotPassword, loginUser],
  );

  const toggleForgotPasswordMode = useCallback(
    (setErrors: (errors: FormikErrors<IAccessTokenRequest>) => void) => {
      setErrors({});
      setMode((prevMode) =>
        prevMode === ELoginMode.Login ? ELoginMode.ForgetPassword : ELoginMode.Login,
      );
    },
    [],
  );

  return (
    <div className={styles.loginContainer}>
      <NetworkConnectionModal className={styles.loginConnectionNotification} />
      <img className={styles.backgroundImage} src={bgImage} alt='logo' />
      <div className={styles.loginForm}>
        <div className={styles.logo}>
          <img src={outboardLogo} alt='outboard logo' />
        </div>
        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={getValidationSchema(mode)}
          onSubmit={handleFormSubmit}
        >
          {({ values, setErrors }) => (
            <Form>
              <FormContent values={values} mode={mode} />
              <Button color={EButtonColor.Blue} type='submit' disabled={isDisabled(values, mode)}>
                {submitButton}
              </Button>
              <ToggleButton mode={mode} onClick={() => toggleForgotPasswordMode(setErrors)} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
