import { formatNumber } from '../formatting/formatNumber';

interface IOptions {
  x: number;
  y: number;
  title: string;
  value: number;
  maxNumber: number;
}

export const setSubgroupText = (svg, options: IOptions) => {
  const { x, y, title, value, maxNumber } = options;
  const formattedValue = formatNumber(value, { maxNumber, fixed: 2 });

  svg
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('fill', 'black')
    .attr('x', x)
    .attr('y', y)
    .style('font-size', '12px')
    .style('font-weight', '600')
    .style('line-height', '11.7')
    .style('font-family', 'Inter, sans-serif')
    .text(`${title} (${formattedValue})`);
};
