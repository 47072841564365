import { anomaliesStatusesColor, nonActiveAnomalyMaskColor } from '@core/constants';
import { EDetectionStatus } from '@core/enums';
import { EAnomalyStatus } from '@core/enums/anomalies';
import { ISample, ISampleDetection } from '@core/interfaces';
import { IAnomaly } from '@core/interfaces/anomaly';

interface IAnomalyMaskParams {
  selectedSample: ISample;
  selectedAnomaly: IAnomaly | undefined;
}

export function getAnomalyMaskParams({ selectedSample, selectedAnomaly }: IAnomalyMaskParams) {
  const activeDetections = (selectedSample.detections as ISampleDetection[]).filter(
    (detection) => detection.status === EDetectionStatus.Active,
  );
  const nonActiveDetections = (selectedSample.detections as ISampleDetection[]).filter(
    (detection) => detection.status === EDetectionStatus.NonActive,
  );

  return {
    [EDetectionStatus.Active]: {
      detections: activeDetections,
      lineWidth: 1,
      color:
        anomaliesStatusesColor[selectedAnomaly ? selectedAnomaly.status : EAnomalyStatus.Major],
      opacity: 0.3,
    },
    [EDetectionStatus.NonActive]: {
      detections: nonActiveDetections,
      lineWidth: 1,
      color: nonActiveAnomalyMaskColor,
      opacity: 0.3,
    },
  };
}
