import { FC, Fragment, memo } from 'react';
import { ReactSVG } from 'react-svg';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import crossIcon from '@assets/icons/cross.svg';
import validIcon from '@assets/icons/valid.svg';
import {
  MAIN_TRANSITION_PROPS,
  OVERLAY_TRANSITION_PROPS,
  PANEL_TRANSITION_PROPS,
} from '@core/constants';
import styles from './styles.scss';

interface IProps {
  title: string;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onDecline: () => void;
  onConfirm: () => void;
}

const Modal: FC<IProps> = ({ title, isOpen, className, onClose, onDecline, onConfirm }) => {
  return (
    <Transition show={isOpen} as={Fragment} {...MAIN_TRANSITION_PROPS}>
      <Dialog className={cn('fixed z-10 inset-0 overflow-y-auto', className)} onClose={onClose}>
        <div className='flex min-h-full items-center justify-center'>
          <Transition.Child as={Fragment} {...OVERLAY_TRANSITION_PROPS}>
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-50' />
          </Transition.Child>
          <Transition.Child as={Fragment} {...PANEL_TRANSITION_PROPS}>
            <Dialog.Panel className={cn('relative', styles.panel)}>
              <Dialog.Title className={styles.title}>{title}</Dialog.Title>
              <div className={styles.controls}>
                <button className={cn(styles.btnControl, styles.decline)} onClick={onDecline}>
                  <ReactSVG className={styles.img} src={crossIcon} />
                </button>
                <button className={cn(styles.btnControl, styles.confirm)} onClick={onConfirm}>
                  <ReactSVG className={styles.img} src={validIcon} />
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default memo(Modal);
