/* eslint-disable @typescript-eslint/ban-ts-comment */
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, Polygon } from 'geojson';
import { ESidebar } from '@core/enums';
import { MapStylePerimeter } from '@modules/Viewers/views/MapViewer/utils/mapboxStyles';
import { getDifferenceMetaCount } from './getDifferenceMetaCount';
import { getFeatureMetaCount } from './getFeatureMetaCount';
import { getMapboxDrawFeatures } from './getMapboxDrawFeatures';
import { ECreateSiteLayers } from '../../../enums/layers';
import { ECreateSiteSources } from '../../../enums/sources';
import { customizeTrash } from '../../customizeTrash';
import { removeLayersAndSources } from '../../removeLayersAndSources';

interface IProps {
  map: mapboxgl.Map | null;
  sidebar: ESidebar;
  perimeter: Polygon | FeatureCollection | null;
  onGeoJsonChange: (geoJson: FeatureCollection) => void;
}

let draw: MapboxDraw | null = null;
let currentMapboxDrawFeatures: Feature<Geometry, GeoJsonProperties>[] = [];
let lastKnownGeoJson: FeatureCollection<Geometry, GeoJsonProperties> | null = null;

export const addEditPerimeter = (props: IProps) => {
  const { map, sidebar, perimeter, onGeoJsonChange } = props;

  if (!map) return;

  if (sidebar !== ESidebar.CreateSite || !perimeter) {
    removeLayersAndSources(map, {
      [ECreateSiteSources.Perimeter]: [ECreateSiteLayers.Perimeter],
    });

    if (draw) {
      map.removeControl(draw);
      draw = null;
    }
    return;
  }

  const updateGeoJson = () => {
    if (!draw) return;
    let data = draw.getAll();

    if (data.features.length > 1) {
      const featureId = data.features[0]?.id;

      if (featureId) {
        draw.delete(featureId.toString());
        data = draw.getAll();
      }
    }

    if (data.features.length > 0) {
      onGeoJsonChange(data);
      lastKnownGeoJson = data;
    }
  };

  map.on('draw.delete', () => {
    setTimeout(() => {
      const data = draw?.getAll();
      if (!data?.features.length && lastKnownGeoJson) {
        draw?.add(lastKnownGeoJson);
      }
    }, 100);
  });

  if (!map.getSource(ECreateSiteSources.Perimeter)) {
    map.addSource(ECreateSiteSources.Perimeter, {
      type: 'geojson',
      data: perimeter,
    });
  } else {
    // @ts-ignore
    (map.getSource(ECreateSiteSources.Perimeter) as mapboxgl.GeoJSONSource).setData(perimeter);
  }

  if (!draw) {
    draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        trash: true,
      },
      styles: MapStylePerimeter,
    });

    map.addControl(draw, 'top-right');
    customizeTrash();

    map.on('click', () => {
      // NOTE: update "currentMapboxDrawFeatures" on each click
      getMapboxDrawFeatures(map).then((features) => (currentMapboxDrawFeatures = features));
    });

    map.on('draw.create', updateGeoJson);
    map.on('draw.delete', updateGeoJson);
    map.on('draw.update', async () => {
      const updatedDrawFeatures = await getMapboxDrawFeatures(map);
      const currentDrawFeaturesMetaInfo = getFeatureMetaCount(currentMapboxDrawFeatures);
      const updatedDrawFeaturesMetaInfo = getFeatureMetaCount(updatedDrawFeatures);
      const midpointsDifference = getDifferenceMetaCount(
        currentDrawFeaturesMetaInfo,
        updatedDrawFeaturesMetaInfo,
        'midpoint',
      );
      const vertexesDifference = getDifferenceMetaCount(
        currentDrawFeaturesMetaInfo,
        updatedDrawFeaturesMetaInfo,
        'vertex',
      );

      // NOTE: update geojson when perimeter is changed without adding midpoint or vertex
      if (midpointsDifference === 0 && vertexesDifference === 0) {
        updateGeoJson();
      }

      currentMapboxDrawFeatures = updatedDrawFeatures;
    });
  }

  // TODO: delete the whole if block after solving the issue of generating the perimeter (OD-491 ticket)
  if ('coordinates' in perimeter && perimeter.coordinates?.length === 0) {
    setTimeout(() => {
      draw?.changeMode('draw_polygon');
    }, 100);
  }

  if (draw) {
    draw.deleteAll();
    draw.add(perimeter);

    const addedFeatures = draw.getAll();

    if (addedFeatures && addedFeatures.features && addedFeatures.features.length > 0) {
      const featureId = addedFeatures.features[0].id;
      if (featureId) {
        draw.changeMode('direct_select', { featureId: featureId.toString() });
      }
    }

    getMapboxDrawFeatures(map).then((features) => (currentMapboxDrawFeatures = features));
  }
};
