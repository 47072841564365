import { weatherAxiosInstance } from '@core/api';
import {
  EWeatherAPIElements,
  EWeatherAPIIconSet,
  EWeatherAPIInclude,
  EWeatherAPILanguage,
  EWeatherAPIUnitGroup,
} from '@core/constants/planInspection';
import { IWeatherAPIResponse } from '@core/interfaces';
import { addQueryParamsToUrl } from '@core/utils';

export interface IForecastQueryParams {
  unitGroup?: EWeatherAPIUnitGroup;
  lang?: EWeatherAPILanguage;
  iconSet?: EWeatherAPIIconSet;
  include?: EWeatherAPIInclude;
  elements?: EWeatherAPIElements;
  key: string;
}
interface IForecastLocation {
  lat: number;
  long: number;
}
interface IForecastQueryProps {
  params: IForecastQueryParams;
  location: IForecastLocation;
}

export const getWeatherForecast = async ({
  params,
  location,
}: IForecastQueryProps): Promise<IWeatherAPIResponse> => {
  const endpoint = addQueryParamsToUrl(`timeline/${location.lat}, ${location.long}`, params);
  return weatherAxiosInstance.get<IWeatherAPIResponse>(endpoint).then((resp) => resp.data);
};
