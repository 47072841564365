import _ from 'lodash';
import { IExtendedSubgroup } from '../aggregations/transformChartData';

export const getGroupHeight = (subgroups: IExtendedSubgroup) => {
  return _.sum(Object.values(subgroups).map((v) => v.height));
};

export const getGroupWidth = (subgroups: IExtendedSubgroup) => {
  return Object.values(subgroups).map((subgroup) => subgroup.width)[0];
};

export const getFilteredSubgroupsHeight = (filteredSubgroups: IExtendedSubgroup[]) => {
  return _.sum(filteredSubgroups.map((subgroup) => subgroup.height));
};
