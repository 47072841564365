import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '@core/router/routerConfig';
import { useCreateSitePerimeter, useCreateSiteZones, logoutUser } from '@core/store/slices';
import { ETimeUnit, getMilliseconds } from '@core/utils';
import { useDispatchTyped } from './useReduxTyped';

interface IAutoLogoutDeps {
  timeoutInMinutes: number;
  onAuth: (isAuthenticated: boolean) => void;
}

export const useAutoLogout = ({ timeoutInMinutes, onAuth }: IAutoLogoutDeps) => {
  const timeoutRef = useRef<number | NodeJS.Timeout>(0);
  const dispatch = useDispatchTyped();
  const timeoutInMs = getMilliseconds(timeoutInMinutes, ETimeUnit.Minute);
  const { isLoadingZones } = useCreateSiteZones();
  const { isLoadingSite } = useCreateSitePerimeter();
  const navigate = useNavigate();

  const handleLogoutUser = () => {
    if (isLoadingZones || isLoadingSite) {
      resetTimer();
      return;
    }

    dispatch(logoutUser());
    resetTimer();
    onAuth(false);
    navigate(ROUTE_PATHS.Login.Root);
  };

  const resetTimer = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(handleLogoutUser, timeoutInMs);
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(handleLogoutUser, timeoutInMs);
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('click', resetTimer);

    return () => {
      clearTimeout(timeoutRef.current);
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('click', resetTimer);
    };
  }, [timeoutInMinutes]);
};
