import { EAnomalyMode, EAnomalyRepairmentStatus } from '@core/enums/anomalies';
import { IAnomaly } from '@core/interfaces/anomaly';
import { isNumber } from '@core/utils';
import { TransformedSites } from '@modules/Sidebar/widgets/EstimatedLosses/interfaces';
import {
  getDisconnectedCostByReportAnomaly,
  getThermalCostByReportAnomaly,
  getVisualCostByReportAnomaly,
  getVisualCostByReportAnomalyFromMixed,
} from '@modules/Sidebar/widgets/EstimatedLosses/utils/formulas/anomaliesCosts';

type ReportAnomaliesCostsResponse = {
  [key in EAnomalyRepairmentStatus]: {
    [EAnomalyMode.Thermal]: number;
    [EAnomalyMode.String]: number;
    [EAnomalyMode.Visual]: number;
  };
};

type SeparatedAnomaliesByStatus = {
  [key in EAnomalyRepairmentStatus]: IAnomaly[];
};

function getDefaultAnomalyCosts() {
  return {
    [EAnomalyMode.Thermal]: 0,
    [EAnomalyMode.String]: 0,
    [EAnomalyMode.Visual]: 0,
  };
}

function getDefaultAnomalyCostsByRepairmentStatus(
  defaultAnomalyCostsValue: ReturnType<typeof getDefaultAnomalyCosts>,
) {
  return Object.values(EAnomalyRepairmentStatus).reduce(
    (acc, repairmentStatus) => ({
      ...acc,
      [repairmentStatus]: defaultAnomalyCostsValue,
    }),
    {} as ReportAnomaliesCostsResponse,
  );
}

export function getAnomaliesCostsByModes(
  anomaliesByRepairmentStatus: SeparatedAnomaliesByStatus,
  sites: TransformedSites,
) {
  return Object.entries(anomaliesByRepairmentStatus).reduce<ReportAnomaliesCostsResponse>(
    (mainAcc, [repairmentStatus, anomalies]) => {
      const costsByRepairmentStatus = anomalies.reduce((anomaliesAcc, anomaly) => {
        const {
          thermal_surface: thermalSurface,
          visual_surface: visualSurface,
          disconnected_panels: disconnectedPanels,
          location_id: locationId,
        } = anomaly;

        const areEnabledCalculationFields = [
          visualSurface,
          thermalSurface,
          disconnectedPanels,
        ].every(isNumber);

        const selectedSite = locationId ? sites[locationId] : null;

        if (areEnabledCalculationFields && selectedSite) {
          const {
            thermal_factor: thermalFactor,
            rgb_factor: rgbFactor,
            cost_per_defective_panel: costPerDefectivePanel,
          } = selectedSite;

          switch (anomaly.mode) {
            case EAnomalyMode.Visual: {
              if (rgbFactor && costPerDefectivePanel) {
                anomaliesAcc[EAnomalyMode.Visual] += getVisualCostByReportAnomaly({
                  visualSurface: visualSurface as number,
                  rgbFactor,
                  costPerDefectivePanel,
                });
              }
              break;
            }
            case EAnomalyMode.Thermal: {
              if (thermalFactor && costPerDefectivePanel) {
                anomaliesAcc[EAnomalyMode.Thermal] += getThermalCostByReportAnomaly({
                  thermalSurface: thermalSurface as number,
                  thermalFactor,
                  costPerDefectivePanel,
                });
              }
              break;
            }
            case EAnomalyMode.Mixed: {
              if (rgbFactor && thermalFactor && costPerDefectivePanel) {
                if (isNumber(visualSurface) && isNumber(thermalSurface)) {
                  anomaliesAcc[EAnomalyMode.Visual] += getVisualCostByReportAnomalyFromMixed({
                    visualSurface,
                    thermalSurface,
                    rgbFactor,
                    costPerDefectivePanel,
                  });

                  anomaliesAcc[EAnomalyMode.Thermal] += getThermalCostByReportAnomaly({
                    thermalSurface: thermalSurface as number,
                    thermalFactor,
                    costPerDefectivePanel,
                  });
                }
              }
              break;
            }
            case EAnomalyMode.String: {
              if (costPerDefectivePanel) {
                anomaliesAcc[EAnomalyMode.String] += getDisconnectedCostByReportAnomaly({
                  disconnectedPanels: disconnectedPanels as number,
                  costPerDefectivePanel,
                });
              }
              break;
            }
          }
        }

        return anomaliesAcc;
      }, getDefaultAnomalyCosts());

      return {
        ...mainAcc,
        [repairmentStatus]: costsByRepairmentStatus,
      };
    },
    getDefaultAnomalyCostsByRepairmentStatus(getDefaultAnomalyCosts()),
  );
}
