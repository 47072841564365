import { axiosInstance } from '@core/api/axios';

export interface IGpsBoundaries {
  bottom_right_lat: number;
  bottom_right_lng: number;
  top_left_lat: number;
  top_left_lng: number;
}

export interface ICreateLocationRequest {
  name?: string;
  lat: number;
  long: number;
  altitude?: number;
  gps_boundaries: IGpsBoundaries;
  picture?: string;
  inspection_frequency?: number;
  location_type: string;
}

export interface ICreateLocationResponse {
  name: string;
  lat: number;
  long: number;
  altitude: number;
  gps_boundaries: IGpsBoundaries;
  picture: string;
  inspection_frequency: number;
  loc_id: string;
  tenant_name: string;
}

export const create = async (body: ICreateLocationRequest) => {
  return axiosInstance.post<ICreateLocationResponse>(`/locations`, body).then((res) => res.data);
};
