import { FC, memo } from 'react';
import cn from 'classnames';
import { ISample } from '@core/interfaces';
import styles from './styles.scss';
import { ELoadingState } from '../../enums/loading';

interface IProps {
  alt?: string;
  className?: string;
  sample: ISample;
  isLoaded: boolean;
  onLoad: (type: ELoadingState) => void;
}

export const AnomalyMask: FC<IProps> = memo(
  ({ sample, isLoaded, className, onLoad, alt = 'anomaly mask' }) => {
    const handleLoadImage = () => onLoad(ELoadingState.Anomaly);

    return (
      <img
        src={sample.anomaly_mask}
        className={cn(styles.image, { [styles.imageLoaded]: !isLoaded }, className)}
        alt={alt}
        onLoad={handleLoadImage}
      />
    );
  },
);
