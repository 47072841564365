import { FC, memo } from 'react';
import cn from 'classnames';
import { t } from 'i18next';
import { ESidebar } from '@core/enums';
import { IAnomaly } from '@core/interfaces/anomaly';
import { useCurrentReportSelector, useSidebarSelector } from '@core/store/slices';
import styles from './styles.scss';
import { SiteView, ZoneView } from './views';

const getters = {
  getAnomalyTitle(anomalies?: IAnomaly[]) {
    return t('sidebar.anomaly.anomalies.keyWithCount', {
      count: anomalies?.length ?? 0,
    });
  },
};

const views = {
  [ESidebar.Site]: () => <SiteView />,
  [ESidebar.Zone]: () => <ZoneView />,
};

interface IProps {
  className?: string;
}

export const AnomalyStatistic: FC<IProps> = memo(({ className }) => {
  const { sidebar } = useSidebarSelector();
  const currentReport = useCurrentReportSelector();

  return (
    <div className={cn(styles.root, className)}>
      <h2 className={styles.title}>{getters.getAnomalyTitle(currentReport?.anomalies)}</h2>
      {views[sidebar]()}
    </div>
  );
});
