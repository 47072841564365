import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import redo from '@assets/icons/redo.svg';
import undo from '@assets/icons/undo.svg';
import { EDynamicZoomActions } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { nextPerimeter, previousPerimeter, useCreateSitePerimeter } from '@core/store/slices';
import { reloadMap, setDynamicZoom } from '@core/store/slices/map';
import styles from './styles.scss';

interface IProps {}

const PerimeterBody: FC<IProps> = () => {
  const { t } = useTranslation();
  const { currentIndex: currentPerimeterIndex, data: perimeters } = useCreateSitePerimeter();

  const dispatch = useDispatchTyped();

  const isPreviousDisabled = Boolean(currentPerimeterIndex !== null && currentPerimeterIndex === 0);
  const isNextDisabled = Boolean(
    currentPerimeterIndex !== null && currentPerimeterIndex === perimeters.length - 1,
  );

  const handleNextPerimeter = () => {
    dispatch(nextPerimeter());
    dispatch(
      setDynamicZoom({
        action: EDynamicZoomActions.AddPerimeterCreateSite,
        isAllowedZoom: false,
      }),
    );
    dispatch(reloadMap(true));
  };

  const handlePreviousPerimeter = () => {
    dispatch(previousPerimeter());
    dispatch(
      setDynamicZoom({
        action: EDynamicZoomActions.AddPerimeterCreateSite,
        isAllowedZoom: false,
      }),
    );
    dispatch(reloadMap(true));
  };

  // TODO: change to 'sidebar.site.edit.sections.perimeter.notification' after solving the issue of generating the perimeter (OD-491 ticket)

  return (
    <div className={styles.body}>
      <h2 className={styles.notification}>
        {t('sidebar.site.edit.sections.perimeter.temporaryNotification')}
      </h2>
      <div className={styles.btnControls}>
        <button
          disabled={isPreviousDisabled}
          onClick={handlePreviousPerimeter}
          className={styles.btn}
        >
          <img src={undo} alt='undo' />
        </button>
        <button disabled={isNextDisabled} onClick={handleNextPerimeter} className={styles.btn}>
          <img src={redo} alt='redo' />
        </button>
      </div>
    </div>
  );
};

export default memo(PerimeterBody);
