/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { round } from 'lodash';
import { areAllValuesValid } from '@core/utils';
import { IChartData, IStackedBarChartStyles, ISubgroup } from '@modules/Sidebar/interfaces';
import {
  IExtendedSubgroup,
  aggregateGroupValues,
  aggregateSubgroupsValues,
  getFilteredSubgroupsHeight,
  getGroupHeight,
  getGroupWidth,
  getGroupXBarPosition,
  getGroupXTextPosition,
  getGroupYTextPosition,
  getMaxValueFromGroups,
  getMaxYAxisValue,
  getStackedBarHeight,
  getSubgroupYTextPosition,
  setHorizontalGrid,
  setSubgroupText,
  setXAxis,
  setYAxis,
  sortSubgroupByPosition,
  transformChartData,
} from '@modules/Sidebar/utils/charts';
import { getField } from '@modules/Sidebar/utils/shared/getField';
import { TOOLTIP_PRECISION_VALUE } from '@modules/Sidebar/widgets/EstimatedLosses/constants';
import { EEstimatedLossesTooltipValue } from '@modules/Sidebar/widgets/EstimatedLosses/enums';
import { getGroupText } from '@modules/Sidebar/widgets/EstimatedLosses/utils/stylization';
import { getCenter } from '@components/Chart/DonutChart/utils/getCalculations';
import { ChartTooltip, ITooltipProps } from '@components/Tooltip';

interface IStackedChartProps {
  chartData: IChartData[];
  stylesData: IStackedBarChartStyles;
}

export const StackedBarChart: React.FC<IStackedChartProps> = ({ chartData, stylesData }) => {
  const [tooltip, setTooltip] = useState<ITooltipProps | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const { chart, bar } = stylesData;

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = d3.select(svgRef.current);

    const groupDomainTitles = chartData.map(getField<string>('data.group.value'));
    const groupsSubgroups = chartData.map(getField<ISubgroup[]>('data.subgroups'));

    // NOTE: Sort only one group with subgroups,
    //       because titles and colors the same for each of subgroup
    const sortedSubgroupsByPosition = sortSubgroupByPosition(groupsSubgroups[0]);
    const subgroupsTitles = sortedSubgroupsByPosition.map(getField<string>('title'));
    const subgroupsColors = sortedSubgroupsByPosition.map(getField<string>('color'));

    const groupsWithAggregatedSubgroupsValues = aggregateSubgroupsValues(chartData);
    const transformedGroupPairs = aggregateGroupValues(chartData);

    // 1. Init chart measures
    svg
      .attr('width', chart.width)
      .attr('height', chart.height)
      .append('g')
      .attr('transform', `translate(${chart.margin.left}, ${chart.margin.top})`);

    // 2. Set x-axis
    const x = d3
      .scaleBand()
      .domain(groupDomainTitles)
      .range([0, chart.width - chart.margin.left]);

    // 3. Set y-axis
    const yMax = getMaxValueFromGroups(groupsSubgroups);
    const yAxisMaxValue = getMaxYAxisValue(yMax);

    const y = d3
      .scaleLinear()
      .domain([0, yAxisMaxValue])
      .range([chart.height - chart.margin.bottom, chart.margin.top]);

    // 4. Prepare the data
    const color = d3.scaleOrdinal().domain(subgroupsTitles).range(subgroupsColors);
    const stackedData = d3.stack().keys(subgroupsTitles)(groupsWithAggregatedSubgroupsValues);

    const transformedChartData = transformChartData(chartData);

    // NOTE: ADD horizontal grid lines
    setHorizontalGrid(svg, y, {
      xOffset: chart.margin.left,
      width: chart.width,
      strokeColor: chart.grid.stroke,
    });

    svg
      .append('g')
      .selectAll('g')
      .data(stackedData)
      .enter()
      .append('g')
      // @ts-ignore
      .attr('fill', (d) => color(d.key))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (_, i) =>
        getGroupXBarPosition({
          xNextBar: bar.width * i,
          marginLeft: chart.margin.left,
          correctionValue: i === 0 ? bar.padding.left : bar.padding.left + bar.gap,
        }),
      )
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => {
        const [start, end] = d;

        const diff = round(Math.abs(start - end), 1);
        const height = getStackedBarHeight(y, start, end);

        const foundSubgroup = Object.entries(d.data).filter(([_, value]) => value === diff);

        // NOTE: Set height for each subgroup
        for (const [title] of foundSubgroup) {
          const subgroup = transformedChartData[d.data.group][title];

          if (!subgroup.height) {
            transformedChartData[d.data.group][title].height = height;
          }
        }

        return height;
      })
      .attr(
        'width',
        (() => {
          // NOTE: Set width for each subgroup (the same)
          for (const group in transformedChartData) {
            const subgroups = transformedChartData[group];

            for (const subgroup in subgroups) {
              transformedChartData[group][subgroup].width = bar.width;
            }
          }
          return bar.width;
        })(),
      )
      // NOTE: Handlers section
      .on('mousemove', (event: MouseEvent, d) => {
        const [start, end] = d;
        const parentNode = (event.target as HTMLElement).parentNode as SVGGElement;
        const stackData = d3.select(parentNode).datum() as any;

        if (stackData.key) {
          const position = {
            x: event.pageX,
            y: event.pageY,
          };

          const data = [
            {
              key: EEstimatedLossesTooltipValue.Group,
              value: transformedGroupPairs[d.data.group],
            },
            {
              key: EEstimatedLossesTooltipValue.Subgroup,
              value: stackData.key,
              color: color(stackData.key) as string,
            },
            {
              key: EEstimatedLossesTooltipValue.Value,
              value: round(end - start, TOOLTIP_PRECISION_VALUE),
            },
          ];

          setTooltip({ position, data });
        }
      })
      .on('mouseout', (event: MouseEvent) => {
        const relatedTarget = event.relatedTarget as HTMLElement | null;
        if (relatedTarget && relatedTarget.tagName === 'text') return;

        setTooltip(null);
      });

    chartData.forEach(({ metadata, data }, i) => {
      const { group } = data;

      const subgroups = transformedChartData[group.key];
      const [groupHeight, groupWidth] = [getGroupHeight, getGroupWidth].map((fn) => fn(subgroups));
      const xNextBar = groupWidth * (i + 1);

      const xGroupPosition = getGroupXTextPosition({
        xNextBar,
        groupWidth,
        marginLeft: chart.margin.left,
        correctionValue: bar.gap + bar.padding.left,
      });

      const yGroupPosition = getGroupYTextPosition({
        chartHeight: chart.height,
        marginBottom: chart.margin.bottom,
        groupHeight: groupHeight,
        correctionValue: 15,
      });

      if (
        metadata &&
        areAllValuesValid(
          metadata.bar.title.color,
          metadata.bar.title.value,
          metadata.bar.title.costRatio,
        )
      ) {
        getGroupText(svg, {
          x: xGroupPosition,
          y: yGroupPosition,
          color: metadata.bar.title.color,
          losses: metadata.bar.title.value,
          costRatio: metadata.bar.title.costRatio,
        });
      }

      const subgroupEntries = Object.entries(subgroups);

      subgroupEntries.forEach(([title, values]) => {
        const { height: subgroupHeight, value } = values as IExtendedSubgroup;

        const filteredSubgroups = Object.values(subgroups).filter(
          (v: any) => v.position > (values as any).position,
        );
        const aggregatedHeightOfFilteredSubgroups = getFilteredSubgroupsHeight(filteredSubgroups);
        const ySubgroupCenter = getCenter(subgroupHeight);
        const yAbsoluteSubgroupHeight = aggregatedHeightOfFilteredSubgroups + ySubgroupCenter;

        const ySubgroupPosition = getSubgroupYTextPosition({
          chartHeight: chart.height,
          subgroupAbsoluteHeight: yAbsoluteSubgroupHeight,
          correctionValue: 40,
        });

        if (value) {
          setSubgroupText(svg, {
            x: xGroupPosition,
            y: ySubgroupPosition,
            title,
            value,
            maxNumber: yAxisMaxValue,
          });
        }
      });
    });

    setYAxis(svg, y, {
      marginLeft: chart.margin.left,
      maxNumber: yAxisMaxValue,
    });
    setXAxis(svg, x, {
      marginLeft: chart.margin.left,
      marginBottom: chart.margin.bottom,
      chartHeight: chart.height,
    });

    // Clean up
    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };
  }, [chartData]);

  return (
    <>
      <svg ref={svgRef} />
      {tooltip && <ChartTooltip {...tooltip} />}
    </>
  );
};
