import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { flowRight, round, sum } from 'lodash';
import { EAnomalyRepairmentStatus } from '@core/enums/anomalies';
import { useCachedValue } from '@core/hooks';
import {
  useCurrentInspectionSelector,
  useReportsByInspectionIdsSelector,
  useSitesSelector,
} from '@core/store/slices';
import { toNegative } from '@core/utils/math';
import { useEstimatedLossesContext } from '@modules/Sidebar/widgets/EstimatedLosses/contexts';
import {
  getAnomaliesCosts,
  getReportsCosts,
  getSeparatedAnomaliesByStatus,
  getVisibleAnomalies,
  transformSites,
} from '@modules/Sidebar/widgets/EstimatedLosses/utils';
import styles from '../../styles.scss';

export const SitesView: FC = () => {
  const { t } = useTranslation();
  const contextValue = useEstimatedLossesContext();
  const { sitesSidebar } = useSitesSelector();
  const currentInspection = useCurrentInspectionSelector();

  const visibleReports = useReportsByInspectionIdsSelector(
    currentInspection ? [currentInspection.id] : [],
  );
  const cachedVisibleReports = useCachedValue(visibleReports);
  const cachedSitesSidebar = useCachedValue(sitesSidebar);

  const transformedSitesSidebar = useMemo(
    () => transformSites(cachedSitesSidebar),
    [cachedSitesSidebar],
  );

  const visibleAnomalies = useMemo(
    () => getVisibleAnomalies(cachedVisibleReports),
    [cachedVisibleReports],
  );

  const separatedAnomaliesByRepairmentStatus = useMemo(
    () => getSeparatedAnomaliesByStatus(visibleAnomalies),
    [visibleAnomalies],
  );

  const reportCosts = useMemo(
    () => getReportsCosts(cachedVisibleReports, transformedSitesSidebar),
    [cachedVisibleReports, transformedSitesSidebar],
  );

  const anomalyCosts = useMemo(
    () => getAnomaliesCosts(separatedAnomaliesByRepairmentStatus, transformedSitesSidebar),
    [separatedAnomaliesByRepairmentStatus, transformedSitesSidebar],
  );

  const totalCostByReports = useMemo(
    () => flowRight(round, sum)(Object.values(reportCosts)),
    [reportCosts],
  );

  const totalCostToRepairByReportsAnomalies = useMemo(
    () => flowRight(round, sum)(Object.values(anomalyCosts[EAnomalyRepairmentStatus.ToRepair])),
    [anomalyCosts[EAnomalyRepairmentStatus.ToRepair]],
  );

  return (
    <div className={styles.tilesList}>
      <section className={styles.tile}>
        <h3 className={styles.tileTitle}>
          {t('sidebar.widgets.estimatedLosses.tiles.atInspection.title')}
        </h3>
        <div className={cn(styles.counter, styles.atInspection)}>
          <span className={styles.counterValue}>{toNegative(totalCostByReports)}</span>
          <div className={styles.moneyPerYear}>{contextValue.formattedMoneyPerYear}</div>
        </div>
      </section>
      <section className={styles.tile}>
        <h3 className={styles.tileTitle}>
          {t('sidebar.widgets.estimatedLosses.tiles.afterRepair.title', {
            repaired:
              separatedAnomaliesByRepairmentStatus[EAnomalyRepairmentStatus.Repaired].length,
            total: visibleAnomalies.length,
          })}
        </h3>
        <div className={cn(styles.counter, styles.afterRepair)}>
          <span className={styles.counterValue}>
            {toNegative(totalCostToRepairByReportsAnomalies)}
          </span>
          <div className={styles.moneyPerYear}>{contextValue.formattedMoneyPerYear}</div>
        </div>
      </section>
    </div>
  );
};
