import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EControlMode, ERoles, ESidebar, EViewer } from '@core/enums';
import { EAnomalyMode, EAnomalyRepairmentStatus, EAnomalyStatus } from '@core/enums/anomalies';
import { useDispatchTyped } from '@core/hooks';
import {
  setCurrentAnomalyId,
  setSidebar,
  setSidebarViewer,
  setViewer,
  useControlsSelector,
  useCurrentAnomalySelector,
  useCurrentSiteSelector,
  useAccessControlSelector,
  useCurrentReportAnomaliesSelector,
  updateAnomaly,
} from '@core/store/slices';
import { useCurrentProgramSelector } from '@core/store/slices/programs';
import { useCurrentReportSelector } from '@core/store/slices/reports';
import {
  setAnomalySamples,
  setSelectedSample,
  useSamplesSelector,
} from '@core/store/slices/samples';
import { authStorage } from '@core/utils';
import { useMainContext } from '@modules/Layout/contexts/main';
import { Navbar } from '@modules/Sidebar/components';
import { InspectionsView } from '@modules/Sidebar/components/InspectionsView';
import { AnomalyDetails } from '@modules/Sidebar/widgets/AnomalyDetails';
import { EstimatedLosses } from '@modules/Sidebar/widgets/EstimatedLosses';
import CommentSectionAnomaly from '@components/CommentSection/CommentSectionAnomaly';
import AnomalyTiles from './components/Tiles/Root';
import { ANOMALY_TILES, RESET_ESTIMATED_LOSSES_ANOMALY_FIELDS } from './config';
import { EAnomalyTiles } from './enums';
import styles from './styles.scss';
import { getAnomalyId } from './utils/getAnomalyId';

export const Anomaly = () => {
  const {
    model: { sidebarOrbitControl },
  } = useMainContext();
  const { t } = useTranslation();
  const currentSite = useCurrentSiteSelector();
  const zone = useCurrentProgramSelector();
  const { mode } = useControlsSelector();
  const currentReport = useCurrentReportSelector();
  const anomaly = useCurrentAnomalySelector();
  const anomalies = useCurrentReportAnomaliesSelector();
  const { anomalySamples, selectedSample } = useSamplesSelector();
  const { user } = useAccessControlSelector();
  const [scrollTop, setScrollTop] = useState(0);

  const [selectedRepairStatus, setSelectedRepairStatus] = useState<EAnomalyRepairmentStatus | null>(
    anomaly?.repairment_status ?? null,
  );
  const [selectedSeverityStatus, setSelectedSeverityStatus] = useState<EAnomalyStatus | null>(
    anomaly?.status ?? null,
  );
  const [selectedTypeStatus, setSelectedTypeStatus] = useState<EAnomalyMode | null>(
    anomaly?.mode ?? null,
  );

  const dispatch = useDispatchTyped();

  const isEditor = useMemo(() => {
    const tenant = authStorage.tenant.get();

    return tenant && user?.data?.roles[tenant]
      ? user.data.roles[tenant].includes(ERoles.Editor)
      : false;
  }, [user?.data?.roles]);

  const handleSwitch = (index: number) => dispatch(setSelectedSample(anomalySamples[index]));

  const handleBackClick = () => {
    if (mode === EControlMode['3D']) {
      // 1. use case (click on anomaly in a sidebar):
      // Problem with closures when obviously unmounting a component (Inspection View).
      sidebarOrbitControl.current?.dispose();
      sidebarOrbitControl.current = null;
      dispatch(setViewer(EViewer.Model));
    } else {
      dispatch(setViewer(EViewer.Map));
    }
    dispatch(setSidebarViewer(EViewer.Video));
    dispatch(setCurrentAnomalyId(null));
    dispatch(setSidebar(ESidebar.Zone));
  };

  const handleAnomalySwitch = (arrow: 'left' | 'right') => {
    if (!anomaly || !currentReport?.anomalies) return;
    const newAnomalyId = getAnomalyId({
      arrow,
      anomalies: currentReport.anomalies,
      currentAnomaly: anomaly,
    });
    dispatch(setCurrentAnomalyId(newAnomalyId));
  };

  const handleRepairStatusClick = useCallback(
    async (repairment_status: EAnomalyRepairmentStatus) => {
      if (anomaly?.id && anomaly?.report_id) {
        await dispatch(
          updateAnomaly({
            anomalyId: anomaly.id,
            reportId: Number(anomaly.report_id),
            payload: { repairment_status },
          }),
        );
        setSelectedRepairStatus(repairment_status);
      }
    },
    [dispatch, anomaly?.id, anomaly?.report_id],
  );

  const handleSeverityStatusClick = useCallback(
    async (status: EAnomalyStatus) => {
      if (anomaly?.id && anomaly?.report_id) {
        await dispatch(
          updateAnomaly({
            anomalyId: anomaly.id,
            reportId: Number(anomaly.report_id),
            payload: { status },
          }),
        );
        setSelectedSeverityStatus(status);
      }
    },
    [dispatch, anomaly?.id, anomaly?.report_id],
  );

  const handleTypeStatusClick = useCallback(
    async (mode: EAnomalyMode) => {
      if (anomaly?.id && anomaly?.report_id) {
        await dispatch(
          updateAnomaly({
            anomalyId: anomaly.id,
            reportId: Number(anomaly.report_id),
            payload: { ...RESET_ESTIMATED_LOSSES_ANOMALY_FIELDS, mode },
          }),
        );
        setSelectedTypeStatus(mode);
      }
    },
    [dispatch, anomaly?.id, anomaly?.report_id],
  );

  useEffect(() => {
    if (mode === EControlMode['3D']) {
      dispatch(setViewer(EViewer.Model));
    }
    dispatch(setSidebarViewer(EViewer.Image));
  }, [mode]);

  useEffect(() => {
    if (anomaly?.id) {
      dispatch(setAnomalySamples(anomaly.id));

      setSelectedRepairStatus(anomaly.repairment_status ?? null);
      setSelectedSeverityStatus(anomaly?.status ?? null);
      setSelectedTypeStatus(anomaly?.mode ?? null);
    }
  }, [anomaly?.id]);

  const handleScroll = (event) => setScrollTop(event.target.scrollTop);

  const areDisabledSwitcherButtons = anomalies.length === 1;
  const isDisabledAnomalyTile =
    anomaly?.repairment_status === EAnomalyRepairmentStatus.Delayed ||
    anomaly?.repairment_status === EAnomalyRepairmentStatus.Repaired;

  return (
    <div className={styles.container}>
      <Navbar
        header={String(t('sidebar.anomaly.title', { id: anomaly?.id }))}
        subheader={`${currentSite?.name} - ${zone?.name}`}
        areDisabledSwitcherButtons={areDisabledSwitcherButtons}
        onBackClick={handleBackClick}
        onLeftClick={handleAnomalySwitch.bind(this, 'left')}
        onRightClick={handleAnomalySwitch.bind(this, 'right')}
      />
      <div className={styles.wrapper} onScroll={handleScroll}>
        <InspectionsView
          classNames={styles.mediaInspections}
          options={anomalySamples}
          selectedId={Number(selectedSample?.id)}
          onSwitch={handleSwitch}
        />
        <div className={styles.statistic}>
          <EstimatedLosses />
        </div>
        <div className={styles.tiles}>
          <AnomalyTiles<EAnomalyRepairmentStatus>
            {...ANOMALY_TILES[EAnomalyTiles.Repair]}
            selectedTile={selectedRepairStatus}
            onClick={handleRepairStatusClick}
            isEditable={isEditor}
          />
          <AnomalyTiles<EAnomalyStatus>
            {...ANOMALY_TILES[EAnomalyTiles.Status]}
            selectedTile={selectedSeverityStatus}
            onClick={handleSeverityStatusClick}
            isEditable={isEditor}
          />
          <AnomalyTiles<EAnomalyMode>
            {...ANOMALY_TILES[EAnomalyTiles.Type]}
            selectedTile={selectedTypeStatus}
            onClick={handleTypeStatusClick}
            isEditable={isEditor}
            isDisabled={isDisabledAnomalyTile}
          />
          {selectedTypeStatus && (
            <AnomalyDetails
              isEditable={isEditor}
              isDisabled={isDisabledAnomalyTile}
              type={selectedTypeStatus}
              scrollTop={scrollTop}
            />
          )}
        </div>
        <CommentSectionAnomaly anomalyId={anomaly?.id} />
      </div>
    </div>
  );
};
