import { createInspectionFile } from '@core/api/inspections/upload/createInspectionFile';
import { updateInspectionUploadedById } from '@core/api/inspections/upload/updateInspectionUploadedById';
import { getInspectionFlights } from './inspections/getInspectionFlights';
import { getInspections } from './inspections/getInspections';
import { getWeatherForecast } from './inspections/getWeatherForecast';
import { inspectionsByProgramId } from './inspections/inspectionsByProgramId';
import { getUploadedFilesByInspectionId } from './upload/getUploadedFilesByInspectionId';

export const inspection = {
  inspectionsByProgramId,
  updateInspectionUploadedById,
  createInspectionFile,
  getUploadedFilesByInspectionId,
  getInspections,
  getInspectionFlights,
  getWeatherForecast,
};
