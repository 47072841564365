import { FC, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import type { OrbitControls as TOrbitControls } from 'three-stdlib';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { useResetOrbitControls } from '@core/hooks';
import { useControlsSelector } from '@core/store/slices/controls';
import { MainContext } from '@modules/Layout/contexts/main';
import { Sidebar } from '@modules/Sidebar';
import { Viewers } from '@modules/Viewers';
import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary';
import { NetworkConnectionModal } from '@components/Modal/components/NetworkConnection';
import styles from './styles.scss';

export const Main: FC = () => {
  const { isCompareMode } = useControlsSelector();
  const viewerOrbitControlRef = useRef<TOrbitControls | null>(null);
  const sidebarOrbitControlRef = useRef<TOrbitControls | null>(null);

  // NOTE: use case (unmount 3D model): reset "orbitControls" for 3d model Scenes
  useResetOrbitControls({ viewerOrbitControlRef, sidebarOrbitControlRef });

  const contextValue = useMemo(
    () => ({
      model: {
        viewerOrbitControl: viewerOrbitControlRef,
        sidebarOrbitControl: sidebarOrbitControlRef,
      },
    }),
    [viewerOrbitControlRef.current, sidebarOrbitControlRef.current],
  );

  // TODO: need to update ErrorBoundary
  return (
    <main className={styles.main}>
      <ErrorBoundary
        fallback={(message: string | null) =>
          message
            ? toast.error(message)
            : toast.error('errors.generalServerError', { autoClose: ERROR_TOAST_DELAY })
        }
      >
        <MainContext.Provider value={contextValue}>
          <NetworkConnectionModal />
          {isCompareMode && <Sidebar toCompare={isCompareMode} />}
          <Viewers />
          <Sidebar />
        </MainContext.Provider>
      </ErrorBoundary>
    </main>
  );
};

export default Main;
