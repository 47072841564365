import _ from 'lodash';
import { getCenter } from './getCenter';

interface IGroupXTextPositionParams {
  xNextBar?: number;
  groupWidth: number;
  marginLeft: number;
  correctionValue: number;
}

export const getGroupXTextPosition = ({
  xNextBar,
  groupWidth,
  marginLeft,
  correctionValue,
}: IGroupXTextPositionParams) => {
  const xCenter = getCenter(groupWidth);
  return _.round((xNextBar ? xNextBar - xCenter : xCenter) + marginLeft + correctionValue, 1);
};

interface IGroupXBarPositionParams {
  xNextBar: number;
  marginLeft: number;
  correctionValue: number;
}

export const getGroupXBarPosition = ({
  xNextBar,
  marginLeft,
  correctionValue,
}: IGroupXBarPositionParams) => {
  return _.round(xNextBar + marginLeft + correctionValue, 1);
};
