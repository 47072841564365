import { FeatureCollection } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { ECreateSiteSteps } from '@core/constants/createSite';
import { ESidebar } from '@core/enums';
import { EAnomalyStatisticCategory } from '@core/enums/anomalies';
import { EGeneratedProgramPreview } from '@core/enums/sites/createSite';
import { IProgram, ISample, ISite } from '@core/interfaces';
import { IAnomaly } from '@core/interfaces/anomaly';
import {
  MapClickEvent,
  MapMouseEnterEvent,
  MapMouseLeaveEvent,
  MapMouseMoveEvent,
} from '@core/interfaces/map';
import { IReport } from '@core/interfaces/report';
import { INoFlyZone, IObstacle } from '@core/interfaces/sites/createSite';
import { AppDispatchType } from '@core/store';
import { addSiteCreation } from '@modules/Viewers/views/MapViewer/utils/createSite/common/addSiteCreation';
import { addEditNoFlyZone } from '@modules/Viewers/views/MapViewer/utils/createSite/noFlyZones/addEditNoFlyZone';
import { addEditObstacle } from '@modules/Viewers/views/MapViewer/utils/createSite/obstacles/addEditObstacle';
import { addEditPerimeter } from '@modules/Viewers/views/MapViewer/utils/createSite/perimeter/addEditPerimeter';
import { addAnomalies } from './anomalies/addAnomalies';
import { addFlightPath } from './flightPath/addFlightPath';
import { addOrtho } from './ortho/addOrthoTiles';
import { addPoints } from './points/addPoints';
import { addSolarPanels } from './solarPanels/addSolarPanels';
import { addZones } from './zones/addZones';

interface IProps {
  dispatch: AppDispatchType;
  map: mapboxgl.Map | null;
  sites: ISite[];
  currentInspectionId: string | null;
  program?: IProgram;
  programs: IProgram[] | null;
  report: IReport | null;
  reports: IReport[];
  sidebar: ESidebar;
  sidebarPrevious: ESidebar | null;
  showFlightPath: boolean;
  showSolarPanels: boolean;
  colorSolarPanels: string;
  selectedAnomaly?: IAnomaly;
  anomalyCategory: EAnomalyStatisticCategory;
  onSiteClick: (event: MapClickEvent) => void;
  onClusterClick: (event: MapClickEvent) => void;
  onZoneClick: (event: MapClickEvent) => void;
  onAnomalyClick: (event: MapClickEvent) => void;
  onAnomalyMouseEnter: (event: MapMouseEnterEvent) => void;
  onAnomalyMouseLeave: (event: MapMouseLeaveEvent) => void;
  onAnomalyMouseMove: (event: MapMouseMoveEvent) => void;
  flightPath?: ISample[];
  flightPlan: any[] | null;
  drawBatteries?: boolean;
  createSiteCurrentStep?: ECreateSiteSteps | null;
  noFlyZones: INoFlyZone[];
  obstacles: IObstacle[];
  perimeter: FeatureCollection | null;
  onEditPerimeter: (geoJson: FeatureCollection) => void;
  noFlyZone: INoFlyZone | null;
  onEditNoFlyZone: (geoJson: FeatureCollection) => void;
  obstacle: IObstacle | null;
  onEditObstacle: (geoJson: FeatureCollection) => void;
  generatedPrograms: IProgram[];
  currentGeneratedProgram?: IProgram;
  currentPlanIndex?: number | null;
  currentOverviewIndex?: number | null;
  programPreview: EGeneratedProgramPreview;
}

export const updateMap = ({
  map,
  sites,
  onSiteClick,
  onClusterClick,
  onZoneClick,
  onAnomalyClick,
  onAnomalyMouseEnter,
  onAnomalyMouseLeave,
  onAnomalyMouseMove,
  createSiteCurrentStep,
  ...restProps
}: IProps) => {
  const {
    dispatch,
    programs,
    currentInspectionId,
    report,
    reports,
    sidebar,
    sidebarPrevious,
    showFlightPath,
    flightPath,
    flightPlan,
    colorSolarPanels,
    selectedAnomaly,
    anomalyCategory,
    showSolarPanels,
    drawBatteries,
    noFlyZones,
    obstacles,
    perimeter,
    onEditPerimeter,
    noFlyZone,
    onEditNoFlyZone,
    obstacle,
    onEditObstacle,
    generatedPrograms,
    currentGeneratedProgram,
    currentPlanIndex,
    currentOverviewIndex,
    programPreview,
  } = restProps;

  if (!map || !sites.length) return;

  if (sites.length) {
    addPoints({ map, sites, sidebar, onPointClick: onSiteClick, onClusterClick });
  }

  addZones({ sidebar, map, programs, onClick: onZoneClick });
  addOrtho({ map, currentInspectionId, programs, reports, sidebar });
  addAnomalies({
    map,
    report,
    selectedAnomaly,
    sidebar,
    isCompareMode: false,
    onClick: onAnomalyClick,
    onMouseEnter: onAnomalyMouseEnter,
    onMouseLeave: onAnomalyMouseLeave,
    onMouseMove: onAnomalyMouseMove,
    hasSolarPanels: showSolarPanels,
    anomalyCategory,
  });
  addSolarPanels({
    map,
    report,
    sidebar,
    sidebarPrevious,
    show: showSolarPanels,
    color: colorSolarPanels,
    isCompareMode: false,
  });
  addFlightPath({ map, show: showFlightPath, flightPlan, flightPath, drawBatteries });

  addEditPerimeter({
    map,
    sidebar,
    perimeter: createSiteCurrentStep === ECreateSiteSteps.Perimeter ? perimeter : null,
    onGeoJsonChange: onEditPerimeter,
  });

  addEditNoFlyZone({
    map,
    sidebar,
    noFlyZone: createSiteCurrentStep === ECreateSiteSteps.NoFlyZones ? noFlyZone : null,
    onGeoJsonChange: onEditNoFlyZone,
  });

  addEditObstacle({
    map,
    sidebar,
    obstacle: createSiteCurrentStep === ECreateSiteSteps.Obstacles ? obstacle : null,
    onGeoJsonChange: onEditObstacle,
  });

  addSiteCreation({
    dispatch,
    createSiteCurrentStep,
    map,
    sidebar,
    perimeter: createSiteCurrentStep === ECreateSiteSteps.Perimeter ? null : perimeter,
    obstacles,
    noFlyZones,
    generatedPrograms,
    currentGeneratedProgram,
    currentPlanIndex,
    currentOverviewIndex,
    programPreview,
  });
};
