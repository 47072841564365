import { axiosInstance } from '@core/api/axios';
import {
  EAnomalyMode,
  EAnomalyRepairmentStatus,
  EAnomalyStatus,
  EAnomalySubtype,
} from '@core/enums/anomalies';

export interface IUpdateAnomalyBodyRequest {
  status: EAnomalyStatus;
  mode: EAnomalyMode;
  submode: EAnomalySubtype | string;
  repairment_status: EAnomalyRepairmentStatus;
  thermal_surface: number;
  visual_surface: number;
  disconnected_panels: number;
  anomaly_temperature: number;
  mean_temperature: number;
  temperature_differential: number;
}

export interface IUpdateAnomalyResponse {
  id: number;
  status: EAnomalyStatus;
  mode: EAnomalyMode;
  submode: EAnomalySubtype;
  repairment_status: EAnomalyRepairmentStatus;
  thermal_surface: number;
  visual_surface: number;
  disconnected_panels: number;
  anomaly_temperature: number;
  mean_temperature: number;
  temperature_differential: number;
}

export const updateAnomaly = async (
  anomalyId: number,
  body: Partial<IUpdateAnomalyBodyRequest>,
) => {
  return axiosInstance
    .put<IUpdateAnomalyResponse>(`/anomalies/${anomalyId}`, body)
    .then((res) => res.data);
};
