import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EControlMode, ESidebar, EViewer } from '@core/enums';
import { EAnomalyStatisticCategory } from '@core/enums/anomalies';
import { useSelectorTyped } from '@core/hooks';
import { IInspection } from '@core/interfaces';
import { setAnomaliesType, setCurrentAnomalyId, setToCompareAnomaliesType } from './anomalies';
import { updateInspectionToCompareId } from './inspections';
import { reloadMap } from './map';
import { setReportToCompareId } from './reports';
import { setViewer } from './viewer';

interface IInitialState {
  mode: EControlMode;
  hasFlightPath: boolean;
  hasSolarPanels: boolean;
  isCompareMode: boolean;
  anomalyChartCategory: EAnomalyStatisticCategory;
}

const initialState: IInitialState = {
  mode: EControlMode['2D'],
  hasFlightPath: false,
  hasSolarPanels: false,
  isCompareMode: false,
  anomalyChartCategory: EAnomalyStatisticCategory.Severity,
};

const setMode = createAsyncThunk('controls/updateMode', (mode: EControlMode, { dispatch }) => {
  const viewer = mode === EControlMode['2D'] ? EViewer.Map : EViewer.Model;

  dispatch(updateMode(mode));
  dispatch(setViewer(viewer));
});

const updateCompareModeAndView = createAsyncThunk(
  'controls/updateCompareModeAndView',
  (compareMode: boolean, { dispatch }) => {
    dispatch(updateCompareMode(compareMode));
    if (compareMode) {
      dispatch(setViewer(EViewer.CompareMap));
      dispatch(setAnomaliesType(null));
      dispatch(setCurrentAnomalyId(null));
    } else {
      dispatch(setViewer(EViewer.Map));
      dispatch(updateInspectionToCompareId(null));
      dispatch(setReportToCompareId(null));
      dispatch(setToCompareAnomaliesType(null));
      dispatch(setCurrentAnomalyId(null));
      dispatch(reloadMap(true));
    }
    dispatch(setSolarPanels(true));
    dispatch(setFlightPath(false));
  },
);

const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    updateMode: (state, actions: PayloadAction<EControlMode>) => {
      state.mode = actions.payload;
    },
    setAnomalyChartCategory: (state, actions: PayloadAction<EAnomalyStatisticCategory>) => {
      state.anomalyChartCategory = actions.payload;
    },
    setSolarPanels: (state, actions: PayloadAction<boolean>) => {
      state.hasSolarPanels = actions.payload;
    },
    setFlightPath: (state, actions: PayloadAction<boolean>) => {
      state.hasFlightPath = actions.payload;
    },
    toggleSolarPanels: (state) => {
      state.hasSolarPanels = !state.hasSolarPanels;
    },
    toggleFlightPath: (state) => {
      state.hasFlightPath = !state.hasFlightPath;
    },
    updateCompareMode: (state, actions: PayloadAction<boolean>) => {
      state.isCompareMode = actions.payload;
    },
    resetControls: () => initialState,
  },
});

const controlsReducer = viewerSlice.reducer;

const {
  updateMode,
  setSolarPanels,
  setFlightPath,
  toggleFlightPath,
  toggleSolarPanels,
  updateCompareMode,
  setAnomalyChartCategory,
  resetControls,
} = viewerSlice.actions;

const useControlsSelector = () => useSelectorTyped((state) => state.controls);
const useAnomalyChartCategorySelector = () =>
  useSelectorTyped((state) => state.controls.anomalyChartCategory);

const useCompareModeSelector = () =>
  useSelectorTyped((state) => {
    const isProgramLevel = state.sidebar.sidebar === ESidebar.Zone;

    const currentInspectionId = state.inspections.currentInspectionId;
    const inspections = state.inspections.inspections;

    const currentInspection = inspections.find(
      (inspection: IInspection) => inspection.id === currentInspectionId,
    );

    let prevInspection: IInspection | null = null;
    const currentProgramId = state.programs.currentProgramId;
    const currentProgramInspections: IInspection[] = inspections.filter(
      (inspection) => inspection.program_id === currentProgramId,
    );

    if (currentInspection && currentProgramInspections.length >= 2) {
      const currentInspectionDate = new Date(currentInspection.date);

      prevInspection = currentProgramInspections.reduce(
        (prev: IInspection | null, current: IInspection) => {
          const currentDate = new Date(current.date);
          if (currentDate < currentInspectionDate && (!prev || currentDate > new Date(prev.date))) {
            return current;
          }
          return prev;
        },
        null,
      );
    }

    const isCompareModeDisabled =
      !isProgramLevel ||
      !currentInspection ||
      !currentInspection?.report_generated ||
      !currentInspection.uploaded ||
      currentProgramInspections.length < 2 ||
      !prevInspection;

    return { isCompareModeDisabled, prevInspection };
  });

export {
  setMode,
  updateMode,
  updateCompareMode,
  toggleFlightPath,
  toggleSolarPanels,
  setSolarPanels,
  setFlightPath,
  setAnomalyChartCategory,
  updateCompareModeAndView,
  resetControls,
  controlsReducer,
  useControlsSelector,
  useCompareModeSelector,
  useAnomalyChartCategorySelector,
  initialState as controlsInitialState,
};
