export enum EOverviewZone {
  Option1 = 'option1',
  RequestCustomPlan = 'requestCustomPlan',
}

export enum EInspectionZone {
  Option1 = 'option1',
  RequestCustomPlan = 'requestCustomPlan',
}

export enum EFlightPlansSection {
  Overview = 'overview',
  Inspection = 'inspection',
}
