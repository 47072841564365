import _ from 'lodash';

interface IParams {
  chartHeight: number;
  subgroupAbsoluteHeight: number;
  correctionValue: number;
}

export const getSubgroupYTextPosition = ({
  chartHeight,
  subgroupAbsoluteHeight,
  correctionValue,
}: IParams) => {
  return _.round(chartHeight - (correctionValue + subgroupAbsoluteHeight), 1);
};
