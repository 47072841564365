import { lazy } from 'react';
import { SelectOrganization, Login } from '@modules/Auth';
import PublicRoute from './Routes/Public';
import { ROUTE_PATHS } from './routerConfig';

const Main = lazy(() => import('@modules/Layout/components/Main/Main'));

export enum ERouteAccess {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export const ROUTES_CONFIG = {
  [ERouteAccess.Private]: [
    {
      path: ROUTE_PATHS.Root,
      element: <Main />,
    },
  ],
  [ERouteAccess.Public]: [
    {
      path: ROUTE_PATHS.Login.Root,
      element: (
        <PublicRoute>
          <Login />
        </PublicRoute>
      ),
    },
    {
      path: ROUTE_PATHS.Login.SelectOrganization,
      element: (
        <PublicRoute>
          <SelectOrganization />
        </PublicRoute>
      ),
    },
  ],
};
