import { FC, useCallback, useMemo } from 'react';
import { EAnomalyStatisticCategory } from '@core/enums/anomalies';
import { useDispatchTyped } from '@core/hooks';
import { setAnomalyChartCategory, useAnomalyChartCategorySelector } from '@core/store/slices';
import { ANOMALY_STATISTICS_PARAMS } from '@modules/Sidebar/views/Zone/constants';
import { AnomalyControls } from './components/Controls';
import { AnomalyStatistics } from './components/Statistics';

export const ZoneView: FC = () => {
  const chartCategory = useAnomalyChartCategorySelector();
  const dispatch = useDispatchTyped();

  const handleSelectedAnomalyChart = useCallback(
    (chart: EAnomalyStatisticCategory) => {
      dispatch(setAnomalyChartCategory(chart));
    },
    [dispatch],
  );

  const anomaliesStatisticProps = useMemo(
    () => ANOMALY_STATISTICS_PARAMS[chartCategory],
    [chartCategory],
  );
  return (
    <>
      <AnomalyControls selectedChart={chartCategory} onClick={handleSelectedAnomalyChart} />
      <AnomalyStatistics {...anomaliesStatisticProps} />
    </>
  );
};
