import { IAnomaly } from '@core/interfaces/anomaly';
import { IReport } from '@core/interfaces/report';

export function getVisibleAnomalies(reports: IReport[]) {
  return reports.reduce<IAnomaly[]>(
    (acc, report) =>
      report?.anomalies
        ? [
            ...acc,
            ...report.anomalies.map((anomaly) => ({
              ...anomaly,
              // NOTE: expanding anomalies with current fields
              report_id: report.id,
              location_id: report.location_id,
            })),
          ]
        : acc,
    [],
  );
}
