import React, { useState, Fragment, FC, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import {
  addWeeks,
  addDays,
  isAfter,
  isSameDay,
  isBefore,
  startOfDay,
  isPast,
  isWithinInterval,
  endOfDay,
  isThisMonth,
} from 'date-fns';
import chevronLeft from '@assets/icons/chevron-left.svg';
import chevronRight from '@assets/icons/chevron-right.svg';
import crossIcon from '@assets/icons/cross.svg';
import { api } from '@core/api';
import { IForecastQueryParams } from '@core/api/inspections/inspections/getWeatherForecast';
import {
  ERROR_TOAST_DELAY,
  EWeatherAPIElements,
  EWeatherAPIIconSet,
  EWeatherAPIInclude,
  EWeatherAPILanguage,
  EWeatherAPIUnitGroup,
  MAIN_TRANSITION_PROPS,
  OVERLAY_TRANSITION_PROPS,
  PANEL_TRANSITION_PROPS,
  WEATHER_API_KEY,
  weatherIconSVG,
} from '@core/constants';
import { ISite } from '@core/interfaces';
import { browserLocalStorage } from '@core/services/storage';
import { Button } from '@components/Button';
import styles from './styles.scss';
import { getWeatherIcon } from '../../utils';

type DateRange = [Date, Date] | Value | null;

enum ECalendarView {
  Month = 'month',
  Year = 'year',
  Decade = 'decade',
  Century = 'century',
}

interface IProps {
  isOpen: boolean;
  site: ISite;
  onClose: () => void;
}

const ONE_DAY = 1;
const ONE_WEEK = 1;
const DAYS_IN_WEEK = 7;

const PlanInspectionCalendar: FC<IProps> = ({ isOpen, site, onClose }) => {
  const { t } = useTranslation();

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(null);
  const [forecastIcons, setForecastIcons] = useState<any>(null);

  const calendarLanguage = browserLocalStorage.getItem('lang') || 'en';
  const firstAvailableDate = startOfDay(addDays(new Date(), ONE_DAY + DAYS_IN_WEEK));
  const notAvailableDates: Date[] = [
    startOfDay(addDays(new Date(), ONE_DAY)),
    startOfDay(addWeeks(new Date(), ONE_WEEK)),
  ];

  useEffect(() => {
    if (isOpen) {
      getWeatherData();
      setSelectedDateRange([
        firstAvailableDate,
        endOfDay(addDays(firstAvailableDate, DAYS_IN_WEEK - ONE_DAY)),
      ]);
    }
  }, [isOpen]);

  const getWeatherData = async () => {
    try {
      const params: IForecastQueryParams = {
        unitGroup: EWeatherAPIUnitGroup.Metric,
        lang: EWeatherAPILanguage.English,
        iconSet: EWeatherAPIIconSet.Default,
        include: EWeatherAPIInclude.Days,
        elements: EWeatherAPIElements.DateAndIcon,
        key: WEATHER_API_KEY,
      };
      const location = { lat: site.lat, long: site.long };
      const weatherData = await api.inspection.getWeatherForecast({ params, location });
      setForecastIcons(weatherData.days);
    } catch (error) {
      toast.error(t('errors.getForecastFail'), {
        autoClose: ERROR_TOAST_DELAY,
      });
    }
  };

  const onSelectDate = (date: Value) => {
    setSelectedDateRange(date);
  };

  const tileContent = ({ date }: { date: Date; view: string }) => {
    // display forecast icon for days starting from today
    const isTileWithForecast = isAfter(date, new Date()) || isSameDay(date, new Date());
    const forecastIconName = getWeatherIcon(date, forecastIcons);
    const forecastIcon = forecastIconName && weatherIconSVG[forecastIconName];

    return (
      <>
        {isTileWithForecast && forecastIcon && forecastIcons && (
          <ReactSVG className={styles.weatherIcon} src={forecastIcon} />
        )}
      </>
    );
  };

  const onDisableDates = ({
    date,
  }: {
    activeStartDate: Date;
    date: Date;
    view: string;
  }): boolean => {
    // disable all dates before first available for inspection day
    return isBefore(date, startOfDay(firstAvailableDate));
  };

  const getTileClassName = ({ date }: { activeStartDate: Date; date: Date; view: string }) => {
    const isPastDate = isPast(date);

    // first week from tomorrow
    const isNotAvailableDates = isWithinInterval(date, {
      start: notAvailableDates[0],
      end: notAvailableDates[1],
    });

    const isDateNotInCurrentMonth = !isThisMonth(date);

    if (isNotAvailableDates) {
      return styles.calendarNotAvailableTile;
    } else if (isDateNotInCurrentMonth) {
      return styles.calendarNotCurrentMonthsTile;
    } else if (isPastDate) {
      return styles.calendarPastTile;
    } else {
      return styles.calendarTile;
    }
  };

  const onRequestAuthorization = () => {
    onClose();
  };

  return (
    <Transition show={isOpen} as={Fragment} {...MAIN_TRANSITION_PROPS}>
      <Dialog className={styles.dialogContainer} onClose={() => {}}>
        <div className={styles.dialog}>
          <Transition.Child as={Fragment} {...OVERLAY_TRANSITION_PROPS}>
            <Dialog.Overlay className={styles.overlay} />
          </Transition.Child>
          <Transition.Child as={Fragment} {...PANEL_TRANSITION_PROPS}>
            <Dialog.Panel className={styles.panel}>
              <div className={styles.panelHeader}>
                <div className={styles.panelTitle}>{t('planInspection.modalTitle')}</div>
                <button className={styles.closeButton} onClick={onClose}>
                  <ReactSVG className={styles.img} src={crossIcon} />
                </button>
              </div>
              <div className={styles.mainContent}>
                <div className={styles.calendarContainer}>
                  <Calendar
                    className={styles.calendar}
                    tileClassName={getTileClassName}
                    tileContent={tileContent}
                    tileDisabled={onDisableDates}
                    selectRange={true}
                    value={selectedDateRange}
                    onChange={onSelectDate}
                    locale={calendarLanguage}
                    defaultActiveStartDate={new Date()}
                    minDetail={ECalendarView.Month}
                    prevLabel={<ReactSVG src={chevronLeft} />}
                    nextLabel={<ReactSVG src={chevronRight} />}
                    prev2Label={null}
                    next2Label={null}
                  />
                </div>
                <div className={styles.requestButtonContainer}>
                  <Button className={styles.requestButton} onClick={onRequestAuthorization}>
                    {t('planInspection.requestAuthorizationButton')}
                    <img src={chevronRight} alt='chevron icon' />
                  </Button>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PlanInspectionCalendar;
