export enum EAnomalyStatus {
  ToReview = 'To Review',
  False = 'False',
  Minor = 'Minor',
  Major = 'Major',
}

export enum EAnomalyRepairmentStatus {
  Delayed = 'Delayed',
  ToRepair = 'To Repair',
  Repaired = 'Repaired',
}
