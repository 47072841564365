import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import chevronRight from '@assets/icons/chevron-right.svg';
import { ERoles, ESidebar, ESiteCategory, EViewer } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { IProgram } from '@core/interfaces';
import { ISite } from '@core/interfaces';
import {
  addSelectedSiteInfo,
  setSidebar,
  setViewer,
  useCurrentSiteSelector,
  useSitesSelector,
  useAccessControlSelector,
} from '@core/store/slices';
import { useProgramsForCurrentLocationSelector } from '@core/store/slices/programs';
import { authStorage } from '@core/utils';
import { Navbar } from '@modules/Sidebar/components';
import { EstimatedLosses, SolarPanelsStatistic, AnomalyStatistic } from '@modules/Sidebar/widgets';
import { Button } from '@components/Button';
import PlanInspectionCalendar from './components/PlanInspectionCalendar/PlanInspectionCalendar';
import { ZonesTable } from './components/ZonesTable';
import styles from './styles.scss';

const getters = {
  getZonesTitle(zones?: IProgram[]) {
    return t('sidebar.anomaly.zones.keyWithCount', {
      count: zones?.length,
    });
  },
};

export const Site = () => {
  const { t } = useTranslation();
  const { user } = useAccessControlSelector();
  const { sites } = useSitesSelector();
  const currentSite = useCurrentSiteSelector();
  const programsForCurrentLocation = useProgramsForCurrentLocationSelector();
  const dispatch = useDispatchTyped();

  const [scrollTop, setScrollTop] = useState(0);
  const [isPlanCalendarOpen, setIsPlanCalendarOpen] = useState(false);

  const isAdminOrSuperuser = useMemo(() => {
    const tenant = authStorage.tenant.get();

    return tenant && user?.data?.roles[tenant]
      ? user.data.roles[tenant].some((role) => [ERoles.Admin, ERoles.Superuser].includes(role))
      : false;
  }, [user?.data?.roles]);

  const isAdminOrInspector = useMemo(() => {
    const tenant = authStorage.tenant.get();

    return tenant && user?.data?.roles[tenant]
      ? user.data.roles[tenant].some((role) => [ERoles.Admin, ERoles.Inspector].includes(role))
      : false;
  }, [user?.data?.roles]);

  const siteCategory =
    currentSite?.reglementation_category &&
    Object.values(ESiteCategory).includes(currentSite?.reglementation_category)
      ? currentSite?.reglementation_category
      : null;

  const isPlanInspectionDisplayed =
    currentSite?.reglementation_category === ESiteCategory.Restricted && isAdminOrInspector;

  const handleBackClick = () => {
    dispatch(setViewer(EViewer.Map));
    dispatch(setSidebar(ESidebar.Sites));
  };

  const setEditSite = useCallback(() => {
    dispatch(setSidebar(ESidebar.EditSite));
  }, [dispatch]);

  const onOpenPlanCalendar = () => {
    setIsPlanCalendarOpen(true);
  };

  const onClosePlanCalendar = () => {
    setIsPlanCalendarOpen(false);
  };

  const getIndexOfCurrentSite = (sites: ISite[], site: ISite | undefined) => {
    if (!site) return 0;
    return sites.findIndex((value: ISite) => value.loc_id === site.loc_id);
  };

  const handleClickNextSite = () => {
    const currentIndex = getIndexOfCurrentSite(sites, currentSite);
    let nextIndex = currentIndex + 1;
    if (nextIndex >= sites.length) nextIndex = 0;
    const nextSite = sites[nextIndex];
    if (!nextSite) return;
    dispatch(
      addSelectedSiteInfo({
        siteId: nextSite.loc_id,
        sidebar: ESidebar.Site,
      }),
    );
  };

  const handleClickPreviousSite = () => {
    const currentIndex = getIndexOfCurrentSite(sites, currentSite);
    let previousIndex = currentIndex - 1;
    if (previousIndex < 0) previousIndex = sites.length - 1;
    const previousSite = sites[previousIndex];
    if (!previousSite) return;
    dispatch(
      addSelectedSiteInfo({
        siteId: previousSite.loc_id,
        sidebar: ESidebar.Site,
      }),
    );
  };

  const zonesTitleWithCount = getters.getZonesTitle(programsForCurrentLocation);
  const areDisabledSwitcherButtons = sites.length === 1;
  const isShownBack = sites.length > 1;

  const handleScroll = (event) => setScrollTop(event.target.scrollTop);

  return currentSite ? (
    <Fragment>
      <div className={styles.site}>
        <Navbar
          header={currentSite?.name}
          subheader={zonesTitleWithCount}
          siteCategory={siteCategory}
          areDisabledSwitcherButtons={areDisabledSwitcherButtons}
          isShownBack={isShownBack}
          onBackClick={handleBackClick}
          onLeftClick={handleClickPreviousSite}
          onRightClick={handleClickNextSite}
        />
        <div className={styles.siteWrapper} onScroll={handleScroll}>
          <div className={styles.logo}>
            {currentSite?.picture && (
              <img className={styles.siteImage} src={currentSite?.picture} alt='site image' />
            )}
          </div>
          <div className={styles.siteZones}>
            <div className={styles.siteTableTitle}>{zonesTitleWithCount}</div>
            <ZonesTable programs={programsForCurrentLocation} />
          </div>
          <div className={styles.statistic}>
            <EstimatedLosses scrollTop={scrollTop} />
            <SolarPanelsStatistic scrollTop={scrollTop} />
            <AnomalyStatistic />
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button disabled={!isAdminOrSuperuser} className={styles.btn} onClick={setEditSite}>
            {t('sidebar.anomaly.editSite')}
            <img src={chevronRight} alt='chevron icon' />
          </Button>
          {isPlanInspectionDisplayed && (
            <Button className={styles.btn} onClick={onOpenPlanCalendar}>
              {t('planInspection.openPlanInspectionButton')}
            </Button>
          )}
        </div>
      </div>
      <PlanInspectionCalendar
        isOpen={isPlanCalendarOpen}
        site={currentSite}
        onClose={onClosePlanCalendar}
      />
    </Fragment>
  ) : null;
};
