import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ERoles } from '@core/enums';
import { ESiteEditActions } from '@core/enums/sites/editSite';
import { useDispatchTyped, useSelectorTyped } from '@core/hooks';
import { TRolesWithoutSuperuserPayload } from '@core/interfaces';
import { updateCreateSiteRoles } from '@core/store/slices';
import RolesBody from '@modules/Sidebar/components/AccordionView/BodyOptions/Roles/RolesBody';
import { IAddInputData } from '@modules/Sidebar/components/AccordionView/BodyOptions/Roles/interfaces/addItem';
import { IDeleteInputData } from '@modules/Sidebar/components/AccordionView/BodyOptions/Roles/interfaces/deleteItem';
import { IUpdateInputData } from '@modules/Sidebar/components/AccordionView/BodyOptions/Roles/interfaces/updateItem';
import { getEntityFromInputName } from '@modules/Sidebar/components/AccordionView/BodyOptions/Roles/utils';

const RolesCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const createSiteRoles = useSelectorTyped((state) => state.createSite.roles);

  const handleChange = useCallback(
    (data: IUpdateInputData) => {
      const transformedData = Object.entries(data);

      if (transformedData.length === 1) {
        const [name, data] = transformedData[0];
        const entity = getEntityFromInputName(name);

        dispatch(
          updateCreateSiteRoles({
            action: ESiteEditActions.UpdateOne,
            data: {
              [entity]: { name, ...data },
            } as TRolesWithoutSuperuserPayload['data'],
          }),
        );
      }
    },
    [dispatch],
  );

  const handleDeleteItem = useCallback(
    ({ name, entity }: IDeleteInputData) => {
      dispatch(
        updateCreateSiteRoles({
          action: ESiteEditActions.DeleteOne,
          data: {
            [entity]: { name },
          } as TRolesWithoutSuperuserPayload['data'],
        }),
      );
    },
    [dispatch],
  );

  const handleAddItem = useCallback(
    (data: IAddInputData) => {
      const transformedData = Object.entries(data);

      if (transformedData.length === 1) {
        const [name, data] = transformedData[0];
        const entity = getEntityFromInputName(name) as ERoles;

        dispatch(
          updateCreateSiteRoles({
            action: ESiteEditActions.AddOne,
            data: {
              [entity]: { name, ...data },
            } as TRolesWithoutSuperuserPayload['data'],
          }),
        );
      }
    },
    [dispatch],
  );

  const rolesData = useMemo(
    () => ({
      [ERoles.Admin]: createSiteRoles?.[ERoles.Admin] || [],
      [ERoles.Editor]: createSiteRoles?.[ERoles.Editor] || [],
      [ERoles.Inspector]: createSiteRoles?.[ERoles.Inspector] || [],
      [ERoles.Viewer]: createSiteRoles?.[ERoles.Viewer] || [],
    }),
    [createSiteRoles],
  );

  return (
    <>
      <RolesBody
        text={{
          notification: t('sidebar.site.edit.sections.roles.notification'),
          addItemText: t('sidebar.site.edit.sections.roles.addItemText'),
          addItemPlaceholder: t('sidebar.site.edit.sections.roles.addItemPlaceholder'),
        }}
        data={rolesData}
        onChange={handleChange}
        onAdd={handleAddItem}
        onDelete={handleDeleteItem}
      />
    </>
  );
};

export default RolesCreate;
