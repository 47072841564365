// TODO:

/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, LineString, Polygon } from '@turf/turf';
import { FeatureCollection } from 'geojson';
import { t } from 'i18next';
import _ from 'lodash';
import { api } from '@core/api';
import { EJobStatus } from '@core/api/locations/jobStatus';
import { IUpdateLocationRequest } from '@core/api/locations/updateById';
import {
  ECreateSiteLocalStorageField,
  ELocalStorageField,
  ERROR_TOAST_DELAY,
} from '@core/constants';
import {
  DEFAULT_GENERATED_PROGRAM_NAME_REGEX,
  DEFAULT_SITE_NAME,
  ECreateSiteSteps,
  INITIAL_CREATE_SITE_COORDINATES,
} from '@core/constants/createSite';
import { EErrorStatus, EInspectionFrequency, ERoles, ESidebar, EViewer } from '@core/enums';
import { ECreateSiteSections, EGeneratedProgramPreview } from '@core/enums/sites/createSite';
import { ESiteEditActions } from '@core/enums/sites/editSite';
import { useSelectorTyped } from '@core/hooks';
import {
  IProgram,
  ISite,
  TRolesWithoutSuperuserPayload,
  TRolesWithoutSuperuserData,
} from '@core/interfaces';
import {
  ICreateSiteInitialState,
  IFakePerimeterProcessing,
  IMapboxCityData,
  INoFlyZone,
  IObstacle,
} from '@core/interfaces/sites/createSite';
import { browserLocalStorage, DataProcessing } from '@core/services';
import { RootState } from '@core/store';
import { setSidebar } from '@core/store/slices/sidebar';
import { setViewer } from '@core/store/slices/viewer';
import { isNumber, isString, isTruthy } from '@core/utils';
import { getMaxStepForContinueSiteCreation } from '@modules/Sidebar/views/CreateSite/utils/getMaxStepForContinueSiteCreation';
import { setRoleInitialState } from '@modules/Sidebar/views/Site/utils';
import { createNoFlyZone, createObstacle } from '../utils/createSite';

const initialState: ICreateSiteInitialState = {
  loc_id: null,
  steps: {
    current: ECreateSiteSteps.SearchCity,
    maxCurrent: ECreateSiteSteps.SearchCity,
  },
  [ECreateSiteSections.SearchLocation]: {
    lat: INITIAL_CREATE_SITE_COORDINATES.lat,
    lng: INITIAL_CREATE_SITE_COORDINATES.lng,
    selectedLocation: null,
  },
  [ECreateSiteSections.Name]: {
    name: '',
  },
  [ECreateSiteSections.Perimeter]: {
    gps_boundaries: {
      top_left_lat: 0,
      top_left_lng: 0,
      bottom_right_lat: 0,
      bottom_right_lng: 0,
    },
    isLoadingSite: false,
    processingPercentage: null,
    currentIndex: null,
    data: [],
  },
  [ECreateSiteSections.NoFlyZones]: {
    sequenceIndex: 0,
    currentIndex: null,
    data: [],
  },
  [ECreateSiteSections.Obstacles]: {
    sequenceIndex: 0,
    currentIndex: null,
    data: [],
  },
  [ECreateSiteSections.Picture]: {
    image: '',
    imageUrl: '',
  },
  [ECreateSiteSections.InspectionFrequency]: {
    inspectionFrequency: EInspectionFrequency.Monthly,
  },
  [ECreateSiteSections.Roles]: {
    [ERoles.Admin]: [setRoleInitialState(ERoles.Admin)],
    [ERoles.Editor]: [setRoleInitialState(ERoles.Editor)],
    [ERoles.Viewer]: [setRoleInitialState(ERoles.Viewer)],
    [ERoles.Inspector]: [setRoleInitialState(ERoles.Inspector)],
  },
  [ECreateSiteSections.Zones]: {
    isLoadingZones: false,
    fakePerimeterProcessing: {
      percentage: 0,
      currentStep: 0,
    },
    programs: [],
    currentProgramId: '',
    currentOverviewIndex: null,
    currentPlanIndex: null,
    programPreview: EGeneratedProgramPreview.Overview,
  },
};

const setCurrentStep = createAsyncThunk<void, number, { dispatch: any; getState: any }>(
  'createSite/setCurrentStep',
  async (newStep, { dispatch, getState }) => {
    const state = getState() as RootState;

    const { loc_id, steps, name, perimeter, picture, inspectionFrequency } = state.createSite;
    const previousStep = steps.current;

    dispatch(_setCurrentStep(newStep));

    // NOTE: use case (changed step from "perimeter", "noFlyZones", "obstacles" to another)
    // Send updated perimeters to a server
    switch (previousStep) {
      case ECreateSiteSteps.Perimeter:
        if (previousStep !== newStep && isNumber(perimeter.currentIndex) && isString(loc_id)) {
          dispatch(
            updateLocationPerimeter({
              currentPerimeterIndex: perimeter.currentIndex,
              loc_id,
              perimeters: perimeter.data,
            }),
          );
        }
        break;
    }

    switch (newStep) {
      case ECreateSiteSteps.Name:
        if (state.createSite.loc_id) return;
        try {
          const midpoint_latitude =
            (perimeter.gps_boundaries.top_left_lat + perimeter.gps_boundaries.bottom_right_lat) / 2;
          const midpoint_longitude =
            (perimeter.gps_boundaries.top_left_lng + perimeter.gps_boundaries.bottom_right_lng) / 2;

          const resp = await api.location.create({
            name: DEFAULT_SITE_NAME,
            lat: midpoint_latitude,
            long: midpoint_longitude,
            gps_boundaries: perimeter.gps_boundaries,
            // TODO: will be set in a new select "Location Type" from the list of data
            location_type: 'Utility scale',
          });
          dispatch(setLng(midpoint_longitude));
          dispatch(setLat(midpoint_latitude));
          dispatch(setLocId(resp.loc_id));
          // TODO: return back after solving the issue of generating the perimeter (OD-491 ticket)
          // dispatch(
          //   updateLocationPerimeter({
          //     currentPerimeterIndex: perimeter.data.length - 1,
          //     loc_id: resp.loc_id,
          //     perimeters: perimeter.data,
          //   }),
          // );
        } catch (e: any) {
          console.error(e);
          // TODO: return back after solving the issue of generating the perimeter (OD-491 ticket)
          // toast.error(t('errors.unableToCreateSite'), { autoClose: ERROR_TOAST_DELAY });
        }
        break;
    }

    try {
      if (!loc_id || !name) return;

      const normalizedName = name.name.trim();
      const updateData: Partial<
        Pick<IUpdateLocationRequest, 'name' | 'inspection_frequency' | 'picture'>
      > = {
        name: normalizedName,
        inspection_frequency: inspectionFrequency.inspectionFrequency,
      };

      if (isTruthy(picture.image)) {
        updateData.picture = picture.image;
      }

      await api.location.updateById(loc_id, updateData);

      dispatch(setName(normalizedName));
    } catch (e: any) {
      toast.error(
        e?.message ?? t('errors.locationUpdateFail', { locationId: state.createSite.loc_id }),
        { autoClose: ERROR_TOAST_DELAY },
      );
    }
  },
);

const loadZones = createAsyncThunk<void, void, { dispatch: any; getState: any }>(
  'createSite/loadZones',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { loc_id, noFlyZones, obstacles, perimeter, zones } = state.createSite;
    dispatch(_setIsLoadingZones(true));

    if (!loc_id) {
      toast.error(t('errors.unableToGenerateZones'), { autoClose: false });
      dispatch(_setIsLoadingZones(false));
      return;
    }

    if (zones.isLoadingZones) {
      dispatch(getJobStatus({ loc_id }));
      return;
    }

    const processedObstacles = obstacles.data.map(({ id, ...rest }) => rest);
    const processedNoFlyZones = noFlyZones.data.map(({ id, ...rest }) => rest);

    const allItems = [...processedObstacles, ...processedNoFlyZones];
    const promises = allItems.map((item) =>
      api.location.createNoFlyZonesByLocationId(loc_id, item),
    );

    try {
      await Promise.all(promises);
      await dispatch(
        updateLocationPerimeter({
          currentPerimeterIndex: perimeter.data.length - 1,
          loc_id,
          perimeters: perimeter.data,
        }),
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.detail ?? t('errors.noFlyZonesOrObstaclesFail'), {
        autoClose: ERROR_TOAST_DELAY,
      });
      dispatch(_setIsLoadingZones(false));
      return;
    }

    return api.location
      .postSetup(loc_id)
      .then(() => {
        dispatch(getJobStatus({ loc_id }));
      })
      .catch((error) => {
        dispatch(_setIsLoadingZones(false));
        toast.error(error?.response?.data?.detail ?? t('errors.somethingWentWrong'), {
          autoClose: ERROR_TOAST_DELAY,
        });
      });
  },
);

const getJobStatus = createAsyncThunk<
  void,
  { loc_id: string; isInitialRequest?: boolean },
  {
    dispatch: any;
    getState: any;
  }
>('createSite/getJobStatus', async ({ loc_id, isInitialRequest }, { dispatch, getState }) => {
  return api.location
    .getJobStatus(loc_id)
    .then((jobStatus) => {
      if (isInitialRequest) {
        if (jobStatus === EJobStatus.Unknown) return;
        if (jobStatus === EJobStatus.In_progress) {
          dispatch(_setIsLoadingZones(true));
          const state = getState() as RootState;
          const step = getMaxStepForContinueSiteCreation(state.createSite);
          dispatch(setCurrentStep(step));
        }
      }
      if (![EJobStatus.Failed, EJobStatus.Completed].includes(jobStatus)) {
        setTimeout(() => {
          dispatch(getJobStatus({ loc_id }));
        }, 3000);
      }

      if (jobStatus === EJobStatus.Failed) {
        dispatch(_setIsLoadingZones(false));
        toast.error(t('errors.zonesGenerateFail'), { autoClose: false });
      }

      if (jobStatus === EJobStatus.Completed) {
        setTimeout(() => {
          api.location
            .getProgramsByLocationId(loc_id)
            .then((programs) => {
              if (!programs || programs?.length === 0) {
                throw new Error('No zones found');
              }

              dispatch(setPrograms(programs));
              dispatch(_setIsLoadingZones(false));
              dispatch(setCurrentStep(ECreateSiteSteps.Zones));
            })
            .catch(() => {
              toast.error(t('errors.getZonesFail'), { autoClose: false });
              dispatch(_setIsLoadingZones(false));
            });
        }, 3000);
      }
    })
    .catch((error) => {
      // NOTE: disable "toast" notification if network connection is lost
      if (error?.code !== EErrorStatus.NetworkError) {
        toast.error(error?.response?.data?.detail ?? t('errors.somethingWentWrong'), {
          autoClose: false,
        });
        dispatch(_setIsLoadingZones(false));
      }
    });
});

const continueCreation = createAsyncThunk<void, string, { dispatch: any; getState: any }>(
  'createSite/continueCreation',
  async (loc_id, { dispatch }) => {
    dispatch(resetCreateSite());
    try {
      const [perimeterResult, noFlyZonesAndObstaclesResult, programsResult] =
        await Promise.allSettled([
          api.location.getPerimeterByLocationId(loc_id),
          api.location.getNoFlyZonesByLocId(loc_id),
          api.location.getProgramsByLocId(loc_id),
        ]);

      let perimeter: FeatureCollection | null = null;
      let noFlyZones: INoFlyZone[] = [];
      let obstacles: IObstacle[] = [];
      let programs: IProgram[] = [];

      if (perimeterResult.status === 'fulfilled') {
        perimeter = perimeterResult.value as FeatureCollection;
      }

      if (noFlyZonesAndObstaclesResult.status === 'fulfilled') {
        const noFlyZonesAndObstacles = noFlyZonesAndObstaclesResult.value;
        noFlyZones = noFlyZonesAndObstacles.filter(
          (item) => item.type === 'no_fly_zone',
        ) as INoFlyZone[];
        obstacles = noFlyZonesAndObstacles.filter(
          (item) => item.type === 'obstacle',
        ) as IObstacle[];
      }

      if (programsResult.status === 'fulfilled') {
        programs = programsResult.value as IProgram[];
      }

      await dispatch(getJobStatus({ loc_id, isInitialRequest: true }));
      // NOTE: waiting for "isLoadingZones" field
      await api.location
        .getById(loc_id)
        .then((site) => {
          if (!site) throw new Error('Unable to get location data');
          dispatch(populateState({ site, perimeter, noFlyZones, obstacles, programs }));
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail ?? t('errors.somethingWentWrong'), {
            autoClose: ERROR_TOAST_DELAY,
          });
        });

      dispatch(setViewer(EViewer.Map));
      dispatch(setSidebar(ESidebar.CreateSite));
    } catch {
      toast.error('errors.continueCreationFail', {
        autoClose: ERROR_TOAST_DELAY,
      });
    }
  },
);

const updateLocationPerimeter = createAsyncThunk<
  void,
  {
    loc_id: string;
    perimeters: any[];
    currentPerimeterIndex: number;
  },
  { dispatch: any; getState: any }
>(
  'createSite/updateLocationPerimeter',
  async ({ loc_id, perimeters, currentPerimeterIndex }, { dispatch }) => {
    const perimeterData = perimeters.at(currentPerimeterIndex ?? 0);

    let polygonData;
    if (perimeterData?.type === 'FeatureCollection' && perimeterData?.features?.length > 0) {
      const feature = perimeterData.features[0];
      if (feature?.geometry?.type === 'Polygon') {
        polygonData = feature.geometry;
      }
    } else if (perimeterData?.type === 'Polygon') {
      polygonData = perimeterData;
    }

    if (!polygonData) {
      toast.error(t('errors.perimeterOrPolygonInvalid'), { autoClose: ERROR_TOAST_DELAY });
      dispatch(_setIsLoadingZones(false));
      return;
    }
    await api.location.postPerimeter(loc_id, polygonData);
  },
);

const setPerimeters = createAsyncThunk<void, any[], { dispatch: any; getState: any }>(
  'createSite/setPerimeters',
  async (perimeters, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { loc_id } = state.createSite;
    if (loc_id) {
      dispatch(
        updateLocationPerimeter({
          currentPerimeterIndex: perimeters.length - 1,
          loc_id,
          perimeters,
        }),
      );
    }
    dispatch(_setPerimeters(perimeters));
  },
);

export const updateCreateSiteRoles = createAsyncThunk<
  void,
  TRolesWithoutSuperuserPayload,
  NonNullable<unknown>
>('createSite/updateCreateSiteRoles', async (rolesDataPayload, { getState, dispatch }) => {
  const state = getState() as RootState;
  let createSiteRolesState = _.cloneDeep(state.createSite.roles);

  const { action: editAction, data: rolesData } = rolesDataPayload;
  const transformedRolesData = Object.entries(rolesData);

  if (transformedRolesData.length) {
    const [role, data] = transformedRolesData[0];

    switch (editAction) {
      case ESiteEditActions.AddOne: {
        createSiteRolesState = {
          ...createSiteRolesState,
          [role]: createSiteRolesState?.[role] ? [...createSiteRolesState[role], data] : [data],
        };
        break;
      }
      case ESiteEditActions.UpdateOne: {
        if (createSiteRolesState?.[role]) {
          createSiteRolesState = {
            ...createSiteRolesState,
            [role]: createSiteRolesState[role].map((item) =>
              item.name === data.name ? data : item,
            ),
          };
        }
        break;
      }
      case ESiteEditActions.DeleteOne: {
        if (createSiteRolesState?.[role]) {
          createSiteRolesState = {
            ...createSiteRolesState,
            [role]: createSiteRolesState[role].filter((item) => item.name !== data.name),
          };
        }
        break;
      }
    }

    dispatch(setCreateSiteRoles(createSiteRolesState));
  }
});

const createSiteSlice = createSlice({
  name: 'createSite',
  initialState,
  reducers: {
    setLocId: (state, action: PayloadAction<string | null>) => {
      state.loc_id = action.payload;
    },
    setCurrentPlanIndex: (state, action: PayloadAction<number | null>) => {
      state[ECreateSiteSections.Zones].currentPlanIndex = action.payload;
    },
    setCurrentOverviewIndex: (state, action: PayloadAction<number | null>) => {
      state[ECreateSiteSections.Zones].currentOverviewIndex = action.payload;
    },
    _setCurrentStep: (state, action: PayloadAction<number>) => {
      state.steps.current = action.payload;

      if (action.payload > state.steps.maxCurrent) {
        state.steps.maxCurrent = action.payload;
      }

      state[ECreateSiteSections.NoFlyZones].currentIndex = null;
      state[ECreateSiteSections.Obstacles].currentIndex = null;
    },
    _setMaxCurrentStep: (state, action: PayloadAction<number>) => {
      state.steps.maxCurrent = action.payload;
    },
    setImage: (state, action: PayloadAction<string | null>) => {
      state[ECreateSiteSections.Picture].image = action.payload;

      if (!action.payload) {
        state[ECreateSiteSections.Picture].imageUrl = null;
      }
    },
    setProgramPreview: (state, action: PayloadAction<EGeneratedProgramPreview>) => {
      state[ECreateSiteSections.Zones].programPreview = action.payload;
    },
    setInspectionFrequency: (state, action: PayloadAction<number>) => {
      state[ECreateSiteSections.InspectionFrequency].inspectionFrequency = action.payload;
    },
    _setPerimeters: (state, action: PayloadAction<any[]>) => {
      state[ECreateSiteSections.Perimeter].data = action.payload;
      state[ECreateSiteSections.Perimeter].currentIndex = 0;
    },
    setObstacles: (state, action: PayloadAction<IObstacle[]>) => {
      state[ECreateSiteSections.Obstacles].data = action.payload;
      state[ECreateSiteSections.Obstacles].currentIndex = 0;
    },
    addObstacle: (state, action: PayloadAction<IObstacle | undefined>) => {
      const newObstacle = action.payload
        ? action.payload
        : createObstacle(state[ECreateSiteSections.Obstacles].sequenceIndex);

      state[ECreateSiteSections.Obstacles].data = [
        ...state[ECreateSiteSections.Obstacles].data,
        newObstacle,
      ];

      state[ECreateSiteSections.Obstacles].currentIndex =
        state[ECreateSiteSections.Obstacles].data.length - 1;
    },
    updateCurrentObstaclePerimeter: (state, action: PayloadAction<Feature<any>>) => {
      if (state[ECreateSiteSections.Obstacles].currentIndex === null) return;

      const currentObstacle =
        state[ECreateSiteSections.Obstacles].data[
          state[ECreateSiteSections.Obstacles].currentIndex
        ];

      if (currentObstacle) {
        currentObstacle.id = action.payload.id ?? currentObstacle.id;
        currentObstacle.perimeter = action.payload.geometry as LineString;
      }
    },
    updateObstacle: (state, action: PayloadAction<IObstacle>) => {
      const index = state[ECreateSiteSections.Obstacles].data.findIndex(
        (obstacle) => obstacle.id === action.payload.id,
      );

      if (index !== -1) {
        state[ECreateSiteSections.Obstacles].data[index] = action.payload;
      }
    },
    deleteObstacle: (state, action: PayloadAction<number | IObstacle>) => {
      const idToDelete = isNumber(action.payload) ? action.payload : action.payload.id;
      const indexToDelete = state[ECreateSiteSections.Obstacles].data.findIndex(
        (obstacle) => obstacle.id === idToDelete,
      );

      if (indexToDelete === -1) return;

      state[ECreateSiteSections.Obstacles].data.splice(indexToDelete, 1);
      const currentIndex = state[ECreateSiteSections.Obstacles].currentIndex;

      if (currentIndex !== null) {
        if (currentIndex === indexToDelete) {
          state[ECreateSiteSections.Obstacles].currentIndex = Math.min(
            currentIndex,
            state[ECreateSiteSections.Obstacles].data.length - 1,
          );
        } else if (currentIndex > indexToDelete) {
          state[ECreateSiteSections.Obstacles].currentIndex = currentIndex - 1;
        }
      }

      if (!state[ECreateSiteSections.Obstacles].data.length) {
        state[ECreateSiteSections.Obstacles].currentIndex = null;
      }
    },
    increaseObstacleSequenceIndex: (state) => {
      state[ECreateSiteSections.Obstacles].sequenceIndex += 1;
    },
    setCurrentObstacleIndex: (state, action: PayloadAction<number>) => {
      state[ECreateSiteSections.Obstacles].currentIndex = action.payload;
    },
    updateCurrentObstacleIndexById: (state, action: PayloadAction<number>) => {
      if (state.steps.current !== ECreateSiteSteps.Obstacles) return;

      const index = state[ECreateSiteSections.Obstacles].data.findIndex(
        (obstacle) => obstacle.id === action.payload,
      );

      if (index === -1) return;
      state[ECreateSiteSections.Obstacles].currentIndex = index;
    },
    setNoFlyZones: (state, action: PayloadAction<INoFlyZone[]>) => {
      state[ECreateSiteSections.NoFlyZones].data = action.payload;
      state[ECreateSiteSections.NoFlyZones].currentIndex = 0;
    },
    addNoFlyZone: (state, action: PayloadAction<INoFlyZone | undefined>) => {
      const newNoFlyZone = action.payload
        ? action.payload
        : createNoFlyZone(state[ECreateSiteSections.NoFlyZones].sequenceIndex);

      state[ECreateSiteSections.NoFlyZones].data = [
        ...state[ECreateSiteSections.NoFlyZones].data,
        newNoFlyZone,
      ];

      state[ECreateSiteSections.NoFlyZones].currentIndex =
        state[ECreateSiteSections.NoFlyZones].data.length - 1;
    },
    updateCurrentNoFlyZonePerimeter: (state, action: PayloadAction<Feature<any>>) => {
      if (state[ECreateSiteSections.NoFlyZones].currentIndex === null) return;

      const currentNoFlyZone =
        state[ECreateSiteSections.NoFlyZones].data[
          state[ECreateSiteSections.NoFlyZones].currentIndex
        ];

      // TODO: check update perimeter
      if (currentNoFlyZone) {
        currentNoFlyZone.id = action.payload.id ?? currentNoFlyZone.id;
        currentNoFlyZone.perimeter = action.payload.geometry as Polygon;
      }
    },
    updateNoFlyZone: (state, action: PayloadAction<INoFlyZone>) => {
      const index = state[ECreateSiteSections.NoFlyZones].data.findIndex(
        (noFlyZone) => noFlyZone.id === action.payload.id,
      );

      if (index !== -1) {
        state[ECreateSiteSections.NoFlyZones].data[index] = action.payload;
      }
    },
    deleteNoFlyZone: (state, action: PayloadAction<number | INoFlyZone>) => {
      const idToDelete = isNumber(action.payload) ? action.payload : action.payload.id;

      const indexToDelete = state[ECreateSiteSections.NoFlyZones].data.findIndex(
        (noFlyZone) => noFlyZone.id === idToDelete,
      );

      if (indexToDelete === -1) return;

      state[ECreateSiteSections.NoFlyZones].data.splice(indexToDelete, 1);

      const currentIndex = state[ECreateSiteSections.NoFlyZones].currentIndex;

      if (currentIndex !== null) {
        if (currentIndex === indexToDelete) {
          state[ECreateSiteSections.NoFlyZones].currentIndex = Math.min(
            currentIndex,
            state[ECreateSiteSections.NoFlyZones].data.length - 1,
          );
        } else if (currentIndex > indexToDelete) {
          state[ECreateSiteSections.NoFlyZones].currentIndex = currentIndex - 1;
        }
      }

      if (!state[ECreateSiteSections.NoFlyZones].data.length) {
        state[ECreateSiteSections.NoFlyZones].currentIndex = null;
      }
    },
    increaseNoFlyZoneSequenceIndex: (state) => {
      state[ECreateSiteSections.NoFlyZones].sequenceIndex += 1;
    },
    setCurrentNoFlyZoneIndex: (state, action: PayloadAction<number>) => {
      state[ECreateSiteSections.NoFlyZones].currentIndex = action.payload;
    },
    updateCurrentNoFlyZoneIndexById: (state, action: PayloadAction<number>) => {
      if (state.steps.current !== ECreateSiteSteps.NoFlyZones) return;

      const index = state[ECreateSiteSections.NoFlyZones].data.findIndex(
        (noFlyZone) => noFlyZone.id === action.payload,
      );

      if (index === -1) return;

      state[ECreateSiteSections.NoFlyZones].currentIndex = index;
    },
    setCreateSiteRoles: (state, action: PayloadAction<TRolesWithoutSuperuserData>) => {
      state[ECreateSiteSections.Roles] = action.payload;
    },
    setIsLoadingSite: (state, action: PayloadAction<boolean>) => {
      state[ECreateSiteSections.Perimeter].isLoadingSite = action.payload;
    },
    _setIsLoadingZones: (state, action: PayloadAction<boolean>) => {
      state[ECreateSiteSections.Zones].isLoadingZones = action.payload;
    },
    previousPerimeter: (state) => {
      const index = state[ECreateSiteSections.Perimeter].currentIndex;

      if (index === null) {
        state[ECreateSiteSections.Perimeter].currentIndex = 0;
      } else if (index > 0) {
        state[ECreateSiteSections.Perimeter].currentIndex = index - 1;
      }
    },
    nextPerimeter: (state) => {
      const index = state[ECreateSiteSections.Perimeter].currentIndex;

      if (index === null) {
        state[ECreateSiteSections.Perimeter].currentIndex = 0;
      } else if (index < state[ECreateSiteSections.Perimeter].data.length - 1) {
        state[ECreateSiteSections.Perimeter].currentIndex = index + 1;
      }
    },
    updatePerimeter: (state, action: PayloadAction<FeatureCollection>) => {
      const index = state[ECreateSiteSections.Perimeter].currentIndex;

      if (index !== null) {
        const updatedPerimeters = [...state[ECreateSiteSections.Perimeter].data, action.payload];
        state[ECreateSiteSections.Perimeter].data = updatedPerimeters;
        state[ECreateSiteSections.Perimeter].currentIndex = updatedPerimeters.length - 1;
      }
    },
    setName: (state, action: PayloadAction<string>) => {
      state[ECreateSiteSections.Name].name = action.payload;
    },
    setPrograms: (state, action: PayloadAction<IProgram[]>) => {
      state[ECreateSiteSections.Zones].programs = action.payload.map(({ name, ...rest }, index) =>
        name.match(DEFAULT_GENERATED_PROGRAM_NAME_REGEX)
          ? { ...rest, name: `Zone ${index + 1}` }
          : { name, ...rest },
      );
    },
    setCreateSiteCurrentProgramId: (state, action: PayloadAction<string>) => {
      state[ECreateSiteSections.Zones].currentProgramId = action.payload;
    },
    updateProgramById: (
      state,
      action: PayloadAction<{ id: string; changes: Partial<IProgram> }>,
    ) => {
      const index = state[ECreateSiteSections.Zones].programs.findIndex(
        (program) => program.program_id === action.payload.id,
      );

      if (index !== -1) {
        // TODO: check this place
        Object.assign(state[ECreateSiteSections.Zones].programs[index], action.payload.changes);
      }
    },
    nextCurrentProgramId: (state) => {
      const currentIndex = state[ECreateSiteSections.Zones].programs.findIndex(
        (program) => program.program_id === state[ECreateSiteSections.Zones].currentProgramId,
      );

      if (currentIndex !== -1) {
        const nextIndex =
          currentIndex === state[ECreateSiteSections.Zones].programs.length - 1
            ? 0
            : currentIndex + 1;
        state[ECreateSiteSections.Zones].programs[nextIndex].isViewed = true;
        state[ECreateSiteSections.Zones].currentProgramId =
          state[ECreateSiteSections.Zones].programs[nextIndex].program_id;
      }
    },
    previousCurrentProgramId: (state) => {
      const currentIndex = state[ECreateSiteSections.Zones].programs.findIndex(
        (program) => program.program_id === state[ECreateSiteSections.Zones].currentProgramId,
      );

      if (currentIndex !== -1) {
        const prevIndex =
          currentIndex === 0
            ? state[ECreateSiteSections.Zones].programs.length - 1
            : currentIndex - 1;
        state[ECreateSiteSections.Zones].programs[prevIndex].isViewed = true;
        state[ECreateSiteSections.Zones].currentProgramId =
          state[ECreateSiteSections.Zones].programs[prevIndex].program_id;
      }
    },
    setSelectedLocation: (state, action: PayloadAction<IMapboxCityData>) => {
      state[ECreateSiteSections.SearchLocation].selectedLocation = action.payload;
    },
    setLng: (state, action: PayloadAction<number>) => {
      state[ECreateSiteSections.SearchLocation].lng = action.payload;
    },
    setLat: (state, action: PayloadAction<number>) => {
      state[ECreateSiteSections.SearchLocation].lat = action.payload;
    },
    setPerimeterGpsBoundaries: (
      state,
      action: PayloadAction<
        ICreateSiteInitialState[ECreateSiteSections.Perimeter]['gps_boundaries']
      >,
    ) => {
      state[ECreateSiteSections.Perimeter].gps_boundaries = action.payload;
    },
    setPerimeterProcessingPercentage: (state, action: PayloadAction<number | null>) => {
      state[ECreateSiteSections.Perimeter].processingPercentage = action.payload;
    },
    setFakePerimeterProcessing: (state, action: PayloadAction<IFakePerimeterProcessing>) => {
      state[ECreateSiteSections.Zones].fakePerimeterProcessing = action.payload;
    },
    resetCreateSite: () => initialState,
    populateState: (
      state,
      action: PayloadAction<{
        site: ISite;
        perimeter: FeatureCollection | null;
        noFlyZones: INoFlyZone[];
        obstacles: IObstacle[];
        programs: IProgram[];
      }>,
    ) => {
      const { name, loc_id, gps_boundaries, lat, long, picture, inspection_frequency } =
        action.payload.site;

      if (lat && long) {
        state[ECreateSiteSections.SearchLocation].lat = lat;
        state[ECreateSiteSections.SearchLocation].lng = long;
      }

      if (gps_boundaries) {
        state[ECreateSiteSections.Perimeter].gps_boundaries = gps_boundaries;
        state.steps.current = ECreateSiteSteps.Name;
        state.steps.maxCurrent = ECreateSiteSteps.Name;
      }

      if (name) {
        state[ECreateSiteSections.Name].name = name;
      }

      if (loc_id) {
        state.loc_id = loc_id;
      }

      if (action.payload.perimeter) {
        state[ECreateSiteSections.Perimeter].data = [action.payload.perimeter];
        state[ECreateSiteSections.Perimeter].currentIndex = 0;
      }

      if (action.payload.obstacles) {
        state[ECreateSiteSections.Obstacles].data = action.payload.obstacles;
        state[ECreateSiteSections.Obstacles].currentIndex = action.payload.obstacles?.length;
      }

      if (action.payload.noFlyZones) {
        state[ECreateSiteSections.NoFlyZones].data = action.payload.noFlyZones;
        state[ECreateSiteSections.Obstacles].currentIndex = action.payload.noFlyZones?.length;
      }

      if (action.payload.programs) {
        state[ECreateSiteSections.Zones].programs = action.payload.programs;
      }

      if (picture) {
        state[ECreateSiteSections.Picture].imageUrl = picture;
      }

      if (inspection_frequency) {
        state[ECreateSiteSections.InspectionFrequency].inspectionFrequency = inspection_frequency;
      }

      // NOTE: localStorage persisted data
      const createSiteStorageState = DataProcessing.deserialize(
        browserLocalStorage.getItem(ELocalStorageField.CreateSite),
      );
      const persistedStep =
        createSiteStorageState?.[loc_id]?.[ECreateSiteLocalStorageField.CurrentStep];

      const step = getMaxStepForContinueSiteCreation(state, persistedStep);

      // NOTE: reset "site" name if the default name is set
      if (name === DEFAULT_SITE_NAME) {
        state[ECreateSiteSections.Name].name = '';
      }

      state.steps.current = step;
      state.steps.maxCurrent = step;
    },
  },
});

const useCurrentPerimeterSelector = () =>
  useSelectorTyped((state) => {
    if (state.createSite[ECreateSiteSections.Perimeter].currentIndex !== null)
      return state.createSite[ECreateSiteSections.Perimeter].data.at(
        state.createSite[ECreateSiteSections.Perimeter].currentIndex,
      );
  });

const useCurrentCreateSiteProgramSelector = () =>
  useSelectorTyped((state) => {
    if (state.createSite[ECreateSiteSections.Zones].currentProgramId !== null)
      return state.createSite[ECreateSiteSections.Zones].programs.find(
        (program) =>
          program.program_id === state.createSite[ECreateSiteSections.Zones].currentProgramId,
      );
  });

const useCurrentNoFlyZoneSelector = () =>
  useSelectorTyped((state) => {
    if (state.createSite[ECreateSiteSections.NoFlyZones].currentIndex !== null)
      return state.createSite[ECreateSiteSections.NoFlyZones].data.at(
        state.createSite[ECreateSiteSections.NoFlyZones].currentIndex,
      );
  });

const useCurrentObstacleSelector = () =>
  useSelectorTyped((state) => {
    if (state.createSite[ECreateSiteSections.Obstacles].currentIndex !== null)
      return state.createSite[ECreateSiteSections.Obstacles].data.at(
        state.createSite[ECreateSiteSections.Obstacles].currentIndex,
      );
  });

const useNewSiteLocationCoordinates = () =>
  useSelectorTyped((state) => {
    return {
      lng: state.createSite[ECreateSiteSections.SearchLocation].lng,
      lat: state.createSite[ECreateSiteSections.SearchLocation].lat,
    };
  });

const useCreateSiteSteps = () => useSelectorTyped((state) => state.createSite.steps);

const useCreateSiteSelectedLocation = () =>
  useSelectorTyped((state) => state.createSite[ECreateSiteSections.SearchLocation]);

const useCreateSiteName = () =>
  useSelectorTyped((state) => state.createSite[ECreateSiteSections.Name]);

const useCreateSitePerimeter = () =>
  useSelectorTyped((state) => state.createSite[ECreateSiteSections.Perimeter]);

const useCreateSiteNoFlyZones = () =>
  useSelectorTyped((state) => state.createSite[ECreateSiteSections.NoFlyZones]);

const useCreateSiteObstacles = () =>
  useSelectorTyped((state) => state.createSite[ECreateSiteSections.Obstacles]);

const useCreateSitePicture = () =>
  useSelectorTyped((state) => state.createSite[ECreateSiteSections.Picture]);

const useCreateSiteInspectionFrequency = () =>
  useSelectorTyped((state) => state.createSite[ECreateSiteSections.InspectionFrequency]);

const useCreateSiteRoles = () =>
  useSelectorTyped((state) => state.createSite[ECreateSiteSections.Roles]);

const useCreateSiteZones = () =>
  useSelectorTyped((state) => state.createSite[ECreateSiteSections.Zones]);

const {
  _setCurrentStep,
  setLocId,
  setName,
  setCurrentPlanIndex,
  setCurrentOverviewIndex,
  setPrograms,
  nextCurrentProgramId,
  previousCurrentProgramId,
  updateProgramById,
  setCreateSiteCurrentProgramId,
  setProgramPreview,
  setImage,
  setInspectionFrequency,
  _setPerimeters,
  nextPerimeter,
  previousPerimeter,
  updatePerimeter,
  setObstacles,
  addObstacle,
  increaseObstacleSequenceIndex,
  setCurrentObstacleIndex,
  updateCurrentObstacleIndexById,
  updateObstacle,
  updateCurrentObstaclePerimeter,
  deleteObstacle,
  setNoFlyZones,
  addNoFlyZone,
  increaseNoFlyZoneSequenceIndex,
  setCurrentNoFlyZoneIndex,
  updateCurrentNoFlyZoneIndexById,
  updateNoFlyZone,
  updateCurrentNoFlyZonePerimeter,
  deleteNoFlyZone,
  _setIsLoadingZones,
  setIsLoadingSite,
  setLng,
  setLat,
  setSelectedLocation,
  setPerimeterGpsBoundaries,
  setPerimeterProcessingPercentage,
  setFakePerimeterProcessing,
  resetCreateSite,
  populateState,
  setCreateSiteRoles,
} = createSiteSlice.actions;

const createSitesReducer = createSiteSlice.reducer;

export {
  setCurrentStep,
  setName,
  setCurrentPlanIndex,
  setCurrentOverviewIndex,
  setCreateSiteCurrentProgramId,
  setIsLoadingSite,
  updateProgramById,
  nextCurrentProgramId,
  previousCurrentProgramId,
  setProgramPreview,
  setImage,
  setInspectionFrequency,
  setPerimeters,
  nextPerimeter,
  previousPerimeter,
  updatePerimeter,
  setObstacles,
  addObstacle,
  increaseObstacleSequenceIndex,
  setCurrentObstacleIndex,
  updateCurrentObstacleIndexById,
  updateObstacle,
  updateCurrentObstaclePerimeter,
  deleteObstacle,
  setNoFlyZones,
  addNoFlyZone,
  increaseNoFlyZoneSequenceIndex,
  setCurrentNoFlyZoneIndex,
  updateCurrentNoFlyZoneIndexById,
  updateNoFlyZone,
  updateCurrentNoFlyZonePerimeter,
  deleteNoFlyZone,
  loadZones,
  setLng,
  setLat,
  setSelectedLocation,
  setPerimeterGpsBoundaries,
  createSitesReducer,
  setPerimeterProcessingPercentage,
  setFakePerimeterProcessing,
  resetCreateSite,
  continueCreation,
  setCreateSiteRoles,
  useCurrentNoFlyZoneSelector,
  useCurrentPerimeterSelector,
  useCurrentObstacleSelector,
  useNewSiteLocationCoordinates,
  useCurrentCreateSiteProgramSelector,
  useCreateSiteSteps,
  useCreateSiteSelectedLocation,
  useCreateSiteName,
  useCreateSitePerimeter,
  useCreateSiteNoFlyZones,
  useCreateSiteObstacles,
  useCreateSitePicture,
  useCreateSiteInspectionFrequency,
  useCreateSiteRoles,
  useCreateSiteZones,
  initialState as createSiteInitialState,
};
