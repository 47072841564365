import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import cn from 'classnames';
import tooltipArrow from '@assets/images/tooltip-arrow.png';
import { ETooltipPlacement } from '@core/enums/tooltip';
import styles from './styles.scss';

interface IProps {
  shield: {
    text: string;
    className?: string;
  };
  tooltip?: {
    text: string;
    className?: string;
  };
}

export const Shield: React.FC<IProps> = ({ shield, tooltip }) => {
  const { text: shieldText, className: shieldClassName } = shield;
  const { text: tooltipText, className: tooltipClassName } = tooltip || {};

  const { getTooltipProps, getArrowProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: ETooltipPlacement.BottomStart,
      offset: [0, 13],
    });

  return (
    <>
      <div className={cn(styles.shield, shieldClassName)} ref={setTriggerRef}>
        {shieldText}
      </div>
      {visible && tooltip && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: cn(styles.tooltipContainer, tooltipClassName) })}
        >
          {tooltipText}
          <img
            src={tooltipArrow}
            alt='Arrow'
            {...getArrowProps({ className: styles.tooltipArrow })}
          />
        </div>
      )}
    </>
  );
};
