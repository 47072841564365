import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { FeatureCollection } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { ESidebar } from '@core/enums';
import { IObstacle } from '@core/interfaces/sites/createSite';
import { customizeTrash } from '@modules/Viewers/views/MapViewer/utils/customizeTrash';
import { MapStyleObstacle } from '@modules/Viewers/views/MapViewer/utils/mapboxStyles';
import { updateLayerOrdering } from './updateLayerOrdering';
import { ECreateSiteLayers, ECreateSiteMapDrawLayers } from '../../../enums/layers';
import { removeLayersAndSources } from '../../removeLayersAndSources';

interface IProps {
  map: mapboxgl.Map | null;
  obstacle?: IObstacle | null;
  sidebar: ESidebar;
  onGeoJsonChange: (geoJson: FeatureCollection) => void;
}

let draw: MapboxDraw | null = null;

export const addEditObstacle = (props: IProps) => {
  const { map, obstacle, sidebar, onGeoJsonChange } = props;
  const obstaclesPerimeter = obstacle?.perimeter;

  if (!map) return;

  if (sidebar !== ESidebar.CreateSite || !obstaclesPerimeter) {
    removeLayersAndSources(map, {
      ['geojson']: ['geojson'],
    });

    if (draw) {
      map.removeControl(draw);
      draw = null;
    }
    return;
  }

  const updateGeoJson = () => {
    if (!draw) return;
    let data = draw.getAll();

    if (data.features.length > 1) {
      const featureId = data.features[0].id;

      if (featureId) {
        draw.delete(featureId.toString());
        data = draw.getAll();
      }
    }

    if (data.features.length > 0) {
      onGeoJsonChange(data);
    }
  };

  if (!map.getSource('geojson')) {
    map.addSource('geojson', {
      type: 'geojson',
      data: obstaclesPerimeter,
    });
  } else {
    (map.getSource('geojson') as mapboxgl.GeoJSONSource).setData(turf.feature(obstaclesPerimeter));
  }

  if (!draw) {
    draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        trash: true,
      },
      styles: MapStyleObstacle,
    });

    map.addControl(draw, 'top-right');

    customizeTrash();

    map.on('draw.create', updateGeoJson);
    map.on('draw.delete', updateGeoJson);
    map.on('draw.update', updateGeoJson);
  }

  if (draw) {
    draw.deleteAll();
    draw.add(obstaclesPerimeter);

    const addedFeatures = draw.getAll();

    if (obstaclesPerimeter.coordinates?.length) {
      const featureId = addedFeatures.features[0].id;

      if (featureId) {
        draw.changeMode('direct_select', { featureId: featureId.toString() });
      }
    } else {
      draw.changeMode('draw_line_string');
    }

    updateLayerOrdering(map, {
      patterns: {
        beforeId: new RegExp(`^${ECreateSiteLayers.Perimeter}`),
        afterIds: [new RegExp(`^${ECreateSiteMapDrawLayers.Obstacles}`)],
      },
    });
  }
};
