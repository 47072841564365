import { DEFAULT_SQUARE_RATIO_FOR_ACTIVE_DETECTION_IN_PERCENTS } from '@core/constants/viewers/imageViewer';
import { getPercents } from '@core/utils';

const FRAME_OFFSET = 8; // - corner frame px (0.5rem)

export function getInitialScale(activeDetectionRect: DOMRect, mainContainerRect: DOMRect) {
  const { width: detectionWidth, height: detectionHeight } = activeDetectionRect;
  const { width: containerWidth, height: containerHeight } = mainContainerRect;

  const extendedDetectionWidth = detectionWidth + FRAME_OFFSET * 2;
  const extendedDetectionHeight = detectionHeight + FRAME_OFFSET * 2;

  const detectionSquare = extendedDetectionWidth * extendedDetectionHeight;
  const containerSquare = containerWidth * containerHeight;

  const areaRatioInPercents = getPercents(detectionSquare, containerSquare);

  const scale = Math.ceil(
    DEFAULT_SQUARE_RATIO_FOR_ACTIVE_DETECTION_IN_PERCENTS / areaRatioInPercents,
  );

  return scale;
}

interface IOffsetResponse {
  top: number; // in percent
  left: number; // in percent
}

export function getInitialImagesContainerOffset(
  scale: number,
  activeDetectionRect: DOMRect,
  mainContainerRect: DOMRect,
): IOffsetResponse {
  const {
    width: detectionWidth,
    height: detectionHeight,
    left: detectionLeft,
    y: rawDetectionTop,
  } = activeDetectionRect;
  const { width: containerWidth, height: containerHeight, y: containerTop } = mainContainerRect;

  const detectionTop = rawDetectionTop - containerTop;

  const [xScreenCenter, yScreenCenter] = [containerWidth, containerHeight].map((v) => v / 2);

  const xDetectionCenter = detectionLeft + detectionWidth / 2;
  const yDetectionCenter = detectionTop + detectionHeight / 2;

  const top = yScreenCenter - yDetectionCenter;
  const left = xScreenCenter - xDetectionCenter;
  const topPositionInPercent = getPercents(top, containerHeight);
  const leftPositionInPercent = getPercents(left, containerWidth);

  return { left: leftPositionInPercent * scale, top: topPositionInPercent * scale };
}
