import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import solarPanelsIcon from '@assets/icons/solar-panels.svg';
import zoningIcon from '@assets/icons/zoning.svg';
import { DEFAULT_SCALE } from '@core/constants/viewers/imageViewer';
import { EImageViewerStyle, EViewerType } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { ISampleDetection } from '@core/interfaces';
import {
  editImageViewer,
  fetchSampleAnomalyMask,
  fetchSampleSegmentationMask,
  setSelectedSample,
  updateImageViewerScale,
  updateImagesStyles,
  useCurrentAnomalyIdSelector,
  useImageViewerSelector,
  useSamplesSelector,
} from '@core/store/slices';
import { createSampleName, getPercents, toPercents } from '@core/utils';
import { useImageViewerContext } from '@modules/Viewers/views/ImageViewer/contexts/viewer';
import {
  getFrameLimiter,
  getPositionData,
} from '@modules/Viewers/views/ImageViewer/utils/getFrameLimiter';
import { transformDetections } from '@modules/Viewers/views/ImageViewer/utils/transformDetections';
import {
  getInitialImagesContainerOffset,
  getInitialScale,
} from '@modules/Viewers/views/ImageViewer/utils/viewers/mainView';
import {
  getInitialMiniFrameOffset,
  getMiniFrameSize,
} from '@modules/Viewers/views/ImageViewer/utils/viewers/miniView';
import { ImageFrame } from '@modules/Viewers/views/ImageViewer/widgets/ImageFrame';
import {
  AnomalyMask,
  RgbMask,
  SegmentationMask,
  ThermalMask,
} from '@modules/Viewers/views/ImageViewer/widgets/ImageMasks';
import { Slider } from '@modules/Viewers/views/ImageViewer/widgets/Slider';
import { Switcher } from '@components/Switcher';
import { ToggleButton } from '@components/ToggleButton';
import styles from './styles.scss';
import commonStyles from '../../styles.scss';
import { MiniViewer } from '../MiniView';

interface IInitialMousePosition {
  x: number;
  y: number;
}

export const MainView: FC = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [initialMousePosition, setInitialMousePosition] = useState<IInitialMousePosition>({
    x: 0,
    y: 0,
  });
  const [transformedDetections, setTransformedDetections] = useState<ISampleDetection[]>([]);

  const { anomalySamples, selectedSample } = useSamplesSelector();
  const { scale, styles: viewerStyles, flags } = useImageViewerSelector();
  const anomalyId = useCurrentAnomalyIdSelector();
  const { loadingState, onLoadImage, mainContainer, rgbAspectRatio } = useImageViewerContext();

  const { segmentationMask, anomalyMask } = flags;
  const thermalOpacity = viewerStyles.thermal.current.opacity;

  const activeFrameRef = useRef<HTMLDivElement | null>(null);
  const imagesWrapperRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatchTyped();

  const {
    rgb: isLoadedRGB,
    segmentation: isLoadedSegmentation,
    thermal: isLoadedThermal,
    anomaly: isLoadedAnomaly,
  } = loadingState;

  const selectedSampleIndex = useMemo(
    () => anomalySamples.findIndex((sample) => selectedSample?.id === sample.id),
    [anomalySamples, selectedSample],
  );
  const sampleName = anomalyId ? createSampleName(anomalyId, selectedSampleIndex) : '';

  const handleToggleSegmentation = useCallback(
    (isEnabledSegmentationMask: boolean) => {
      dispatch(editImageViewer({ segmentationMask: isEnabledSegmentationMask }));

      if (isEnabledSegmentationMask) {
        dispatch(fetchSampleSegmentationMask());
      }
    },
    [dispatch],
  );

  const handleToggleAnomaly = useCallback(
    (isEnabledAnomalyMask: boolean) => {
      dispatch(editImageViewer({ anomalyMask: isEnabledAnomalyMask }));

      if (isEnabledAnomalyMask) {
        dispatch(fetchSampleAnomalyMask());
      }
    },
    [dispatch],
  );

  const handleChangeThermal = useCallback(
    (opacity: number) => {
      dispatch(
        updateImagesStyles({
          [EImageViewerStyle.Thermal]: {
            default: { opacity },
            current: { opacity },
          },
        }),
      );
    },
    [dispatch],
  );

  const handleSwitch = useCallback(
    (index: number) => dispatch(setSelectedSample(anomalySamples[index])),
    [dispatch, anomalySamples],
  );

  const isEnabledSegmentationMask = segmentationMask && selectedSample?.segmentation_mask;
  const isEnabledAnomalyMask = anomalyMask && selectedSample?.anomaly_mask;

  const handleSetActiveFrameRef = useCallback((activeFrame: HTMLDivElement) => {
    activeFrameRef.current = activeFrame;
    if (imagesWrapperRef.current && activeFrameRef.current) {
      const activeDetectionFrameRect = activeFrameRef.current.getBoundingClientRect();
      const imagesContainerFrameRect = imagesWrapperRef.current.getBoundingClientRect();

      const initialScale = getInitialScale(activeDetectionFrameRect, imagesContainerFrameRect);
      const frameLimiter = getFrameLimiter(initialScale);

      const initialImagesContainerOffset = getPositionData(
        frameLimiter,
        getInitialImagesContainerOffset(
          initialScale,
          activeDetectionFrameRect,
          imagesContainerFrameRect,
        ),
      );

      const initialMiniFrameOffset = getPositionData(
        frameLimiter,
        getInitialMiniFrameOffset(initialScale, activeDetectionFrameRect, imagesContainerFrameRect),
      );

      dispatch(updateImageViewerScale({ current: initialScale, default: initialScale }));
      dispatch(
        updateImagesStyles({
          [EImageViewerStyle.Container]: {
            current: initialImagesContainerOffset.position,
            default: initialImagesContainerOffset.position,
          },
          [EImageViewerStyle.MiniFrame]: {
            current: initialMiniFrameOffset.position,
            default: initialMiniFrameOffset.position,
          },
        }),
      );
    }
  }, []);

  const imagesStyles = useMemo(() => {
    const properties: React.CSSProperties = {
      cursor: isDragging ? 'grabbing' : 'grab',
    };
    const { left, top } = viewerStyles.container.current;

    if (scale.current) {
      properties.transform = `scale(${scale.current})`;
    }

    if (top) {
      properties.top = toPercents(top);
    }

    if (left) {
      properties.left = toPercents(left);
    }

    return properties;
  }, [
    scale.current,
    viewerStyles.container.current.top,
    viewerStyles.container.current.left,
    isDragging,
  ]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
    setInitialMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    if (isDragging && imagesWrapperRef.current && scale.current > DEFAULT_SCALE) {
      const frameLimiter = getFrameLimiter(scale.current);
      const imagesContainerFrameRect = imagesWrapperRef.current.getBoundingClientRect();

      const deltaX = event.clientX - initialMousePosition.x;
      const deltaY = event.clientY - initialMousePosition.y;

      const deltaXInPercent = getPercents(deltaX, imagesWrapperRef.current.clientWidth);
      const deltaYInPercent = getPercents(deltaY, imagesWrapperRef.current.clientHeight);

      const { top: containerTop, left: containerLeft } = viewerStyles.container.current;
      const { top: miniFrameTop, left: miniFrameLeft } = viewerStyles.miniFrame.current;

      const currentContainerTop = containerTop + deltaYInPercent;
      const currentContainerLeft = containerLeft + deltaXInPercent;

      const currentMiniFrameTop = miniFrameTop - deltaYInPercent / scale.current;
      const currentMiniFrameLeft = miniFrameLeft - deltaXInPercent / scale.current;

      const containerPositionData = getPositionData(frameLimiter, {
        top: currentContainerTop,
        left: currentContainerLeft,
      });

      const currentMiniFramePositionData = getPositionData(frameLimiter, {
        left: currentMiniFrameLeft,
        top: currentMiniFrameTop,
      });

      const currentMiniFrameSize = getMiniFrameSize(scale.current, imagesContainerFrameRect);

      const payload = {
        [EImageViewerStyle.Container]: {
          current: containerPositionData.position,
        },
        [EImageViewerStyle.MiniFrame]: {
          current: currentMiniFramePositionData.position,
        },
      };

      if (containerPositionData.limiter.top) {
        payload[EImageViewerStyle.MiniFrame].current.top = 0;
      }
      if (containerPositionData.limiter.left) {
        payload[EImageViewerStyle.MiniFrame].current.left = 0;
      }
      if (containerPositionData.limiter.right) {
        payload[EImageViewerStyle.MiniFrame].current.left = 100 - currentMiniFrameSize.width;
      }
      if (containerPositionData.limiter.bottom) {
        payload[EImageViewerStyle.MiniFrame].current.top = 100 - currentMiniFrameSize.height;
      }

      dispatch(updateImagesStyles(payload));
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging]);

  useEffect(() => {
    setTransformedDetections(
      mainContainer.current
        ? transformDetections({
            sampleDetections: selectedSample?.detections,
            type: EViewerType.Viewer,
            containerHeight: mainContainer.current.clientHeight,
            containerWidth: mainContainer.current.clientWidth,
            aspectRatio: rgbAspectRatio,
          })
        : [],
    );
  }, [selectedSample?.detections, rgbAspectRatio]);

  if (!selectedSample) return null;

  return (
    <>
      <MiniViewer imagesContainer={imagesWrapperRef} />
      <div ref={imagesWrapperRef} className={styles.imagesWrapper}>
        <div
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          style={imagesStyles}
          className={cn(commonStyles.images, styles.withControls)}
        >
          <RgbMask sample={selectedSample} isLoaded={isLoadedRGB} onLoad={onLoadImage} />
          {isEnabledSegmentationMask && (
            <SegmentationMask
              sample={selectedSample}
              isLoaded={isLoadedSegmentation}
              onLoad={onLoadImage}
            />
          )}
          <ThermalMask
            sample={selectedSample}
            isLoaded={isLoadedThermal}
            value={thermalOpacity}
            onLoad={onLoadImage}
          />
          {isEnabledAnomalyMask && (
            <AnomalyMask isLoaded={isLoadedAnomaly} sample={selectedSample} onLoad={onLoadImage} />
          )}
          {transformedDetections.map((detection) => (
            <ImageFrame
              key={detection.id}
              detection={detection}
              type={EViewerType.Viewer}
              onSetActiveFrameRef={handleSetActiveFrameRef}
            />
          ))}
        </div>
      </div>
      <div className={styles.controls}>
        <div className={styles.controlsLeft}>
          <Slider
            initialValue={thermalOpacity}
            min={0}
            max={100}
            step={5}
            onChange={handleChangeThermal}
          />
          <ToggleButton active={anomalyMask} icon={zoningIcon} onChange={handleToggleAnomaly} />
          <ToggleButton
            active={segmentationMask}
            icon={solarPanelsIcon}
            onChange={handleToggleSegmentation}
          />
        </div>
        <div className={styles.controlsCenter}>
          <Switcher
            title={sampleName}
            options={anomalySamples}
            selectedId={Number(selectedSample?.id)}
            onArrowClick={handleSwitch}
            onDotClick={handleSwitch}
          />
        </div>
      </div>
    </>
  );
};
