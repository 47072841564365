import { IChartData } from '@modules/Sidebar/interfaces';

type SubgroupValue = number;
type SubgroupPair = { [subgroupTitle: string]: SubgroupValue };
type Response = SubgroupPair & { group: string };

export const aggregateSubgroupsValues = (chartData: IChartData[]) => {
  const aggregateSubgroupValues = chartData.map((data) => {
    const { group, subgroups } = data.data;

    const reducedSubgroups = subgroups.reduce(
      (acc, item) => ({ ...acc, [item.title]: item.value }),
      {} as SubgroupPair,
    );

    return {
      ...reducedSubgroups,
      group: group.key,
    } as Response;
  });

  return aggregateSubgroupValues;
};
