/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { round } from 'lodash';
import { IChartData, IStackedBarChartStyles } from '@modules/Sidebar/interfaces';
import { getField } from '@modules/Sidebar/utils';
import {
  IExtendedSubgroup,
  aggregateSubgroupsValues,
  getCenter,
  getFilteredSubgroupsHeight,
  getGroupWidth,
  getGroupXTextPosition,
  getMaxValueFromGroups,
  getMaxYAxisValue,
  getStackedBarHeight,
  getSubgroupYTextPosition,
  setHorizontalGrid,
  setSubgroupText,
  setXAxis,
  setYAxis,
  sortSubgroupByPosition,
  transformChartData,
} from '@modules/Sidebar/utils/charts';
import { EDefectivePanelsTooltipValue } from '@modules/Sidebar/widgets/SolarPanels/enums';
import { ITooltipProps, ChartTooltip } from '@components/Tooltip/Chart/Tooltip';

interface IStackedChartProps {
  chartData: IChartData[];
  stylesData: IStackedBarChartStyles;
}

export const StackedBarChart: FC<IStackedChartProps> = ({ chartData, stylesData }) => {
  const [tooltip, setTooltip] = useState<ITooltipProps | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const { chart, bar } = stylesData;

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = d3.select(svgRef.current);

    const groupsSubgroups = chartData.map(getField<any[]>('data.subgroups'));

    const sortedSubgroupsByPosition = sortSubgroupByPosition(groupsSubgroups[0]);
    const subgroupsTitles = sortedSubgroupsByPosition.map(getField<string>('title'));
    const subgroupsColors = sortedSubgroupsByPosition.map(getField<string>('color'));

    const groupsWithAggregatedSubgroupsValues = aggregateSubgroupsValues(chartData);

    // 1. Init chart measures
    svg
      .attr('width', chart.width)
      .attr('height', chart.height)
      .append('g')
      .attr('transform', `translate(${chart.margin.left}, ${chart.margin.top})`);

    // 2. Set x-axis
    const x = d3
      .scaleBand()
      .domain([])
      .range([0, chart.width - chart.margin.left]);

    const yMax = getMaxValueFromGroups(groupsSubgroups);
    const yAxisMaxValue = getMaxYAxisValue(yMax);

    const y = d3
      .scaleLinear()
      .domain([0, yAxisMaxValue])
      .range([chart.height - chart.margin.bottom, chart.margin.top]);

    // 4. Prepare the data
    const color = d3.scaleOrdinal().domain(subgroupsTitles).range(subgroupsColors);
    const stackedData = d3.stack().keys(subgroupsTitles)(groupsWithAggregatedSubgroupsValues);
    const transformedChartData = transformChartData(chartData);

    setHorizontalGrid(svg, y, {
      xOffset: chart.margin.left,
      width: chart.width,
      strokeColor: chart.grid.stroke,
    });

    svg
      .append('g')
      .selectAll('g')
      .data(stackedData)
      .enter()
      .append('g')
      // @ts-ignore
      .attr('fill', (d) => color(d.key))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', () => chart.margin.left + bar.gap)
      .attr('y', (d) => y(d[1]))
      .attr('height', (d) => {
        const [start, end] = d;

        const diff = round(Math.abs(start - end), 1);
        const height = getStackedBarHeight(y, start, end);

        const foundSubgroup = Object.entries(d.data).filter(([_, value]) => value === diff);

        // NOTE: Set height for each subgroup
        for (const [title] of foundSubgroup) {
          const subgroup = transformedChartData[d.data.group][title];

          if (!subgroup.height) {
            transformedChartData[d.data.group][title].height = height;
          }
        }

        return height;
      })
      .attr(
        'width',
        (() => {
          // NOTE: Set width for each subgroup (the same)
          for (const group in transformedChartData) {
            const subgroups = transformedChartData[group];

            for (const subgroup in subgroups) {
              transformedChartData[group][subgroup].width = bar.width;
            }
          }
          return bar.width;
        })(),
      )
      // NOTE: Handlers section
      .on('mousemove', (event: MouseEvent, d) => {
        const [start, end] = d;
        const parentNode = (event.target as HTMLElement).parentNode as SVGGElement;
        const stackData = d3.select(parentNode).datum() as any;

        if (stackData.key) {
          const position = {
            x: event.pageX,
            y: event.pageY,
          };

          const data = [
            {
              key: EDefectivePanelsTooltipValue.Subgroup,
              value: stackData.key,
              color: color(stackData.key) as string,
            },
            {
              key: EDefectivePanelsTooltipValue.Value,
              value: round(end - start),
            },
          ];

          setTooltip({ position, data });
        }
      })
      .on('mouseout', (event: MouseEvent) => {
        const relatedTarget = event.relatedTarget as HTMLElement | null;
        if (relatedTarget && relatedTarget.tagName === 'text') return;

        setTooltip(null);
      });

    chartData.forEach(({ data }) => {
      const { group } = data;

      const subgroups = transformedChartData[group.key];
      const groupWidth = getGroupWidth(subgroups);
      const xGroupPosition = getGroupXTextPosition({
        groupWidth,
        marginLeft: chart.margin.left,
        correctionValue: bar.gap,
      });

      const subgroupEntries = Object.entries(subgroups);

      subgroupEntries.forEach(([title, values]) => {
        const { height: subgroupHeight, value } = values as any;

        const filteredSubgroups = Object.values(subgroups).filter(
          (v: any) => v.position > (values as any).position,
        ) as IExtendedSubgroup[];

        const aggregatedHeightOfFilteredSubgroups = getFilteredSubgroupsHeight(filteredSubgroups);
        const ySubgroupCenter = getCenter(subgroupHeight);
        const yAbsoluteSubgroupHeight = aggregatedHeightOfFilteredSubgroups + ySubgroupCenter;

        const ySubgroupPosition = getSubgroupYTextPosition({
          chartHeight: chart.height,
          subgroupAbsoluteHeight: yAbsoluteSubgroupHeight,
          correctionValue: 40.5,
        });

        if (value) {
          setSubgroupText(svg, {
            x: xGroupPosition,
            y: ySubgroupPosition,
            title,
            value,
            maxNumber: yAxisMaxValue,
          });
        }
      });
    });

    setYAxis(svg, y, {
      marginLeft: chart.margin.left,
      maxNumber: yAxisMaxValue,
    });

    setXAxis(svg, x, {
      marginLeft: chart.margin.left,
      marginBottom: chart.margin.bottom,
      chartHeight: chart.height,
    });

    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };
  }, [chartData]);

  return (
    <>
      <svg ref={svgRef} />
      {tooltip && <ChartTooltip {...tooltip} />}
    </>
  );
};
