import { FC, memo, useMemo } from 'react';
import cn from 'classnames';
import { ISample } from '@core/interfaces';
import { isNumber, toPercents } from '@core/utils';
import styles from './styles.scss';
import { ELoadingState } from '../../enums/loading';

interface IProps {
  alt?: string;
  value?: number;
  className?: string;
  sample: ISample | null;
  isLoaded: boolean;
  onLoad: (type: ELoadingState) => void;
}

export const ThermalMask: FC<IProps> = memo(
  ({ sample, isLoaded, value, className, onLoad, alt = 'thermal image' }) => {
    const handleLoadImage = () => onLoad(ELoadingState.Thermal);

    const cssStyles = useMemo(() => {
      const properties: React.CSSProperties = {};

      if (isNumber(value)) {
        properties.opacity = toPercents(value);
      }

      return properties;
    }, [value]);

    return (
      <img
        src={sample?.thermal_image}
        className={cn(styles.thermalImage, { [styles.imageLoaded]: !isLoaded }, className)}
        style={cssStyles}
        alt={alt}
        onLoad={handleLoadImage}
      />
    );
  },
);
