import { createContext, useContext } from 'react';

export interface IAnomalyDetailsValue {
  isEditable: boolean;
  isDisabled: boolean;
  scrollTop?: number;
}

export const AnomalyDetailsContext = createContext<IAnomalyDetailsValue | undefined>(undefined);
export const useAnomalyDetailsContext = (): IAnomalyDetailsValue => {
  const context = useContext(AnomalyDetailsContext);

  if (!context) {
    throw new Error('"useAnomalyDetailsContext" must be used within an "AnomalyDetailsContext"');
  }

  return context;
};
