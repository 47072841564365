import { EAnomalyMode, EAnomalySubtype } from '@core/enums/anomalies';

const documentStyle = getComputedStyle(document.documentElement);

export const stackedBarChartStylesData = {
  chart: {
    width: 285,
    height: 400,
    margin: {
      top: 20,
      right: 30,
      bottom: 45,
      left: 35,
    },
    grid: {
      stroke: documentStyle.getPropertyValue('--outflier-dark-graylish-blue'),
    },
  },
  bar: {
    width: 235,
    gap: 15,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
};

// NOTE: for fast test without request data
export const mockData = [
  {
    data: {
      group: {
        key: '',
        title: '',
        value: '',
      },
      subgroups: [
        {
          key: EAnomalySubtype.BypassDiode,
          position: 1,
          title: 'Diode',
          color: '#FE5038',
          value: 1,
        },
        {
          key: EAnomalySubtype.Hotspot,
          position: 2,
          title: 'Hotspots',
          color: '#F77046',
          value: 5,
        },
        {
          key: EAnomalySubtype.MultipleHotspot,
          position: 3,
          title: 'Multiple hotspots',
          color: '#F98645',
          value: 39,
        },
        {
          key: EAnomalySubtype.Coldspot,
          position: 4,
          title: 'Coldspot',
          color: '#FBA251',
          value: 3,
        },
        {
          key: EAnomalyMode.String,
          position: 5,
          title: 'Strings',
          color: '#FFF06B',
          value: 2,
        },
        {
          key: EAnomalyMode.Mixed,
          position: 6,
          title: 'Mixed',
          color: '#7C80EC',
          value: 15,
        },
        {
          key: EAnomalyMode.Visual,
          position: 7,
          title: 'Visual',
          color: '#7CB6EC',
          value: 15,
        },
      ],
    },
  },
];
