import {
  SURFACE_AFFECTED_POPOVER_HEIGHT,
  TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
} from '../constants';
import { EAnomalyDetailsPopovers } from '../enums';

export const STRING_INITIAL_POPOVERS_STYLES = {
  [EAnomalyDetailsPopovers.PanelsAffected]: {
    height: SURFACE_AFFECTED_POPOVER_HEIGHT,
  },
  [EAnomalyDetailsPopovers.TemperatureDifference]: {
    height: TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
  },
};
