import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '@core/api';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { useAuth } from '@core/contexts/auth';
import { ROUTE_PATHS } from '@core/router/routerConfig';
import { useAccessControlSelector } from '@core/store/slices';
import { authStorage } from '@core/utils';
import { Button } from '@components/Button';
import { EButtonColor } from '@components/Button/Button';
import styles from './styles.scss';

const SelectOrganization = () => {
  const { tenantRef, onLogout } = useAuth();
  const { t } = useTranslation();
  const { user } = useAccessControlSelector();
  const navigate = useNavigate();

  const handleSelectedOrganization = useCallback(async (tenant: string) => {
    try {
      const refresh_token = authStorage.tokens.refresh_token.get();

      if (refresh_token) {
        const response = await api.user.addTenantToToken({ tenant, refresh_token });

        Object.entries(response).forEach(([key, value]) => {
          if (key in authStorage.tokens) {
            authStorage.tokens[key].set(value);
          }
        });

        authStorage.tenant.set(tenant);
        tenantRef.current = tenant;
        navigate(ROUTE_PATHS.Root);
      }
    } catch {
      toast.error(t('errors.somethingIsWrong'), { autoClose: ERROR_TOAST_DELAY });
    }
  }, []);

  return (
    user.data && (
      <div className={styles.root}>
        <section className={styles.container}>
          <header className={styles.header}>
            <h1 className={styles.title}>
              {t('selectOrganization.title', { user: user.data.full_name })}
            </h1>
            <h2 className={styles.subtitle}>{t('selectOrganization.subtitle')}</h2>
          </header>
          <main className={styles.main}>
            <ul className={styles.list}>
              {(user.data?.tenants || []).map((tenant) => (
                <li className={styles.tenantItem} key={tenant}>
                  <Button
                    color={EButtonColor.Grey}
                    onClick={handleSelectedOrganization.bind(null, tenant)}
                  >
                    {tenant}
                  </Button>
                </li>
              ))}
            </ul>
          </main>
          <footer className={styles.footer}>
            <Button color={EButtonColor.Red} className={styles.logoutBtn} onClick={onLogout}>
              {t('selectOrganization.logout')}
            </Button>
          </footer>
        </section>
      </div>
    )
  );
};

export default SelectOrganization;
