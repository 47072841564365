import * as d3 from 'd3';

interface IXAxisOptions {
  marginLeft: number;
  marginBottom: number;
  chartHeight: number;
}

export const setXAxis = (svg, x, options: IXAxisOptions) => {
  const { marginLeft, marginBottom, chartHeight } = options;

  svg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(${marginLeft}, ${chartHeight - marginBottom})`)
    .call(d3.axisBottom(x))
    .style('font-size', '12px')
    .style('font-weight', '500');

  // NOTE: Remove vertical strokes which are located opposite each of the x-axis value
  svg.selectAll('.x-axis line').style('stroke', 'none');
  // NOTE: Remove horizontal x-axis line
  svg.selectAll('.x-axis path').style('display', 'none');
};
