import { ISolarPanelsWithIncidentAnomalies } from '@core/api/report/solarPanels';
import { EAnomalyMode, EAnomalySubtype } from '@core/enums/anomalies';

export function aggregateDefectivePanelsByAnomalyTypeAndSubtype(
  solarPanels: ISolarPanelsWithIncidentAnomalies[],
) {
  return solarPanels.reduce(
    (acc, solarPanel) => {
      if (solarPanel.incident_anomalies.length) {
        solarPanel.incident_anomalies.forEach((anomaly) => {
          switch (anomaly.mode) {
            case EAnomalyMode.Thermal: {
              if (anomaly.submode) {
                acc[anomaly.submode] += 1;
              }
              break;
            }
            case EAnomalyMode.Visual:
            case EAnomalyMode.Mixed:
            case EAnomalyMode.String: {
              acc[anomaly.mode] += 1;
              break;
            }
          }
        });
      }

      return acc;
    },
    {
      [EAnomalySubtype.BypassDiode]: 0,
      [EAnomalySubtype.Coldspot]: 0,
      [EAnomalySubtype.Hotspot]: 0,
      [EAnomalySubtype.MultipleHotspot]: 0,
      [EAnomalyMode.String]: 0,
      [EAnomalyMode.Mixed]: 0,
      [EAnomalyMode.Visual]: 0,
    },
  );
}
