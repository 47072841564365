import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import userIcon from '@assets/icons/user.svg';
import { useAuth } from '@core/contexts/auth';
import { useDispatchTyped } from '@core/hooks';
import { ROUTE_PATHS } from '@core/router/routerConfig';
import { changeUserOrganization, useAccessControlSelector } from '@core/store/slices';
import { Dropdown } from '@components/Dropdown';
import { IOption } from '@components/Dropdown/Dropdown';
import styles from './styles.scss';

interface IProps {}

export const User: React.FC<IProps> = () => {
  const { onLogout } = useAuth();
  const { user, isDisabledLogout } = useAccessControlSelector();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatchTyped();

  const redirectToSelectOrganizationPage = useCallback(() => {
    dispatch(changeUserOrganization());
    navigate(ROUTE_PATHS.Login.SelectOrganization);
  }, []);

  const options: IOption[] = useMemo(() => {
    const dynamicOptions: IOption[] = [];

    if (user.data?.tenants && user.data?.tenants.length > 1) {
      dynamicOptions.push({
        id: 'changeOrganization',
        name: t('userDropdown.changeOrganizationButton'),
        disabled: isDisabledLogout,
        onClick: redirectToSelectOrganizationPage,
      });
    }

    dynamicOptions.push({
      id: 'logout',
      name: t('userDropdown.logoutButton'),
      disabled: isDisabledLogout,
      onClick: onLogout,
    });

    return dynamicOptions;
  }, [isDisabledLogout, onLogout, redirectToSelectOrganizationPage, user.data?.tenants]);

  return (
    <Menu as='div' className={styles.userContainer}>
      {({ open }) => (
        <>
          <Menu.Button className={styles.user}>
            <span className={styles.userName}>{user?.data?.full_name}</span>
            <img className={styles.userIcon} src={userIcon} alt='user icon' />
          </Menu.Button>
          <Dropdown open={open} options={options} />
        </>
      )}
    </Menu>
  );
};
