import { FC, useCallback, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { DEFAULT_DONUT_CHART_PARAMS } from './constants/params';
import { getCenter, getInnerRadius, getOuterRadius } from './utils/getCalculations';

interface DataItem {
  value: number;
}

interface IProps {
  data: Array<{ color: string; value: number }>;
  width?: number;
  height?: number;
  innerRadiusCoefficient?: number;
  label?: string;
  labelColor?: string;
}

export const DonutChart: FC<IProps> = ({
  data,
  label,
  width = DEFAULT_DONUT_CHART_PARAMS.width,
  height = DEFAULT_DONUT_CHART_PARAMS.height,
  innerRadiusCoefficient = DEFAULT_DONUT_CHART_PARAMS.innerRadiusCoefficient,
  labelColor = DEFAULT_DONUT_CHART_PARAMS.labelColor,
}) => {
  const svgRef = useRef<any | null>(null);

  const renderDonutChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    // NOTE: calculations
    const outerRadius = getOuterRadius(width, height);
    const innerRadius = getInnerRadius(outerRadius, innerRadiusCoefficient);
    const [xCenter, yCenter] = [width, height].map(getCenter);

    const pie = d3.pie<DataItem>().value((d) => d.value);
    const pieData = pie(data);

    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${xCenter}, ${yCenter})`)
      .selectAll('path')
      .data(pieData)
      .enter()
      .append('path')
      .attr('d', arc as any)
      .attr('fill', (d, i) => data[i].color);

    if (label) {
      svg
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', labelColor)
        .attr('x', xCenter)
        .attr('y', yCenter)
        .attr('dy', '0.35rem')
        .text(label);
    }
  }, [data, width, height, innerRadiusCoefficient, label, labelColor]);

  useEffect(() => {
    renderDonutChart();

    return () => {
      d3.select(svgRef.current).selectAll('*').remove();
    };
  }, [renderDonutChart]);

  return <svg ref={svgRef} />;
};
