import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatchTyped } from '@core/hooks';
import { IMapboxCityData } from '@core/interfaces/sites/createSite';
import {
  setLat,
  setLng,
  setSelectedLocation,
  useCreateSiteSelectedLocation,
} from '@core/store/slices';
import MapboxSearch from '@modules/Sidebar/views/CreateSite/components/SearchLocation/MapboxSearch';

const SearchLocationStep: React.FC = () => {
  const { t } = useTranslation();

  const { selectedLocation } = useCreateSiteSelectedLocation();
  const dispatch = useDispatchTyped();

  const handleCitySelect = useCallback(
    (data: IMapboxCityData) => {
      const { coordinates } = data;
      const [lng, lat] = coordinates;

      if (lat) dispatch(setLat(lat));
      if (lng) dispatch(setLng(lng));

      dispatch(setSelectedLocation(data));
    },
    [dispatch],
  );

  return (
    <>
      <h1 className='text-base text-outflier-yellow'>
        {t('sidebar.site.create.steps.searchLocation.title')}
      </h1>
      <MapboxSearch onCitySelect={handleCitySelect} selectedLocation={selectedLocation} />
    </>
  );
};

export default SearchLocationStep;
