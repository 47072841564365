import { FC, useEffect, useState } from 'react';
import { EViewerType } from '@core/enums';
import { ISampleDetection } from '@core/interfaces';
import { useImageViewerSelector, useSamplesSelector } from '@core/store/slices';
import { useImageViewerContext } from '@modules/Viewers/views/ImageViewer/contexts/viewer';
import { transformDetections } from '@modules/Viewers/views/ImageViewer/utils/transformDetections';
import { ImageFrame } from '@modules/Viewers/views/ImageViewer/widgets/ImageFrame';
import {
  AnomalyMask,
  RgbMask,
  SegmentationMask,
  ThermalMask,
} from '@modules/Viewers/views/ImageViewer/widgets/ImageMasks';
import commonStyles from '../styles.scss';

export const SidebarView: FC = () => {
  const { selectedSample } = useSamplesSelector();
  const { flags, styles: viewerStyles } = useImageViewerSelector();
  const { loadingState, mainContainer, rgbAspectRatio, onLoadImage } = useImageViewerContext();

  const [transformedDetections, setTransformedDetections] = useState<ISampleDetection[]>([]);

  const { segmentationMask, anomalyMask } = flags;
  const thermalOpacity = viewerStyles.thermal.current.opacity;

  const {
    rgb: isLoadedRGB,
    segmentation: isLoadedSegmentation,
    thermal: isLoadedThermal,
    anomaly: isLoadedAnomaly,
  } = loadingState;

  const isEnabledSegmentationMask = segmentationMask && selectedSample?.segmentation_mask;
  const isEnabledAnomalyMask = anomalyMask && selectedSample?.anomaly_mask;
  const isEnabledThermalMask = Boolean(selectedSample?.thermal_image);
  const isEnabledRgbMask = selectedSample?.rgb_image;

  useEffect(() => {
    setTransformedDetections(
      mainContainer.current
        ? transformDetections({
            sampleDetections: selectedSample?.detections,
            type: EViewerType.InspectionView,
            containerHeight: mainContainer.current.clientHeight,
            containerWidth: mainContainer.current.clientWidth,
            aspectRatio: rgbAspectRatio,
          })
        : [],
    );
  }, [selectedSample?.detections, rgbAspectRatio]);

  return (
    <div className={commonStyles.images}>
      {isEnabledRgbMask && (
        <RgbMask sample={selectedSample} isLoaded={isLoadedRGB} onLoad={onLoadImage} />
      )}
      {isEnabledSegmentationMask && (
        <SegmentationMask
          sample={selectedSample}
          isLoaded={isLoadedSegmentation}
          onLoad={onLoadImage}
        />
      )}
      {isEnabledThermalMask && (
        <ThermalMask
          sample={selectedSample}
          isLoaded={isLoadedThermal}
          onLoad={onLoadImage}
          value={thermalOpacity}
        />
      )}
      {isEnabledAnomalyMask && (
        <AnomalyMask sample={selectedSample} isLoaded={isLoadedAnomaly} onLoad={onLoadImage} />
      )}
      {(!isLoadedRGB || !isLoadedThermal) &&
        transformedDetections.map((detection) => (
          <ImageFrame key={detection.id} detection={detection} type={EViewerType.InspectionView} />
        ))}
    </div>
  );
};
