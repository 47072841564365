import clearWeatherIcon from '@assets/icons/weather/clear.svg';
import cloudyWeatherIcon from '@assets/icons/weather/cloudy.svg';
import fogWeatherIcon from '@assets/icons/weather/fog.svg';
import partlyCloudyWeatherIcon from '@assets/icons/weather/partly-cloudy.svg';
import rainWeatherIcon from '@assets/icons/weather/rainy.svg';
import snowWeatherIcon from '@assets/icons/weather/snow.svg';
import windWeatherIcon from '@assets/icons/weather/wind.svg';

export const WEATHER_API_KEY = 'WBDJFCWUK86YMW2H8L7CDJ7Q9';
export const WEATHER_API_URL =
  'https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services';

export enum EWeatherAPIUnitGroup {
  Metric = 'metric',
  US = 'us',
  UK = 'uk',
  Base = 'base',
}

export enum EWeatherAPILanguage {
  English = 'en',
  French = 'fr',
}

export enum EWeatherAPIIconSet {
  Default = 'icons1',
  Extended = 'icons2',
}

export enum EWeatherAPIInclude {
  Days = 'days',
  Hours = 'hours',
  Alerts = 'alerts',
  Forecast = 'fcst',
}

export enum EWeatherAPIElements {
  DateAndIcon = 'datetime,icon',
}

export enum EWeatherIconName {
  Snow = 'snow',
  Rain = 'rain',
  Fog = 'fog',
  Wind = 'wind',
  Cloudy = 'cloudy',
  PartlyCloudy = 'partly-cloudy-day',
  Clear = 'clear-day',
}

export const weatherIconSVG: Record<EWeatherIconName, string> = {
  [EWeatherIconName.Clear]: clearWeatherIcon,
  [EWeatherIconName.Snow]: snowWeatherIcon,
  [EWeatherIconName.Rain]: rainWeatherIcon,
  [EWeatherIconName.Cloudy]: cloudyWeatherIcon,
  [EWeatherIconName.Fog]: fogWeatherIcon,
  [EWeatherIconName.PartlyCloudy]: partlyCloudyWeatherIcon,
  [EWeatherIconName.Wind]: windWeatherIcon,
};
