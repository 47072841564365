import { ELocationStatus } from '@core/interfaces';
import { styleSiteStatuses } from '@modules/Sidebar/views/Sites/utils/getTable';

export const getStatusStyle = (status?: string, locationStatus?: ELocationStatus) => {
  if (
    locationStatus &&
    [
      ELocationStatus.WaitingForApproval,
      ELocationStatus.UnderCreation,
      ELocationStatus.Approved,
    ].includes(locationStatus)
  ) {
    return styleSiteStatuses[locationStatus];
  }
  return styleSiteStatuses[String(status)];
};
