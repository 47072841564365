/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as d3 from 'd3';
import { formatNumber } from '../formatting/formatNumber';

interface IXAxisOptions {
  marginLeft: number;
  maxNumber: number;
}

export const setYAxis = (svg, y, options: IXAxisOptions) => {
  const { marginLeft, maxNumber } = options;
  const yAxisFormatNumber = (v: number) => formatNumber(v, { maxNumber });

  svg
    .append('g')
    .attr('class', 'y-axis')
    .attr('transform', `translate(${marginLeft}, 0)`)
    .call(
      d3
        .axisLeft(y)
        .ticks(6)
        // @ts-ignore
        .tickFormat(yAxisFormatNumber),
    )
    .style('font-size', '12px')
    .style('font-weight', '500');

  // NOTE: Remove horizontal strokes which are located opposite each of the y-axis value
  svg.selectAll('.y-axis line').style('stroke', 'none');
  // NOTE: Remove vertical y-axis line
  svg.select('.y-axis path').style('display', 'none');
};
