import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EDynamicZoomActions } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';

interface IInitialState {
  reload: boolean;
  dynamicZoom: {
    action: EDynamicZoomActions;
    isAllowedZoom: boolean;
  };
  previousZoom: number;
}

const initialState: IInitialState = {
  reload: false,
  dynamicZoom: {
    action: EDynamicZoomActions.AutoZoom,
    isAllowedZoom: true,
  },
  previousZoom: 0,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    reloadMap: (state, actions: PayloadAction<boolean>) => {
      state.reload = actions.payload;
    },
    setDynamicZoom: (
      state,
      actions: PayloadAction<{ action: EDynamicZoomActions; isAllowedZoom: boolean }>,
    ) => {
      state.dynamicZoom = actions.payload;
    },
    setPreviousZoom: (state, actions: PayloadAction<number>) => {
      state.previousZoom = actions.payload;
    },
  },
});

const mapReducer = mapSlice.reducer;

const { reloadMap, setPreviousZoom, setDynamicZoom } = mapSlice.actions;

const useMapSelector = () => useSelectorTyped((state) => state.map);

export {
  reloadMap,
  setPreviousZoom,
  setDynamicZoom,
  mapReducer,
  useMapSelector,
  initialState as mapInitialState,
};
