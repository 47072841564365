import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import cn from 'classnames';
import { EAnomalySubtype } from '@core/enums/anomalies';
import { SUBTYPE_LABEL } from '@modules/Sidebar/widgets/AnomalyDetails/config';
import { SubtypeOption } from '@modules/Sidebar/widgets/AnomalyDetails/interfaces';
import { Popover } from '@components/Popover';
import styles from './styles.scss';

interface IProps {
  selectedValue: EAnomalySubtype | null;
  className?: string;
  style?: React.CSSProperties;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (option: SubtypeOption) => void;
}

export const SubtypePopover: FC<IProps> = memo(
  ({ isOpen, className, style = {}, selectedValue, onClose, onUpdate }) => {
    const { t } = useTranslation();

    const handleRadioGroupChange = useCallback(
      (value: EAnomalySubtype) => onUpdate({ value, label: SUBTYPE_LABEL[value] }),
      [onUpdate],
    );

    const subtypeOptions = useMemo(
      () =>
        Object.values(EAnomalySubtype).map((value) => ({
          value,
          label: SUBTYPE_LABEL[value],
        })),
      [t],
    );

    const popoverTitle = t('sidebar.anomaly.widgets.details.tiles.subtype.modal.title');

    return (
      <Popover
        title={popoverTitle}
        className={cn(styles.popover, className)}
        style={style}
        isOpen={isOpen}
        onClose={onClose}
      >
        <RadioGroup
          className={styles.list}
          value={selectedValue}
          onChange={handleRadioGroupChange}
          as='ul'
        >
          {subtypeOptions.map(({ value, label }) => (
            <RadioGroup.Option key={value} value={value} className={styles.listItem} as='li'>
              {({ checked }) => (
                <>
                  <RadioGroup.Description>{label}</RadioGroup.Description>
                  <div
                    className={cn(styles.checkboxButton, {
                      [styles.isCheckedOption]: checked,
                    })}
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </Popover>
    );
  },
);
