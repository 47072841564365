export enum ECreateSiteSteps {
  SearchCity = 0,
  Name = 1,
  Perimeter = 2,
  NoFlyZones = 3,
  Obstacles = 4,
  Picture = 5,
  InspectionFrequency = 6,
  Roles = 7,
  Zones = 8,
}

export enum ENoFlyZoneType {
  NoFlyZone = 'no_fly_zone',
  Obstacle = 'obstacle',
}

export enum ECreatePerimeterType {
  Perimeter = 'perimeter',
}

export enum EProgressCalculationMode {
  Actual = 'actual',
  Fake = 'fake',
}

export const DEFAULT_GENERATED_PROGRAM_NAME_REGEX =
  /^LocalH\d+P\d+\/\d{4}\d{2}\d{2}-\d{2}\d{2}\d{2}$/;
export const DEFAULT_GENERATED_PROGRAM_NAME_WITH_INDEX_REGEX = /^Zone \d+$/;
export const DEFAULT_SITE_NAME = 'New site';
export const INITIAL_CREATE_SITE_COORDINATES = { lat: 43.54656, lng: 5.18031 };
export const FAKE_PERCENTAGE_STEP = 10;
export const FAKE_STEPS_LIMIT = 100 / FAKE_PERCENTAGE_STEP;
