import { FC } from 'react';
import cn from 'classnames';
import styles from './styles.scss';
import commonStyles from '../styles.scss';

interface IProps {
  className?: string;
  value: string;
}

export const AverageTemperatureTile: FC<IProps> = ({ className, value }) => {
  return (
    <div className={cn(commonStyles.valueWrapper, className)}>
      <span className={cn(commonStyles.value, styles.averageTemperatureValue)}>{value}</span>
    </div>
  );
};
