import { ALLOWED_FILE_EXTENSIONS } from '@modules/Sidebar/components/AccordionView/BodyOptions/Picture/constants/file';

export const validateFile = (fileName: string) => {
  let message: string | null = null;
  const isAllowedExt = ALLOWED_FILE_EXTENSIONS.some((fileExt) => fileName?.endsWith(fileExt));

  if (!isAllowedExt) {
    message = 'Incorrect file extension';
  }

  return message;
};
