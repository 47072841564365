import { FC, memo, useMemo } from 'react';
import cn from 'classnames';
import { t } from 'i18next';
import { anomaliesChartsColor } from '@core/constants';
import { EAnomalyMode, EAnomalyStatisticCategory } from '@core/enums/anomalies';
import { useCurrentReportSelector } from '@core/store/slices';
import { getLowerCasedValueSplitBySeparator } from '@core/utils';
import { getAggregatedDataByGridColumns } from '@modules/Sidebar/utils';
import { IStyleDataResponse, getStylesData } from '@modules/Sidebar/views/Zone/utils';
import { DonutChart } from '@components/Chart';
import { Tile } from '@components/Tile/Tile';
import styles from './styles.scss';

const getters = {
  getDonutChartLabel(chartKey: string) {
    return t(`sidebar.widgets.charts.${chartKey}.title`);
  },
  getTileLabel(chartKey: string, anomalyKey: string) {
    return t(
      `sidebar.widgets.charts.${chartKey}.tiles.${getLowerCasedValueSplitBySeparator(anomalyKey)}`,
    );
  },
};

export interface IAnomalyStatisticsProps {
  className?: string;
  anomalyAggregatedKey: string;
  anomalyChartKey: EAnomalyStatisticCategory;
  tilesColumns: number;
}

export const AnomalyStatistics: FC<IAnomalyStatisticsProps> = memo(
  ({ className, anomalyAggregatedKey, anomalyChartKey, tilesColumns }) => {
    const currentReport = useCurrentReportSelector();

    const filteredAnomalies = useMemo(
      () => currentReport?.anomalies?.filter((anomaly) => anomaly.mode !== EAnomalyMode.Manual),
      [currentReport?.anomalies],
    );
    const styleConfigData = useMemo(
      () =>
        getStylesData({
          anomalies: filteredAnomalies ?? [],
          anomalyAggregatedKey,
          anomalyChartKey,
          defaultValue: anomaliesChartsColor[anomalyChartKey],
        }),
      [filteredAnomalies, anomalyAggregatedKey, anomalyChartKey],
    );

    const aggregatedStylesDataByGridColumns = useMemo(
      () => getAggregatedDataByGridColumns<IStyleDataResponse>(styleConfigData, tilesColumns),
      [styleConfigData, tilesColumns],
    );

    return (
      <div className={cn(styles.root, className)}>
        <div className={styles.chart}>
          <DonutChart data={styleConfigData} label={getters.getDonutChartLabel(anomalyChartKey)} />
        </div>
        <div className={styles.tilesList}>
          {aggregatedStylesDataByGridColumns.map((columns, idx) => (
            <ul key={idx} className={styles.tilesRow}>
              {columns.map(({ key, value }) => (
                <Tile
                  key={key}
                  as='li'
                  label={getters.getTileLabel(anomalyChartKey, key)}
                  value={value}
                />
              ))}
            </ul>
          ))}
        </div>
      </div>
    );
  },
);
