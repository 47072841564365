import { createElement } from 'react';
import cn from 'classnames';
import { getLowerCasedValueSplitBySeparator } from '@core/utils';
import styles from './styles.scss';

type DivAttributes = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
type LiAttributes = React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement>;
type AllowedComponent = 'div' | 'li';

type IProps<T extends AllowedComponent> = {
  as?: T;
  label: string;
  value: number;
  className?: string;
} & (T extends 'div' ? DivAttributes : LiAttributes);

export const Tile = <T extends AllowedComponent>({
  as: Element,
  label,
  value,
  className,
}: IProps<T>) => {
  const ElementTag = Element as keyof JSX.IntrinsicElements;

  return createElement(
    ElementTag,
    { className: cn(styles.root, className) },
    <>
      <span className={styles.label}>{label}</span>
      <span
        className={cn(styles.value, styles[`color-${getLowerCasedValueSplitBySeparator(label)}`])}
      >
        {value}
      </span>
    </>,
  );
};
