import _ from 'lodash';
import { EAnomalyMode } from '@core/enums/anomalies';
import { IStackedBarChartStyles } from '@modules/Sidebar/interfaces';
import { EEstimatedLossesGroup } from '../enums';

const documentStyle = getComputedStyle(document.documentElement);

export const stackedBarChartStylesData: IStackedBarChartStyles = {
  chart: {
    width: 380,
    height: 420,
    margin: {
      top: 20,
      right: 30,
      bottom: 45,
      left: 35,
    },
    grid: {
      stroke: documentStyle.getPropertyValue('--outflier-dark-graylish-blue'),
    },
  },
  bar: {
    width: 160,
    gap: 5,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 10,
    },
  },
};

// NOTE: for fast test without request data
export const mockData = [
  {
    metadata: {
      bar: {
        title: {
          value: -27417,
          costRatio: '€/y',
          color: '#E43535',
        },
      },
    },
    data: {
      group: {
        key: EEstimatedLossesGroup.AtInspected,
        title: 'At inspection',
        value: 'At inspection',
      },
      subgroups: [
        {
          key: EAnomalyMode.Thermal,
          position: 1,
          title: 'Thermal',
          color: '#FF6955',
          value: _.round(16313.76, 1),
        },
        {
          key: EAnomalyMode.String,
          position: 2,
          title: 'Strings',
          color: '#FFF06B',
          value: 0,
        },
        {
          key: EAnomalyMode.Visual,
          position: 3,
          title: 'Visual',
          color: '#7CB6EC',
          value: _.round(11103.4589, 1),
        },
      ],
    },
  },
  {
    metadata: {
      bar: {
        title: {
          value: -25617,
          costRatio: '€/y',
          color: '#FFBC2D',
        },
      },
    },
    data: {
      group: {
        key: EEstimatedLossesGroup.AfterRepair,
        title: 'After repairs',
        value: 'After 45/87 repairs',
      },
      subgroups: [
        {
          key: EAnomalyMode.Thermal,
          position: 1,
          title: 'Thermal',
          color: '#FF6955',
          value: _.round(15209.76, 1),
        },
        {
          key: EAnomalyMode.String,
          position: 2,
          title: 'Strings',
          color: '#FFF06B',
          value: 0,
        },
        {
          key: EAnomalyMode.Visual,
          position: 3,
          title: 'Visual',
          color: '#7CB6EC',
          value: _.round(10686.4589, 1),
        },
      ],
    },
  },
];
