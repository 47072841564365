import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { flowRight, round, sum } from 'lodash';
import { EAnomalyRepairmentStatus } from '@core/enums/anomalies';
import { IAnomaly } from '@core/interfaces/anomaly';
import {
  useCurrentAnomalySelector,
  useCurrentReportSelector,
  useCurrentSiteSelector,
} from '@core/store/slices';
import { toNegative } from '@core/utils/math';
import { useEstimatedLossesContext } from '@modules/Sidebar/widgets/EstimatedLosses/contexts';
import {
  getAnomaliesCosts,
  getSeparatedAnomaliesByStatus,
  transformSites,
} from '@modules/Sidebar/widgets/EstimatedLosses/utils';
import styles from '../../styles.scss';

export const AnomalyView: FC = () => {
  const { t } = useTranslation();
  const contextValue = useEstimatedLossesContext();
  const currentSite = useCurrentSiteSelector();
  const currentAnomaly = useCurrentAnomalySelector();
  const currentReport = useCurrentReportSelector();

  const transformedSitesSidebar = useMemo(
    () => transformSites(currentSite ? [currentSite] : []),
    [currentSite],
  );

  const expandedAnomaly = useMemo(
    () =>
      currentReport?.id && currentSite?.loc_id
        ? ({
            ...currentAnomaly,
            report_id: currentReport.id,
            location_id: currentSite.loc_id,
          } as IAnomaly)
        : currentAnomaly,
    [currentAnomaly, currentReport?.location_id, currentSite?.loc_id],
  );

  const separatedAnomaliesByRepairmentStatus = useMemo(
    () => getSeparatedAnomaliesByStatus(expandedAnomaly ? [expandedAnomaly] : []),
    [expandedAnomaly],
  );

  const anomalyCosts = useMemo(
    () => getAnomaliesCosts(separatedAnomaliesByRepairmentStatus, transformedSitesSidebar),
    [separatedAnomaliesByRepairmentStatus, transformedSitesSidebar],
  );

  const totalCostToRepairByReportsAnomalies = useMemo(
    () => flowRight(round, sum)(Object.values(anomalyCosts[EAnomalyRepairmentStatus.ToRepair])),
    [anomalyCosts[EAnomalyRepairmentStatus.ToRepair]],
  );

  return (
    <div className={styles.tilesList}>
      <section className={styles.tile}>
        <h3 className={styles.tileTitle}>
          {t('sidebar.widgets.estimatedLosses.tiles.atInspection.title')}
        </h3>
        <div className={cn(styles.counter, styles.atInspection)}>
          <span className={styles.counterValue}>
            {toNegative(totalCostToRepairByReportsAnomalies)}
          </span>
          <div className={styles.moneyPerYear}>{contextValue.formattedMoneyPerYear}</div>
        </div>
      </section>
    </div>
  );
};
