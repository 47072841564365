import { FC, memo } from 'react';
import cn from 'classnames';
import { ISample } from '@core/interfaces';
import styles from './styles.scss';
import { ELoadingState } from '../../enums/loading';

interface IProps {
  alt?: string;
  className?: string;
  sample: ISample;
  isLoaded: boolean;
  onLoad: (type: ELoadingState) => void;
}

export const SegmentationMask: FC<IProps> = memo(
  ({ sample, isLoaded, className, onLoad, alt = 'segmentation image' }) => {
    const handleLoadImage = () => onLoad(ELoadingState.Segmentation);

    return (
      <img
        src={sample.segmentation_mask}
        className={cn(
          styles.segmentationMask,
          { [styles.segmentationMaskLoaded]: !isLoaded },
          className,
        )}
        alt={alt}
        onLoad={handleLoadImage}
      />
    );
  },
);
