import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { t } from 'i18next';
import { api } from '@core/api';
import { ISolarPanelsWithIncidentAnomalies } from '@core/api/report/solarPanels';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { useCachedValue } from '@core/hooks';
import { useCurrentReportSelector } from '@core/store/slices';
import { toPixels } from '@core/utils';
import { HEADER_HEIGHT } from '@modules/Layout/constants';
import { getPopoverYPosition } from '@modules/Sidebar/utils/charts';
import { Popover } from '@components/Popover';
import { DirtinessIndex } from './components/DirtinessIndex';
import { PanelsGrid } from './components/PanelsGrid';
import { PanelsStatistics } from './components/PanelsStatistics';
import { SolarPanelsChart } from './components/StackedChart';
import { DEFECTIVE_PANELS_POPOVER_HEIGHT, PANELS_GRID_STYLES_CONFIG } from './constants';
import styles from './styles.scss';

interface IProps {
  className?: string;
  scrollTop?: number;
}

export const SolarPanelsStatistic: FC<IProps> = ({ className, scrollTop }) => {
  const currentReport = useCurrentReportSelector();
  const [solarPanels, setSolarPanels] = useState<ISolarPanelsWithIncidentAnomalies[]>([]);
  const [isOpenedPopover, setIsOpenedPopover] = useState(false);
  const [popoverStyles, setPopoverStyles] = useState<React.CSSProperties>({
    height: DEFECTIVE_PANELS_POPOVER_HEIGHT,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);
  const anomalies = useCachedValue(currentReport?.anomalies ? currentReport.anomalies : []);

  const getSolarPanelsWithIncidentAnomalies = useCallback(async (reportId: string | number) => {
    try {
      const response = await api.reports.getSolarPanelsWithIncidentAnomalies(reportId);
      setSolarPanels(response);
    } catch (err) {
      toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
    }
  }, []);

  useEffect(() => {
    if (currentReport?.id) {
      getSolarPanelsWithIncidentAnomalies(currentReport.id);
    }
  }, [currentReport?.id]);

  const updatePopoverTopPosition = useCallback(() => {
    if (containerRef.current) {
      const { height, top } = containerRef.current.getBoundingClientRect();

      const popoverTopPosition = getPopoverYPosition({
        parentHeight: height,
        parentTop: top,
        popoverHeight: DEFECTIVE_PANELS_POPOVER_HEIGHT,
        correctionValue: HEADER_HEIGHT,
      });

      setPopoverStyles((styles) => ({
        ...styles,
        top: toPixels(popoverTopPosition),
      }));
    }
  }, []);

  const handleShowPopover = useCallback(() => setIsOpenedPopover(true), []);
  const handleHidePopover = useCallback(() => setIsOpenedPopover(false), []);

  useEffect(() => {
    if (isOpenedPopover) {
      updatePopoverTopPosition();
    }
  }, [scrollTop, isOpenedPopover]);

  return (
    <div className={cn(styles.root, className)}>
      <Popover
        title={t('sidebar.widgets.panels.popover.title')}
        className={styles.popover}
        style={popoverStyles}
        isOpen={isOpenedPopover}
        onClose={handleHidePopover}
      >
        <SolarPanelsChart solarPanels={solarPanels} />
      </Popover>
      <h2 className={styles.title}>
        {t('sidebar.widgets.panels.title', { count: solarPanels.length })}
      </h2>
      <div className={styles.container}>
        <div className={styles.panelsTile} onClick={handleShowPopover} ref={containerRef}>
          <PanelsGrid styles={PANELS_GRID_STYLES_CONFIG} solarPanels={solarPanels} />
          <PanelsStatistics solarPanels={solarPanels} />
        </div>
        <DirtinessIndex solarPanels={solarPanels} anomalies={anomalies} />
      </div>
    </div>
  );
};
