export const getFrameLimiter = (scale: number) => {
  const incrementInTheScaledImageByOneUnitInPercents = 100 / 2;
  return (scale - 1) * incrementInTheScaledImageByOneUnitInPercents;
};

interface IPositionData {
  position: {
    left: number;
    top: number;
  };
  limiter: {
    top: boolean;
    left: boolean;
    bottom: boolean;
    right: boolean;
  };
}

export const getPositionData = (
  frameLimiter: number,
  position: IPositionData['position'],
): IPositionData => {
  const state = {
    position: {
      top: position.top,
      left: position.left,
    },
    limiter: {
      top: false,
      left: false,
      right: false,
      bottom: false,
    },
  };

  if (Math.abs(state.position.left) > frameLimiter) {
    const sign = Math.sign(state.position.left);

    switch (sign) {
      case -1:
        state.limiter.right = true;
        break;
      case 1: {
        state.limiter.left = true;
        break;
      }
    }

    state.position.left = state.position.left < 0 ? -frameLimiter : frameLimiter;
  }

  if (Math.abs(state.position.top) > frameLimiter) {
    const sign = Math.sign(state.position.top);

    switch (sign) {
      case -1:
        state.limiter.bottom = true;
        break;
      case 1: {
        state.limiter.top = true;
        break;
      }
    }

    state.position.top = state.position.top < 0 ? -frameLimiter : frameLimiter;
  }

  return state;
};
