import { ReactNode, FC, Fragment, memo } from 'react';
import { ReactSVG } from 'react-svg';
import { Popover as HeadlessUIPopover, Transition } from '@headlessui/react';
import cn from 'classnames';
import crossIcon from '@assets/icons/cross.svg';
import { OutsideClicker } from '@components/OutsideClicker';
import styles from './styles.scss';

const mainTransitionProps = {
  enter: 'transition duration-100 ease-out',
  enterFrom: 'transform scale-95 opacity-0',
  enterTo: 'transform scale-100 opacity-100',
  leave: 'transition duration-75 ease-out',
  leaveFrom: 'transform scale-100 opacity-100',
  leaveTo: 'transform scale-95 opacity-0',
};

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  title: string;
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
}

export const Popover: FC<IProps> = memo(
  ({ className, title, style, children, isOpen, onClose }) => {
    return (
      <OutsideClicker onClick={onClose}>
        <Transition show={isOpen} as={Fragment} {...mainTransitionProps}>
          <HeadlessUIPopover style={style ?? {}} className={cn(styles.popover, className)}>
            <div className={styles.root}>
              <header className={styles.header}>
                <h2 className={styles.title}>{title}</h2>
                <HeadlessUIPopover.Button className={styles.closeBtn} onClick={onClose}>
                  <ReactSVG className={styles.closeIcon} src={crossIcon} />
                </HeadlessUIPopover.Button>
              </header>
              <section className={styles.content}>{children}</section>
            </div>
          </HeadlessUIPopover>
        </Transition>
      </OutsideClicker>
    );
  },
);
