import { create } from '@core/api/locations/create';
import { getById } from '@core/api/locations/getById';
import { getNoFlyZonesByLocId } from '@core/api/locations/getNoFlyZonesByLocId';
import { getProgramsByLocId } from '@core/api/locations/getProgramsByLocId';
import { getJobStatus } from '@core/api/locations/jobStatus';
import { createNoFlyZonesByLocationId } from '@core/api/locations/noFlyZones';
import { postPerimeter } from '@core/api/locations/perimeter';
import { postSetup } from '@core/api/locations/setup';
import { deleteById } from './deleteById';
import { getAll } from './getAll';
import { getInspectionsByLocationId } from './inspections';
import { getPerimeterByLocationId } from './perimeterByLocationId';
import { getProgramsByLocationId, getProgramsStatusesByLocationId } from './programs';
import { getLocationTypes } from './types';
import { updateById } from './updateById';

export const location = {
  create,
  getById,
  getProgramsByLocationId,
  getProgramsStatusesByLocationId,
  getAll,
  getPerimeterByLocationId,
  getNoFlyZonesByLocId,
  getInspectionsByLocationId,
  getProgramsByLocId,
  postSetup,
  createNoFlyZonesByLocationId,
  postPerimeter,
  getJobStatus,
  updateById,
  deleteById,
  getLocationTypes,
};
