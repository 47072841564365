import React from 'react';
import cn from 'classnames';
import styles from './styles.scss';

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

export const TableHeadCell: React.FC<IProps> = ({ className, children }) => {
  return <th className={cn(styles.tableHeadCell, className)}>{children}</th>;
};
