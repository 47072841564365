import { axiosInstance } from '@core/api/axios';
import { EAnomalyMode, EAnomalySubtype } from '@core/enums/anomalies';

interface IIncidentAnomaly {
  id: number;
  mode: EAnomalyMode;
  submode?: EAnomalySubtype; // actual for "thermal" mode
}

export interface ISolarPanelsWithIncidentAnomalies {
  id: number;
  incident_anomalies: IIncidentAnomaly[];
}

export const getSolarPanelsWithIncidentAnomalies = async (id: number | string) => {
  return axiosInstance
    .get<ISolarPanelsWithIncidentAnomalies[]>(`/reports/${id}/solar_panels/status`)
    .then((res) => res.data);
};
