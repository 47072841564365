import { isArray } from '@core/utils';

// NOTE: change data structure from a plain array to a matrix
export function getAggregatedDataByGridColumns<T>(data: T[], columns: number) {
  const separatedDataByRows: T[][] = [];
  let rowIdx = 0;

  for (let i = 1; i <= data.length; i += 1) {
    const item = data[i - 1];

    if (!isArray(separatedDataByRows[rowIdx])) {
      separatedDataByRows.push([]);
    }

    if (separatedDataByRows[rowIdx].length < columns) {
      separatedDataByRows[rowIdx].push(item);
    }

    if (i % columns === 0) {
      rowIdx += 1;
    }
  }

  return separatedDataByRows;
}
