import _ from 'lodash';

interface IParams {
  chartHeight: number;
  groupHeight: number;
  marginBottom: number;
  correctionValue: number;
}

export const getGroupYTextPosition = ({
  chartHeight,
  marginBottom,
  groupHeight,
  correctionValue,
}: IParams) => {
  return _.round(chartHeight - (groupHeight + marginBottom + correctionValue), 1);
};
