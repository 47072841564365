import { EAnomalyAggregatedKey, EAnomalyStatisticCategory } from '@core/enums/anomalies';
import { IAnomalyStatistics } from '@modules/Sidebar/widgets/AnomalyStatistic/interfaces';

export const ANOMALY_STATISTICS_PARAMS: {
  [key in EAnomalyStatisticCategory]: IAnomalyStatistics;
} = {
  [EAnomalyStatisticCategory.Severity]: {
    anomalyAggregatedKey: EAnomalyAggregatedKey.Status,
    anomalyChartKey: EAnomalyStatisticCategory.Severity,
    tilesColumns: 2,
  },
  [EAnomalyStatisticCategory.Type]: {
    anomalyAggregatedKey: EAnomalyAggregatedKey.Mode,
    anomalyChartKey: EAnomalyStatisticCategory.Type,
    tilesColumns: 2,
  },
  [EAnomalyStatisticCategory.Repair]: {
    anomalyAggregatedKey: EAnomalyAggregatedKey.RepairmentStatus,
    anomalyChartKey: EAnomalyStatisticCategory.Repair,
    tilesColumns: 2,
  },
};
