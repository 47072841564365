export enum EAnomalyStatisticCategory {
  Severity = 'severity',
  Type = 'type',
  Repair = 'repair',
}

export enum EAnomalyAggregatedKey {
  Status = 'status',
  Mode = 'mode',
  RepairmentStatus = 'repairment_status',
}
