import {
  EAccessControlLocalStorageField,
  ELocalStorageField,
  OUTFLIER_ACCESS_TOKEN,
  OUTFLIER_REFRESH_TOKEN,
  OUTFLIER_TOKEN_TYPE,
} from '@core/constants';
import { DataProcessing, browserLocalStorage } from '@core/services';

export const authStorage = {
  tenant: {
    get(): string | null {
      return (
        DataProcessing.deserialize(browserLocalStorage.getItem(ELocalStorageField.AccessControl))?.[
          EAccessControlLocalStorageField.SelectedTenant
        ] ?? null
      );
    },
    set(tenant: string) {
      const accessControlStorageState =
        DataProcessing.deserialize(browserLocalStorage.getItem(ELocalStorageField.AccessControl)) ??
        {};

      const updatedAccessControlStorageState = {
        ...accessControlStorageState,
        [EAccessControlLocalStorageField.SelectedTenant]: tenant,
      };

      browserLocalStorage.setItem(
        ELocalStorageField.AccessControl,
        DataProcessing.serialize(updatedAccessControlStorageState),
      );
    },
    remove() {
      const accessControlStorageState =
        DataProcessing.deserialize(browserLocalStorage.getItem(ELocalStorageField.AccessControl)) ??
        {};

      if (accessControlStorageState?.[EAccessControlLocalStorageField.SelectedTenant]) {
        delete accessControlStorageState[EAccessControlLocalStorageField.SelectedTenant];
        browserLocalStorage.setItem(
          ELocalStorageField.AccessControl,
          DataProcessing.serialize(accessControlStorageState),
        );
      }
    },
  },
  tokens: {
    access_token: {
      get(): string | null {
        return browserLocalStorage.getItem(OUTFLIER_ACCESS_TOKEN) ?? null;
      },
      set(token: string) {
        return browserLocalStorage.setItem(OUTFLIER_ACCESS_TOKEN, token);
      },
      remove() {
        return browserLocalStorage.removeItem(OUTFLIER_ACCESS_TOKEN);
      },
    },
    refresh_token: {
      get(): string | null {
        return browserLocalStorage.getItem(OUTFLIER_REFRESH_TOKEN) ?? null;
      },
      set(token: string) {
        return browserLocalStorage.setItem(OUTFLIER_REFRESH_TOKEN, token);
      },
      remove() {
        return browserLocalStorage.removeItem(OUTFLIER_REFRESH_TOKEN);
      },
    },
    token_type: {
      get(): string | null {
        return browserLocalStorage.getItem(OUTFLIER_TOKEN_TYPE) ?? null;
      },
      set(type: string) {
        return browserLocalStorage.setItem(OUTFLIER_TOKEN_TYPE, type);
      },
      remove() {
        return browserLocalStorage.removeItem(OUTFLIER_TOKEN_TYPE);
      },
    },
  },
};
