import { useEffect } from 'react';
import { useDispatchTyped } from '@core/hooks';
import { IAnomaly } from '@core/interfaces/anomaly';
import { updateAnomaly } from '@core/store/slices';
import { isString } from '@core/utils';

interface IUpdateAnomalyDeps<T = number> {
  isAllowed: boolean;
  key: string;
  editableValue: T;
  anomaly?: IAnomaly;
}

export const useUpdateAnomaly = <T>({
  isAllowed,
  key,
  editableValue,
  anomaly,
}: IUpdateAnomalyDeps<T>) => {
  const dispatch = useDispatchTyped();

  useEffect(() => {
    if (anomaly) {
      const canUpdateAnomaly = Boolean(
        isAllowed && key in anomaly && editableValue !== anomaly[key],
      );

      if (canUpdateAnomaly) {
        dispatch(
          updateAnomaly({
            anomalyId: anomaly.id,
            reportId: isString(anomaly.report_id)
              ? Number(anomaly.report_id)
              : (anomaly.report_id as number),
            payload: { [key]: editableValue },
          }),
        );
      }
    }
  }, [isAllowed, editableValue, anomaly?.[key]]);
};
