import { FC, useEffect, useMemo, useRef } from 'react';
import { ISolarPanelsWithIncidentAnomalies } from '@core/api/report/solarPanels';
import { isNumber } from '@core/utils';
import { IStyleConfig } from '@modules/Sidebar/widgets/SolarPanels/interfaces';
import { getDefectivePercent } from '@modules/Sidebar/widgets/SolarPanels/utils';

interface IProps {
  styles: IStyleConfig;
  solarPanels: ISolarPanelsWithIncidentAnomalies[];
  rows?: number;
  columns?: number;
}

export const PanelsGrid: FC<IProps> = ({
  styles,
  solarPanels,
  rows = 10,
  columns = 10,
}: IProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { canvas, rectCell, gap, colors } = styles;

  const defectivePercent = useMemo(() => getDefectivePercent(solarPanels), [solarPanels]);

  const renderCanvas = (defectivePercent: number) => {
    const canvas = canvasRef.current;

    if (canvas) {
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        const rectCellHeight = rectCell.height;
        const rectCellWidth = rectCell.width;
        const middle = Math.floor(columns / 2);
        const start = 0;
        let x = 0;
        let y = 0;
        let xGap = gap.initial;
        let yGap = gap.initial;

        for (let row = 0; row < rows; row += 1) {
          switch (row) {
            case start:
              yGap = gap.initial;
              y = 0;
              break;
            case middle:
              yGap = gap.middle;
              y += rectCellHeight + yGap;
              break;
            default:
              yGap = gap.main;
              y += rectCellHeight + yGap;
              break;
          }

          for (let column = 0; column < columns; column += 1) {
            switch (column) {
              case start:
                xGap = gap.initial;
                x = 0;
                break;
              case middle:
                xGap = gap.middle;
                x += rectCellWidth + xGap;
                break;
              default:
                xGap = gap.main;
                x += rectCellWidth + xGap;
                break;
            }

            const cellNumber = column + row * rows;

            // NOTE: set color based on "defectivePercent" value
            ctx.fillStyle =
              cellNumber < defectivePercent ? colors.defectivePanels : colors.activePanels;

            ctx.fillRect(x, y, rectCellWidth, rectCellHeight);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isNumber(defectivePercent)) {
      renderCanvas(defectivePercent);
    }
  }, [defectivePercent]);

  return <canvas ref={canvasRef} width={canvas.width} height={canvas.height} />;
};
