import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '@core/contexts/auth';
import { ROUTE_PATHS } from '../routerConfig';

const redirectCheckers = {
  toLogin(isAuth: boolean) {
    return !isAuth;
  },
  toSelectedOrganization(isAuth: boolean, tenant: string | null) {
    return isAuth && !tenant;
  },
};

const RequireAuth = () => {
  const { isAuthenticated, tenantRef } = useAuth();
  const isAllowed = isAuthenticated && tenantRef.current;

  const redirectedPath = useMemo(() => {
    if (redirectCheckers.toLogin(isAuthenticated)) {
      return ROUTE_PATHS.Login.Root;
    }
    if (redirectCheckers.toSelectedOrganization(isAuthenticated, tenantRef.current)) {
      return ROUTE_PATHS.Login.SelectOrganization;
    }
    return ROUTE_PATHS.Login.Root;
  }, [isAuthenticated, tenantRef.current]);

  return isAllowed ? <Outlet /> : <Navigate to={redirectedPath} />;
};

export default RequireAuth;
