import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DEFAULT_NUMERIC_VALUE, MISSING_VALUE } from '@core/constants';
import { EControlStepCounterAction } from '@core/enums';
import { IAnomaly } from '@core/interfaces/anomaly';
import { useCurrentAnomalySelector } from '@core/store/slices';
import { toPixels } from '@core/utils';
import { HEADER_HEIGHT } from '@modules/Layout/constants';
import { getPopoverYPosition } from '@modules/Sidebar/utils/charts';
import { StepperPopover } from '@modules/Sidebar/widgets/AnomalyDetails/components/Popovers';
import {
  AverageTemperatureTile,
  DetailsTile,
  SubtypeTile,
  SurfaceAffectedTile,
  TemperatureDifferenceTile,
} from '@modules/Sidebar/widgets/AnomalyDetails/components/Tiles';
import { MIXED_INITIAL_POPOVERS_STYLES } from '@modules/Sidebar/widgets/AnomalyDetails/config';
import {
  SURFACE_AFFECTED_POPOVER_HEIGHT,
  TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
} from '@modules/Sidebar/widgets/AnomalyDetails/constants';
import { useAnomalyDetailsContext } from '@modules/Sidebar/widgets/AnomalyDetails/contexts';
import {
  EAnomalyDetailsPopovers,
  EAnomalyDetailsUpdateFields,
} from '@modules/Sidebar/widgets/AnomalyDetails/enums';
import { useUpdateAnomaly } from '@modules/Sidebar/widgets/AnomalyDetails/hooks';
import { IUpdatePopoverTopPositionParams } from '@modules/Sidebar/widgets/AnomalyDetails/interfaces';
import {
  formatAverageTemperature,
  formatSurfaceAffected,
  formatTemperatureDifference,
  getRoundedSurfaceAffected,
  getRoundedTemperature,
} from '@modules/Sidebar/widgets/AnomalyDetails/utils';
import styles from '../../styles.scss';

export const MixedView = () => {
  const { t } = useTranslation();
  const detailsContext = useAnomalyDetailsContext();
  const currentAnomaly = useCurrentAnomalySelector();

  const [isOpenedTemperatureDifferenceModal, setIsOpenedTemperatureDifferenceModal] =
    useState(false);
  const [isOpenedThermalSurfaceAffectedModal, setIsOpenedThermalSurfaceAffectedModal] =
    useState(false);
  const [isOpenedVisualSurfaceAffectedModal, setIsOpenedVisualSurfaceAffectedModal] =
    useState(false);

  const [temperatureDifferenceValue, setTemperatureDifferenceValue] = useState(
    currentAnomaly?.temperature_differential ?? DEFAULT_NUMERIC_VALUE,
  );
  const [thermalSurfaceAffectedValue, setThermalSurfaceAffectedValue] = useState(
    currentAnomaly?.thermal_surface ?? DEFAULT_NUMERIC_VALUE,
  );
  const [visualSurfaceAffectedValue, setVisualSurfaceAffectedValue] = useState(
    currentAnomaly?.visual_surface ?? DEFAULT_NUMERIC_VALUE,
  );

  const [popoverStyles, setPopoverStyles] = useState(MIXED_INITIAL_POPOVERS_STYLES);

  const temperatureDiffRef = useRef<HTMLDivElement | null>(null);
  const thermalSurfaceAffectedRef = useRef<HTMLDivElement | null>(null);
  const visualSurfaceAffectedRef = useRef<HTMLDivElement | null>(null);

  useUpdateAnomaly({
    isAllowed: !isOpenedThermalSurfaceAffectedModal,
    anomaly: currentAnomaly,
    key: EAnomalyDetailsUpdateFields.ThermalSurface,
    editableValue: thermalSurfaceAffectedValue,
  });
  useUpdateAnomaly({
    isAllowed: !isOpenedVisualSurfaceAffectedModal,
    anomaly: currentAnomaly,
    key: EAnomalyDetailsUpdateFields.VisualSurface,
    editableValue: visualSurfaceAffectedValue,
  });
  useUpdateAnomaly({
    isAllowed: !isOpenedTemperatureDifferenceModal,
    anomaly: currentAnomaly,
    key: EAnomalyDetailsUpdateFields.TemperatureDifferential,
    editableValue: temperatureDifferenceValue,
  });

  const resetMixedViewState = useCallback((anomaly: IAnomaly) => {
    setIsOpenedTemperatureDifferenceModal(false);
    setIsOpenedThermalSurfaceAffectedModal(false);
    setIsOpenedVisualSurfaceAffectedModal(false);

    setPopoverStyles(MIXED_INITIAL_POPOVERS_STYLES);

    setTemperatureDifferenceValue(anomaly.temperature_differential ?? DEFAULT_NUMERIC_VALUE);
    setThermalSurfaceAffectedValue(anomaly.thermal_surface ?? DEFAULT_NUMERIC_VALUE);
    setVisualSurfaceAffectedValue(anomaly.visual_surface ?? DEFAULT_NUMERIC_VALUE);
  }, []);

  const handleUpdateThermalSurfaceAffectedValue = useCallback(
    (counterAction: EControlStepCounterAction) => {
      setThermalSurfaceAffectedValue((value) => {
        const percent = 1 / 100;
        return counterAction === EControlStepCounterAction.Decrease
          ? value - percent
          : value + percent;
      });
    },
    [],
  );

  const handleUpdateVisualSurfaceAffectedValue = useCallback(
    (counterAction: EControlStepCounterAction) => {
      setVisualSurfaceAffectedValue((value) => {
        const percent = 1 / 100;
        return counterAction === EControlStepCounterAction.Decrease
          ? value - percent
          : value + percent;
      });
    },
    [],
  );

  const handleTemperatureDifferenceValue = useCallback(
    (counterAction: EControlStepCounterAction) => {
      setTemperatureDifferenceValue((value) => {
        const roundedValue = _.round(value);
        return counterAction === EControlStepCounterAction.Decrease
          ? roundedValue - 1
          : roundedValue + 1;
      });
    },
    [],
  );

  const handleShowThermalSurfaceAffectedModal = useCallback(
    () => setIsOpenedThermalSurfaceAffectedModal(true),
    [],
  );
  const handleHideThermalSurfaceAffectedModal = useCallback(
    () => setIsOpenedThermalSurfaceAffectedModal(false),
    [],
  );

  const handleShowVisualSurfaceAffectedModal = useCallback(
    () => setIsOpenedVisualSurfaceAffectedModal(true),
    [],
  );
  const handleHideVisualSurfaceAffectedModal = useCallback(
    () => setIsOpenedVisualSurfaceAffectedModal(false),
    [],
  );

  const handleShowTemperatureDifferenceModal = useCallback(
    () => setIsOpenedTemperatureDifferenceModal(true),
    [],
  );
  const handleHideTemperatureDifferenceModal = useCallback(
    () => setIsOpenedTemperatureDifferenceModal(false),
    [],
  );

  const updatePopoverTopPosition = useCallback(
    ({ ref, key, popoverHeight }: IUpdatePopoverTopPositionParams) => {
      if (ref.current) {
        const { height, top } = ref.current.getBoundingClientRect();
        const popoverTopPosition = getPopoverYPosition({
          parentHeight: height,
          parentTop: top,
          popoverHeight,
          correctionValue: HEADER_HEIGHT,
        });

        setPopoverStyles((styles) => ({
          ...styles,
          [key]: {
            ...styles[key],
            top: toPixels(popoverTopPosition),
          },
        }));
      }
    },
    [],
  );

  useEffect(() => {
    if (isOpenedTemperatureDifferenceModal) {
      updatePopoverTopPosition({
        ref: temperatureDiffRef,
        key: EAnomalyDetailsPopovers.TemperatureDifference,
        popoverHeight: TEMPERATURE_DIFFERENCE_POPOVER_HEIGHT,
      });
    } else if (isOpenedThermalSurfaceAffectedModal) {
      updatePopoverTopPosition({
        ref: thermalSurfaceAffectedRef,
        key: EAnomalyDetailsPopovers.SurfaceAffected,
        popoverHeight: SURFACE_AFFECTED_POPOVER_HEIGHT,
      });
    } else if (isOpenedVisualSurfaceAffectedModal) {
      updatePopoverTopPosition({
        ref: visualSurfaceAffectedRef,
        key: EAnomalyDetailsPopovers.SurfaceAffected,
        popoverHeight: SURFACE_AFFECTED_POPOVER_HEIGHT,
      });
    }
  }, [
    detailsContext.scrollTop,
    isOpenedTemperatureDifferenceModal,
    isOpenedThermalSurfaceAffectedModal,
    isOpenedVisualSurfaceAffectedModal,
  ]);

  useEffect(() => {
    if (currentAnomaly?.id) {
      resetMixedViewState(currentAnomaly);
    }
  }, [currentAnomaly?.id]);

  const subtypeTitle = t('sidebar.anomaly.widgets.details.tiles.subtype.title');
  const averageTypeTitle = t('sidebar.anomaly.widgets.details.tiles.averageTemperature.title');
  const thermalSurfaceAffectedTitle = t(
    'sidebar.anomaly.widgets.details.tiles.surfaceAffected.thermal',
  );
  const visualSurfaceAffectedTitle = t(
    'sidebar.anomaly.widgets.details.tiles.surfaceAffected.visual',
  );
  const temperatureDifferenceTitle = t(
    'sidebar.anomaly.widgets.details.tiles.temperatureDifference.title',
  );

  const formattedThermalSurfaceAffectedValue = useMemo(
    () =>
      _.flowRight(formatSurfaceAffected, getRoundedSurfaceAffected)(thermalSurfaceAffectedValue),
    [thermalSurfaceAffectedValue],
  );

  const formattedVisualSurfaceAffectedValue = useMemo(
    () => _.flowRight(formatSurfaceAffected, getRoundedSurfaceAffected)(visualSurfaceAffectedValue),
    [visualSurfaceAffectedValue],
  );

  const formattedAverageTemperatureValue = useMemo(
    () =>
      _.flowRight(
        formatAverageTemperature,
        getRoundedTemperature,
      )(currentAnomaly?.mean_temperature),
    [currentAnomaly?.mean_temperature],
  );

  const formattedTemperatureDifferenceValue = useMemo(
    () =>
      _.flowRight(formatTemperatureDifference, getRoundedTemperature)(temperatureDifferenceValue),
    [temperatureDifferenceValue],
  );

  return (
    <ul className={styles.tiles}>
      <li className={styles.row}>
        <DetailsTile title={subtypeTitle}>
          <SubtypeTile isEditable={false} isDisabled={false} value={MISSING_VALUE} />
        </DetailsTile>
      </li>
      <li className={styles.row}>
        <DetailsTile title={averageTypeTitle}>
          <AverageTemperatureTile value={formattedAverageTemperatureValue} />
        </DetailsTile>
        <DetailsTile title={temperatureDifferenceTitle} ref={temperatureDiffRef}>
          <div className={styles.details}>
            <StepperPopover
              classNames={{
                popover: styles.temperatureDifferencePopover,
                value: styles.temperatureDifferenceValue,
              }}
              value={formattedTemperatureDifferenceValue}
              style={popoverStyles[EAnomalyDetailsPopovers.TemperatureDifference]}
              title={temperatureDifferenceTitle}
              isOpen={isOpenedTemperatureDifferenceModal}
              onClose={handleHideTemperatureDifferenceModal}
              onUpdate={handleTemperatureDifferenceValue}
            />
            <TemperatureDifferenceTile
              isEditable={detailsContext.isEditable}
              isDisabled={detailsContext.isDisabled}
              value={formattedTemperatureDifferenceValue}
              onClick={handleShowTemperatureDifferenceModal}
            />
          </div>
        </DetailsTile>
      </li>
      <li className={styles.row}>
        <DetailsTile title={thermalSurfaceAffectedTitle} ref={thermalSurfaceAffectedRef}>
          <div className={styles.details}>
            <StepperPopover
              classNames={{
                popover: styles.thermalSurfaceAffectedPopover,
                value: styles.thermalSurfaceAffectedValue,
              }}
              value={formattedThermalSurfaceAffectedValue}
              style={popoverStyles[EAnomalyDetailsPopovers.SurfaceAffected]}
              title={thermalSurfaceAffectedTitle}
              isOpen={isOpenedThermalSurfaceAffectedModal}
              onClose={handleHideThermalSurfaceAffectedModal}
              onUpdate={handleUpdateThermalSurfaceAffectedValue}
            />
            <SurfaceAffectedTile
              className={styles.thermalSurfaceAffectedTileValue}
              isEditable={detailsContext.isEditable}
              isDisabled={detailsContext.isDisabled}
              value={formattedThermalSurfaceAffectedValue}
              onClick={handleShowThermalSurfaceAffectedModal}
            />
          </div>
        </DetailsTile>
        <DetailsTile title={visualSurfaceAffectedTitle} ref={visualSurfaceAffectedRef}>
          <div className={styles.details}>
            <StepperPopover
              classNames={{
                popover: styles.visualSurfaceAffectedPopover,
                value: styles.visualSurfaceAffectedValue,
              }}
              value={formattedVisualSurfaceAffectedValue}
              style={popoverStyles[EAnomalyDetailsPopovers.SurfaceAffected]}
              title={visualSurfaceAffectedTitle}
              isOpen={isOpenedVisualSurfaceAffectedModal}
              onClose={handleHideVisualSurfaceAffectedModal}
              onUpdate={handleUpdateVisualSurfaceAffectedValue}
            />
            <SurfaceAffectedTile
              className={styles.visualSurfaceAffectedTileValue}
              isEditable={detailsContext.isEditable}
              isDisabled={detailsContext.isDisabled}
              value={formattedVisualSurfaceAffectedValue}
              onClick={handleShowVisualSurfaceAffectedModal}
            />
          </div>
        </DetailsTile>
      </li>
    </ul>
  );
};
