import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EViewer } from '@core/enums';
import { EAnomalyStatus } from '@core/enums/anomalies';
import { useDispatchTyped } from '@core/hooks';
import { setSidebarViewer, setToCompareVideo, useVideoSelector } from '@core/store/slices';
import {
  setCurrentAnomalyId,
  setToCompareAnomaliesType,
  useAnomaliesSelector,
} from '@core/store/slices/anomalies';
import {
  useInspectionToCompareSelector,
  useInspectionsToCompareSelector,
} from '@core/store/slices/inspections';
import { useReportToCompareSelector } from '@core/store/slices/reports';
import { InspectionsDate } from '@modules/Layout/components/Header/components/InspectionsDate';
import { InspectionsView } from '@modules/Sidebar/components/InspectionsView';
import { Statuses } from '@components/Statuses';
import { StyledTable } from '@components/StyledTable/StyledTable';
import styles from '../../styles.scss';
import { getAnomaliesStatus } from '../../utils/getAnomaliesStatus';
import { getAnomaliesTable } from '../../utils/getAnomaliesTable';
import { sortAnomalies } from '../../utils/sortAnomalies';

export const ZoneCompareView: React.FC = () => {
  const { t } = useTranslation();
  const [videos, setVideos] = useState<string[]>([]);
  const dropdownInspections = useInspectionsToCompareSelector();
  const { toCompareAnomaliesType } = useAnomaliesSelector();
  const reportToCompare = useReportToCompareSelector();
  const { toCompareVideo } = useVideoSelector();
  const inspectionToCompare = useInspectionToCompareSelector();
  const dispatch = useDispatchTyped();

  const { columnWidth, head, body } = getAnomaliesTable(
    sortAnomalies(reportToCompare?.anomalies || [], toCompareAnomaliesType),
  );

  const handleSwitch = (index: number) => {
    if (videos) dispatch(setToCompareVideo(videos[index]));
  };

  const setVideoId = (videos: string[]) => {
    return videos.findIndex((url) => toCompareVideo === url);
  };

  const handleTableRowClick = (id: string) => {
    dispatch(setCurrentAnomalyId(Number(id)));
  };

  const handleAnomaliesFilter = (status?: EAnomalyStatus | null) => {
    dispatch(setToCompareAnomaliesType(status ?? null));
  };

  useEffect(() => {
    dispatch(setSidebarViewer(EViewer.Video));
  }, []);

  useEffect(() => {
    const videos = reportToCompare?.inspection_videos;
    setVideos(videos ?? ([] as string[]));
  }, [reportToCompare]);

  return (
    <div className={styles.zone}>
      <InspectionsDate
        toCompare
        inspection={inspectionToCompare}
        inspectionsOptions={dropdownInspections}
      />
      <div className={styles.zoneWrapper}>
        <InspectionsView
          classNames={styles.mediaInspections}
          options={videos}
          selectedId={setVideoId(videos)}
          onSwitch={handleSwitch}
          toCompare={true}
        />
        <div className={styles.anomaliesStatuses}>
          <div className={styles.anomaliesStatusesTitle}>
            {t('sidebar.anomaly.anomalies.keyWithCount', {
              count: reportToCompare?.anomalies?.length ?? 0,
            })}
          </div>
          <Statuses
            statuses={getAnomaliesStatus(reportToCompare?.anomalies || [])}
            selectedStatus={toCompareAnomaliesType}
            onClick={handleAnomaliesFilter}
          />
        </div>
        <StyledTable
          body={body}
          columnWidth={columnWidth}
          head={head}
          isSortable={true}
          handleTableRowClick={handleTableRowClick}
        />
      </div>
    </div>
  );
};
