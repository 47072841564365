import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EAspectRatio, EViewerType } from '@core/enums';
import { useCurrentInspectionSelector } from '@core/store/slices';
import { useSamplesSelector } from '@core/store/slices/samples';
import { ImageViewerContext, TImageViewerLoadingState } from './contexts/viewer';
import { ELoadingState } from './enums/loading';
import styles from './styles.scss';
import { MainView, SidebarView } from './views';

interface IProps {
  type: EViewerType;
}

const initialLoadingState = {
  [ELoadingState.RGB]: true,
  [ELoadingState.Segmentation]: true,
  [ELoadingState.Thermal]: true,
  [ELoadingState.Anomaly]: true,
};

const views = {
  [EViewerType.Viewer]: () => <MainView />,
  [EViewerType.InspectionView]: () => <SidebarView />,
};

export const ImageViewer: FC<IProps> = ({ type }) => {
  const { anomalySamples } = useSamplesSelector();
  const currentInspection = useCurrentInspectionSelector();
  const [loadingState, setLoadingState] = useState(initialLoadingState);

  const handleLoadImage = useCallback((imageType: keyof TImageViewerLoadingState) => {
    setLoadingState((prevState) => ({ ...prevState, [imageType]: false }));
  }, []);

  const mainContainerRef = useRef<HTMLDivElement | null>(null);

  const rgbAspectRatio = useMemo(() => {
    const rgbAspectRatio = currentInspection?.rgb_aspect_ratio;

    if (rgbAspectRatio) {
      const isAvailableAspectRatio = Object.values(EAspectRatio).includes(rgbAspectRatio);

      if (isAvailableAspectRatio) {
        return rgbAspectRatio;
      }
    }
    return EAspectRatio.ParrotAnafiDrone;
  }, [currentInspection?.rgb_aspect_ratio]);

  useEffect(() => {
    setLoadingState(initialLoadingState);
  }, [anomalySamples]);

  const contextValue = useMemo(
    () => ({
      mainContainer: mainContainerRef,
      loadingState,
      rgbAspectRatio,
      onLoadImage: handleLoadImage,
    }),
    [loadingState, rgbAspectRatio, mainContainerRef.current, handleLoadImage],
  );

  return (
    <div ref={mainContainerRef} className={styles.container}>
      <ImageViewerContext.Provider value={contextValue}>
        {views[type]()}
      </ImageViewerContext.Provider>
    </div>
  );
};
