export enum ECookiesField {
  StoragePersistPermission = 'storagePersistPermission',
}

export enum ELocalStorageField {
  PersistRoot = 'persist:root',
  CreateSite = 'createSite',
  AccessControl = 'accessControl',
}

export enum EAccessControlLocalStorageField {
  SelectedTenant = 'selectedTenant',
}

export enum ECreateSiteLocalStorageField {
  CurrentZonesPerimeterCalculation = 'currentZonesPerimeterCalculation',
  CurrentZone = 'currentZone',
  CurrentStep = 'currentStep',
}
