import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { format } from 'date-fns';
import { ERROR_TOAST_DELAY, FORMAT_DATE_WITH_TIMES_OF_DAY } from '@core/constants';
import { useDispatchTyped } from '@core/hooks';
import { IComment } from '@core/interfaces';
import { setPreloader, useAccessControlSelector } from '@core/store/slices';
import { convertAMPM } from '@core/utils/formatting/timeFormat';
import { Textarea } from '@components/Textarea';
import styles from './styles.scss';

interface IProps {
  comment: IComment;
  onDelete: () => void;
  onUpdate: (commentId: number, text: string | undefined) => Promise<any>;
  className?: string;
}

export const Comment: React.FC<IProps> = ({ comment, onDelete, onUpdate, className }) => {
  const { user } = useAccessControlSelector();
  const { t } = useTranslation();

  const dispatch = useDispatchTyped();
  const [isEditMode, setIsEditMode] = useState(false);

  const isUserEditorAndAuthor = user?.data?.name === comment.user_name;

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const saveUpdate = (updatedComment: string | undefined) => {
    if (!updatedComment) return;

    dispatch(setPreloader(true));

    (async () => {
      try {
        await onUpdate(comment.id, updatedComment);
        comment.comment = updatedComment;
        setIsEditMode(false);
      } catch {
        toast.error(t('errors.commentUpdateFail'), { autoClose: ERROR_TOAST_DELAY });
      } finally {
        dispatch(setPreloader(false));
      }
    })();
  };

  return (
    <>
      {!isEditMode && (
        <div className={cn(className, styles.container)}>
          <div className={styles.head}>
            <div className={styles.name}>
              <h3>{comment?.user_full_name}</h3>
            </div>
            {isUserEditorAndAuthor && (
              <div className={styles.buttons}>
                <button onClick={handleEdit}>{t('buttons.edit')}</button>
                <button onClick={onDelete}>{t('buttons.delete')}</button>
              </div>
            )}
          </div>
          <div className={styles.body}>{comment?.comment}</div>
          <div className={styles.footer}>
            {convertAMPM(format(new Date(comment?.created_on), FORMAT_DATE_WITH_TIMES_OF_DAY))}
          </div>
        </div>
      )}
      {isEditMode && (
        <Textarea
          className={cn(className, styles.textareaWrapper)}
          initialValue={comment?.comment}
          onSave={(e) => saveUpdate(e)}
          onCancel={() => setIsEditMode(false)}
        />
      )}
    </>
  );
};
