import { EAnomalyMode } from '@core/enums/anomalies';
import { IReport } from '@core/interfaces/report';
import { isNumber } from '@core/utils';
import { TransformedSites } from '@modules/Sidebar/widgets/EstimatedLosses/interfaces';
import {
  getDisconnectedCostByReport,
  getThermalCostByReport,
  getVisualCostByReport,
} from '@modules/Sidebar/widgets/EstimatedLosses/utils/formulas/reportsCosts';

type ReportCostsResponse = {
  [EAnomalyMode.Thermal]: number;
  [EAnomalyMode.String]: number;
  [EAnomalyMode.Visual]: number;
};

function getDefaultReportsCosts() {
  return {
    [EAnomalyMode.Thermal]: 0,
    [EAnomalyMode.String]: 0,
    [EAnomalyMode.Visual]: 0,
  };
}

export function getReportsCostsByModes(
  reports: IReport[],
  sites: TransformedSites,
): ReportCostsResponse {
  return reports.reduce<ReportCostsResponse>((acc, report) => {
    const {
      thermal_surface: thermalSurface,
      net_visual_surface: netVisualSurface,
      location_id: locationId,
      disconnected_panels: disconnectedPanels,
    } = report;

    const areEnabledCalculationFields = [
      netVisualSurface,
      thermalSurface,
      disconnectedPanels,
    ].every(isNumber);

    const selectedSite = locationId ? sites[locationId] : null;

    if (areEnabledCalculationFields && selectedSite) {
      const {
        thermal_factor: thermalFactor,
        rgb_factor: rgbFactor,
        cost_per_defective_panel: costPerDefectivePanel,
      } = selectedSite;

      if (rgbFactor && costPerDefectivePanel) {
        const visualCost = getVisualCostByReport({
          netVisualSurface: netVisualSurface as number,
          rgbFactor,
          costPerDefectivePanel,
        });

        acc[EAnomalyMode.Visual] += visualCost;
      }

      if (thermalFactor && costPerDefectivePanel) {
        const thermalCost = getThermalCostByReport({
          thermalSurface: thermalSurface as number,
          thermalFactor,
          costPerDefectivePanel,
        });

        acc[EAnomalyMode.Thermal] += thermalCost;
      }

      if (costPerDefectivePanel) {
        const disconnectedCost = getDisconnectedCostByReport({
          disconnectedPanels: disconnectedPanels as number,
          costPerDefectivePanel,
        });

        acc[EAnomalyMode.String] += disconnectedCost;
      }
    }
    return acc;
  }, getDefaultReportsCosts());
}
