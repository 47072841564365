import React, { useEffect, useMemo, useRef, memo } from 'react';
import { ReactSVG } from 'react-svg';
import frameAngleBlack from '@assets/icons/frame-angle-black.svg';
import { anomaliesFrameIcon } from '@core/constants';
import { EDetectionStatus, EViewerType } from '@core/enums';
import { ISampleDetection } from '@core/interfaces';
import { useCurrentAnomalySelector } from '@core/store/slices';
import { isString, toPercents } from '@core/utils';
import {
  detectionImageFrameConfig,
  framesOptions,
} from '@modules/Viewers/views/ImageViewer/configs';

interface IProps {
  detection: ISampleDetection;
  type: EViewerType;
  onSetActiveFrameRef?: (ref: HTMLDivElement) => void;
}

export const ImageFrame: React.FC<IProps> = memo(({ detection, type, onSetActiveFrameRef }) => {
  const { h, w, xPercent, yPercent, status } = detection;
  const { defaultFrameLengthInPixels, factors } = detectionImageFrameConfig[type];
  const imageFrameRef = useRef<HTMLDivElement | null>(null);

  const anomaly = useCurrentAnomalySelector();

  const isActiveDetection = detection.status === EDetectionStatus.Active && anomaly?.status;
  const frameIcon = isActiveDetection ? anomaliesFrameIcon[anomaly.status] : frameAngleBlack;

  const handleSvgBeforeInjection = (svg: SVGSVGElement) => {
    // 1. Calculate width/height measure for frame detection
    const minFrameMeasure = Math.min(w, h);
    const deltaFrameLength = minFrameMeasure * factors.frameLength;
    const measure = defaultFrameLengthInPixels + deltaFrameLength;

    // 2. Update frame detection "stroke-width" field
    const pathElement = svg.children[0];
    const strokeWidth = pathElement?.getAttribute('stroke-width');
    const updatedStrokeWidth = isString(strokeWidth)
      ? parseFloat(strokeWidth) * factors.strokeWidth
      : null;

    if (updatedStrokeWidth) {
      pathElement.setAttribute('stroke-width', updatedStrokeWidth.toString());
    }

    svg.setAttribute('width', measure.toString());
    svg.setAttribute('height', measure.toString());
  };

  const cssStyles = useMemo(() => {
    const properties: React.CSSProperties = {
      height: h,
      width: w,
    };

    if (yPercent) {
      properties.top = toPercents(yPercent);
    }

    if (xPercent) {
      properties.left = toPercents(xPercent);
    }

    return properties;
  }, [h, w, yPercent, xPercent]);

  useEffect(() => {
    if (
      type === EViewerType.Viewer &&
      status === EDetectionStatus.Active &&
      imageFrameRef.current
    ) {
      onSetActiveFrameRef?.(imageFrameRef.current);
    }
  }, [imageFrameRef.current, onSetActiveFrameRef]);

  return (
    <div ref={imageFrameRef} className='absolute opacity-80' style={cssStyles}>
      <div className='w-full h-full relative'>
        {framesOptions[type].map(({ id, className }) => (
          <ReactSVG
            key={id}
            beforeInjection={handleSvgBeforeInjection}
            className={className}
            src={frameIcon}
          />
        ))}
      </div>
    </div>
  );
});
