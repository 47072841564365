import { t } from 'i18next';
import _ from 'lodash';
import { EAnomalyMode, EAnomalySubtype } from '@core/enums/anomalies';

const documentStyle = getComputedStyle(document.documentElement);

interface ISolarPanelsChartData {
  aggregatedAnomaliesData: {
    [EAnomalySubtype.BypassDiode]: number;
    [EAnomalySubtype.Coldspot]: number;
    [EAnomalySubtype.Hotspot]: number;
    [EAnomalySubtype.MultipleHotspot]: number;
    [EAnomalyMode.String]: number;
    [EAnomalyMode.Mixed]: number;
    [EAnomalyMode.Visual]: number;
  };
}

export const prepareSolarPanelsData = ({ aggregatedAnomaliesData }: ISolarPanelsChartData) => {
  return {
    data: {
      group: {
        key: '',
        title: '',
        value: '',
        color: documentStyle.getPropertyValue('--white'),
      },
      subgroups: [
        {
          key: EAnomalySubtype.BypassDiode,
          position: 1,
          title: t('sidebar.widgets.panels.popover.subgroups.bypass_diode'),
          color: documentStyle.getPropertyValue('--red-orange-dark'),
          value: _.round(aggregatedAnomaliesData[EAnomalySubtype.BypassDiode], 1),
        },
        {
          key: EAnomalySubtype.Hotspot,
          position: 2,
          title: t('sidebar.widgets.panels.popover.subgroups.hotspot'),
          color: documentStyle.getPropertyValue('--red-orange-light'),
          value: _.round(aggregatedAnomaliesData[EAnomalySubtype.Hotspot], 1),
        },
        {
          key: EAnomalySubtype.MultipleHotspot,
          position: 3,
          title: t('sidebar.widgets.panels.popover.subgroups.multiple_hotspot'),
          color: documentStyle.getPropertyValue('--red-orange-light-shade'),
          value: _.round(aggregatedAnomaliesData[EAnomalySubtype.MultipleHotspot], 1),
        },
        {
          key: EAnomalySubtype.Coldspot,
          position: 4,
          title: t('sidebar.widgets.panels.popover.subgroups.coldspot'),
          color: documentStyle.getPropertyValue('--orange-light'),
          value: _.round(aggregatedAnomaliesData[EAnomalySubtype.Coldspot], 1),
        },
        {
          key: EAnomalyMode.String,
          position: 5,
          title: t('sidebar.widgets.panels.popover.subgroups.string'),
          color: documentStyle.getPropertyValue('--outflier-yellow-light-shade'),
          value: _.round(aggregatedAnomaliesData[EAnomalyMode.String], 1),
        },
        {
          key: EAnomalyMode.Mixed,
          position: 6,
          title: t('sidebar.widgets.panels.popover.subgroups.multimodal'),
          color: documentStyle.getPropertyValue('--outflier-blue-sky'),
          value: _.round(aggregatedAnomaliesData[EAnomalyMode.Mixed], 1),
        },
        {
          key: EAnomalyMode.Visual,
          position: 7,
          title: t('sidebar.widgets.panels.popover.subgroups.rgb'),
          color: documentStyle.getPropertyValue('--outflier-blue-cyan'),
          value: _.round(aggregatedAnomaliesData[EAnomalyMode.Visual], 1),
        },
      ],
    },
  };
};
