import { IChartData, ISubgroup } from '@modules/Sidebar/interfaces';

export interface IExtendedSubgroup extends ISubgroup {
  height: number;
  width: number;
}

interface ITransformedChartData {
  [group: string]: IExtendedSubgroup;
}

export const transformChartData = (chartData: IChartData[]) => {
  const updatedChartData = chartData.reduce((acc, item) => {
    const subgroups = item.data.subgroups.reduce(
      (acc, { title, ...rest }) => ({
        ...acc,
        [title]: { ...rest, height: 0, width: 0 },
      }),
      {} as any,
    );

    return {
      ...acc,
      [item.data.group.key]: subgroups,
    };
  }, {} as ITransformedChartData);

  return updatedChartData;
};
