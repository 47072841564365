import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '@core/api';
import { IUsersMeResponse } from '@core/api/user/users';
import { ECookiesField, ELocalStorageField } from '@core/constants/storage';
import { EAsyncStatus } from '@core/enums';
import { ECreateSiteSections } from '@core/enums/sites/createSite';
import { useSelectorTyped } from '@core/hooks';
import { IBrowserStorage } from '@core/interfaces/accessControl';
import { browserLocalStorage, cookiesStorage } from '@core/services/storage';
import { RootState } from '@core/store';
import { resetCreateSite } from '@core/store/slices/createSite';
import { authStorage } from '@core/utils';
import { resetAnomalies } from './anomalies';
import { resetControls } from './controls';
import { resetInspections } from './inspections';
import { resetModel3DState } from './model3D';
import { resetPrograms } from './programs';
import { resetReports } from './reports';
import { resetAnomalySamples } from './samples';
import { resetSidebar } from './sidebar';
import { resetSites } from './sites';
import { resetVideos } from './video';
import { resetViewer } from './viewer';

interface IInitialState {
  user: {
    data: IUsersMeResponse | null;
    fetchStatus: EAsyncStatus;
    error: unknown | null;
  };
  isDisabledLogout: boolean;
  browserStorage: IBrowserStorage;
}

const initialState: IInitialState = {
  user: {
    data: null,
    fetchStatus: EAsyncStatus.Idle,
    error: null,
  },
  isDisabledLogout: false,
  browserStorage: {
    error: {
      status: null,
      message: null,
    },
  },
};

const fetchUser = createAsyncThunk('accessControl/fetchUser', async (_, { dispatch }) => {
  try {
    dispatch(setLoadedUserStatus(EAsyncStatus.Pending));

    const user = await api.user.usersMe();

    dispatch(setUserData(user));
    dispatch(setLoadedUserStatus(EAsyncStatus.Success));
  } catch {
    dispatch(setUserData(null));
    dispatch(setLoadedUserStatus(EAsyncStatus.Error));
  }
});

const changeUserOrganization = createAsyncThunk(
  'accessControl/changeUserOrganization',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    if (state.createSite[ECreateSiteSections.Zones].isLoadingZones) {
      return;
    }
    // NOTE: remove all "create site" data
    browserLocalStorage.removeItem(ELocalStorageField.CreateSite);
    authStorage.tenant.remove();
    // NOTE: remove persist permission for "SiteCreation"
    cookiesStorage.removeItem(ECookiesField.StoragePersistPermission);

    // Reset store
    dispatch(resetModel3DState());
    dispatch(resetAccessControl());
    dispatch(resetAnomalies());
    dispatch(resetControls());
    dispatch(resetInspections());
    dispatch(resetPrograms());
    dispatch(resetReports());
    dispatch(resetAnomalySamples());
    dispatch(resetSidebar());
    dispatch(resetSites());
    dispatch(resetVideos());
    dispatch(resetViewer());
    dispatch(resetCreateSite());
  },
);

const logoutUser = createAsyncThunk('accessControl/logout', async (_, { dispatch, getState }) => {
  const state = getState() as RootState;
  if (state.createSite[ECreateSiteSections.Zones].isLoadingZones) {
    return;
  }
  // NOTE: remove all "create site" data
  browserLocalStorage.removeItem(ELocalStorageField.CreateSite);
  browserLocalStorage.removeItem(ELocalStorageField.AccessControl);
  // NOTE: remove persist permission for "SiteCreation"
  cookiesStorage.removeItem(ECookiesField.StoragePersistPermission);
  // NOTE: remove tokens section
  Object.values(authStorage.tokens).forEach((field) => {
    field.remove();
  });

  // Reset store
  dispatch(resetModel3DState());
  dispatch(resetAccessControl());
  dispatch(resetAnomalies());
  dispatch(resetControls());
  dispatch(resetInspections());
  dispatch(resetPrograms());
  dispatch(resetReports());
  dispatch(resetAnomalySamples());
  dispatch(resetSidebar());
  dispatch(resetSites());
  dispatch(resetVideos());
  dispatch(resetViewer());
  dispatch(resetCreateSite());
});

const accessControlSlice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {
    setLoadedUserStatus: (state, action: PayloadAction<EAsyncStatus>) => {
      state.user.fetchStatus = action.payload;
    },
    setLoadedUserError: (state, action: PayloadAction<unknown | null>) => {
      state.user.error = action.payload;
    },
    setUserData: (state, actions: PayloadAction<IUsersMeResponse | null>) => {
      state.user.data = actions.payload;
    },
    removeUser: (state) => {
      state.user = initialState.user;
    },
    setIsDisabledLogout: (state, action: PayloadAction<boolean>) => {
      state.isDisabledLogout = action.payload;
    },
    setBrowserStorageError: (state, action: PayloadAction<IBrowserStorage['error'] | null>) => {
      state.browserStorage.error.status = action.payload?.status ?? null;
      state.browserStorage.error.message = action.payload?.message ?? null;
    },
    resetAccessControl: () => ({ ...initialState, loading: false }),
  },
});

const accessControlReducer = accessControlSlice.reducer;

const {
  setUserData,
  setLoadedUserStatus,
  setLoadedUserError,
  removeUser,
  setIsDisabledLogout,
  setBrowserStorageError,
  resetAccessControl,
} = accessControlSlice.actions;

const useAccessControlSelector = () => useSelectorTyped((state) => state.accessControl);

export {
  accessControlReducer,
  fetchUser,
  setUserData,
  setLoadedUserStatus,
  setLoadedUserError,
  logoutUser,
  removeUser,
  changeUserOrganization,
  setIsDisabledLogout,
  resetAccessControl,
  useAccessControlSelector,
  setBrowserStorageError,
  initialState as accessControlInitialState,
};
