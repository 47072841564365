import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import chevronRight from '@assets/icons/chevron-right.svg';
import styles from './styles.scss';
import commonStyles from '../styles.scss';

interface IProps {
  className?: string;
  value: string;
  isEditable: boolean;
  isDisabled: boolean;
  onClick: () => void;
}

export const SurfaceAffectedTile: FC<IProps> = ({
  className,
  value,
  isEditable,
  isDisabled,
  onClick,
}) => {
  return (
    <div className={cn(commonStyles.valueWrapper, className)}>
      <span className={cn(commonStyles.value, styles.surfaceAffectedValue)}>{value}</span>
      {isEditable && (
        <div className={commonStyles.chevronWrapper}>
          <button onClick={onClick} disabled={isDisabled}>
            <ReactSVG src={chevronRight} className={cn(commonStyles.chevronIcon, 'rotate-90')} />
          </button>
        </div>
      )}
    </div>
  );
};
