import { Feature } from 'geojson';
import { layersStyles, setFeatureCollectionData } from './mapboxConfig';
import { EAnomalyLayers, EZonesLayers } from '../../enums/layers';
import { EAnomalySources } from '../../enums/sources';

export const setAnomalies = (map: mapboxgl.Map, features: Feature[], hasSolarPanels?: boolean) => {
  map.addSource(EAnomalySources.Anomalies, {
    type: 'geojson',
    data: setFeatureCollectionData(features),
  });

  map.addLayer(
    layersStyles[EAnomalyLayers.Anomalies]({ hasSolarPanels }),
    EZonesLayers.PolygonBorder,
  );
  map.addLayer(layersStyles[EAnomalyLayers.AnomaliesBorder]({ hasSolarPanels }));
};

export const setAnomaliesPerimeter = (
  map: mapboxgl.Map,
  features: Feature[],
  hasSolarPanels?: boolean,
) => {
  map.addSource(EAnomalySources.AnomaliesPerimeter, {
    type: 'geojson',
    data: setFeatureCollectionData(features),
  });
  map.addLayer(layersStyles[EAnomalyLayers.AnomaliesPerimeter]({ hasSolarPanels }));
};
