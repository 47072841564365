import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { EMode } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { TEditZone } from '@core/interfaces';
import {
  nextCurrentProgramId,
  previousCurrentProgramId,
  setCreateSiteCurrentProgramId,
  updateProgramById,
  useCreateSiteName,
  useCreateSiteZones,
  useCurrentCreateSiteProgramSelector,
} from '@core/store/slices/createSite';
import { IUpdateInputData } from '@modules/Sidebar/components/AccordionView/BodyOptions/Roles/interfaces/updateItem';
import SelectedZone from '@modules/Sidebar/components/AccordionView/BodyOptions/Zones/components/SelectedZone/SelectedZone';
import { BodyItem, StyledTable } from '@components/StyledTable/StyledTable';
import styles from '../NoFlyZones/styles.scss';

const columnWidth = ['200px', '100px'];
const head = [];

const ZonesCreate = () => {
  const { t } = useTranslation();
  const { programs, currentProgramId } = useCreateSiteZones();
  const { name: siteName } = useCreateSiteName();
  const currentProgram = useCurrentCreateSiteProgramSelector();
  const dispatch = useDispatchTyped();

  const areDisabledSwitcherButtons = programs.length === 1;

  const handleBackClick = useCallback(() => {
    dispatch(setCreateSiteCurrentProgramId(''));
  }, [dispatch]);

  const handleTableRowClick = useCallback(
    (zoneId: string) => {
      dispatch(setCreateSiteCurrentProgramId(zoneId));
      dispatch(updateProgramById({ id: zoneId, changes: { isViewed: true } }));
    },
    [dispatch],
  );

  const body: BodyItem[] = useMemo(
    () =>
      programs.map((program) => {
        const { isViewed, custom_inspection_plan_requested, custom_overview_plan_requested } =
          program;

        let name = t('sidebar.site.edit.sections.zones.table.toReview');
        if (isViewed) name = t('sidebar.site.edit.sections.zones.table.toValidate');
        if (custom_overview_plan_requested || custom_inspection_plan_requested)
          name = t('sidebar.site.edit.sections.zones.table.toReadjust');

        return {
          id: program.program_id,
          data: [
            {
              name: program.name,
              className: 'pr-3',
            },
            {
              name: name,
              className: cn(
                {
                  'text-outflier-yellow':
                    custom_overview_plan_requested || custom_inspection_plan_requested,
                  'text-outflier-green': isViewed,
                  'text-outflier-soft-gray':
                    !isViewed &&
                    !custom_overview_plan_requested &&
                    !custom_inspection_plan_requested,
                },
                'flex flex-end font-normal',
              ),
            },
          ],
        };
      }),
    [t, programs],
  );

  const handleChange = useCallback(
    (data: IUpdateInputData) => {
      const firstKey = Object.keys(data)[0];
      if (firstKey) {
        const firstValue = data[firstKey].value;
        dispatch(updateProgramById({ id: currentProgramId, changes: { name: firstValue } }));
      }
    },
    [dispatch],
  );

  const handleNextClick = useCallback(() => {
    dispatch(nextCurrentProgramId());
  }, [dispatch]);

  const handlePreviousClick = useCallback(() => {
    dispatch(previousCurrentProgramId());
  }, [dispatch]);

  const zoneData = useMemo(() => {
    const data: TEditZone = {
      program_id: currentProgram?.program_id ?? '',
      customName: currentProgram?.name ?? 'Site name',
      customInspectionPlanRequested: currentProgram?.custom_inspection_plan_requested,
      customOverviewPlanRequested: currentProgram?.custom_overview_plan_requested,
      name: {
        value: currentProgram?.name ?? '',
        errorMessage: null,
      },
    };

    if (currentProgram?.plans) {
      data.plans = currentProgram?.plans;
    }

    return data;
  }, [
    currentProgram?.program_id,
    currentProgram?.name,
    currentProgram?.custom_inspection_plan_requested,
    currentProgram?.custom_overview_plan_requested,
    currentProgram?.plans,
  ]);

  return (
    <div className={styles.body}>
      {currentProgramId && (
        <SelectedZone
          mode={EMode.Create}
          zone={zoneData}
          siteName={siteName}
          onBackClick={handleBackClick}
          onChange={handleChange}
          areDisabledSwitcherButtons={areDisabledSwitcherButtons}
          onRightClick={handleNextClick}
          onLeftClick={handlePreviousClick}
        />
      )}
      <h2 className={styles.notification}>{t('sidebar.site.edit.sections.zones.notification')}</h2>
      <span className='px-2.5 text-sm font-medium'>
        {t('sidebar.site.edit.sections.zones.table.head.column1.keyWithCount', {
          count: programs.length ?? 0,
        })}
      </span>
      <div className={styles.wrapper}>
        <StyledTable
          body={body}
          head={head}
          columnWidth={columnWidth}
          isSortable={false}
          handleTableRowClick={handleTableRowClick}
        />
      </div>
    </div>
  );
};

export default ZonesCreate;
