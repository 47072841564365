export enum EControlMode {
  '2D' = '2D',
  '3D' = '3D',
}

export enum EArrowDirection {
  Left = 'left',
  Right = 'right',
}

export enum EControlStepCounterAction {
  Decrease = 'decrease',
  Increase = 'increase',
}
