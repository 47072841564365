import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ISolarPanelsWithIncidentAnomalies } from '@core/api/report/solarPanels';
import { getSolarPanelsCounters } from '@modules/Sidebar/widgets/SolarPanels/utils';
import styles from './styles.scss';

interface IProps {
  solarPanels: ISolarPanelsWithIncidentAnomalies[];
}

export const PanelsStatistics: FC<IProps> = memo(({ solarPanels }) => {
  const { t } = useTranslation();

  const counters = useMemo(() => getSolarPanelsCounters(solarPanels), [solarPanels]);

  return (
    <div className={styles.root}>
      {useMemo(
        () =>
          Object.entries(counters).map(([key, value]) => (
            <div key={key} className={styles.counter}>
              <h3 className={styles.title}>
                {t(`sidebar.widgets.panels.statistics.${key}.title`)}
              </h3>
              <span className={cn(styles.counterValue, styles[key])}>{value}</span>
            </div>
          )),
        [counters],
      )}
    </div>
  );
});
