export enum EEstimatedLossesGroup {
  AtInspected = 'atInspected',
  AfterRepair = 'afterRepair',
}

export enum EEstimatedLossesTooltipValue {
  Group = 'group',
  Subgroup = 'subgroup',
  Value = 'value',
}
