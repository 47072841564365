import { getPercents } from '@core/utils';

interface IFrameOffset {
  top: number; // in percent
  left: number; // in percent
}

export const getInitialMiniFrameOffset = (
  scale: number,
  activeDetectionRect: DOMRect,
  mainContainerRect: DOMRect,
): IFrameOffset => {
  const {
    width: detectionWidth,
    height: detectionHeight,
    left: detectionLeft,
    y: rawDetectionTop,
  } = activeDetectionRect;
  const { width: containerWidth, height: containerHeight, y: containerTop } = mainContainerRect;
  const detectionTop = rawDetectionTop - containerTop;

  const xDetectionCenter = detectionLeft + detectionWidth / 2;
  const yDetectionCenter = detectionTop + detectionHeight / 2;

  const yDetectionCenterInPercents = getPercents(yDetectionCenter, containerHeight);
  const xDetectionCenterInPercents = getPercents(xDetectionCenter, containerWidth);

  const miniFrameSize = getMiniFrameSize(scale, mainContainerRect);

  return {
    left: xDetectionCenterInPercents - miniFrameSize.width / 2,
    top: yDetectionCenterInPercents - miniFrameSize.height / 2,
  };
};

interface IFrameSize {
  width: number; // in percent
  height: number; // in percent
}

export const getMiniFrameSize = (scale: number, mainContainerRect: DOMRect): IFrameSize => {
  const { width, height } = mainContainerRect;
  const [scaledWidth, scaledHeight] = [width, height].map((v) => v * scale);
  const screenWidthInPercent = getPercents(width, scaledWidth);
  const screenHeightInPercent = getPercents(height, scaledHeight);

  return {
    width: screenWidthInPercent,
    height: screenHeightInPercent,
  };
};
