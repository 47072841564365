import { ISolarPanelsWithIncidentAnomalies } from '@core/api/report/solarPanels';

export function getDefectivePercent(solarPanels: ISolarPanelsWithIncidentAnomalies[]) {
  const solarPanelsWithAnomalies = solarPanels.filter(
    (solarPanel) => solarPanel.incident_anomalies.length,
  );
  const allSolarPanelsAmount = solarPanels.length;
  const solarPanelsWithAnomaliesAmount = solarPanelsWithAnomalies.length;

  return allSolarPanelsAmount
    ? Math.round((solarPanelsWithAnomaliesAmount * 100) / allSolarPanelsAmount)
    : 0;
}
