interface IGridOptions {
  xOffset: number;
  width: number;
  strokeColor: string;
}

export const setHorizontalGrid = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  y: d3.ScaleLinear<number, number, never>,
  options: IGridOptions,
) => {
  const { xOffset, width, strokeColor } = options;

  svg
    .selectAll('.grid')
    .data(y.ticks())
    .enter()
    .append('line')
    .attr('class', 'grid')
    .attr('x1', xOffset)
    .attr('y1', (d) => y(d))
    .attr('x2', width)
    .attr('y2', (d) => y(d))
    .attr('stroke', strokeColor);
};
