import { useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from '@core/contexts/auth';
import { EAsyncStatus } from '@core/enums';
import RequireAuth from '@core/router/Routes/Private';
import { ROUTE_PATHS } from '@core/router/routerConfig';
import { ERouteAccess, ROUTES_CONFIG } from '@core/router/routes';
import { usePreloaderSelector, useAccessControlSelector } from '@core/store/slices';
import { Layout } from '@modules/Layout';
import { Preloader } from '@components/Preloader';

export const App = () => {
  const { user } = useAccessControlSelector();
  const preloader = usePreloaderSelector();

  const isLoadingStatus = useMemo(
    () => preloader || user.fetchStatus === EAsyncStatus.Pending,
    [preloader, user.fetchStatus],
  );

  return (
    <Router>
      <AuthProvider>
        <ToastContainer position='bottom-center' />
        {isLoadingStatus && <Preloader />}
        <Routes>
          {ROUTES_CONFIG[ERouteAccess.Public].map((props) => (
            <Route key={props.path} {...props} />
          ))}
          <Route element={<RequireAuth />}>
            <Route element={<Layout />}>
              {ROUTES_CONFIG[ERouteAccess.Private].map((props) => (
                <Route key={props.path} {...props} />
              ))}
            </Route>
          </Route>
          <Route path='*' element={<Navigate to={ROUTE_PATHS.Login.Root} />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};
