import { EAnomalyStatus } from '@core/enums/anomalies';
import { IProgram } from '@core/interfaces';

export const getProgramsWithWorstAnomalyStatus = (programs: IProgram[] | null): IProgram[] => {
  if (!programs) return [];

  const anomalyStatusRanking: { [key in EAnomalyStatus]: number } = {
    [EAnomalyStatus.Major]: 4,
    [EAnomalyStatus.ToReview]: 3,
    [EAnomalyStatus.Minor]: 2,
    [EAnomalyStatus.False]: 1,
  };

  return programs.map((program) => {
    if (program.statuses) {
      let worstStatus = EAnomalyStatus.False;

      for (const [status, count] of Object.entries(program.statuses)) {
        if (count > 0 && anomalyStatusRanking[status] > anomalyStatusRanking[worstStatus]) {
          worstStatus = status as EAnomalyStatus;
        }
      }
      return { ...program, worst_anomaly_status: worstStatus };
    }

    return program;
  });
};
