import React from 'react';
import cn from 'classnames';
import { EAnomalyStatus } from '@core/enums/anomalies';
import { IStatus } from '@core/interfaces';
import styles from './styles.scss';
import { getStyleAmount } from './utils/getStyleAmount';
import { getStyleButton } from './utils/getStyleButton';

interface IProps {
  mode?: 'zone' | 'anomaly';
  statuses: IStatus[];
  className?: string;
  selectedStatus?: string | null;
  isStatusesClickable?: boolean;
  onClick?: (statusName: EAnomalyStatus | null) => void;
}

export const Statuses: React.FC<IProps> = React.memo(
  ({
    mode = 'zone',
    statuses = [],
    className,
    selectedStatus,
    isStatusesClickable = true,
    onClick,
  }) => {
    const handleClick = (name: string) => {
      if (!onClick) return;
      if (selectedStatus === name) return onClick(null);

      onClick(name as EAnomalyStatus);
    };

    return (
      <div className={cn(styles.statuses, className)}>
        {statuses.map(({ name, color, amount }) => (
          <button
            key={name}
            style={getStyleButton(name, selectedStatus === name)}
            className={cn(styles.status, { [styles.notClicked]: !onClick })}
            onClick={handleClick.bind(this, name)}
            disabled={!isStatusesClickable}
          >
            <span className={cn(styles.statusName, { [styles.bigText]: mode === 'anomaly' })}>
              {name}
            </span>
            {Number.isInteger(amount) && (
              <span
                className={styles.statusAmount}
                style={color ? { color } : getStyleAmount(name, selectedStatus === name)}
              >
                {amount}
              </span>
            )}
          </button>
        ))}
      </div>
    );
  },
);
