import { t } from 'i18next';
import { ESiteCategory } from '@core/enums';

export const shieldPropsConfig = {
  [ESiteCategory.Open]: {
    shield: {
      text: t('sitesCategories.openCategory'),
      className: 'bg-outflier-green-transparent text-outflier-green',
    },
    tooltip: {
      text: t('sitesCategories.openTooltip'),
      className: '',
    },
  },
  [ESiteCategory.Restricted]: {
    shield: {
      text: t('sitesCategories.restrictedCategory'),
      className: 'bg-outflier-dark-red-transparent text-outflier-dark-red',
    },
    tooltip: {
      text: t('sitesCategories.restrictedTooltip'),
      className: '',
    },
  },
};
