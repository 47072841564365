import { FC, useCallback } from 'react';
import {
  ECreateSiteLocalStorageField,
  ELocalStorageField,
  ESTIMATED_TIME_OF_ZONES_GENERATION,
} from '@core/constants';
import { ECreateSiteSections } from '@core/enums/sites/createSite';
import { useDispatchTyped, useSelectorTyped } from '@core/hooks';
import { DataProcessing } from '@core/services/processing';
import { browserLocalStorage } from '@core/services/storage';
import { setFakePerimeterProcessing } from '@core/store/slices';
import { FakeProgressBar, ProgressOverlay } from '@components/ProgressBar';

interface IFakeData {
  currentStep: number;
  percentage: number;
}

interface IProps {
  isHidden: boolean;
  estimatedTimeMs?: number;
}

export const ZonesPerimeterProgressBar: FC<IProps> = ({
  isHidden,
  estimatedTimeMs = ESTIMATED_TIME_OF_ZONES_GENERATION,
}) => {
  const dispatch = useDispatchTyped();
  const fakePerimeterProcessing = useSelectorTyped(
    (state) => state.createSite[ECreateSiteSections.Zones].fakePerimeterProcessing,
  );
  const locationId = useSelectorTyped((state) => state.createSite.loc_id);

  const handleUpdateFakePerimeterCurrentStep = useCallback(
    (data: IFakeData) => {
      dispatch(setFakePerimeterProcessing(data));

      if (locationId) {
        const createSiteStorageState =
          DataProcessing.deserialize(browserLocalStorage.getItem(ELocalStorageField.CreateSite)) ??
          {};

        const payloadData = {
          [ECreateSiteLocalStorageField.CurrentZonesPerimeterCalculation]: {
            percentage: fakePerimeterProcessing.percentage,
            timestamp: Date.now(),
          },
        };
        const updatedCreateSiteStorageState = {
          ...createSiteStorageState,
          [locationId]: createSiteStorageState[locationId]
            ? {
                ...createSiteStorageState[locationId],
                ...payloadData,
              }
            : payloadData,
        };

        browserLocalStorage.setItem(
          ELocalStorageField.CreateSite,
          DataProcessing.serialize(updatedCreateSiteStorageState),
        );
      }
    },
    [locationId, fakePerimeterProcessing.percentage],
  );

  return (
    <ProgressOverlay isHidden={isHidden} text={'Calculating\nZone'}>
      <FakeProgressBar
        currentStep={fakePerimeterProcessing.currentStep}
        percentage={fakePerimeterProcessing.percentage ?? 0}
        estimatedTimeMs={estimatedTimeMs}
        onUpdate={handleUpdateFakePerimeterCurrentStep}
        isTextHidden
      />
    </ProgressOverlay>
  );
};
