import { EImageViewerStyle } from '@core/enums/viewers';

export interface IImageViewerScale {
  default: number;
  current: number;
}

export interface IImageViewerFlags {
  segmentationMask?: boolean;
  anomalyMask?: boolean;
}

export interface IImageViewerPosition {
  top: number;
  left: number;
}

export interface IThermalStyles {
  opacity: number;
}

export interface IImageViewerStyles {
  [EImageViewerStyle.Container]: {
    current: IImageViewerPosition;
    default: IImageViewerPosition;
  };
  [EImageViewerStyle.MiniFrame]: {
    current: IImageViewerPosition;
    default: IImageViewerPosition;
  };
  [EImageViewerStyle.Thermal]: {
    current: IThermalStyles;
    default: IThermalStyles;
  };
}

export type PartialOfPartial<T> = {
  [K in keyof T]?: T[K] extends object ? PartialOfPartial<T[K]> : T[K];
};
