import { t } from 'i18next';
import { ENoFlyZoneType } from '@core/constants';
import { INoFlyZone, IObstacle } from '@core/interfaces/sites/createSite';

export const createObstacle = (index = 0): IObstacle => ({
  id: Date.now(),
  name: `${t('sidebar.site.edit.sections.obstacles.defaultName')} ${index}`,
  type: ENoFlyZoneType.Obstacle,
  perimeter: {
    type: 'LineString',
    coordinates: [],
  },
});

export const createNoFlyZone = (index = 0): INoFlyZone => ({
  id: Date.now(),
  name: `${t('sidebar.site.edit.sections.noFlyZones.defaultName')} ${index}`,
  type: ENoFlyZoneType.NoFlyZone,
  perimeter: {
    type: 'Polygon',
    coordinates: [],
  },
});
