import { FC, ReactElement, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@core/contexts/auth';
import { areAllValuesValid } from '@core/utils';
import { ROUTE_PATHS } from '../routerConfig';

interface IProps {
  children: ReactElement;
}

const redirectCheckers = {
  toLogin(isAuth: boolean, pathname: string) {
    return areAllValuesValid(!isAuth, pathname !== ROUTE_PATHS.Login.Root);
  },
  toSelectedOrganization(isAuth: boolean, pathname: string, tenant: string | null) {
    return areAllValuesValid(isAuth, !tenant, pathname !== ROUTE_PATHS.Login.SelectOrganization);
  },
  toApp(isAuth: boolean, tenant: string | null) {
    return areAllValuesValid(isAuth, tenant);
  },
};

const PublicRoute: FC<IProps> = ({ children }) => {
  const { isAuthenticated, tenantRef } = useAuth();
  const location = useLocation();

  const redirectedPath = useMemo(() => {
    if (redirectCheckers.toLogin(isAuthenticated, location.pathname)) {
      return ROUTE_PATHS.Login.Root;
    }
    if (
      redirectCheckers.toSelectedOrganization(isAuthenticated, location.pathname, tenantRef.current)
    ) {
      return ROUTE_PATHS.Login.SelectOrganization;
    }
    if (redirectCheckers.toApp(isAuthenticated, tenantRef.current)) {
      return ROUTE_PATHS.Root;
    }
    return null;
  }, [isAuthenticated, tenantRef.current, location.pathname]);

  return redirectedPath ? <Navigate to={redirectedPath} /> : children;
};

export default PublicRoute;
