import { FC, memo, useCallback } from 'react';
import cn from 'classnames';
import { t } from 'i18next';
import { EAnomalyStatisticCategory } from '@core/enums/anomalies';
import styles from './styles.scss';

const getters = {
  getControllerLabel(chart: EAnomalyStatisticCategory) {
    return t(`sidebar.widgets.charts.${chart}.title`);
  },
};

interface IAnomalyControlsProps {
  selectedChart: EAnomalyStatisticCategory;
  onClick: (selectedChart: EAnomalyStatisticCategory) => void;
}

export const AnomalyControls: FC<IAnomalyControlsProps> = memo(({ selectedChart, onClick }) => {
  const handleSelectedAnomalySeverityStatistic = useCallback(() => {
    onClick(EAnomalyStatisticCategory.Severity);
  }, [onClick]);

  const handleSelectedAnomalyTypeStatistic = useCallback(() => {
    onClick(EAnomalyStatisticCategory.Type);
  }, [onClick]);

  const handleSelectedAnomalyRepairStatistic = useCallback(() => {
    onClick(EAnomalyStatisticCategory.Repair);
  }, [onClick]);

  return (
    <div className={styles.anomalyStatisticControls}>
      <button
        className={cn(styles.controlBtn, {
          [styles.isActive]: selectedChart === EAnomalyStatisticCategory.Severity,
        })}
        onClick={handleSelectedAnomalySeverityStatistic}
      >
        {getters.getControllerLabel(EAnomalyStatisticCategory.Severity)}
      </button>
      <button
        className={cn(styles.controlBtn, {
          [styles.isActive]: selectedChart === EAnomalyStatisticCategory.Type,
        })}
        onClick={handleSelectedAnomalyTypeStatistic}
      >
        {getters.getControllerLabel(EAnomalyStatisticCategory.Type)}
      </button>
      <button
        className={cn(styles.controlBtn, {
          [styles.isActive]: selectedChart === EAnomalyStatisticCategory.Repair,
        })}
        onClick={handleSelectedAnomalyRepairStatistic}
      >
        {getters.getControllerLabel(EAnomalyStatisticCategory.Repair)}
      </button>
    </div>
  );
});
