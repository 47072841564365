// TODO:

/* eslint-disable @typescript-eslint/ban-types */
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { api } from '@core/api';
import { ERROR_TOAST_DELAY, errorsMap } from '@core/constants';
import { ECamera, EErrorStatus } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';
import { ISample } from '@core/interfaces';
import { IAnomaly } from '@core/interfaces/anomaly';
import { SampleError } from '@core/services/errors/samples';
import { RootState } from '@core/store';
import { resetImageViewerScale, resetImageViewerStyles } from './imageViewer';
import { setPreloader } from './preloader';
import { addStatusToDetections } from '../utils/samples/addStatusToDetections';
import { getAnomalyMaskParams } from '../utils/samples/getAnomalyMaskParams';
import { getSampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter } from '../utils/samples/getSampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter';
import { updateAnomalyMask } from '../utils/samples/updateAnomalyMask';

interface IInitialState {
  anomalySamples: ISample[];
  currentReportSamples: ISample[] | null;
  reportToCompareSamples: ISample[] | null;
  selectedSample: ISample | null;
  loading: boolean;
}

const initialState: IInitialState = {
  anomalySamples: [],
  currentReportSamples: null,
  reportToCompareSamples: null,
  selectedSample: null,
  loading: false,
};

const setAnomalySamples = createAsyncThunk(
  'samples/setAnomalySamples',
  async (anomalyId: number, { dispatch, getState }) => {
    try {
      dispatch(setPreloader(true));
      const cb = (a: ISample, b: ISample) => a.id - b.id;

      const newAnomalySamples = (
        await api.anomalies.getSamplesByAnomalyId(anomalyId, ECamera.All)
      ).sort(cb);

      dispatch(updateAnomalySamples(newAnomalySamples));

      const state = getState() as RootState;

      const currentAnomalyId = state.anomalies.currentAnomalyId;
      const currentReportAnomalies = state.reports.reports.find(
        (report) => String(report.id) === state.reports.currentReportId,
      )?.anomalies;
      const anomalySamples = state.samples.anomalySamples;

      const currentAnomaly = currentReportAnomalies?.find(
        (anomaly: IAnomaly) => anomaly.id === currentAnomalyId,
      );

      if (currentAnomaly) {
        const closestSample = getSampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter(
          currentAnomaly,
          anomalySamples,
        );

        dispatch(setSelectedSample(closestSample ? closestSample : newAnomalySamples[0]));

        // TODO: re-check this functionality for "ImageViewer"
        dispatch(resetImageViewerScale());
        dispatch(resetImageViewerStyles());
      }
    } catch {
      toast.error(t('errors.somethingIsWrong'), { autoClose: ERROR_TOAST_DELAY });
    }
  },
);

export const setCurrentReportSamplesByReportId = createAsyncThunk<void, string, {}>(
  'samples/setCurrentReportSamplesByReportId',
  async (reportId, { dispatch }) => {
    try {
      dispatch(startDataProcessing());
      const reportSamples = await api.reports.getSamplesByReportId(Number(reportId), 'all');
      dispatch(setCurrentReportSamples(reportSamples.length ? reportSamples : []));
    } catch {
      toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(finishDataProcessing());
    }
  },
);

export const setReportToCompareSamplesByReportId = createAsyncThunk<void, string, {}>(
  'samples/setReportToCompareSamplesByReportId',
  async (reportId, { dispatch }) => {
    try {
      dispatch(startDataProcessing());
      const reportSamples = await api.reports.getSamplesByReportId(Number(reportId), ECamera.All);
      dispatch(setReportToCompareSamples(reportSamples));
    } catch {
      toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(finishDataProcessing());
    }
  },
);

export const fetchSampleRgbImage = createAsyncThunk<void, void>(
  'samples/fetchSampleRgbImage',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const selectedSample = state.samples.selectedSample;
    const selectedReportId = state.reports.currentReportId;

    if (!selectedSample) return;
    if (!selectedReportId) return;
    if (selectedSample.rgb_image) return;

    try {
      dispatch(setPreloader(true));
      const rgbImage = await api.reports.getSampleRgbImageByReportId(
        selectedReportId,
        selectedSample.id,
      );

      dispatch(
        updateSelectedSample({
          ...selectedSample,
          rgb_image: rgbImage,
        }),
      );
    } catch {
      toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(setPreloader(false));
    }
  },
);

export const fetchSampleThermalImage = createAsyncThunk<void, void>(
  'samples/fetchSampleThermalImage',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const selectedSample = state.samples.selectedSample;
    const selectedReportId = state.reports.currentReportId;

    if (!selectedSample) return;
    if (!selectedReportId) return;
    if (selectedSample.thermal_image) return;

    try {
      dispatch(setPreloader(true));
      const thermalImage = await api.reports.getSampleThermalImageByReportId(
        selectedReportId,
        selectedSample.id,
      );

      dispatch(
        updateSelectedSample({
          ...selectedSample,
          thermal_image: thermalImage,
        }),
      );
    } catch {
      toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(setPreloader(false));
    }
  },
);

export const fetchSampleSegmentationMask = createAsyncThunk<void, void>(
  'samples/fetchSampleSegmentationMask',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const selectedSample = state.samples.selectedSample;
    const selectedReportId = state.reports.currentReportId;

    if (!selectedSample) return;
    if (!selectedReportId) return;
    if (selectedSample.segmentation_mask) return;

    try {
      dispatch(setPreloader(true));
      const segmentationMask = await api.reports.getSampleSegmentationMaskByReportId(
        selectedReportId,
        selectedSample.id,
      );

      dispatch(
        updateSelectedSample({
          ...selectedSample,
          segmentation_mask: segmentationMask,
        }),
      );
    } catch {
      toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(setPreloader(false));
    }
  },
);

export const fetchSampleAnomalyMask = createAsyncThunk<void, void>(
  'samples/fetchSampleAnomalyMask',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const selectedSample = state.samples.selectedSample;
    const selectedReportId = state.reports.currentReportId;

    if (!selectedSample) return;
    if (!selectedReportId) return;
    if (selectedSample.anomaly_mask) return;

    try {
      dispatch(setPreloader(true));

      if (!selectedSample.detections?.length) {
        throw new SampleError(
          errorsMap[EErrorStatus.MissingSampleDetections],
          EErrorStatus.MissingSampleDetections,
        );
      }

      const anomalyMask = await api.reports.getSampleAnomalyMaskByReportId(
        selectedReportId,
        selectedSample.id,
      );
      const selectedReport = state.reports.reports.find(
        (report) => String(report.id) === selectedReportId,
      );
      const selectedAnomaly = selectedReport?.anomalies?.find(
        (anomaly) => anomaly.id === state.anomalies.currentAnomalyId,
      );

      const sample = {
        ...selectedSample,
        detections: addStatusToDetections(
          selectedSample.detections,
          selectedAnomaly?.detection_ids,
        ),
      };
      const updatedAnomalyMask = await updateAnomalyMask(
        anomalyMask,
        getAnomalyMaskParams({ selectedAnomaly, selectedSample: sample }),
      );

      dispatch(
        updateSelectedSample({
          ...sample,
          anomaly_mask: updatedAnomalyMask,
        }),
      );
    } catch (err) {
      // TODO: will be handled in the future
      if (err instanceof SampleError) {
        console.info(err.message);
      } else {
        toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
      }
    } finally {
      dispatch(setPreloader(false));
    }
  },
);

const setSelectedSample = createAsyncThunk<void, ISample | null>(
  'samples/setSelectedSample',
  async (sample, { dispatch, getState }) => {
    const state = getState() as RootState;
    const selectedReportId = state.reports.currentReportId;

    if (!sample) return;
    if (!selectedReportId) return;

    try {
      dispatch(setPreloader(true));
      dispatch(startDataProcessing());
      const additionalSample = await api.reports.getSampleByReportId(selectedReportId, sample.id);

      const promises: [
        Promise<string>?,
        Promise<string>?,
        (Promise<string> | null)?,
        (Promise<string> | null)?,
      ] = [];

      promises.push(api.reports.getSampleRgbImageByReportId(selectedReportId, sample.id));
      promises.push(api.reports.getSampleThermalImageByReportId(selectedReportId, sample.id));

      const isEnableAnomalyMask =
        state.imageViewer.flags.anomalyMask && additionalSample.detections?.length;
      const isEnabledSegmentationMask = state.imageViewer.flags.segmentationMask;

      promises.push(
        isEnableAnomalyMask
          ? api.reports.getSampleAnomalyMaskByReportId(selectedReportId, sample.id)
          : null,
      );
      promises.push(
        isEnabledSegmentationMask
          ? api.reports.getSampleSegmentationMaskByReportId(selectedReportId, sample.id)
          : null,
      );

      const [rgbImage, thermalImage, anomalyMask, segmentationMask] = await Promise.all(promises);

      const selectedReport = state.reports.reports.find(
        (report) => String(report.id) === selectedReportId,
      );
      const selectedAnomaly = selectedReport?.anomalies?.find(
        (anomaly) => anomaly.id === state.anomalies.currentAnomalyId,
      );

      additionalSample.detections = addStatusToDetections(
        additionalSample.detections,
        selectedAnomaly?.detection_ids,
      );

      const response: ISample = {
        ...sample,
        ...additionalSample,
      };

      response.rgb_image = rgbImage;

      response.thermal_image = thermalImage;

      if (segmentationMask) response.segmentation_mask = segmentationMask;
      else delete response.segmentation_mask;

      if (anomalyMask) {
        const updatedAnomalyMask = await updateAnomalyMask(
          anomalyMask,
          getAnomalyMaskParams({ selectedAnomaly, selectedSample: additionalSample }),
        );
        response.anomaly_mask = updatedAnomalyMask;
      } else delete response.anomaly_mask;

      dispatch(updateSelectedSample(response));

      const doesNotExistAnomalyMask = !anomalyMask && !additionalSample.detections?.length;
      if (doesNotExistAnomalyMask) {
        throw new SampleError(
          errorsMap[EErrorStatus.MissingSampleDetections],
          EErrorStatus.MissingSampleDetections,
        );
      }
    } catch (err) {
      // TODO: will be handled in the future
      if (err instanceof SampleError) {
        console.info(err.message);
      } else {
        toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
      }
    } finally {
      // NOTE: remove image viewer scale and styles
      dispatch(resetImageViewerScale());
      dispatch(resetImageViewerStyles());

      dispatch(finishDataProcessing());
      dispatch(setPreloader(false));
    }
  },
);

const samplesSlice = createSlice({
  name: 'samples',
  initialState,
  reducers: {
    startDataProcessing: (state) => {
      state.loading = true;
    },
    finishDataProcessing: (state) => {
      state.loading = false;
    },
    updateAnomalySamples: (state, actions: PayloadAction<ISample[]>) => {
      state.anomalySamples = actions.payload;
    },
    updateSelectedSample: (state, actions: PayloadAction<ISample | null>) => {
      state.selectedSample = actions.payload;
    },
    resetAnomalySamples: (state) => {
      state.anomalySamples = [];
      state.selectedSample = null;
    },
    setCurrentReportSamples: (state, actions: PayloadAction<ISample[] | null>) => {
      state.currentReportSamples = actions.payload;
    },
    resetCurrentReportSamples: (state) => {
      state.currentReportSamples = [];
    },
    setReportToCompareSamples: (state, actions: PayloadAction<ISample[] | null>) => {
      state.reportToCompareSamples = actions.payload;
    },
    resetReportToCompareSamples: (state) => {
      state.reportToCompareSamples = [];
    },
  },
});

const samplesReducer = samplesSlice.reducer;

const {
  startDataProcessing,
  finishDataProcessing,
  updateAnomalySamples,
  updateSelectedSample,
  resetAnomalySamples,
  setCurrentReportSamples,
  resetCurrentReportSamples,
  setReportToCompareSamples,
  resetReportToCompareSamples,
} = samplesSlice.actions;

const useSamplesSelector = () => useSelectorTyped((state) => state.samples);

export {
  setAnomalySamples,
  setSelectedSample,
  resetAnomalySamples,
  setCurrentReportSamples,
  resetCurrentReportSamples,
  samplesReducer,
  useSamplesSelector,
  resetReportToCompareSamples,
  initialState as samplesInitialState,
};
