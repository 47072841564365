import {
  getAnomaliesByReportId,
  getReportEstimatedLoss,
  getReportAnomaliesEstimatedLoss,
} from './anomalies';
import { getReportByInspectionId } from './inspections';
import { getReportById } from './reports';
import {
  getSampleAnomalyMaskByReportId,
  getSampleSegmentationMaskByReportId,
  getSampleRgbImageByReportId,
  getSampleThermalImageByReportId,
  getSamplesByReportId,
  getSampleByReportId,
} from './samples';
import { getSolarPanelsByReportId, getSolarPanelsWithIncidentAnomalies } from './solarPanels';

export const reports = {
  getReportByInspectionId,
  getAnomaliesByReportId,
  getSampleAnomalyMaskByReportId,
  getSampleSegmentationMaskByReportId,
  getSampleRgbImageByReportId,
  getSampleThermalImageByReportId,
  getSamplesByReportId,
  getSampleByReportId,
  getSolarPanelsByReportId,
  getSolarPanelsWithIncidentAnomalies,
  getReportEstimatedLoss,
  getReportAnomaliesEstimatedLoss,
  getReportById,
};
