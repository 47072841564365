import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DEFAULT_POSITION,
  DEFAULT_SCALE,
  DEFAULT_THERMAL,
} from '@core/constants/viewers/imageViewer';
import { EImageViewerStyle } from '@core/enums/viewers';
import { useSelectorTyped } from '@core/hooks';
import {
  IImageViewerFlags,
  IImageViewerStyles,
  IImageViewerScale,
  PartialOfPartial,
} from '@core/interfaces/viewers/imageViewer';

interface IInitialState {
  scale: IImageViewerScale;
  flags: IImageViewerFlags;
  styles: IImageViewerStyles;
}

const initialState: IInitialState = {
  scale: {
    default: DEFAULT_SCALE,
    current: DEFAULT_SCALE,
  },
  flags: {
    segmentationMask: false,
    anomalyMask: false,
  },
  styles: {
    [EImageViewerStyle.Container]: {
      default: DEFAULT_POSITION,
      current: DEFAULT_POSITION,
    },
    [EImageViewerStyle.MiniFrame]: {
      default: DEFAULT_POSITION,
      current: DEFAULT_POSITION,
    },
    // NOTE: "default", "current" added for consistency
    [EImageViewerStyle.Thermal]: {
      default: DEFAULT_THERMAL,
      current: DEFAULT_THERMAL,
    },
  },
};

const imageViewerSlice = createSlice({
  name: 'imageViewer',
  initialState,
  reducers: {
    updateImageViewerScale: (state, action: PayloadAction<Partial<IImageViewerScale>>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.scale[key] = value;
      });
    },
    setDefaultImageViewerScale: (state) => {
      state.scale.current = state.scale.default;
    },
    resetImageViewerScale: (state) => {
      state.scale = initialState.scale;
    },
    updateImagesStyles: <T extends IImageViewerStyles>(
      state,
      action: PayloadAction<PartialOfPartial<T>>,
    ) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        // NOTE: Structure helper
        // key - enum EImageViewerStyle
        // value - { current?, default? }
        Object.entries(value).forEach(([stateKey, stateValue]) => {
          state.styles[key][stateKey] = stateValue;
        });
      });
    },
    setDefaultImageStyles: (state) => {
      state.styles.container.current = state.styles.container.default;
      state.styles.miniFrame.current = state.styles.miniFrame.default;
    },
    resetImageViewerStyles: (state) => {
      state.styles = initialState.styles;
    },
    editImageViewer: (state, action: PayloadAction<IImageViewerFlags>) => {
      const { segmentationMask, anomalyMask } = state.flags;

      state.flags.segmentationMask = action.payload.segmentationMask ?? segmentationMask;
      state.flags.anomalyMask = action.payload.anomalyMask ?? anomalyMask;
    },
    resetImageViewer: () => initialState,
  },
});

const imageViewerReducer = imageViewerSlice.reducer;

const {
  editImageViewer,
  resetImageViewer,
  updateImageViewerScale,
  resetImageViewerScale,
  updateImagesStyles,
  resetImageViewerStyles,
  setDefaultImageStyles,
  setDefaultImageViewerScale,
} = imageViewerSlice.actions;

const useImageViewerSelector = () => useSelectorTyped((state) => state.imageViewer);
const useImageViewerScaleSelector = () => useSelectorTyped((state) => state.imageViewer.scale);
const useImageViewerFlagsSelector = () => useSelectorTyped((state) => state.imageViewer.flags);
const useImageViewerStyleSelector = () => useSelectorTyped((state) => state.imageViewer.styles);

export {
  resetImageViewer,
  editImageViewer,
  resetImageViewerScale,
  updateImageViewerScale,
  updateImagesStyles,
  resetImageViewerStyles,
  setDefaultImageStyles,
  setDefaultImageViewerScale,
  imageViewerReducer,
  useImageViewerSelector,
  useImageViewerScaleSelector,
  useImageViewerFlagsSelector,
  useImageViewerStyleSelector,
  initialState as imageViewerInitialState,
};
