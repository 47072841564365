import { FC, memo } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import minusIcon from '@assets/icons/shared/tiny-minus.svg';
import plusIcon from '@assets/icons/shared/tiny-plus.svg';
import { EControlStepCounterAction } from '@core/enums';
import { Popover } from '@components/Popover';
import styles from './styles.scss';

interface IProps {
  value: string;
  title: string;
  classNames?: {
    popover?: string;
    value?: string;
  };
  style?: React.CSSProperties;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (counterAction: EControlStepCounterAction) => void;
}

export const StepperPopover: FC<IProps> = memo(
  ({ classNames, value, title, style, isOpen, onClose, onUpdate }) => {
    const handleDecreaseCounter = () => onUpdate(EControlStepCounterAction.Decrease);
    const handleIncreaseCounter = () => onUpdate(EControlStepCounterAction.Increase);

    return (
      <Popover
        className={cn(styles.popover, classNames?.popover)}
        title={title}
        style={style}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div className={styles.container}>
          <button className={styles.controllerBtn} onClick={handleDecreaseCounter}>
            <ReactSVG className={styles.controllerIcon} src={minusIcon} />
          </button>
          <div className={styles.valueWrapper}>
            <span className={cn(styles.value, classNames?.value)}>{value}</span>
          </div>
          <button className={styles.controllerBtn} onClick={handleIncreaseCounter}>
            <ReactSVG className={styles.controllerIcon} src={plusIcon} />
          </button>
        </div>
      </Popover>
    );
  },
);
