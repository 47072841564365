import { updateAnomaly } from './anomalies';
import { getComments, createComment, updateComment, deleteComment } from './comments';
import { getSamplesByAnomalyId } from './samples';

export const anomalies = {
  getSamplesByAnomalyId,
  getComments,
  createComment,
  updateComment,
  deleteComment,
  updateAnomaly,
};
