import { IReport } from '@core/interfaces/report';
import { isNumber } from '@core/utils';
import {
  getDisconnectedCostByReport,
  getThermalCostByReport,
  getVisualCostByReport,
} from '@modules/Sidebar/widgets/EstimatedLosses/utils/formulas/reportsCosts';
import { TransformedSites } from '../../interfaces/transformation';

enum EReportCosts {
  ThermalCost = 'thermalCost',
  VisualCost = 'visualCost',
  DisconnectedCost = 'disconnectedCost',
}

type ReportCostsResponse = {
  [key in EReportCosts]: number;
};

function getDefaultReportsCosts() {
  return Object.values(EReportCosts).reduce(
    (acc, costField) => ({ ...acc, [costField]: 0 }),
    {} as ReportCostsResponse,
  );
}
export function getReportsCosts(reports: IReport[], sites: TransformedSites): ReportCostsResponse {
  return reports.reduce<ReportCostsResponse>((acc, report) => {
    const {
      thermal_surface: thermalSurface,
      net_visual_surface: netVisualSurface,
      location_id: locationId,
      disconnected_panels: disconnectedPanels,
    } = report;

    const areEnabledCalculationFields = [
      netVisualSurface,
      thermalSurface,
      disconnectedPanels,
    ].every(isNumber);

    const selectedSite = locationId ? sites[locationId] : null;

    if (areEnabledCalculationFields && selectedSite) {
      const {
        thermal_factor: thermalFactor,
        rgb_factor: rgbFactor,
        cost_per_defective_panel: costPerDefectivePanel,
      } = selectedSite;

      if (rgbFactor && costPerDefectivePanel) {
        const visualCost = getVisualCostByReport({
          netVisualSurface: netVisualSurface as number,
          rgbFactor,
          costPerDefectivePanel,
        });

        acc[EReportCosts.VisualCost] += visualCost;
      }

      if (thermalFactor && costPerDefectivePanel) {
        const thermalCost = getThermalCostByReport({
          thermalSurface: thermalSurface as number,
          thermalFactor,
          costPerDefectivePanel,
        });

        acc[EReportCosts.ThermalCost] += thermalCost;
      }

      if (costPerDefectivePanel) {
        const disconnectedCost = getDisconnectedCostByReport({
          disconnectedPanels: disconnectedPanels as number,
          costPerDefectivePanel,
        });

        acc[EReportCosts.DisconnectedCost] += disconnectedCost;
      }
    }
    return acc;
  }, getDefaultReportsCosts());
}
