import { FC, memo } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import styles from './styles.scss';

type TooltipData = {
  key: string;
  value: number | string;
  color?: string;
};

type TooltipPosition = {
  x: number;
  y: number;
};

export interface ITooltipProps {
  position: TooltipPosition;
  data: TooltipData[];
}

export const ChartTooltip: FC<ITooltipProps> = memo(({ position: { x, y }, data }) => {
  return createPortal(
    <div className={cn(styles.tooltip)} style={{ left: x, top: y }}>
      {data.map(({ key, value, color }) => (
        <div key={key} className={styles[key]} style={color ? { color } : {}}>
          {value}
        </div>
      ))}
    </div>,
    document.body,
  );
});
