import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import cleanIcon from '@assets/icons/clean.svg';
import { ISolarPanelsWithIncidentAnomalies } from '@core/api/report/solarPanels';
import { IAnomaly } from '@core/interfaces/anomaly';
import { DIRTINESS_INDEX_STYLES_CONFIG } from '@modules/Sidebar/widgets/SolarPanels/constants';
import { getDirtinessIndex } from '@modules/Sidebar/widgets/SolarPanels/utils/getDirtinessIndex';
import { Shield } from '@components/Shield/Shield';
import styles from './styles.scss';

interface IProps {
  solarPanels: ISolarPanelsWithIncidentAnomalies[];
  anomalies?: IAnomaly[];
}

export const DirtinessIndex: FC<IProps> = memo(({ solarPanels, anomalies = [] }) => {
  const { t } = useTranslation();

  const dirtinessIndex = useMemo(
    () => getDirtinessIndex(solarPanels, anomalies),
    [solarPanels, anomalies],
  );

  const renderDirtinessIndex = useMemo(() => {
    let properties: React.CSSProperties = {};
    let content;

    for (const entry of DIRTINESS_INDEX_STYLES_CONFIG) {
      const [range, styles] = entry;
      const [min, max] = range;

      if (dirtinessIndex > min && dirtinessIndex <= max) {
        if (styles.content) {
          content = styles.content;
        } else {
          properties = styles;
        }
        break;
      }
    }

    return content ? (
      <div className={styles.dirtinessIndexValue}>
        <Shield
          shield={{ text: content, className: 'bg-outflier-green-transparent text-outflier-green' }}
        />
      </div>
    ) : (
      <div className={styles.dirtinessIndex}>
        <div style={properties} className={styles.progressBar} />
      </div>
    );
  }, [dirtinessIndex]);

  return (
    <div className={styles.root}>
      <div className={styles.label}>
        <ReactSVG className={styles.labelIcon} src={cleanIcon} />
        <span className={styles.labelTitle}>
          {t('sidebar.widgets.panels.dirtinessIndex.title')}
        </span>
      </div>
      {renderDirtinessIndex}
    </div>
  );
});
