import { createRef, ElementType, memo, useEffect, useRef } from 'react';
import { Disclosure } from '@headlessui/react';
import cn from 'classnames';
import chevronRight from '@assets/icons/chevron-right.svg';
import { isNumber } from '@core/utils';
import styles from './styles.scss';

type TOption = {
  id: string;
  disabled?: boolean;
  head: JSX.Element;
  body: JSX.Element;
};

type TArrowSettings = {
  position: 'after' | 'before';
  direction: 'right' | 'left' | 'top' | 'bottom';
  className?: string;
};

interface IProps {
  as?: ElementType;
  arrowSettings?: Partial<TArrowSettings>;
  className?: string;
  options: TOption[];
  currentStep?: number;
  stepToCollapse?: number | null;
  onStepChange?: (step: number) => void;
}

const Accordion: React.FC<IProps> = ({
  as = 'div',
  arrowSettings = {
    position: 'before',
    direction: 'right',
  },
  className,
  options,
  currentStep,
  stepToCollapse,
  onStepChange,
}) => {
  const refs = useRef(options.map(() => createRef<HTMLButtonElement>()));

  useEffect(() => {
    refs.current = options.map((_, i) => refs.current[i] ?? createRef<HTMLButtonElement>());
  }, [options]);

  useEffect(() => {
    if (isNumber(currentStep) && refs.current[currentStep] && refs.current[currentStep].current) {
      const isOpen = refs.current[currentStep]?.current?.getAttribute('data-open') === 'true';
      if (!isOpen) refs.current[currentStep]?.current?.click();
    }
  }, [currentStep, refs.current]);

  useEffect(() => {
    if (stepToCollapse && isNumber(currentStep)) {
      const isStepOpen =
        refs.current[stepToCollapse]?.current?.getAttribute('data-open') === 'true';
      isStepOpen && refs.current[stepToCollapse]?.current?.click();
      if (onStepChange) onStepChange(currentStep);
    }
  }, [stepToCollapse]);

  const handleClosingOthersPanels = (id: string, index: number) => {
    refs.current
      .filter((ref) => ref.current?.getAttribute('data-id') !== id)
      .forEach((ref) => {
        const isOpen = ref.current?.getAttribute('data-open') === 'true';
        isOpen && ref.current?.click();
      });

    if (onStepChange) onStepChange(index);
  };

  return (
    <div className='flex flex-col gap-[5px]'>
      {options.map(({ id, disabled, head, body }, idx) => (
        <Disclosure
          key={id}
          as={as}
          className={cn(styles.root, className)}
          defaultOpen={idx === currentStep}
        >
          {({ open }) => (
            <>
              <Disclosure.Button
                ref={refs.current[idx]}
                className={cn({ 'text-outflier-soft-gray': disabled }, styles.button)}
                disabled={disabled}
                data-id={id}
                data-open={open}
                onClick={() => handleClosingOthersPanels(id, idx)}
              >
                <img
                  className={cn(
                    styles.arrow,
                    styles[`arrow-${arrowSettings.position}`],
                    styles[`arrow-${arrowSettings.direction}`],
                    { [styles['arrow-bottom']]: open },
                    arrowSettings.className,
                  )}
                  src={chevronRight}
                  alt='arrow'
                />
                <div className={cn(styles.item, styles[`item-${arrowSettings.position}`])}>
                  {head}
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className={styles.body}>{body}</Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default memo(Accordion);
