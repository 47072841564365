import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { FeatureCollection, Polygon } from 'geojson';
import mapboxgl from 'mapbox-gl';
import noFlyZoneIcon from '@assets/images/noFlyZone.png';
import { ECreateSiteSteps } from '@core/constants/createSite';
import { ESidebar } from '@core/enums';
import { EGeneratedProgramPreview } from '@core/enums/sites/createSite';
import { IProgram } from '@core/interfaces';
import { INoFlyZone, IObstacle } from '@core/interfaces/sites/createSite';
import { AppDispatchType } from '@core/store';
import {
  setCurrentProgramId,
  updateCurrentNoFlyZoneIndexById,
  updateCurrentObstacleIndexById,
  updateProgramById,
} from '@core/store/slices';
import { removeLayersAndSources } from '@modules/Viewers/views/MapViewer/utils/removeLayersAndSources';
import {
  getGeneratedProgramsFeatureCollection,
  getNoFlyZonesFeatureCollection,
  getObstaclesFeatureCollection,
  getPerimeterDisplayFeatureCollection,
  getPerimeterFeatureCollection,
} from './getFeatureCollections';
import {
  getIconPointsFeatures,
  getInspectionPlanLineStringFeature,
  getOverviewPlanLineStringFeature,
} from './getFeatures';
import { layersStyles } from './mapboxConfig';
import { ECreateSiteLayers, EMapImages } from '../../../enums/layers';
import { ECreateSiteSources } from '../../../enums/sources';
import { updateLayerOrdering } from '../obstacles/updateLayerOrdering';

interface IProps {
  dispatch: AppDispatchType;
  createSiteCurrentStep: ECreateSiteSteps | null | undefined;
  map: mapboxgl.Map | null;
  perimeter?: FeatureCollection | Polygon | null;
  obstacles?: IObstacle[];
  noFlyZones?: INoFlyZone[];
  sidebar: ESidebar;
  generatedPrograms: IProgram[];
  currentGeneratedProgram?: IProgram;
  currentPlanIndex?: number | null;
  currentOverviewIndex?: number | null;
  programPreview?: EGeneratedProgramPreview;
}

let draw: MapboxDraw | null = null;

export const addSiteCreation = (props: IProps) => {
  const {
    currentGeneratedProgram,
    generatedPrograms,
    dispatch,
    createSiteCurrentStep,
    map,
    sidebar,
    perimeter,
    noFlyZones,
    obstacles,
    currentPlanIndex,
    currentOverviewIndex,
    programPreview,
  } = props;

  if (!map) return;

  if (sidebar !== ESidebar.CreateSite) {
    removeLayersAndSources(map, {
      [ECreateSiteSources.Obstacles]: [ECreateSiteLayers.ObstaclesLine],
      [ECreateSiteSources.Perimeter]: [ECreateSiteLayers.Perimeter],
      [ECreateSiteSources.NoFlyZones]: [
        ECreateSiteLayers.NoFlyZonesBorder,
        ECreateSiteLayers.NoFlyZonesFill,
      ],
    });

    if (draw) {
      map.removeControl(draw);
      draw = null;
    }

    return;
  }

  const obstaclesFeatureCollection = getObstaclesFeatureCollection(obstacles);
  const noFlyZonesFeatureCollection = getNoFlyZonesFeatureCollection(noFlyZones);
  const iconPointsFeatures = getIconPointsFeatures(noFlyZonesFeatureCollection);
  const iconPointsFeatureCollection = turf.featureCollection(iconPointsFeatures);
  const perimeterFeatureCollection = getPerimeterFeatureCollection(perimeter);
  const generatedProgramsFeatureCollection =
    getGeneratedProgramsFeatureCollection(generatedPrograms);

  map.loadImage(noFlyZoneIcon, (error, image) => {
    if (error) throw error;
    if (!map.hasImage(EMapImages.NoFlyZoneIconId) && image) {
      map.addImage(EMapImages.NoFlyZoneIconId, image);
    }
  });

  if (currentGeneratedProgram) {
    // 1. Display display_perimeter as Polygon
    const perimeterDisplayFeatureCollection =
      getPerimeterDisplayFeatureCollection(currentGeneratedProgram);

    if (!map.getSource(ECreateSiteSources.PerimeterDisplay)) {
      map.addSource(ECreateSiteSources.PerimeterDisplay, {
        type: 'geojson',
        data: perimeterDisplayFeatureCollection,
      });
      map.addLayer(layersStyles[ECreateSiteLayers.PerimeterDisplay]());
    } else {
      (map.getSource(ECreateSiteSources.PerimeterDisplay) as mapboxgl.GeoJSONSource).setData(
        perimeterDisplayFeatureCollection,
      );
    }

    switch (programPreview) {
      case EGeneratedProgramPreview.Overview: {
        const overviewPlanLineStringFeature = getOverviewPlanLineStringFeature(
          currentOverviewIndex,
          currentGeneratedProgram,
        );

        if (overviewPlanLineStringFeature) {
          if (!map.getSource(ECreateSiteSources.OverviewPlan)) {
            map.addSource(ECreateSiteSources.OverviewPlan, {
              type: 'geojson',
              data: overviewPlanLineStringFeature,
            });
            map.addLayer(layersStyles[ECreateSiteLayers.OverviewPlan]());
          } else {
            (map.getSource(ECreateSiteSources.OverviewPlan) as mapboxgl.GeoJSONSource).setData(
              overviewPlanLineStringFeature,
            );
          }
        }

        removeLayersAndSources(map, {
          [ECreateSiteSources.InspectionPlan]: [ECreateSiteLayers.InspectionPlan],
        });
        break;
      }
      case EGeneratedProgramPreview.Inspection: {
        const inspectionPlanLineStringFeature = getInspectionPlanLineStringFeature(
          currentPlanIndex,
          currentGeneratedProgram,
        );

        if (inspectionPlanLineStringFeature) {
          if (!map.getSource(ECreateSiteSources.InspectionPlan)) {
            map.addSource(ECreateSiteSources.InspectionPlan, {
              type: 'geojson',
              data: inspectionPlanLineStringFeature,
            });
            map.addLayer(layersStyles[ECreateSiteLayers.InspectionPlan]());
          } else {
            (map.getSource(ECreateSiteSources.InspectionPlan) as mapboxgl.GeoJSONSource).setData(
              inspectionPlanLineStringFeature,
            );
          }
        }

        removeLayersAndSources(map, {
          [ECreateSiteSources.OverviewPlan]: [ECreateSiteLayers.OverviewPlan],
        });
        break;
      }
    }
  } else {
    removeLayersAndSources(map, {
      [ECreateSiteSources.PerimeterDisplay]: [ECreateSiteLayers.PerimeterDisplay],
      [ECreateSiteSources.OverviewPlan]: [ECreateSiteLayers.OverviewPlan],
      [ECreateSiteSources.InspectionPlan]: [ECreateSiteLayers.InspectionPlan],
    });
  }

  // Handle generatedPrograms
  if (
    createSiteCurrentStep === ECreateSiteSteps.Zones &&
    !currentGeneratedProgram &&
    generatedProgramsFeatureCollection.features.length > 0
  ) {
    if (!map.getSource(ECreateSiteSources.GeneratedPrograms)) {
      map.addSource(ECreateSiteSources.GeneratedPrograms, {
        type: 'geojson',
        data: generatedProgramsFeatureCollection,
      });

      map.addLayer(layersStyles[ECreateSiteLayers.GeneratedProgramsLine]());
      map.addLayer(layersStyles[ECreateSiteLayers.GeneratedProgramsFill]());
      map.addLayer(layersStyles[ECreateSiteLayers.GeneratedProgramsText]());
    } else {
      (map.getSource(ECreateSiteSources.GeneratedPrograms) as mapboxgl.GeoJSONSource).setData(
        generatedProgramsFeatureCollection,
      );
    }
  } else {
    removeLayersAndSources(map, {
      [ECreateSiteSources.GeneratedPrograms]: [
        ECreateSiteLayers.GeneratedProgramsLine,
        ECreateSiteLayers.GeneratedProgramsFill,
        ECreateSiteLayers.GeneratedProgramsText,
      ],
    });
  }

  // Add a click event listener to the map
  if (map.getLayer(ECreateSiteLayers.GeneratedProgramsFill)) {
    map.on('click', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [ECreateSiteLayers.GeneratedProgramsFill],
      });

      if (features.length) {
        const clickedFeature = features[0];
        const id = clickedFeature.properties?.id;
        if (!id) return;
        dispatch(updateProgramById({ id, changes: { isViewed: true } }));
        dispatch(setCurrentProgramId(id));
      }
    });
  }

  // Handle perimeter
  if (
    createSiteCurrentStep !== ECreateSiteSteps.Zones &&
    perimeterFeatureCollection.features.length
  ) {
    if (!map.getSource(ECreateSiteSources.Perimeter)) {
      map.addSource(ECreateSiteSources.Perimeter, {
        type: 'geojson',
        data: perimeterFeatureCollection,
      });
      map.addLayer(layersStyles[ECreateSiteLayers.Perimeter]());
    } else {
      (map.getSource(ECreateSiteSources.Perimeter) as mapboxgl.GeoJSONSource).setData(
        perimeterFeatureCollection,
      );
    }
  } else {
    removeLayersAndSources(map, {
      [ECreateSiteSources.Perimeter]: [ECreateSiteLayers.Perimeter],
    });
  }

  // Handle noFlyZones
  if (noFlyZonesFeatureCollection.features.length) {
    if (!map.getSource(ECreateSiteSources.NoFlyZones)) {
      map.addSource(ECreateSiteSources.NoFlyZones, {
        type: 'geojson',
        data: noFlyZonesFeatureCollection,
      });
      map.addLayer(layersStyles[ECreateSiteLayers.NoFlyZonesFill]());
      map.addLayer(layersStyles[ECreateSiteLayers.NoFlyZonesBorder]());

      map.on('click', ECreateSiteLayers.NoFlyZonesFill, (e) => {
        const noFlyZoneId = e.features?.[0]?.properties?.id;
        if (!noFlyZoneId) return;
        dispatch(updateCurrentNoFlyZoneIndexById(noFlyZoneId));
      });
    } else {
      (map.getSource(ECreateSiteSources.NoFlyZones) as mapboxgl.GeoJSONSource).setData(
        noFlyZonesFeatureCollection,
      );

      updateLayerOrdering(map, {
        patterns: {
          beforeId: new RegExp(`^${ECreateSiteLayers.Perimeter}`),
          afterIds: [
            new RegExp(`^${ECreateSiteLayers.NoFlyZonesBorder}`),
            new RegExp(`^${ECreateSiteLayers.NoFlyZonesFill}`),
          ],
        },
      });
    }
  } else {
    removeLayersAndSources(map, {
      [ECreateSiteSources.NoFlyZones]: [
        ECreateSiteLayers.NoFlyZonesBorder,
        ECreateSiteLayers.NoFlyZonesFill,
      ],
    });
  }

  // Assuming you have updated iconPointsFeatureCollection whenever zones data changes
  if (noFlyZonesFeatureCollection.features.length) {
    if (!map.getSource(ECreateSiteSources.NoFlyZoneIcons)) {
      map.addSource(ECreateSiteSources.NoFlyZoneIcons, {
        type: 'geojson',
        data: iconPointsFeatureCollection,
      });
      map.addLayer(layersStyles[ECreateSiteLayers.NoFlyZoneIcons]());
    } else {
      (map.getSource(ECreateSiteSources.NoFlyZoneIcons) as mapboxgl.GeoJSONSource).setData(
        iconPointsFeatureCollection,
      );

      updateLayerOrdering(map, {
        patterns: {
          beforeId: new RegExp(`^${ECreateSiteLayers.Perimeter}`),
          afterIds: [new RegExp(`^${ECreateSiteLayers.NoFlyZoneIcons}`)],
        },
      });
    }
  } else {
    // Optionally, remove the layer and source if there are no features
    removeLayersAndSources(map, {
      [ECreateSiteSources.NoFlyZoneIcons]: [ECreateSiteLayers.NoFlyZoneIcons],
    });
  }

  // Handle obstacles
  if (obstaclesFeatureCollection.features.length) {
    if (!map.getSource(ECreateSiteSources.Obstacles)) {
      map.addSource(ECreateSiteSources.Obstacles, {
        type: 'geojson',
        data: obstaclesFeatureCollection,
      });
      map.addLayer(layersStyles[ECreateSiteLayers.ObstaclesBuffer]());
      map.addLayer(layersStyles[ECreateSiteLayers.ObstaclesLine]());

      map.on('click', ECreateSiteLayers.ObstaclesBuffer, (e) => {
        const obstacleId = e.features?.[0]?.properties?.id;
        if (!obstacleId) return;
        dispatch(updateCurrentObstacleIndexById(obstacleId));
      });
    } else {
      (map.getSource(ECreateSiteSources.Obstacles) as mapboxgl.GeoJSONSource).setData(
        obstaclesFeatureCollection,
      );

      updateLayerOrdering(map, {
        patterns: {
          beforeId: new RegExp(`^${ECreateSiteLayers.Perimeter}`),
          afterIds: [
            new RegExp(`^${ECreateSiteLayers.ObstaclesLine}`),
            new RegExp(`^${ECreateSiteLayers.ObstaclesBuffer}`),
          ],
        },
      });
    }
  } else {
    removeLayersAndSources(map, {
      [ECreateSiteSources.Obstacles]: [
        ECreateSiteLayers.ObstaclesLine,
        ECreateSiteLayers.ObstaclesBuffer,
      ],
    });
  }
};
