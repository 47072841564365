import React, {
  createContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
  useRef,
  useContext,
  MutableRefObject,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { api } from '@core/api';
import { IUsersMeResponse } from '@core/api/user/users';
import { AUTO_LOGOUT_TIMEOUT, ERROR_TOAST_DELAY } from '@core/constants';
import { useAutoLogout, useDispatchTyped } from '@core/hooks';
import { useFetchUser } from '@core/hooks/useFetchUser';
import { useUpdateTenant } from '@core/hooks/useUpdateTenant';
import { ROUTE_PATHS } from '@core/router/routerConfig';
import { logoutUser, useAccessControlSelector } from '@core/store/slices';
import { authStorage } from '@core/utils';

interface IAuthContextProps {
  children: ReactNode;
}

interface IAuthContextValue {
  isAuthenticated: boolean;
  tenantRef: MutableRefObject<string | null>;
  onLogin: (user: IUsersMeResponse) => void;
  onLogout: () => void;
}

const AuthContext = createContext<IAuthContextValue | undefined>(undefined);

export const AuthProvider: React.FC<IAuthContextProps> = ({ children }) => {
  const { user } = useAccessControlSelector();
  const [isAuthenticated, setIsAuthenticated] = useState(
    Boolean(authStorage.tokens.access_token.get()),
  );
  const selectedTenantRef = useRef<string | null>(authStorage.tenant.get());

  const navigate = useNavigate();
  const dispatch = useDispatchTyped();

  const handleLogin = useCallback(async (user: IUsersMeResponse) => {
    if (user.tenants.length === 1) {
      const [tenant] = user.tenants;

      try {
        const refresh_token = authStorage.tokens.refresh_token.get();

        if (refresh_token) {
          const response = await api.user.addTenantToToken({ tenant, refresh_token });

          Object.entries(response).forEach(([key, value]) => {
            if (key in authStorage.tokens) {
              authStorage.tokens[key].set(value);
            }
          });

          authStorage.tenant.set(tenant);
          selectedTenantRef.current = tenant;
          navigate(ROUTE_PATHS.Root);
        }
      } catch {
        toast.error(t('errors.somethingIsWrong'), { autoClose: ERROR_TOAST_DELAY });
      }
    } else {
      navigate(ROUTE_PATHS.Login.SelectOrganization);
    }

    setIsAuthenticated(true);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logoutUser());
    setIsAuthenticated(false);
    navigate(ROUTE_PATHS.Login.Root);
  }, []);

  const handleAuthenticated = useCallback((isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
  }, []);

  const contextValue: IAuthContextValue = useMemo(
    () => ({
      isAuthenticated,
      tenantRef: selectedTenantRef,
      onLogin: handleLogin,
      onLogout: handleLogout,
    }),
    [isAuthenticated, selectedTenantRef.current, handleLogin, handleLogout],
  );

  useAutoLogout({ timeoutInMinutes: AUTO_LOGOUT_TIMEOUT, onAuth: handleAuthenticated });
  useUpdateTenant(selectedTenantRef);
  useFetchUser({ isAuthenticated, userData: user.data });

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthContextValue => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
