import { anomaliesChartsColor } from '@core/constants';
import {
  EAnomalyMode,
  EAnomalyRepairmentStatus,
  EAnomalyStatisticCategory,
  EAnomalyStatus,
} from '@core/enums/anomalies';
import { IAnomaly } from '@core/interfaces/anomaly';

type DefaultValue = EAnomalyStatus | EAnomalyRepairmentStatus | EAnomalyMode;
type DefaultAggregatedCollection = { [key in DefaultValue]: number };

interface IParams {
  anomalies: IAnomaly[];
  anomalyAggregatedKey: string;
  anomalyChartKey: EAnomalyStatisticCategory;
  defaultValue: DefaultValue;
}

export interface IStyleDataResponse {
  key: string;
  color: string;
  value: number;
}

const getDefaultValue = (defaultValue: DefaultValue) => {
  return Object.keys(defaultValue).reduce(
    (acc, key) => ({ ...acc, [key]: 0 }),
    {} as DefaultAggregatedCollection,
  );
};

export const getStylesData = ({
  anomalies,
  anomalyAggregatedKey,
  anomalyChartKey,
  defaultValue,
}: IParams): IStyleDataResponse[] => {
  const aggregatedAnomaliesByStatus = anomalies.reduce((acc, anomaly) => {
    return { ...acc, [anomaly[anomalyAggregatedKey]]: acc[anomaly[anomalyAggregatedKey]] + 1 };
  }, getDefaultValue(defaultValue));

  const stylesData = Object.entries(aggregatedAnomaliesByStatus).map(([key, value]) => ({
    key: key.toLowerCase(),
    color: anomaliesChartsColor[anomalyChartKey][key],
    value: value,
  }));

  return stylesData;
};
