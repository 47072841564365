import { FC, memo, useMemo } from 'react';
import { EAnomalyRepairmentStatus } from '@core/enums/anomalies';
import { ISite } from '@core/interfaces';
import { IAnomaly } from '@core/interfaces/anomaly';
import { IReport } from '@core/interfaces/report';
import { stackedBarChartStylesData } from '@modules/Sidebar/widgets/EstimatedLosses/config';
import {
  getAnomaliesCostsByModes,
  getReportsCostsByModes,
  prepareAnomaliesChartData,
  prepareReportChartData,
} from '@modules/Sidebar/widgets/EstimatedLosses/utils';
import { StackedBarChart } from './StackedChart';

interface IProps {
  visibleReports: IReport[];
  visibleAnomalies: IAnomaly[];
  totalCostByReports: number;
  totalCostToRepairByReportsAnomalies: number;
  separatedAnomaliesByRepairmentStatus: {
    [key in EAnomalyRepairmentStatus]: IAnomaly[];
  };
  transformedSitesSidebar: {
    [key: string]: ISite;
  };
  costRatio: string;
}

export const EstimatedLossesChart: FC<IProps> = memo(
  ({
    visibleReports,
    visibleAnomalies,
    totalCostByReports,
    totalCostToRepairByReportsAnomalies,
    separatedAnomaliesByRepairmentStatus,
    transformedSitesSidebar,
    costRatio,
  }) => {
    const reportCostsByModes = useMemo(
      () => getReportsCostsByModes(visibleReports, transformedSitesSidebar),
      [visibleReports, transformedSitesSidebar],
    );

    const anomalyCostsByModes = useMemo(
      () => getAnomaliesCostsByModes(separatedAnomaliesByRepairmentStatus, transformedSitesSidebar),
      [separatedAnomaliesByRepairmentStatus, transformedSitesSidebar],
    );

    const chartData = useMemo(
      () => [
        prepareReportChartData({
          reportCostsByModes,
          totalCostByReports,
          costRatio,
        }),
        prepareAnomaliesChartData({
          anomalyCostsByModes,
          visibleAnomalies,
          separatedAnomaliesByRepairmentStatus,
          totalCostToRepairByReportsAnomalies,
          costRatio,
        }),
      ],
      [
        visibleAnomalies,
        totalCostByReports,
        totalCostToRepairByReportsAnomalies,
        separatedAnomaliesByRepairmentStatus,
        reportCostsByModes,
        anomalyCostsByModes,
        costRatio,
      ],
    );

    return <StackedBarChart chartData={chartData} stylesData={stackedBarChartStylesData} />;
  },
);
