import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ESidebar } from '@core/enums';
import { ECurrency } from '@core/enums/shared';
import { useSidebarSelector } from '@core/store/slices';
import { EstimatedLossesContext } from './contexts';
import styles from './styles.scss';
import { formatMoneyPerYear } from './utils/formatting';
import { AnomalyView } from './views/Anomaly/Anomaly';
import { SiteView } from './views/Site/Site';
import { SitesView } from './views/Sites/Sites';
import { ZoneView } from './views/Zone/Zone';

interface IProps {
  className?: string;
  currency?: ECurrency;
  scrollTop?: number;
}

const views = {
  [ESidebar.Sites]: () => <SitesView />,
  [ESidebar.Site]: () => <SiteView />,
  [ESidebar.Zone]: () => <ZoneView />,
  [ESidebar.Anomaly]: () => <AnomalyView />,
};

export const EstimatedLosses: FC<IProps> = memo(
  ({ className, scrollTop, currency = ECurrency.Euro }) => {
    const { t } = useTranslation();
    const { sidebar } = useSidebarSelector();

    const formattedMoneyPerYear = formatMoneyPerYear(currency);

    const contextValue = useMemo(
      () => ({
        formattedMoneyPerYear,
        scrollTop,
      }),
      [formattedMoneyPerYear, scrollTop],
    );

    return (
      <div className={cn(styles.root, className)}>
        <h2 className={styles.title}>{t('sidebar.widgets.estimatedLosses.title')}</h2>
        <EstimatedLossesContext.Provider value={contextValue}>
          {views[sidebar]()}
        </EstimatedLossesContext.Provider>
      </div>
    );
  },
);
