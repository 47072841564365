import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Preloader } from '@components/Preloader';
import { Header } from './components/Header';
import styles from './styles.scss';

export const Layout = () => {
  return (
    <div className={styles.layout}>
      <Header />
      <Suspense fallback={<Preloader />}>
        <Outlet />
      </Suspense>
    </div>
  );
};
