type TVisualCostParams = {
  netVisualSurface: number;
  rgbFactor: number;
  costPerDefectivePanel: number;
};

type TThermalCostParams = {
  thermalSurface: number;
  thermalFactor: number;
  costPerDefectivePanel: number;
};

type TDisconnectedCostParams = {
  disconnectedPanels: number;
  costPerDefectivePanel: number;
};

export const getVisualCostByReport = ({
  netVisualSurface,
  rgbFactor,
  costPerDefectivePanel,
}: TVisualCostParams) => netVisualSurface * rgbFactor * costPerDefectivePanel;

export const getThermalCostByReport = ({
  thermalSurface,
  thermalFactor,
  costPerDefectivePanel,
}: TThermalCostParams) => thermalSurface * thermalFactor * costPerDefectivePanel;

export const getDisconnectedCostByReport = ({
  disconnectedPanels,
  costPerDefectivePanel,
}: TDisconnectedCostParams) => disconnectedPanels * costPerDefectivePanel;
