// TODO:

/* eslint-disable @typescript-eslint/ban-types */
import { toast } from 'react-toastify';
import { RootState } from '..';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { api } from '@core/api';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { ESidebar } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';
import { IInspection } from '@core/interfaces';
import { IReport } from '@core/interfaces/report';
import { updateStatuses } from '@core/store/slices/sites';
import { isNumber } from '@core/utils';
import { sortByDate } from '@core/utils/sorting/sortByDate';
import { setCurrentReportId, setReportToCompareId } from './reports';
import {
  resetReportToCompareSamples,
  setCurrentReportSamplesByReportId,
  setReportToCompareSamplesByReportId,
} from './samples';
import { awaitStateUpdates } from '../utils/common/awaitStateUpdates';

interface IInitialState {
  inspections: IInspection[];
  loading: boolean;
  currentInspectionId: string | null;
  inspectionToCompareId: string | null;
}

const initialState: IInitialState = {
  inspections: [],
  loading: false,
  currentInspectionId: null,
  inspectionToCompareId: null,
};

export const fetchInspections = createAsyncThunk<void, void, {}>(
  'inspections/fetchInspections',
  async (_, { dispatch, getState }) => {
    try {
      dispatch(startDataProcessing());
      const allInspections = await api.inspection.getInspections();

      dispatch(addInspections(allInspections));
    } catch {
      toast.error(t('errors.getInspectionsFail'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(finishDataProcessing());
      await awaitStateUpdates(getState, ['sites']);
      dispatch(updateStatuses());
    }
  },
);

export const setCurrentInspectionIdBySiteId = createAsyncThunk<
  void,
  {
    siteId: string | null;
    options?: {
      withReport?: boolean;
    };
  },
  {}
>(
  'inspections/setCurrentInspectionIdBySiteId',
  async ({ siteId, options }, { getState, dispatch }) => {
    const state = getState() as RootState;

    let filteredInspectionsByLocationWithAnomalies = state.inspections.inspections.filter(
      (inspection: IInspection) => isNumber(inspection.nb_anomalies),
    );

    if (siteId) {
      filteredInspectionsByLocationWithAnomalies =
        filteredInspectionsByLocationWithAnomalies.filter(
          (inspection: IInspection) => inspection.location_id === siteId,
        );
    }
    const sortedInspections = sortByDate<IInspection>(filteredInspectionsByLocationWithAnomalies);
    const currentInspectionId = sortedInspections.at(-1)?.id ?? null;

    if (currentInspectionId) {
      dispatch(updateCurrentInspectionId(currentInspectionId));
    }

    if (options?.withReport) {
      const inspectionReport = state.reports.reports.find(
        (report) => report.inspection_id === currentInspectionId,
      );

      if (inspectionReport?.id) {
        const reportId = String(inspectionReport.id);
        dispatch(setCurrentReportId(reportId));
      }
    }
  },
);

export const setCurrentInspectionIdByProgramId = createAsyncThunk<
  void,
  {
    programId: string;
    options?: {
      withReport?: boolean;
      withReportSamples?: boolean;
    };
  },
  {}
>(
  'inspections/setCurrentInspectionIdByProgramId',
  ({ programId, options }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const inspectionsByProgramId = state.inspections.inspections
      .filter((inspection) => inspection.program_id === programId)
      .filter((inspection) => isNumber(inspection.nb_anomalies));

    const sortedInspections = sortByDate<IInspection>(inspectionsByProgramId);
    const currentInspectionId = sortedInspections.at(-1)?.id ?? null;

    if (currentInspectionId) {
      dispatch(updateCurrentInspectionId(currentInspectionId));
    }

    if (options?.withReport) {
      const inspectionReport = state.reports.reports.find(
        (report) => report.inspection_id === currentInspectionId,
      );

      if (inspectionReport?.id) {
        const reportId = String(inspectionReport.id);

        dispatch(setCurrentReportId(reportId));

        if (options.withReportSamples) {
          dispatch(setCurrentReportSamplesByReportId(reportId));
        }
      }
    }
  },
);

export const setInspectionAndReportToCompareIds = createAsyncThunk<void, string | null, {}>(
  'inspections/setInspectionAndReportToCompareIds',
  (inspectionId, { dispatch, getState }) => {
    const state = getState() as RootState;

    dispatch(updateInspectionToCompareId(inspectionId));

    if (inspectionId) {
      const reports: IReport[] = state.reports.reports;
      const reportToCompare: IReport | undefined = reports.find(
        (report) => report.inspection_id === inspectionId,
      );
      if (reportToCompare?.id) {
        dispatch(setReportToCompareId(String(reportToCompare.id)));
        dispatch(setReportToCompareSamplesByReportId(String(reportToCompare.id)));
      } else {
        dispatch(setReportToCompareId(null));
      }
    } else {
      dispatch(setReportToCompareId(null));
      dispatch(resetReportToCompareSamples());
    }
  },
);

const inspectionsSlice = createSlice({
  name: 'inspections',
  initialState,
  reducers: {
    startDataProcessing: (state) => {
      state.loading = true;
    },
    finishDataProcessing: (state) => {
      state.loading = false;
    },
    addInspections: (state, actions: PayloadAction<IInspection[]>) => {
      const sortedInspections = sortByDate(actions.payload);
      state.currentInspectionId = sortedInspections.at(-1)?.id ?? null;
      state.inspections = sortedInspections;
    },
    updateCurrentInspectionId: (state, action: PayloadAction<string>) => {
      state.currentInspectionId = action.payload;
    },
    updateInspectionToCompareId: (state, action: PayloadAction<string | null>) => {
      state.inspectionToCompareId = action.payload;
    },
    resetInspections: () => initialState,
  },
});

const inspectionsReducer = inspectionsSlice.reducer;

const {
  startDataProcessing,
  finishDataProcessing,
  addInspections,
  updateCurrentInspectionId,
  updateInspectionToCompareId,
  resetInspections,
} = inspectionsSlice.actions;

const useInspectionsSelector = () => useSelectorTyped((state) => state.inspections);

const useVisibleInspectionsSelector = () =>
  useSelectorTyped((state) => {
    let visibleSitesIds: string[] = [];

    if (state.sites.currentSiteId && [ESidebar.Site].includes(state.sidebar.sidebar)) {
      visibleSitesIds = [state.sites.currentSiteId];
    }

    if (state.sidebar.sidebar === ESidebar.Sites) {
      visibleSitesIds = state.sites.sitesSidebar.map((site) => site.loc_id);
    }

    return state.inspections.inspections.filter((inspection: IInspection) => {
      if (visibleSitesIds.length) {
        return visibleSitesIds.includes(inspection.location_id ?? '');
      } else {
        return state.programs.currentProgramId === inspection.program_id;
      }
    });
  });

const useDropdownVisibleInspectionsSelector = () =>
  useSelectorTyped((state) => {
    const isCompareMode = state.controls.isCompareMode;
    const visibleInspections = useVisibleInspectionsSelector();

    if (!isCompareMode) {
      return visibleInspections;
    } else {
      return visibleInspections.length <= 1 ? visibleInspections : visibleInspections.splice(-1);
    }
  });

const useInspectionsToCompareSelector = () =>
  useSelectorTyped(() => {
    const visibleInspections = useVisibleInspectionsSelector();
    const currentInspectionId = useCurrentInspectionIdSelector();
    const currentInspectionIndex = visibleInspections.findIndex(
      (inspection) => inspection.id === currentInspectionId,
    );

    return visibleInspections.splice(0, currentInspectionIndex);
  });

const useInspectionToCompareSelector = () =>
  useSelectorTyped((state) => {
    const inspectionToCompareId = state.inspections.inspectionToCompareId;
    return (
      state.inspections.inspections.find((inspection) => inspection.id === inspectionToCompareId) ??
      null
    );
  });

const useCurrentInspectionIdSelector = () =>
  useSelectorTyped((state) => state.inspections.currentInspectionId);

const useCurrentInspectionSelector = () =>
  useSelectorTyped((state) => {
    const currentInspectionId = state.inspections.currentInspectionId;
    return (
      state.inspections.inspections.find((inspection) => inspection.id === currentInspectionId) ??
      null
    );
  });

const useInspectionsByProgramIdsSelector = (programIds: string[]) =>
  useSelectorTyped((state) => {
    return state.inspections.inspections.filter((inspection) =>
      programIds.includes(inspection.program_id),
    );
  });

const useInspectionByProgramIdSelector = (programId: string) => {
  const callback = ({ program_id }: IInspection) => program_id === programId;

  return useSelectorTyped(({ inspections }) => inspections.inspections.find(callback));
};

const useInspectionsByProgramIdSelector = (programId: string) => {
  return useSelectorTyped((state) => {
    return state.inspections.inspections.filter((inspection) => inspection.program_id == programId);
  });
};

export {
  inspectionsReducer,
  useInspectionsSelector,
  addInspections,
  updateCurrentInspectionId,
  resetInspections,
  updateInspectionToCompareId,
  useCurrentInspectionIdSelector,
  useVisibleInspectionsSelector,
  useCurrentInspectionSelector,
  useInspectionsByProgramIdsSelector,
  useInspectionsByProgramIdSelector,
  useInspectionByProgramIdSelector,
  useInspectionToCompareSelector,
  useDropdownVisibleInspectionsSelector,
  useInspectionsToCompareSelector,
  initialState as inspectionsInitialState,
};
