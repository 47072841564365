import { ISolarPanelsWithIncidentAnomalies } from '@core/api/report/solarPanels';
import { IAnomaly } from '@core/interfaces/anomaly';

export function getDirtinessIndex(
  solarPanels: ISolarPanelsWithIncidentAnomalies[],
  anomalies: IAnomaly[],
) {
  const transformedAnomalies = anomalies.reduce(
    (acc, anomaly) => ({ ...acc, [anomaly.id]: anomaly }),
    {},
  );

  const visualSurfaceInPercents = solarPanels.reduce((acc, solarPanel) => {
    if (solarPanel.incident_anomalies.length) {
      const aggregatedIncidentAnomaliesForSolarPanel = solarPanel.incident_anomalies.reduce(
        (acc, anomaly) => {
          const selectedAnomaly = transformedAnomalies[anomaly.id];

          if (selectedAnomaly.visual_surface) {
            acc += selectedAnomaly.visual_surface;
          }

          return acc;
        },
        0,
      );

      acc += aggregatedIncidentAnomaliesForSolarPanel;
    }
    return acc;
  }, 0);

  return solarPanels.length ? visualSurfaceInPercents / (100 * solarPanels.length) : 0;
}
