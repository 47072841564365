import { DEFAULT_SITE_NAME, ECreateSiteSteps } from '@core/constants/createSite';
import { ECreateSiteSections } from '@core/enums/sites/createSite';
import { ICreateSiteInitialState } from '@core/interfaces';

export const getMaxStepForContinueSiteCreation = (
  site: ICreateSiteInitialState,
  persistedStep?: number,
) => {
  let step = ECreateSiteSteps.Zones;

  if (
    !site[ECreateSiteSections.Zones].programs ||
    site[ECreateSiteSections.Zones].programs.length === 0
  ) {
    step = ECreateSiteSteps.Roles;
  }

  if (!site[ECreateSiteSections.InspectionFrequency].inspectionFrequency) {
    step = ECreateSiteSteps.Picture;
  }

  if (!site[ECreateSiteSections.Picture].image && !site[ECreateSiteSections.Picture].imageUrl) {
    if (persistedStep) {
      step = persistedStep;
    } else {
      if (site[ECreateSiteSections.Perimeter].data.length) {
        step = ECreateSiteSteps.Perimeter;
      }
      if (site[ECreateSiteSections.NoFlyZones].data.length) {
        step = ECreateSiteSteps.NoFlyZones;
      }
      if (site[ECreateSiteSections.Obstacles].data.length) {
        step = ECreateSiteSteps.Obstacles;
      }
    }
  }

  if (
    !site[ECreateSiteSections.Name].name ||
    site[ECreateSiteSections.Name].name === DEFAULT_SITE_NAME
  ) {
    step = ECreateSiteSteps.Name;
  }

  if (
    !site[ECreateSiteSections.Perimeter].data ||
    !site[ECreateSiteSections.Perimeter].data.length
  ) {
    step = ECreateSiteSteps.SearchCity;
  }

  if (site[ECreateSiteSections.Zones].isLoadingZones && step < ECreateSiteSteps.Picture) {
    step = ECreateSiteSteps.Picture;
  }

  if (site[ECreateSiteSections.Zones].programs.length) {
    step = ECreateSiteSteps.Zones;
  }

  return step;
};
