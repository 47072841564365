import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import plusIcon from '@assets/icons/plus.svg';
import { EDynamicZoomActions } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { INoFlyZone } from '@core/interfaces/sites/createSite';
import {
  addNoFlyZone,
  deleteNoFlyZone,
  increaseNoFlyZoneSequenceIndex,
  updateNoFlyZone,
  useCreateSiteNoFlyZones,
} from '@core/store/slices';
import { reloadMap, setDynamicZoom } from '@core/store/slices/map';
import AddInput from '@modules/Sidebar/components/AccordionView/BodyOptions/NoFlyZones/AddInput/AddInput';
import styles from './styles.scss';

interface IProps {}

const NoFlyZonesBody: FC<IProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const { data: noFlyZones } = useCreateSiteNoFlyZones();

  const lastNoFlyZone = noFlyZones[noFlyZones?.length - 1];

  const handleAddNoFlyZone = () => {
    dispatch(increaseNoFlyZoneSequenceIndex());
    dispatch(addNoFlyZone());
    dispatch(
      setDynamicZoom({
        action: EDynamicZoomActions.AddNoFlyZonesCreateSite,
        isAllowedZoom: false,
      }),
    );
    dispatch(reloadMap(true));
  };

  const handleChangeNoFlyZone = (noFlyZone: INoFlyZone) => {
    dispatch(updateNoFlyZone(noFlyZone));
  };

  const handleDelete = (noFlyZone: INoFlyZone) => {
    dispatch(deleteNoFlyZone(noFlyZone));
    dispatch(reloadMap(true));
  };

  return (
    <div className={styles.body}>
      <h2 className={styles.notification}>
        {t('sidebar.site.edit.sections.noFlyZones.notification')}
      </h2>
      <div className={styles.wrapper}>
        <ul className='flex flex-col gap-[10px] mb-[10px]'>
          {noFlyZones?.map((noFlyZone) => (
            <AddInput
              key={noFlyZone.id}
              value={noFlyZone}
              onChange={handleChangeNoFlyZone}
              onDelete={handleDelete}
            />
          ))}
        </ul>
        <button
          disabled={lastNoFlyZone?.perimeter?.coordinates?.length === 0}
          className={styles.addBtn}
          onClick={handleAddNoFlyZone}
        >
          <div className={styles.addBtnIcon}>
            <img className={styles.addIcon} src={plusIcon} alt='add' />
          </div>
          <span className={styles.btnText}>
            {t('sidebar.site.edit.sections.noFlyZones.buttons.addNoFlyZone')}
          </span>
        </button>
      </div>
    </div>
  );
};

export default memo(NoFlyZonesBody);
