import React from 'react';
import cn from 'classnames';
import styles from './styles.scss';

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

export const TableBodyCell: React.FC<IProps> = ({ className, children }) => {
  return <td className={cn(styles.tableBodyCell, className)}>{children}</td>;
};
