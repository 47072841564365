type TVisualCostReportAnomalyParams = {
  visualSurface: number;
  rgbFactor: number;
  costPerDefectivePanel: number;
};

type TThermalCostReportAnomalyParams = {
  thermalSurface: number;
  thermalFactor: number;
  costPerDefectivePanel: number;
};

type TDisconnectedCostParams = {
  disconnectedPanels: number;
  costPerDefectivePanel: number;
};

type TMixedCostAnomalyParams = {
  visualSurface: number;
  thermalSurface: number;
  rgbFactor: number;
  thermalFactor: number;
  costPerDefectivePanel: number;
};

export const getVisualCostByReportAnomaly = ({
  visualSurface,
  rgbFactor,
  costPerDefectivePanel,
}: TVisualCostReportAnomalyParams) => visualSurface * rgbFactor * costPerDefectivePanel;

export const getDisconnectedCostByReportAnomaly = ({
  disconnectedPanels,
  costPerDefectivePanel,
}: TDisconnectedCostParams) => disconnectedPanels * costPerDefectivePanel;

export const getThermalCostByReportAnomaly = ({
  thermalSurface,
  thermalFactor,
  costPerDefectivePanel,
}: TThermalCostReportAnomalyParams) => thermalSurface * thermalFactor * costPerDefectivePanel;

export const getMixedCostByReportAnomaly = ({
  visualSurface,
  thermalSurface,
  rgbFactor,
  thermalFactor,
  costPerDefectivePanel,
}: TMixedCostAnomalyParams) => {
  let diffSurface = visualSurface - thermalSurface;
  diffSurface = diffSurface < 0 ? 0 : diffSurface;

  return (
    diffSurface * rgbFactor * costPerDefectivePanel +
    thermalSurface * thermalFactor * costPerDefectivePanel
  );
};

export const getVisualCostByReportAnomalyFromMixed = ({
  visualSurface,
  thermalSurface,
  rgbFactor,
  costPerDefectivePanel,
}: Omit<TMixedCostAnomalyParams, 'thermalFactor'>) => {
  let diffSurface = visualSurface - thermalSurface;
  diffSurface = diffSurface < 0 ? 0 : diffSurface;

  return diffSurface * rgbFactor * costPerDefectivePanel;
};
