import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { flowRight, round, sum } from 'lodash';
import { EAnomalyRepairmentStatus } from '@core/enums/anomalies';
import { useCachedValue } from '@core/hooks';
import { useCurrentReportSelector, useCurrentSiteSelector } from '@core/store/slices';
import { toPixels } from '@core/utils';
import { toNegative } from '@core/utils/math';
import { HEADER_HEIGHT } from '@modules/Layout/constants';
import { getPopoverYPosition } from '@modules/Sidebar/utils/charts';
import { EstimatedLossesChart } from '@modules/Sidebar/widgets/EstimatedLosses/components/StackedChart';
import { ESTIMATED_LOSSES_POPOVER_HEIGHT } from '@modules/Sidebar/widgets/EstimatedLosses/constants';
import { useEstimatedLossesContext } from '@modules/Sidebar/widgets/EstimatedLosses/contexts';
import {
  getAnomaliesCosts,
  getReportsCosts,
  getSeparatedAnomaliesByStatus,
  getVisibleAnomalies,
  transformSites,
} from '@modules/Sidebar/widgets/EstimatedLosses/utils';
import { Popover } from '@components/Popover';
import styles from '../../styles.scss';

export const ZoneView: FC = () => {
  const contextValue = useEstimatedLossesContext();
  const { t } = useTranslation();
  const currentSite = useCurrentSiteSelector();
  const currentReport = useCurrentReportSelector();
  const [isOpenedPopover, setIsOpenedPopover] = useState(false);
  const [popoverStyles, setPopoverStyles] = useState<React.CSSProperties>({
    height: ESTIMATED_LOSSES_POPOVER_HEIGHT,
  });
  const containerRef = useRef<HTMLDivElement | null>(null);

  const cachedVisibleReports = useCachedValue(currentReport ? [currentReport] : []);
  const cachedSitesSidebar = useCachedValue(currentSite ? [currentSite] : []);

  const transformedSitesSidebar = useMemo(
    () => transformSites(cachedSitesSidebar),
    [cachedSitesSidebar],
  );

  const visibleAnomalies = useMemo(
    () => getVisibleAnomalies(cachedVisibleReports),
    [cachedVisibleReports],
  );

  const separatedAnomaliesByRepairmentStatus = useMemo(
    () => getSeparatedAnomaliesByStatus(visibleAnomalies),
    [visibleAnomalies],
  );

  const reportCosts = useMemo(
    () => getReportsCosts(cachedVisibleReports, transformedSitesSidebar),
    [cachedVisibleReports, transformedSitesSidebar],
  );

  const anomalyCosts = useMemo(
    () => getAnomaliesCosts(separatedAnomaliesByRepairmentStatus, transformedSitesSidebar),
    [separatedAnomaliesByRepairmentStatus, transformedSitesSidebar],
  );

  const totalCostByReports = useMemo(
    () => flowRight(round, sum)(Object.values(reportCosts)),
    [reportCosts],
  );

  const totalCostToRepairByReportsAnomalies = useMemo(
    () => flowRight(round, sum)(Object.values(anomalyCosts[EAnomalyRepairmentStatus.ToRepair])),
    [anomalyCosts[EAnomalyRepairmentStatus.ToRepair]],
  );

  const updatePopoverTopPosition = useCallback(() => {
    if (containerRef.current) {
      const { height, top } = containerRef.current.getBoundingClientRect();

      const popoverTopPosition = getPopoverYPosition({
        parentHeight: height,
        parentTop: top,
        popoverHeight: ESTIMATED_LOSSES_POPOVER_HEIGHT,
        correctionValue: HEADER_HEIGHT,
      });

      setPopoverStyles((styles) => ({
        ...styles,
        top: toPixels(popoverTopPosition),
      }));
    }
  }, []);

  const handleShowPopover = useCallback(() => setIsOpenedPopover(true), []);
  const handleHidePopover = useCallback(() => setIsOpenedPopover(false), []);

  useEffect(() => {
    if (isOpenedPopover) {
      updatePopoverTopPosition();
    }
  }, [contextValue.scrollTop, isOpenedPopover]);

  return (
    <>
      <Popover
        title={t('sidebar.widgets.estimatedLosses.popover.title')}
        className={styles.estimatedLossesPopover}
        isOpen={isOpenedPopover}
        style={popoverStyles}
        onClose={handleHidePopover}
      >
        <EstimatedLossesChart
          visibleReports={cachedVisibleReports}
          visibleAnomalies={visibleAnomalies}
          totalCostByReports={totalCostByReports}
          totalCostToRepairByReportsAnomalies={totalCostToRepairByReportsAnomalies}
          separatedAnomaliesByRepairmentStatus={separatedAnomaliesByRepairmentStatus}
          transformedSitesSidebar={transformedSitesSidebar}
          costRatio={contextValue.formattedMoneyPerYear}
        />
      </Popover>
      <div
        className={cn(styles.tilesList, 'cursor-pointer')}
        onClick={handleShowPopover}
        ref={containerRef}
      >
        <section className={styles.tile}>
          <h3 className={styles.tileTitle}>
            {t('sidebar.widgets.estimatedLosses.tiles.atInspection.title')}
          </h3>
          <div className={cn(styles.counter, styles.atInspection)}>
            <span className={styles.counterValue}>{toNegative(totalCostByReports)}</span>
            <div className={styles.moneyPerYear}>{contextValue.formattedMoneyPerYear}</div>
          </div>
        </section>
        <section className={styles.tile}>
          <h3 className={styles.tileTitle}>
            {t('sidebar.widgets.estimatedLosses.tiles.afterRepair.title', {
              repaired:
                separatedAnomaliesByRepairmentStatus[EAnomalyRepairmentStatus.Repaired].length,
              total: visibleAnomalies.length,
            })}
          </h3>
          <div className={cn(styles.counter, styles.afterRepair)}>
            <span className={styles.counterValue}>
              {toNegative(totalCostToRepairByReportsAnomalies)}
            </span>
            <div className={styles.moneyPerYear}>{contextValue.formattedMoneyPerYear}</div>
          </div>
        </section>
      </div>
    </>
  );
};
