import { t } from 'i18next';
import _ from 'lodash';
import { EAnomalyMode } from '@core/enums/anomalies';
import { toNegative } from '@core/utils/math';
import { EEstimatedLossesGroup } from '@modules/Sidebar/widgets/EstimatedLosses/enums';

interface IReportChartData {
  reportCostsByModes: {
    [EAnomalyMode.Thermal]: number;
    [EAnomalyMode.String]: number;
    [EAnomalyMode.Visual]: number;
  };
  totalCostByReports: number;
  costRatio: string;
}

const documentStyle = getComputedStyle(document.documentElement);

export const prepareReportChartData = ({
  reportCostsByModes,
  totalCostByReports,
  costRatio,
}: IReportChartData) => {
  return {
    metadata: {
      bar: {
        title: {
          value: toNegative(totalCostByReports),
          costRatio: costRatio,
          color: documentStyle.getPropertyValue('--red'),
        },
      },
    },
    data: {
      group: {
        key: EEstimatedLossesGroup.AtInspected,
        title: t('sidebar.widgets.estimatedLosses.tiles.atInspection.title'),
        value: t('sidebar.widgets.estimatedLosses.tiles.atInspection.title'),
        color: documentStyle.getPropertyValue('--white'),
      },
      subgroups: [
        {
          key: EAnomalyMode.Thermal,
          position: 1,
          title: t('sidebar.widgets.estimatedLosses.popover.subgroups.thermal'),
          color: documentStyle.getPropertyValue('--outflier-red-light-shade'),
          value: _.round(reportCostsByModes[EAnomalyMode.Thermal], 1),
        },
        {
          key: EAnomalyMode.String,
          position: 2,
          title: t('sidebar.widgets.estimatedLosses.popover.subgroups.string'),
          color: documentStyle.getPropertyValue('--outflier-yellow-light-shade'),
          value: _.round(reportCostsByModes[EAnomalyMode.String], 1),
        },
        {
          key: EAnomalyMode.Visual,
          position: 3,
          title: t('sidebar.widgets.estimatedLosses.popover.subgroups.rgb'),
          color: documentStyle.getPropertyValue('--outflier-blue-cyan'),
          value: _.round(reportCostsByModes[EAnomalyMode.Visual], 1),
        },
      ],
    },
  };
};
