import { anomaliesStatusesColor } from '@core/constants';
import { EAnomalyStatus } from '@core/enums/anomalies';
import { getColor } from '@core/utils';
import { EZonesLayers } from '../../enums/layers';
import { EZonesSources } from '../../enums/sources';

export const layersStyles: { [key in EZonesLayers]: () => mapboxgl.AnyLayer } = {
  [EZonesLayers.Polygon]: () => ({
    id: EZonesLayers.Polygon,
    type: 'fill',
    source: EZonesSources.Zones,
    paint: {
      'fill-color': '#ff0012',
      'fill-opacity': 0,
    },
  }),
  [EZonesLayers.PolygonBorder]: () => ({
    id: EZonesLayers.PolygonBorder,
    type: 'line',
    source: EZonesSources.Zones,
    paint: {
      'line-color': [
        'case',
        ['get', 'isEditMode'],
        getColor('--outflier-blue'),
        ['get', 'isReportProcessing'],
        getColor('--outflier-blue'),
        [
          'match',
          ['get', 'worst_anomaly_status'],
          'To Review',
          anomaliesStatusesColor[EAnomalyStatus.ToReview],
          'False',
          anomaliesStatusesColor[EAnomalyStatus.False],
          'Minor',
          anomaliesStatusesColor[EAnomalyStatus.Minor],
          'Major',
          anomaliesStatusesColor[EAnomalyStatus.Major],
          '#252525',
        ],
      ],
      'line-width': 2,
    },
  }),
};
