import { t } from 'i18next';
import { IStyleConfig } from '../interfaces';

const documentStyle = getComputedStyle(document.documentElement);

export const PANELS_GRID_STYLES_CONFIG: IStyleConfig = {
  canvas: {
    width: 222,
    height: 132,
  },
  rectCell: {
    width: 20.2,
    height: 11.2,
  },
  gap: {
    initial: 0,
    main: 2,
    middle: 4,
  },
  colors: {
    activePanels: documentStyle.getPropertyValue('--outflier-dark-blue'),
    defectivePanels: documentStyle.getPropertyValue('--outflier-dark-red'),
  },
};

export const DIRTINESS_INDEX_STYLES_CONFIG = new Map([
  [
    [-Infinity, 0.1],
    {
      content: t('sidebar.widgets.panels.dirtinessIndex.valueTitle'),
    },
  ],
  [
    [0.1, 0.2],
    {
      width: '20%',
      backgroundColor: documentStyle.getPropertyValue('--white'),
    },
  ],
  [
    [0.2, 0.3],
    {
      width: '40%',
      backgroundColor: documentStyle.getPropertyValue('--outflier-yellow'),
    },
  ],
  [
    [0.3, 0.4],
    {
      width: '60%',
      backgroundColor: documentStyle.getPropertyValue('--outflier-yellow'),
    },
  ],
  [
    [0.4, 0.5],
    {
      width: '80%',
      backgroundColor: documentStyle.getPropertyValue('--red'),
    },
  ],
  [
    [0.5, Infinity],
    {
      width: '100%',
      backgroundColor: documentStyle.getPropertyValue('--red'),
    },
  ],
]);
