import React, { ForwardedRef } from 'react';
import cn from 'classnames';
import styles from './styles.scss';

interface IProps {
  className?: string;
  title: string;
  children: React.ReactNode;
}

export const DetailsTile = React.forwardRef<HTMLDivElement, IProps>(
  ({ className, title, children }, ref?: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className={cn(styles.tile, className)} ref={ref}>
        <h3 className={styles.title}>{title}</h3>
        {children}
      </div>
    );
  },
);
