import { FC, memo } from 'react';
import cn from 'classnames';
import { ISample } from '@core/interfaces';
import styles from './styles.scss';
import { ELoadingState } from '../../enums/loading';

interface IProps {
  alt?: string;
  className?: string;
  sample: ISample | null;
  isLoaded: boolean;
  onLoad: (type: ELoadingState) => void;
}

export const RgbMask: FC<IProps> = memo(
  ({ sample, isLoaded, className, onLoad, alt = 'rgb image' }) => {
    const handleLoadImage = () => onLoad(ELoadingState.RGB);

    return (
      <img
        src={sample?.rgb_image}
        className={cn(styles.image, { [styles.imageLoaded]: !isLoaded }, className)}
        alt={alt}
        onLoad={handleLoadImage}
      />
    );
  },
);
