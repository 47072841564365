import { EAnomalyMode, EAnomalyRepairmentStatus } from '@core/enums/anomalies';
import { IAnomaly } from '@core/interfaces/anomaly';
import { isNumber } from '@core/utils';
import { TransformedSites } from '../../interfaces/transformation';
import {
  getDisconnectedCostByReportAnomaly,
  getMixedCostByReportAnomaly,
  getThermalCostByReportAnomaly,
  getVisualCostByReportAnomaly,
} from '../formulas/anomaliesCosts';

enum EReportAnomalyCosts {
  ThermalCost = 'thermalCost',
  VisualCost = 'visualCost',
  MixedCost = 'mixedCost',
  DisconnectedCost = 'disconnectedCost',
}

type ReportAnomaliesCostsResponse = {
  [key in EAnomalyRepairmentStatus]: {
    [key in EReportAnomalyCosts]: number;
  };
};

type SeparatedAnomaliesByStatus = {
  [key in EAnomalyRepairmentStatus]: IAnomaly[];
};

function getDefaultAnomalyCosts() {
  return Object.values(EReportAnomalyCosts).reduce(
    (acc, costField) => ({ ...acc, [costField]: 0 }),
    {} as ReportAnomaliesCostsResponse,
  );
}

function getDefaultAnomalyCostsByRepairmentStatus(
  defaultAnomalyCostsValue: ReturnType<typeof getDefaultAnomalyCosts>,
) {
  return Object.values(EAnomalyRepairmentStatus).reduce(
    (acc, repairmentStatus) => ({
      ...acc,
      [repairmentStatus]: defaultAnomalyCostsValue,
    }),
    {} as ReportAnomaliesCostsResponse,
  );
}

export function getAnomaliesCosts(
  anomaliesByRepairmentStatus: SeparatedAnomaliesByStatus,
  sites: TransformedSites,
) {
  return Object.entries(anomaliesByRepairmentStatus).reduce<ReportAnomaliesCostsResponse>(
    (mainAcc, [repairmentStatus, anomalies]) => {
      const costsByRepairmentStatus = anomalies.reduce((anomaliesAcc, anomaly) => {
        const {
          thermal_surface: thermalSurface,
          visual_surface: visualSurface,
          disconnected_panels: disconnectedPanels,
          location_id: locationId,
        } = anomaly;

        const areEnabledCalculationFields = [
          visualSurface,
          thermalSurface,
          disconnectedPanels,
        ].every(isNumber);

        const selectedSite = locationId ? sites[locationId] : null;

        if (areEnabledCalculationFields && selectedSite) {
          const {
            thermal_factor: thermalFactor,
            rgb_factor: rgbFactor,
            cost_per_defective_panel: costPerDefectivePanel,
          } = selectedSite;

          switch (anomaly.mode) {
            case EAnomalyMode.Visual: {
              if (rgbFactor && costPerDefectivePanel) {
                anomaliesAcc[EReportAnomalyCosts.VisualCost] += getVisualCostByReportAnomaly({
                  visualSurface: visualSurface as number,
                  rgbFactor,
                  costPerDefectivePanel,
                });
              }
              break;
            }
            case EAnomalyMode.Thermal: {
              if (thermalFactor && costPerDefectivePanel) {
                anomaliesAcc[EReportAnomalyCosts.ThermalCost] += getThermalCostByReportAnomaly({
                  thermalSurface: thermalSurface as number,
                  thermalFactor,
                  costPerDefectivePanel,
                });
              }
              break;
            }
            case EAnomalyMode.Mixed: {
              if (rgbFactor && thermalFactor && costPerDefectivePanel) {
                anomaliesAcc[EReportAnomalyCosts.MixedCost] += getMixedCostByReportAnomaly({
                  visualSurface: visualSurface as number,
                  thermalSurface: thermalSurface as number,
                  rgbFactor,
                  thermalFactor,
                  costPerDefectivePanel,
                });
              }
              break;
            }
            case EAnomalyMode.String: {
              if (costPerDefectivePanel) {
                anomaliesAcc[EReportAnomalyCosts.DisconnectedCost] +=
                  getDisconnectedCostByReportAnomaly({
                    disconnectedPanels: disconnectedPanels as number,
                    costPerDefectivePanel,
                  });
              }
              break;
            }
          }
        }

        return anomaliesAcc;
      }, getDefaultAnomalyCosts());

      return {
        ...mainAcc,
        [repairmentStatus]: costsByRepairmentStatus,
      };
    },
    getDefaultAnomalyCostsByRepairmentStatus(getDefaultAnomalyCosts()),
  );
}
