import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DEFAULT_NUMERIC_VALUE, MISSING_VALUE } from '@core/constants';
import { EControlStepCounterAction } from '@core/enums';
import { IAnomaly } from '@core/interfaces/anomaly';
import { useCurrentAnomalySelector } from '@core/store/slices';
import { toPixels } from '@core/utils';
import { HEADER_HEIGHT } from '@modules/Layout/constants';
import { getPopoverYPosition } from '@modules/Sidebar/utils/charts';
import { StepperPopover } from '@modules/Sidebar/widgets/AnomalyDetails/components/Popovers';
import {
  AverageTemperatureTile,
  DetailsTile,
  SubtypeTile,
  SurfaceAffectedTile,
  TemperatureDifferenceTile,
} from '@modules/Sidebar/widgets/AnomalyDetails/components/Tiles';
import { VISUAL_INITIAL_POPOVERS_STYLES } from '@modules/Sidebar/widgets/AnomalyDetails/config';
import { SURFACE_AFFECTED_POPOVER_HEIGHT } from '@modules/Sidebar/widgets/AnomalyDetails/constants';
import { useAnomalyDetailsContext } from '@modules/Sidebar/widgets/AnomalyDetails/contexts';
import {
  EAnomalyDetailsPopovers,
  EAnomalyDetailsUpdateFields,
} from '@modules/Sidebar/widgets/AnomalyDetails/enums';
import { useUpdateAnomaly } from '@modules/Sidebar/widgets/AnomalyDetails/hooks';
import { IUpdatePopoverTopPositionParams } from '@modules/Sidebar/widgets/AnomalyDetails/interfaces';
import {
  formatAverageTemperature,
  formatSurfaceAffected,
  getRoundedSurfaceAffected,
  getRoundedTemperature,
} from '@modules/Sidebar/widgets/AnomalyDetails/utils';
import styles from '../../styles.scss';

export const VisualView = () => {
  const { t } = useTranslation();
  const detailsContext = useAnomalyDetailsContext();
  const currentAnomaly = useCurrentAnomalySelector();

  const [isOpenedSurfaceAffectedModal, setIsOpenedSurfaceAffectedModal] = useState(false);
  const [surfaceAffectedValue, setSurfaceAffectedValue] = useState(
    currentAnomaly?.visual_surface ?? DEFAULT_NUMERIC_VALUE,
  );
  const [popoverStyles, setPopoverStyles] = useState(VISUAL_INITIAL_POPOVERS_STYLES);
  const surfaceAffectedRef = useRef<HTMLDivElement | null>(null);

  useUpdateAnomaly({
    isAllowed: !isOpenedSurfaceAffectedModal,
    anomaly: currentAnomaly,
    key: EAnomalyDetailsUpdateFields.VisualSurface,
    editableValue: surfaceAffectedValue,
  });

  const resetVisualViewState = useCallback((anomaly: IAnomaly) => {
    setIsOpenedSurfaceAffectedModal(false);
    setSurfaceAffectedValue(anomaly.visual_surface ?? DEFAULT_NUMERIC_VALUE);
    setPopoverStyles(VISUAL_INITIAL_POPOVERS_STYLES);
  }, []);

  const handleUpdateSurfaceAffectedValue = useCallback(
    (counterAction: EControlStepCounterAction) => {
      setSurfaceAffectedValue((value) => {
        const percent = 1 / 100;
        return counterAction === EControlStepCounterAction.Decrease
          ? value - percent
          : value + percent;
      });
    },
    [],
  );

  const handleShowSurfaceAffectedModal = useCallback(
    () => setIsOpenedSurfaceAffectedModal(true),
    [],
  );
  const handleHideSurfaceAffectedModal = useCallback(
    () => setIsOpenedSurfaceAffectedModal(false),
    [],
  );

  const updatePopoverTopPosition = useCallback(
    ({ ref, key, popoverHeight }: IUpdatePopoverTopPositionParams) => {
      if (ref.current) {
        const { height, top } = ref.current.getBoundingClientRect();
        const popoverTopPosition = getPopoverYPosition({
          parentHeight: height,
          parentTop: top,
          popoverHeight,
          correctionValue: HEADER_HEIGHT,
        });

        setPopoverStyles((styles) => ({
          ...styles,
          [key]: {
            ...styles[key],
            top: toPixels(popoverTopPosition),
          },
        }));
      }
    },
    [],
  );

  useEffect(() => {
    if (isOpenedSurfaceAffectedModal) {
      updatePopoverTopPosition({
        ref: surfaceAffectedRef,
        key: EAnomalyDetailsPopovers.SurfaceAffected,
        popoverHeight: SURFACE_AFFECTED_POPOVER_HEIGHT,
      });
    }
  }, [detailsContext.scrollTop, isOpenedSurfaceAffectedModal]);

  useEffect(() => {
    if (currentAnomaly?.id) {
      resetVisualViewState(currentAnomaly);
    }
  }, [currentAnomaly?.id]);

  const subtypeTitle = t('sidebar.anomaly.widgets.details.tiles.subtype.title');
  const averageTypeTitle = t('sidebar.anomaly.widgets.details.tiles.averageTemperature.title');
  const surfaceAffectedTitle = t('sidebar.anomaly.widgets.details.tiles.surfaceAffected.default');
  const temperatureDifferenceTitle = t(
    'sidebar.anomaly.widgets.details.tiles.temperatureDifference.title',
  );

  const formattedSurfaceAffectedValue = useMemo(
    () => _.flowRight(formatSurfaceAffected, getRoundedSurfaceAffected)(surfaceAffectedValue),
    [surfaceAffectedValue],
  );

  const formattedAverageTemperatureValue = useMemo(
    () =>
      _.flowRight(
        formatAverageTemperature,
        getRoundedTemperature,
      )(currentAnomaly?.mean_temperature),
    [currentAnomaly?.mean_temperature],
  );

  return (
    <ul className={styles.tiles}>
      <li className={styles.row}>
        <DetailsTile title={subtypeTitle}>
          <SubtypeTile isEditable={false} isDisabled={false} value={MISSING_VALUE} />
        </DetailsTile>
        <DetailsTile title={averageTypeTitle}>
          <AverageTemperatureTile value={formattedAverageTemperatureValue} />
        </DetailsTile>
      </li>
      <li className={styles.row}>
        <DetailsTile title={surfaceAffectedTitle} ref={surfaceAffectedRef}>
          <div className={styles.details}>
            <StepperPopover
              classNames={{
                popover: styles.surfaceAffectedPopover,
                value: styles.surfaceAffectedValue,
              }}
              value={formattedSurfaceAffectedValue}
              style={popoverStyles[EAnomalyDetailsPopovers.SurfaceAffected]}
              title={surfaceAffectedTitle}
              isOpen={isOpenedSurfaceAffectedModal}
              onClose={handleHideSurfaceAffectedModal}
              onUpdate={handleUpdateSurfaceAffectedValue}
            />
            <SurfaceAffectedTile
              isEditable={detailsContext.isEditable}
              isDisabled={detailsContext.isDisabled}
              value={formattedSurfaceAffectedValue}
              onClick={handleShowSurfaceAffectedModal}
            />
          </div>
        </DetailsTile>
        <DetailsTile title={temperatureDifferenceTitle}>
          <TemperatureDifferenceTile isEditable={false} isDisabled={false} value={MISSING_VALUE} />
        </DetailsTile>
      </li>
    </ul>
  );
};
