import { TAxiosInstance } from '@core/api';
import { authStorage } from '@core/utils';

export const accessTokenInterceptor = (axiosInstance: TAxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (['token'].includes(String(config.url))) {
        return config;
      }

      const accessToken = authStorage.tokens.access_token.get();
      const tokenType = authStorage.tokens.token_type.get();

      if (accessToken) config.headers.Authorization = `${tokenType ?? 'Bearer'} ${accessToken}`;

      return config;
    },
    (error) => Promise.reject(error),
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.config.url === 'token') {
        if (response.data.access_token) {
          authStorage.tokens.access_token.set(response.data.access_token);
        }

        if (response.data.refresh_token) {
          authStorage.tokens.refresh_token.set(response.data.refresh_token);
        }
      }
      return response;
    },
    async (error) => {
      return Promise.reject(error);
    },
  );
};
