import { Feature, Polygon, MultiPolygon, Properties } from '@turf/turf';
import { IProgram } from '@core/interfaces';
import { INoFlyZone } from '@core/interfaces/sites/createSite';
import { getIntersectionBetweenTwoPolygons } from '@modules/Viewers/views/MapViewer/utils/geospatialUtils';

type FirstPolygon = number;
type SecondPolygon = number;

type TIntersectedPair = {
  polygons: [FirstPolygon, SecondPolygon];
  intersection: Feature<Polygon | MultiPolygon, Properties>;
};

// TODO: it will be removed in the future
// NOTE: it's a temporary function
export function getIntersectedZones(programs: IProgram[]) {
  const intersectedPairs: TIntersectedPair[] = [];

  for (let i = 0; i < programs.length; i += 1) {
    const currentPolygon = programs[i].display_perimeter;

    for (let k = i + 1; k < programs.length; k += 1) {
      const candidatePolygon = programs[k].display_perimeter;
      const intersection = getIntersectionBetweenTwoPolygons(currentPolygon, candidatePolygon);
      if (intersection) {
        intersectedPairs.push({ polygons: [i + 1, k + 1], intersection });
      }
    }
  }
  return intersectedPairs;
}

// TODO: it will be removed in the future
// NOTE: it's a temporary function
export function getIntersectedPolygons(noFlyZones: INoFlyZone[], programs: IProgram[]) {
  const intersectedPairs: TIntersectedPair[] = [];

  for (let i = 0; i < noFlyZones.length; i += 1) {
    const currentPolygon = noFlyZones[i].perimeter;

    for (let k = 0; k < programs.length; k += 1) {
      const candidatePolygon = programs[k].display_perimeter;
      const intersection = getIntersectionBetweenTwoPolygons(currentPolygon, candidatePolygon);
      if (intersection) {
        intersectedPairs.push({ polygons: [i + 1, k + 1], intersection });
      }
    }
  }
  return intersectedPairs;
}
