import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ECookiesField } from '@core/constants';
import { EAppAction, EArrowDirection, EControlMode, ESidebar, EViewer } from '@core/enums';
import { EReportSelector } from '@core/enums/reports/report';
import { EUploadingStep } from '@core/enums/uploadModal';
import { useDispatchTyped } from '@core/hooks';
import { IBrowserStorage } from '@core/interfaces/accessControl';
import { DataProcessing, cookiesStorage } from '@core/services';
import {
  fetchAnomaliesAndSolarPanelsToReports,
  fetchProgramsStatuses,
  resetAnomalies,
  resetAnomalySamples,
  resetModel3DState,
  resetVideos,
  setCurrentAnomalyId,
  setCurrentInspectionIdByProgramId,
  setCurrentProgramId,
  setInspectionAndReportToCompareIds,
  setSidebar,
  setSidebarViewer,
  setVideo,
  setViewer,
  updateCompareModeAndView,
  updateModelState,
  useAccessControlSelector,
  useControlsSelector,
  useCurrentInspectionSelector,
  useCurrentProgramSelector,
  useCurrentReportSelector,
  useCurrentSiteSelector,
  useModel3DSelector,
  useProgramsForCurrentLocation,
  useReportByInspectionIdSelector,
  useVideoSelector,
} from '@core/store/slices';
import { useMainContext } from '@modules/Layout/contexts/main';
import { Navbar } from '@modules/Sidebar/components';
import { InspectionsView } from '@modules/Sidebar/components/InspectionsView';
import { UploadInspectionModal, AnomaliesTable } from '@modules/Sidebar/views/Zone/components';
import { SolarPanelsStatistic, AnomalyStatistic, EstimatedLosses } from '@modules/Sidebar/widgets';
import { ESocketType } from '@modules/Viewers/views/ModelViewer/components/ThreejsViewer/enums';
import { getSelectedAnomalyCameraPosition } from '@modules/Viewers/views/ModelViewer/components/ThreejsViewer/utils/getSelectedAnomalyCameraPosition';
import styles from '../../styles.scss';

export const ZoneDefaultView: React.FC = () => {
  const {
    model: { sidebarOrbitControl },
  } = useMainContext();
  const model3D = useModel3DSelector();
  const [videos, setVideos] = useState<string[]>([]);
  const [isOpenUploadFilesModal, setIsOpenUploadFilesModal] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const zone = useCurrentProgramSelector();
  const { mode, isCompareMode } = useControlsSelector();
  const currentReport = useCurrentReportSelector();
  const currentInspection = useCurrentInspectionSelector();
  const inspectionReport = useReportByInspectionIdSelector(currentInspection?.id);
  const currentSite = useCurrentSiteSelector();
  const { browserStorage } = useAccessControlSelector();
  const { video } = useVideoSelector();
  const { programsForCurrentLocation } = useProgramsForCurrentLocation();

  const dispatch = useDispatchTyped();

  const drawablesRef = useRef(model3D.model.state.drawables);
  drawablesRef.current = model3D.model.state.drawables;

  const datasetCameraPosesRef = useRef(model3D.model.state.datasetCameraPoses);
  datasetCameraPosesRef.current = model3D.model.state.datasetCameraPoses;

  const socketConnectionRef = useRef(model3D.connections.socketConnection);
  socketConnectionRef.current = model3D.connections.socketConnection;

  const currentBrowserStorageRef = useRef<IBrowserStorage | null>(browserStorage);
  currentBrowserStorageRef.current = browserStorage;

  const handleOpenUploadModal = () => setIsOpenUploadFilesModal(true);
  const handleCloseUploadModal = () => setIsOpenUploadFilesModal(false);

  const disableStoragePersistPermission = useCallback(() => {
    cookiesStorage.removeItem(ECookiesField.StoragePersistPermission);
  }, []);

  const handleTableRowClickIn3DMode = useCallback(
    (anomalyId: number) => {
      // 1. use case (click on anomaly in a sidebar):
      // Problem with closures when obviously unmounting a component (Inspection View).
      sidebarOrbitControl.current?.dispose();
      sidebarOrbitControl.current = null;

      // NOTE: center camera position for selected anomaly
      const drawables = drawablesRef.current;
      const datasetCameraPoses = datasetCameraPosesRef.current;
      const socketConnection = socketConnectionRef.current;

      const drawableAnomalyPattern = `with_anomaly_${anomalyId}`;
      const foundSolarPanel = drawables.find((drawable) =>
        drawable.id.includes(drawableAnomalyPattern),
      );

      if (foundSolarPanel && datasetCameraPoses) {
        const cameraView = getSelectedAnomalyCameraPosition(foundSolarPanel, datasetCameraPoses);

        if (socketConnection?.ws.readyState === WebSocket.OPEN) {
          const serializedBody = DataProcessing.serialize({
            type: ESocketType.CameraChange,
            payload: cameraView,
          });
          socketConnection?.ws.send(serializedBody);
        }

        dispatch(updateModelState({ initCamera: cameraView }));
      }
    },
    [dispatch],
  );

  const handleTableRowClick = useCallback(
    (anomalyId: number) => {
      if (!isCompareMode) {
        if (mode === EControlMode['3D']) {
          handleTableRowClickIn3DMode(anomalyId);
          dispatch(setViewer(EViewer.Model));
        } else {
          dispatch(setViewer(EViewer.Map));
        }

        dispatch(setCurrentAnomalyId(anomalyId));
        dispatch(setSidebar(ESidebar.Anomaly));
      }
    },
    [isCompareMode, mode, handleTableRowClickIn3DMode, dispatch],
  );

  const handleBackClick = () => {
    dispatch(setViewer(EViewer.Map));
    dispatch(setSidebarViewer(EViewer.Video));

    // NOTE: Reset all data associated with the "Zone"
    dispatch(resetModel3DState());
    dispatch(resetVideos());
    dispatch(resetAnomalies());
    dispatch(resetAnomalySamples());
    dispatch(setSidebar(ESidebar.Site));

    disableStoragePersistPermission();

    if (currentSite?.loc_id) {
      dispatch(
        fetchProgramsStatuses({
          data: {
            locationId: currentSite.loc_id,
            inspectionId: currentInspection?.id,
          },
          action: EAppAction.SidebarZoneBackClick,
        }),
      );
    }
  };

  const handleZoneToggle = (arrow: EArrowDirection) => {
    const zones = programsForCurrentLocation;
    let index = zones.findIndex(({ program_id }) => program_id === zone?.program_id);

    switch (arrow) {
      case EArrowDirection.Left:
        index = index === 0 ? programsForCurrentLocation.length - 1 : index - 1;
        break;
      case EArrowDirection.Right:
        index = index === programsForCurrentLocation.length - 1 ? 0 : index + 1;
        break;
    }

    const selectedProgramId = zones[index].program_id;

    dispatch(setCurrentProgramId(selectedProgramId));
    dispatch(updateCompareModeAndView(false));
    dispatch(setInspectionAndReportToCompareIds(null));

    dispatch(
      setCurrentInspectionIdByProgramId({
        programId: selectedProgramId,
        options: {
          withReport: true,
          withReportSamples: true,
        },
      }),
    );

    dispatch(
      fetchAnomaliesAndSolarPanelsToReports({
        selector: EReportSelector.GetBySelectedReport,
      }),
    );
  };

  const handleSwitch = useCallback(
    (index: number) => {
      if (videos) dispatch(setVideo(videos[index]));
    },
    [videos, dispatch],
  );

  const handleScroll = (event) => setScrollTop(event.target.scrollTop);

  const selectedVideoId = useMemo(() => videos.findIndex((url) => video === url), [videos, video]);
  const areDisabledSwitcherButtons = programsForCurrentLocation.length === 1;

  useEffect(() => {
    if (currentReport) {
      const videos = currentReport?.inspection_videos;
      const initialVideo = videos?.[0];

      setVideos(videos ?? ([] as string[]));

      if (initialVideo) {
        dispatch(setVideo(initialVideo));
      }
    } else {
      dispatch(resetVideos());
    }
  }, [currentReport]);

  useEffect(() => {
    if (currentInspection?.uploaded && inspectionReport) {
      handleCloseUploadModal();
    } else {
      if (!currentInspection?.report_generated || !currentInspection.uploaded) {
        handleOpenUploadModal();
      }
    }
  }, [currentInspection?.uploaded, inspectionReport]);

  return (
    <>
      {isOpenUploadFilesModal && (
        <UploadInspectionModal
          onClose={handleCloseUploadModal}
          initialStep={
            currentInspection?.uploaded && !inspectionReport
              ? EUploadingStep.Complete
              : EUploadingStep.Upload
          }
        />
      )}
      <Navbar
        header={currentSite?.name}
        subheader={programsForCurrentLocation.length > 1 ? zone?.name : undefined}
        areDisabledSwitcherButtons={areDisabledSwitcherButtons}
        onBackClick={handleBackClick}
        onLeftClick={handleZoneToggle.bind(null, EArrowDirection.Left)}
        onRightClick={handleZoneToggle.bind(null, EArrowDirection.Right)}
      />
      <div className={styles.zoneWrapper} onScroll={handleScroll}>
        <InspectionsView
          classNames={styles.mediaInspections}
          options={videos}
          selectedId={selectedVideoId}
          isDisabledActions={!currentReport}
          onSwitch={handleSwitch}
        />
        <div className={styles.statistic}>
          <EstimatedLosses scrollTop={scrollTop} />
          <SolarPanelsStatistic scrollTop={scrollTop} />
          <AnomalyStatistic />
        </div>
        <AnomaliesTable onRowClick={handleTableRowClick} isSortable />
      </div>
    </>
  );
};
