import { ESiteEditSections } from '@core/enums/sites/editSite';
import { TRolesWithoutSuperuser } from '@core/interfaces';
import { IEditSiteInitialState } from '@core/store/slices';

type RolesWithEmails = { [key in TRolesWithoutSuperuser]: string[] };
type EmailDict = { [email: string]: TRolesWithoutSuperuser[] };
type ApiRequestBody = { email: string; roles: TRolesWithoutSuperuser[] };

export function transformRolesDataForApiRequest(
  rolesEditInfo: IEditSiteInitialState[ESiteEditSections.Roles],
): ApiRequestBody[] {
  // 1. Prepare from { [role]: [{email, ...}] } to { [role]: [email] }
  const rolesWithEmails = Object.entries(rolesEditInfo).reduce((acc, [role, data]) => {
    const emails = data.filter(({ value }) => value).map(({ value }) => value);

    return emails.length ? { ...acc, [role]: emails } : acc;
  }, {} as RolesWithEmails);

  // 2. Get only unique emails from all roles;
  const uniqueEmails = [...new Set(Object.values(rolesWithEmails).flat())];

  // 3. Prepare from [email] to { [email]: [] }
  const emailsDict = uniqueEmails.reduce<EmailDict>((acc, email) => ({ ...acc, [email]: [] }), {});

  for (const [role, emails] of Object.entries(rolesWithEmails)) {
    const typedRole = role as TRolesWithoutSuperuser;

    emails.forEach((email) => {
      if (!emailsDict[email].includes(typedRole)) {
        emailsDict[email].push(typedRole);
      }
    });
  }

  // 4. Prepare from  { [email]: [role] } to [{email, roles}]
  return Object.entries(emailsDict).reduce<ApiRequestBody[]>(
    (acc, [email, roles]) => [...acc, { email, roles }],
    [],
  );
}
