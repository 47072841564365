import { FeatureCollection, Feature, Geometry, GeoJsonProperties } from 'geojson';
import { EAnomalyLayers } from '../../enums/layers';
import { EAnomalySources } from '../../enums/sources';

interface ILayersStylesDeps {
  hasSolarPanels?: boolean;
}

export const layersStyles: {
  [key in EAnomalyLayers]: (deps: ILayersStylesDeps) => mapboxgl.AnyLayer;
} = {
  [EAnomalyLayers.Anomalies]: ({ hasSolarPanels }: ILayersStylesDeps) => ({
    id: EAnomalyLayers.Anomalies,
    type: 'fill',
    source: EAnomalySources.Anomalies,
    paint: hasSolarPanels
      ? { 'fill-color': ['get', 'color'], 'fill-opacity': ['get', 'opacity'] }
      : {
          'fill-antialias': false,
          'fill-opacity': ['get', 'opacity'],
        },
  }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [EAnomalyLayers.AnomaliesPerimeter]: ({ hasSolarPanels }: ILayersStylesDeps) => ({
    id: EAnomalyLayers.AnomaliesPerimeter,
    type: 'line',
    source: EAnomalySources.AnomaliesPerimeter,
    paint: {
      'line-color': ['get', 'borderColor'],
      'line-width': ['get', 'borderWidth'],
    },
  }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [EAnomalyLayers.AnomaliesBorder]: (deps: ILayersStylesDeps) => ({
    id: EAnomalyLayers.AnomaliesBorder,
    type: 'line',
    source: EAnomalySources.Anomalies,
    paint: {
      'line-color': ['get', 'borderColor'],
      'line-width': ['get', 'borderWidth'],
    },
  }),
};

export const setFeatureCollectionData = (
  anomalies: Feature<Geometry, GeoJsonProperties>[],
): FeatureCollection => ({
  type: 'FeatureCollection',
  features: [...anomalies],
});
