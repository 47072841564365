import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import styles from './styles.scss';

const transitionProps = {
  as: Fragment,
  enter: 'transition ease-out duration-100',
  enterFrom: 'transform opacity-0 scale-95',
  enterTo: 'transform opacity-100 scale-100',
  leave: 'transition ease-in duration-75',
  leaveFrom: 'transform opacity-100 scale-100',
  leaveTo: 'transform opacity-0 scale-95',
};

export interface IOption {
  id: string;
  name: string;
  disabled: boolean;
  onClick: () => void;
}

interface IProps {
  open: boolean;
  options: IOption[];
}

export const Dropdown: React.FC<IProps> = ({ open, options }) => {
  return (
    <Transition show={open} {...transitionProps}>
      <Menu.Items className={styles.menu}>
        {options.map(({ id, name, disabled, onClick }) => (
          <Menu.Item
            as='button'
            key={id}
            className={styles.menuItem}
            onClick={onClick}
            disabled={disabled}
          >
            {name}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Transition>
  );
};
