import frameAngleGreen from '@assets/icons/frame-angle-green.svg';
import frameAngleRed from '@assets/icons/frame-angle-red.svg';
import frameAngleWhite from '@assets/icons/frame-angle-white.svg';
import frameAngleYellow from '@assets/icons/frame-angle-yellow.svg';
import {
  EAnomalyMode,
  EAnomalyRepairmentStatus,
  EAnomalyStatisticCategory,
  EAnomalyStatus,
} from '@core/enums/anomalies';
import i18n from '@core/i18n';

const documentStyle = getComputedStyle(document.documentElement);

export const anomaliesChartsColor: Record<string, any> = {
  [EAnomalyStatisticCategory.Severity]: {
    [EAnomalyStatus.False]: documentStyle.getPropertyValue('--outflier-blue-grey'),
    [EAnomalyStatus.Minor]: documentStyle.getPropertyValue('--white'),
    [EAnomalyStatus.ToReview]: documentStyle.getPropertyValue('--outflier-yellow'),
    [EAnomalyStatus.Major]: documentStyle.getPropertyValue('--red'),
  },
  [EAnomalyStatisticCategory.Type]: {
    [EAnomalyMode.Visual]: documentStyle.getPropertyValue('--outflier-blue-cyan'),
    [EAnomalyMode.Mixed]: documentStyle.getPropertyValue('--outflier-blue-sky'),
    [EAnomalyMode.String]: documentStyle.getPropertyValue('--outflier-yellow-light-shade'),
    [EAnomalyMode.Thermal]: documentStyle.getPropertyValue('--outflier-red-light-shade'),
  },
  [EAnomalyStatisticCategory.Repair]: {
    [EAnomalyRepairmentStatus.Delayed]: documentStyle.getPropertyValue('--white'),
    [EAnomalyRepairmentStatus.ToRepair]: documentStyle.getPropertyValue('--red'),
    [EAnomalyRepairmentStatus.Repaired]: documentStyle.getPropertyValue('--green'),
  },
};

export const anomaliesChartsColorWithGreyShade: Record<string, any> = {
  [EAnomalyStatisticCategory.Severity]: {
    [EAnomalyStatus.False]: documentStyle.getPropertyValue('--outflier-blue-grey-shade'),
    [EAnomalyStatus.Minor]: documentStyle.getPropertyValue('--white-grey'),
    [EAnomalyStatus.ToReview]: documentStyle.getPropertyValue('--outflier-yellow-grey'),
    [EAnomalyStatus.Major]: documentStyle.getPropertyValue('--red-grey'),
  },
  [EAnomalyStatisticCategory.Type]: {
    [EAnomalyMode.Visual]: documentStyle.getPropertyValue('--outflier-blue-cyan-grey-shade'),
    [EAnomalyMode.Mixed]: documentStyle.getPropertyValue('--outflier-blue-sky-grey-shade'),
    [EAnomalyMode.String]: documentStyle.getPropertyValue('--outflier-yellow-light-grey-shade'),
    [EAnomalyMode.Thermal]: documentStyle.getPropertyValue('--outflier-red-light-grey-shade'),
  },
  [EAnomalyStatisticCategory.Repair]: {
    [EAnomalyRepairmentStatus.Delayed]: documentStyle.getPropertyValue('--white-grey'),
    [EAnomalyRepairmentStatus.ToRepair]: documentStyle.getPropertyValue('--red-grey'),
    [EAnomalyRepairmentStatus.Repaired]: documentStyle.getPropertyValue('--green-grey'),
  },
};

export const anomaliesStatusesColor: Record<string, string> = {
  [EAnomalyStatus.ToReview]: documentStyle.getPropertyValue('--outflier-yellow'),
  [EAnomalyStatus.False]: documentStyle.getPropertyValue('--green'),
  [EAnomalyStatus.Minor]: documentStyle.getPropertyValue('--white'),
  [EAnomalyStatus.Major]: documentStyle.getPropertyValue('--red'),
};

export const anomaliesStatusesColorWithGreyShade: Record<string, string> = {
  [EAnomalyStatus.ToReview]: documentStyle.getPropertyValue('--outflier-yellow-grey'),
  [EAnomalyStatus.False]: documentStyle.getPropertyValue('--green-grey'),
  [EAnomalyStatus.Minor]: documentStyle.getPropertyValue('--white-grey'),
  [EAnomalyStatus.Major]: documentStyle.getPropertyValue('--red-grey'),
};

export const anomaliesContrastTextColor: Record<string, string> = {
  [EAnomalyStatus.ToReview]: documentStyle.getPropertyValue('--main-bg'),
  [EAnomalyStatus.False]: documentStyle.getPropertyValue('--white'),
  [EAnomalyStatus.Minor]: documentStyle.getPropertyValue('--main-bg'),
  [EAnomalyStatus.Major]: documentStyle.getPropertyValue('--white'),
};

export const anomalyMode: Record<string, string> = {
  [EAnomalyMode.Visual]: i18n.t('typesAnomalies.visual'),
  [EAnomalyMode.Mixed]: i18n.t('typesAnomalies.mixed'),
  [EAnomalyMode.Thermal]: i18n.t('typesAnomalies.thermal'),
  [EAnomalyMode.Manual]: i18n.t('typesAnomalies.manual'),
  [EAnomalyMode.String]: i18n.t('typesAnomalies.string'),
};

export const anomaliesFrameIcon: Record<string, string> = {
  [EAnomalyStatus.ToReview]: frameAngleYellow,
  [EAnomalyStatus.False]: frameAngleGreen,
  [EAnomalyStatus.Minor]: frameAngleWhite,
  [EAnomalyStatus.Major]: frameAngleRed,
};

export const nonActiveAnomalyMaskColor = documentStyle.getPropertyValue('--hard-black');
