export enum EFlightPathSources {
  FlightPath = 'flight-path',
  Batteries = 'batteries',
}

export enum ESampleSources {
  Samples = 'samples',
  Frustum = 'frustum',
}

export enum ECreateSiteSources {
  Obstacles = 'obstacles',
  Perimeter = 'perimeter',
  PerimeterDisplay = 'perimeter-display',
  NoFlyZones = 'no-fly-zones',
  NoFlyZoneIcons = 'no-fly-zone-icons',
  OverviewPlan = 'overview-plan',
  InspectionPlan = 'inspection-plan',
  GeneratedPrograms = 'generated-programs',
}

export enum EAnomalySources {
  Anomalies = 'anomalies',
  AnomaliesPerimeter = 'anomalies-perimeter',
}

export enum EZonesSources {
  Zones = 'zones',
}

export enum ELocationSources {
  LocationsPoints = 'locations-points',
}

export enum ESolarPanelsSources {
  SolarPanels = 'solar-panels',
}
